import {make} from 'vuex-pathify';
import {each, find} from "lodash";
import {getLStorage, setLStorage} from "@/helpers/storage.helper";

const state = {
  list: [],
  pickerVisible: false,
  loading: false
};


const getters = {

  ...make.getters(state),

  getServiceById: (state) => (id) => {
    return find(state.list, {id: id});
  },
};

const mutations = make.mutations(state);

const actions = {

  async load({commit}) {
    commit('loading', true);

    let list = getLStorage('services', 24);

    if (!list) {
      list = await api.url(`/services`).get().json();
      setLStorage('services', list, 24);
    }

    commit('list', list);
    commit('loading', false);
  },

  bind({getters}, list) {
    each(list, (item) => {
      item.addon = getters.getServiceById(item.addon_id);
    });
    return list;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

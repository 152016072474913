<script>
import IconQuickbooks from "@/components/common/icon-quickbooks.vue";

export default {
  name: "confirms",
  components: {IconQuickbooks}
}
</script>

<template>
  <ConfirmDialog class="w-4"></ConfirmDialog>
  <ConfirmDialog group="quickbooks">
    <template #container="{message, acceptCallback, rejectCallback}">
      <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
        <div class="border-circle inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8" style="background-color:#2ca01c">
          <icon-quickbooks color="#ffffff" size="75"/>
        </div>
        <h2 class="text-500 mb-2 mt-4">{{ message.header }}</h2>
        <p class="mb-0">{{ message.message }}</p>
        <div class="flex align-items-center gap-2 mt-4">
          <Button label="Cancel" outlined @click="rejectCallback" class="w-8rem" severity="secondary"></Button>
          <Button :label="message.confirmLabel" @click="acceptCallback" class="w-8rem" style="background-color:#2ca01c; border-color: #2ca01c; color:#ffffff"></Button>
        </div>
      </div>
    </template>
  </ConfirmDialog>
</template>

<style scoped lang="scss">

</style>
<script>
import EmployeeQuickView from "@/components/employees/quick-view/employee-quick-view.vue";
import CustomerQuickView from "@/components/customers/customer-quick-view.vue";
import EventQuickView from "@/components/events/quick-view/event-quick-view.vue";

export default {
  name: "quick-views",
  components: {EventQuickView, CustomerQuickView, EmployeeQuickView}
}
</script>

<template>
  <event-quick-view/>
  <customer-quick-view/>
  <employee-quick-view/>
</template>

<style scoped lang="scss">

</style>

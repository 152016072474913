<template>
  <TabView v-model:activeIndex="activeTabIndex" @tab-change="handleTabChange">
    <TabPanel header="New">
      <keep-alive>
        <tab-inquiry v-if="isActive('new')"/>
      </keep-alive>
    </TabPanel>
    <TabPanel header="Booking">
      <keep-alive>
        <tab-booking v-if="isActive('booking')"/>
      </keep-alive>
    </TabPanel>
    <TabPanel header="Complete">
      <keep-alive>
        <tab-ready v-if="isActive('complete')"/>
      </keep-alive>
    </TabPanel>
    <TabPanel header="Serviced">
      <keep-alive>
        <tab-done v-if="isActive('serviced')"/>
      </keep-alive>
    </TabPanel>
    <TabPanel header="Cancelled">
      <keep-alive>
        <tab-cancelled v-if="isActive('cancelled')"/>
      </keep-alive>
    </TabPanel>
    <TabPanel header="Credit">
      <keep-alive>
        <tab-credit v-if="isActive('credit')"/>
      </keep-alive>
    </TabPanel>
    <TabPanel header="All">
      <keep-alive>
        <tab-all v-if="isActive('all')"/>
      </keep-alive>
    </TabPanel>
  </TabView>
</template>

<script>

import TabInquiry from "./tab-inquiry.vue";
import TabBooking from "./tab-booking.vue";
import TabReady from "./tab-ready.vue";
import TabDone from "./tab-done.vue";
import TabCancelled from "./tab-cancelled.vue";
import TabStaff from "./tab-staff.vue";
import TabAll from "@/components/events/list/tab-all.vue";
import EmployeeStaffingModal from "@/components/employees/employee-staffing-modal.vue";
import {getStatusLabel} from "@/helpers/status.helper";
import TabCredit from "@/components/events/list/tab-credit.vue";

export default {
  name: "EventsList",
  components: {
    TabCredit,
    EmployeeStaffingModal, TabAll, TabStaff, TabCancelled, TabDone, TabReady, TabBooking, TabInquiry},
  data() {
    return {
      validTabs: ['new', 'booking', 'complete', 'serviced', 'cancelled', 'credit', 'all'],
      activeTabIndex: 0,
    }
  },
  async mounted() {
    await this.loadParties();
    this.$nextTick(() => {
      this.setTab();
    });
  },
  computed: {
    activeTab() {
      return this.$route.params.tab || this.getDefaultTab();
    },
    token() {
      return store.get('Party@token');
    },
    party() {
      return store.get('Parties/Party/current')
    }
  },
  watch: {
    '$route.params.tab'(newTab, oldTab) {
      if(newTab === undefined) {
        newTab = this.getDefaultTab();
      }
      else if (newTab !== oldTab) {
        this.setTab(newTab);
      }
    }
  },
  methods: {
    getDefaultTab() {
      if(this.party)
        return getStatusLabel(this.party.status);
      else return "new";
    },
    async loadParties() {
      await store.dispatch("Parties/loadParties")
    },
    edit() {
      store.commit('Parties/Party/edit', true);
    },
    isActive(tab) {
      return this.activeTabIndex === this.validTabs.indexOf(tab);
    },
    setTab() {
      this.activeTabIndex = this.validTabs.indexOf(this.activeTab)
    },
    handleTabChange(e) {
      const newTab = this.validTabs[e.index];
      if (newTab !== this.activeTab) {

        this.$router.push({ name: 'Events', params: { tab: newTab } }).catch(err => {
          if (err.name !== 'NavigationDuplicated') {
            throw err;
          }
        });

      }
    },
  },
};
</script>

<style scoped>
/* You can add your styles here */
</style>

<template>
  <div class="col-12" v-if="transactions.length">
    <h2 class="text-700">Banking</h2>
    <quick-transaction-table :transactions="transactions" :is-loading="isLoading"/>
  </div>
</template>

<script>
import QuickTransactionTable from "@/components/banking/quick-transaction-table.vue";

export default {
  name: "event-quickview-transaction-table",
  components: {QuickTransactionTable},
  computed: {
    isLoading() {
      return store.get('Parties/Party/loading');
    },
    party() {
      return store.get('Parties/Party/current')
    },
    transactions() {
      return this.party.transactions;
    },
  },
}
</script>

<style scoped lang="scss">

</style>

import {make} from 'vuex-pathify';
import {each, has} from "lodash";
import api from "@/config.wretch";

const state = {

  id: null,
  first: '',
  last: '',
  company: '',
  email: '',
  phone: '',
  quickbooks_display_name: '',
  check_name: '',
  notes: '',

  address: '',
  city: '',
  state: 'Ca',
  zip: '',

  cardOnFile:false,
  loading:false,

  parties: [],
  items: [],
  transactions: [],
  loadingParties: false,
};

const restate = {...state};

const getters = {
  ...make.getters(state),
};

const mutations = {
  ...make.mutations(state),
};

const actions = {

  async set({commit, dispatch}, current) {
    await commit('loading', true);
    each(current, (value, key) => {
      if (has(state, key)) {
        commit(key, value);
      }
    });

    if(current === null){
      dispatch('set',{...restate});
    }
    commit('loading', false);
  },

  async save({commit, dispatch, state }) {
    commit('loading', true);
    const response =  await api.url(`/customers/${state.id}`).put(state).res();

    if (response.status !== 200) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to save customer');
    }

    await dispatch('set', response);
    commit('loading', false);
  },

  async create({ commit, state }, customer) {
    commit('loading', true);
    try {
      let response = await api.url(`/customers`).post(customer?customer:state).json();
      store.commit('Customers/current', response);
      return response;
    } finally {
      commit('loading', false);
    }
  },

  async loadParties({commit, state, dispatch}) {
    commit('loadingParties', true);
    let parties = await api.url(`/events/customer/${state.id}`).get().json();
    await commit('parties', parties);
    await dispatch('getItemsFromParties');
    await dispatch('getTransactionsFromParties');
    commit('loadingParties', false);
  },

  async getItemsFromParties({commit, state}) {
    let items = state.parties.flatMap(party =>
      party.scheduleables.filter(scheduleable => scheduleable.visible === 1)
    );
    await commit('items', items);
  },

  async getTransactionsFromParties({commit, state}) {
    let transactions = state.parties.flatMap(party =>
      party.transactions
    );
    await commit('transactions', transactions);
  },

  async reset({dispatch}) {
    dispatch('set', null);
  },

  async reload({dispatch}){
    let current = await store.get('Customers/current');
    await dispatch('set', current);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

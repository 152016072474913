<template>
  <div v-if="!party.quickbooks_id">
    <h3>Payment Association</h3>
    <p>A Quickbooks Invoice is required to associate a payment with a party. Please create an invoice using
      <router-link :to="`/event/${party.token}/edit/quickbooks`">Edit Party</router-link>
    </p>
  </div>
  <div v-else>
    <div>
      <h3>Add Payment Association</h3>

      <InputGroup>
        <InputText v-model="search" placeholder="Search Quickbooks Payment ID" @keyup.enter="searchPayment"/>
        <Button icon="pi pi-times" class="bg-primary-reverse" @click="restart"/>
        <Button label="Find Payment" severity="success" @click="searchPayment" :disabled="search === ''"/>
      </InputGroup>
    </div>

    <section v-if="loading" class="flex justify-content-center align-content-center h-30rem">
      <progress-spinner class="h-full"/>
    </section>
    <section v-else class="fadein animation-duration-500">

      <Card v-if="search && payment" class="my-3">
        <template #content>
          <div class="flex justify-content-between">
            <div class="flex align-self-center">
              <icon-quickbooks size="36"/>
              <h2 class="px-2 my-1 text-500">Payment Details</h2>
            </div>
            <div>
              <div>Date: {{ formatDateTime(payment.MetaData.CreateTime) }}</div>
              <div>Reference ID: {{ payment.PaymentRefNum }}</div>
            </div>
          </div>
          <div class="flex justify-content-between pt-3">
            <div>
              <h4 class="mb-0 text-500">Customer: {{ customer.DisplayName }}</h4>
              <div>
                <a v-if="payment.Id" @click="goToQuickbooksPayment(payment.Id)" target="_blank" class="text-primary underline text-sm cursor-pointer pr-3">View Payment</a>
                <a v-if="invoiceId" @click="goToQuickbooksInvoice(invoiceId)" target="_blank" class="text-primary underline text-sm cursor-pointer pr-3">View Invoice</a>
                <span v-if="!invoiceId" class="pr-3">no invoice</span>
                <a v-if="transId && !isCheck" @click="goToAuthorizeTransaction(transId)" target="_blank" class="text-primary underline text-sm cursor-pointer pr-3">View Transaction</a>
              </div>
            </div>
            <div>
              <h4 class="mb-0 text-500">Amount: ${{ payment.TotalAmt }}</h4>
              <div>Type: {{ payment.PaymentType }}</div>
            </div>
          </div>
        </template>
      </Card>
      <p v-else>
        <span v-if="loading!==null">No Quickbooks Payments found.</span>
      </p>

      <div v-if="hasLinkedInvoice && !sameInvoice">
        <Message severity="error">This payment associated with another <span @click="goToQuickbooksInvoice(invoiceId)" class="text-primary underline cursor-pointer">invoice</span>. To associate a payment with this
          party, please remove the existing association in quickbooks.
        </Message>
      </div>
      <div v-else-if="hasTransaction">
        <Message severity="error">This payment is already associated with this Party.</Message>
      </div>
      <div v-else>
        <div v-if="payment" class="my-3 justify-content-end flex">
          <Button icon="pi pi-save" label="Associate with Party" rounded @click="save()"/>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {formatMoney} from "accounting-js";
import {formatDateTime} from "@/helpers/date.helper";
import IconQuickbooks from "@/components/common/icon-quickbooks.vue";
import {find} from "lodash";
import {goToAuthorizeTransaction, goToQuickbooksInvoice, goToQuickbooksPayment} from "@/helpers/link.helper";

export default {
  name: "quickbooks-payment-association",
  components: {IconQuickbooks},
  emits: ['success'],
  data() {
    return {
      loading: null,

      search: '',

      paymentId: '',
      payment: null,
      customer: null,
      invoiceId: '',

      transId: '',
      transaction: null
    };
  },
  computed: {
    party() {
      return store.get('Parties/Party/current');
    },
    hasTransaction(){
      return find(this.party.transactions, {quickbooks_id: this.paymentId});
    },
    sameInvoice() {
      return this.invoiceId === this.party.quickbooks_id;
    },
    hasLinkedInvoice() {
      return this.invoiceId;
    },
    isCheck() {
      return this.payment?.PaymentType === 'Check';
    }
  },
  methods: {
    formatDateTime,
    formatMoney,

    goToQuickbooksInvoice,
    goToQuickbooksPayment,
    goToAuthorizeTransaction,

    restart() {
      this.payment = null;
      this.customer = null;
      this.transaction = null;
      this.transId = '';
      this.paymentId = '';
      this.loading = null;
    },

    async searchPayment() {
      this.restart();

      this.loading = true;
      this.paymentId = this.search;
      await this.getPayment();
      this.loading = false;
    },

    async getPayment() {
      try {
        let {payment, customer} = await api.url(`/quickbooks/payment/${this.paymentId}`).get().json();
        this.payment = payment;
        this.customer = customer;

        if (payment){
          this.invoiceId = payment.Line?.LinkedTxn?.TxnId;

          if (this.payment.PaymentRefNum)
            this.transId = this.payment.PaymentRefNum;

          if(this.payment.PaymentType !== 'Check')
            await this.getTransaction();
        }
      } catch (error) {
        this.$toast.add({severity: 'error', summary: 'Error', detail: error.message, life: 5000});
      }
    },

    async getTransaction() {
      try {
        this.transaction = await api.url(`/payments/${this.transId}`).get().json();

        if (this.transaction.quickbooks_id) {
          this.paymentId = this.transaction.quickbooks_id;
        }
      } catch (e) {
        this.$toast.add({severity: 'error', summary: 'Authorize.net Error', detail: e.message, life: 5000});
      }
    },

    async associateTransaction() {
      this.loading = true;
      try {

          await api.url(`/payments/associate`).post({transId: this.transId, token: this.party.token}).res();
          let payment = await api.url(`/quickbooks/payment/associate/${this.party.token}/${this.paymentId}/${this.transId}`).put();

          this.$toast.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Transaction associated successfully.',
            life: 5000
          });

          store.dispatch('Interactions/create', `Payment Associated: ${formatMoney(this.payment.TotalAmt)} | ${this.payment.PaymentType} | Transaction ID:${this.payment.PaymentRefNum}`);
          this.restart();
          this.search = '';
          this.loading = false;
          return payment;

      } catch (error) {
        this.$toast.add({severity: 'error', summary: 'Error', detail: error.message, life: 5000});
        this.loading = false;
      }

    },

    getCheck(){
      return {
        'transId': this.paymentId + 'x' + this.payment.PaymentRefNum,
        'batch_id':null,
        'submitTimeUTC': this.payment.MetaData.CreateTime,
        'submitTimeLocal': this.payment.MetaData.CreateTime,
        'transactionStatus': 'settledSuccessfully',
        'invoice': this.party.token,
        'firstName':this.customer.GivenName,
        'lastName':this.customer.FamilyName,
        'amount':this.payment.TotalAmt,
        'accountType':this.payment.PaymentType,
        'quickbooks_id':this.paymentId,
      };
    },

    async save() {
      let payment = null;

      if(this.isCheck){
        payment = store.dispatch('Banking/addQuickbooksCheckPayment', this.getCheck());
      }else{
       if(!this.transaction){
         this.$toast.add({severity: 'error', summary: 'Error', detail: 'Something went wrong there is no transaction', life: 5000});
       }else {
         payment = await this.associateTransaction();
       }
      }

      if(payment){
        console.log('payment', payment);
        this.$emit('success');
      }
    }
  },
}
</script>


<style scoped lang="scss">

</style>

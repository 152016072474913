<template>
  <div class="flex align-items-start flex-column lg:flex-row lg:justify-content-between">
    <div class="flex align-items-center flex-column md:flex-row">
      <div>
        <Avatar icon="pi pi-user" size="xl" shape="circle" class="mr-2" v-if="!customer.quickbooks_id"/>
        <icon-quickbooks class="mr-2" size="36" v-else @click="goToQuickbooksCustomer" title="Click to see quickbooks profile"/>
      </div>
      <div>
        <h2 class="m-0 p-0 line-height-3"><span v-if="customer.company"> {{customer.company}}: </span>{{ customer.first }} {{ customer.last }}</h2>
        <div class="flex align-items-center flex-wrap text-sm">
          <div class="mr-5">
            <div class="text-primary cursor-pointer hover:underline" @click="phoneCustomer">{{ customer.phone }}</div>
          </div>
          <div>
            <div class="text-primary cursor-pointer hover:underline" @click="emailCustomer">{{ customer.email }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import IconQuickbooks from "@/components/common/icon-quickbooks.vue"
import config from "@/config";
export default {
  name: 'customer-header',
  components: {IconQuickbooks},
  props: {
    customer: {
      type: Object,
      required: true,
    }
  },
  methods: {
    phoneCustomer() {
      window.location.href = `tel:${this.customer.phone}`;
    },
    emailCustomer(){
      window.open(`mailto:${this.customer.email}`, '_blank');
    },
    goToQuickbooksCustomer(){
      window.open(`${config.quickbooksUrl}/app/customerdetail?nameId=${this.customer.quickbooks_id}`, '_blank');
    },
  },
}
</script>

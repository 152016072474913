<template>

  <Dialog v-model:visible="visible" modal class="w-3 h-auto surface-overlay" position="top"
          :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
          @dblclick.stop>
    <template #container>
      <section class="p-5">

        <div class="flex justify-content-between">
          <h2 class="text-600 m-1"><i class="pi pi-bookmark"></i> Staff Note</h2>
          <div>
            <Button icon="pi pi-save" rounded @click="save" size="small" text severity="secondary"/>
            <Button type="button" icon="pi pi-times" class="ml-2 align-self-start" size="small" text rounded @click="close" severity="secondary"></Button>
          </div>
        </div>

        <div class="my-2">
          <Editor id="note" v-model="note" editorStyle="height: 180px"></Editor>
        </div>
      </section>
    </template>
  </Dialog>
</template>

<script>
import {sync} from 'vuex-pathify';
export default {
  name: "notes-modal",
  data() {
    return {
      note: ''
    }
  },
  watch: {
    selectedRow(val) {
        this.note = val ? val.notes : val;
    }
  },
  computed: {
    visible: sync('Scheduleables/showNotes', null, false),
    selectedRow(){
      return store.get('Scheduleables/selectedRow');
    }
  },
  methods: {
    close() {
      this.visible = false;
    },
    save(){
      if(this.note === '') this.note = null;
      store.dispatch('Scheduleables/addNote', this.note)
      this.close();
    },
  },
}
</script>

<style scoped lang="scss">

</style>
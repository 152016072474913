<template>
  <div class="flex align-items-start flex-column lg:flex-row lg:justify-content-between">
    <div class="flex align-items-start flex-column md:flex-row">
      <div class="relative">
            <span class="absolute right-0 bottom-0">
                <i class="pi pi-star-fill pr-5 text-4xl text-pink-500" v-if="isPrimary" title="Primary In House"></i>
                <i class="pi pi-star-fill pr-5 text-4xl text-400" v-if="isSub" title="Sub In House"></i>
                <i class="pi pi-truck bg-white pr-3 mb-0 text-4xl text-400 vendor" v-if="isVendor" title="Vendor"></i>
            </span>
        <img :src="img" class="mr-5 mb-3 lg:mb-0 border-circle border-6 border-400 img w-6rem h-6rem inline-block" :alt="employee.fullName" v-if="img"/>
      </div>
      <div>
        <h2 class="mt-0">{{ employee.fullName }}</h2>
        <div class="flex align-items-center flex-wrap text-sm">
          <div class="mr-5">
            <span class="font-bold text-500">Since</span>
            <div class="text-700 mt-2">{{ employeeDuration }}</div>
          </div>
          <div class="mr-5">
            <span class="font-bold text-500">Gigs</span>
            <div class="text-700 mt-2">{{ gigCount }}</div>
          </div>
          <div class="mr-5">
            <span class="font-bold text-500">Last Gig</span>
            <div class="text-700 mt-2">{{ formatDate(lastPartyDate) }}</div>
          </div>
          <div class="mr-5">
            <span class="font-bold text-500">Earned</span>
            <div class="text-700 mt-2">{{ formatMoney(totalEarned) }}</div>
          </div>
          <div class="" v-if="calendar">
            <span class="font-bold text-500">Calendar</span>
            <div class="mt-2 text-700 hover:text-primary cursor-pointer" @click="copyICal">
              <i class="pi pi-calendar"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {formatDate} from "@/helpers/date.helper";
import {getEmployeeImage} from "@/helpers/images.helper";
import {orderBy, sumBy} from "lodash";
import dayjs from "dayjs";
import {formatMoney} from "accounting-js";
import Config from "@/config";

export default {
  name: "employee-quick-view-profile",

  computed: {

    employee() {
      return store.get('Employees/current');
    },
    isPrimary() {
      return this.employee.type === 'primary';
    },
    isSub() {
      return this.employee.type === 'sub';
    },
    isVendor() {
      return this.employee.type === 'vendor';
    },
    scheduleables() {
      return this.employee.scheduleables;
    },
    gigCount(){
      return this.scheduleables?.length || 0;
    },
    calendar() {
      return this.employee.calendar_id ? `${Config.domain}/calendar/staff/ical/${this.employee.calendar_id}` : null;
    },
    img() {
      return getEmployeeImage(this.employee);
    },
    employeeSince() {
      if (this.gigCount > 0) {
        const sortedParties = orderBy(this.scheduleables, ['start_time'], ['desc']);
        return sortedParties[0].date;
      }
      return 'N/A';
    },
    employeeDuration() {
      const now = dayjs();
      const createdAt = dayjs(this.employeeSince);
      const years = now.diff(createdAt, 'year');
      const months = now.diff(createdAt, 'month');

      if (years >= 1) {
        return `${years} ${years === 1 ? 'yr' : 'yrs'}`;
      } else {
        return `${Math.max(months, 1)} ${months === 1 ? 'mo' : 'mos'}`;
      }
    },

    lastPartyDate() {
      if (this.gigCount > 0) {
        const sortedParties = orderBy(this.scheduleables, ['start_time'], ['asc']);
        return sortedParties[0].date;
      }
      return 'N/A';
    },

    totalEarned() {
      return sumBy(this.scheduleables, scheduleable => {
        const cost = parseFloat(scheduleable.cost);
        return isNaN(cost) ? 0 : cost;
      });
    },
  },

  methods: {
    ...{formatDate, formatMoney},
    async copyICal() {
      try{
        await navigator.clipboard.writeText(this.calendar);
        this.$toast.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Calendar copied to clipboard, paste it in your calendar app',
          life: 3000
        });
      } catch(e) {
        console.error(e);
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Calendar failed to copy to clipboard',
          life: 3000
        });
      }
    },
  }
}
</script>

<style scoped lang="scss">

.img {
  width: 90px;
  height: 90px
}

.vendor {
  line-height: 26px;
}
</style>

<template>
  <div v-if="party">

    <h2 v-if="party.for_whom" class="m-0 hover:text-primary hover:underline cursor-pointer" @click="goToEvent" title="Go To Event">
      {{ capitalize(party.for_whom) }}'s {{ capitalize(party.occasion) || 'Party' }} in {{ party.city }}
    </h2>
    <h2 v-else class="m-0 hover:text-primary hover:underline cursor-pointer" @click="goToEvent" title="Go To Event">
      {{ customer.full_name }}'s {{ capitalize(party.occasion) || 'Party' }} in {{ party.city }}
    </h2>

    <p class="my-1 cursor-pointer hover:text-primary hover:underline text-sm inline-block" @click="openDayCalendar()">{{ date }} @
      {{ startTime }} - {{ endTime }} ({{ party.duration }}hrs)
    </p>

    <div class="mt-2 font-bold text-sm" v-if="!quickHeader">

      <div class="inline-block mb-3">
        <i class="pi pi-user mr-2 mb-2" v-if="!customer.quickbooks_id"></i>
        <icon-quickbooks class="-mb-2 mr-2 cursor-pointer" v-else @click="goToQuickbooksCustomer" title="Click to see quickbooks profile"/>
        <span @click="openCustomer" class="cursor-pointer hover:text-primary hover:underline"><span v-if="customer.company" >{{ customer.company }}: </span>{{ customer.full_name }}</span>
      </div>

      <div v-if="party.guests" class="inline-block"><span class="mx-2">|</span> Guests: {{ party.guests }}</div>

      <div v-if="party.party_notes" title="click to show notes" class="cursor-pointer inline-block">
        <span class="mx-2">|</span> <i class="pi pi-bookmark" @click="showNotes"></i>
        <OverlayPanel ref="op" class="w-3">
          <h4 class="mt-0 mb-2">Party Note</h4>
          {{ party.party_notes }}
        </OverlayPanel>
      </div>

      <div class="inline-block">
        <Chip v-if="isArchived" size="small" label="archived"  class="p-1 text-sm m-0 px-2 ml-2 bg-primary text-white" :pt="{label:{class:'m-0'}}" @click="unArchive"></Chip>

        <Chip v-if="balance > 0" size="small" :label="`balance: ${formatMoney(balance)}`" class="p-1 text-sm m-0 px-2 ml-2 bg-primary" :pt="{label:{class:'m-0'}}"></Chip>
        <Chip v-if="balance === 0" size="small" label="PAID" class="p-1 text-sm m-0 px-2 ml-2 bg-green-300 text-white" :pt="{label:{class:'m-0'}}"></Chip>
        <Chip v-if="balance < 0" size="small" :label="`credit: ${formatMoney(Math.abs(balance))}`" class="p-1 text-sm m-0 px-2 ml-2 bg-blue-300 text-white" :pt="{label:{class:'m-0'}}"></Chip>


        <Chip v-if="inQuickbooks"
              size="small" label="quickbooks"
              icon="pi pi-check"
              class="p-1 text-sm m-0 px-2 ml-2 cursor-pointer bg-green-300 text-white"
              :pt="{label:{class:'m-0'}}" @click="goToQuickbooksInvoice(party.quickbooks_id)"
              :title="`Click to view Quickbooks Invoice`"></Chip>
        <Chip v-if="!inQuickbooks"
              size="small" label="quickbooks"
              icon="pi pi-times"
              class="p-1 text-sm m-0 px-2 ml-2 cursor-pointer"
              :pt="{label:{class:'m-0'}}" @click="goToQuickbooksTab"
              :title="`Click to create Quickbooks Invoice`"></Chip>

        <Chip v-if="getStatusLabel(status) !== ''" size="small" :label="getStatusLabel(status)" class="p-1 text-sm px-2 ml-2 cursor-pointer" :class="{'bg-blue-300 text-white': status === 'credit' }" :pt="{label:{class:'m-0'}}" @click="goToStatusTab(status)" :title="`Click to show all ${status} events`"></Chip>

      </div>
    </div>
  </div>
</template>

<script>
import IconQuickbooks from "@/components/common/icon-quickbooks.vue";
import dayjs from "dayjs";
import {capitalize} from "lodash";
import {formatMoney} from "accounting-js";
import {getStatusLabel} from "@/helpers/status.helper";
import config from "@/config";

export default {
  name: "event-header-content",
  props: {
    quickHeader: {
      type: Boolean,
      default: false
    }
  },
  components: {IconQuickbooks},
  computed: {
    party() {
      return store.get('Parties/Party/current');
    },
    status() {
      return store.get('Parties/Party/status');
    },
    customer() {
      return this.party.customer;
    },
    date() {
      return dayjs(this.party.date).format("MM/DD/YYYY, ddd");
    },
    startTime() {
      return dayjs(this.party.date).format('h:mma');
    },
    endTime() {
      return dayjs(this.party.date).add(dayjs.duration(this.party.duration, 'hours')).format('h:mma')
    },
    total() {
      return store.get('Parties/Party/total');
    },
    balance() {
      return store.get('Parties/Party/balance');
    },
    isArchived(){
      return this.party.archived;
    },
    inQuickbooks() {
      return !!this.party?.quickbooks_id;
    },
  },
  methods: {
    ...{capitalize, formatMoney},

    openCustomer() {
      store.commit('Customers/current', this.customer);
      store.commit('Customers/view', true);
    },

    openDayCalendar() {
      store.commit('DayCalendar/showDay', true);
      store.commit('DayCalendar/currentDate', this.party.date);
    },

    showNotes(event) {
      this.$refs.op.toggle(event);
    },

    goToStatusTab(status){
      router.push('/events/'+getStatusLabel(status));
    },
    goToQuickbooksTab(){
      store.commit('Parties/Party/edit', true);
    },
    goToQuickbooksInvoice(id){
      window.open(`${config.quickbooksUrl}/app/invoice?txnId=${id}`, '_blank');
    },
    goToQuickbooksCustomer(){
      window.open(`${config.quickbooksUrl}/app/customerdetail?nameId=${this.customer.quickbooks_id}`, '_blank');
    },
    getStatusLabel,

    unArchive(){
        this.$confirm.require({
          header: 'Unarchive Event',
          message: `Are you sure you want to unarchive this event?`,

          accept: async () => {
            await store.dispatch('Parties/archive', [this.party.id]);

            this.$toast.add({severity: 'success', summary: 'Archive Successful', detail: 'The archived state has been toggled for the selected events.', life: 5000});
          }
        })
    },

    goToEvent(){
      router.push('/event/'+this.party.token);
    }
  }
}
</script>

<style scoped lang="scss">

</style>

import { make } from 'vuex-pathify';

const state = {
  list:[],
  loading:false,
  currentDate: new Date(),
  startDate: new Date(),
  endDate: new Date(),
  selectedEmployees: [],
  selectedStatus: [],
};

const getters = {
  ...make.getters(state),
  employees(state){
    return state.list.map(item => item.employee);
  },
};

const mutations = make.mutations(state);

const actions = {

  async load({ commit }) {
    commit('loading',true);
    let markers = await api.url('/logistics/all-future-parties').get().json();
    commit('list',markers);
    commit('loading',false);
  },
};

export default {
  namespaced:true,
  state,
  getters,
  actions,
  mutations,
};

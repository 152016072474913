<template>
        <DataTable
                :value="employees"
                paginator
                :rows="10"
                v-model:expandedRows="expandedRows"
                 v-model:filters="filters"
                :globalFilterFields="['fullName', 'phone']"
                showGridlines
                stripedRows
                removableSort
                sortField="fullName"
                dataKey="id"
                @row-click="onRowClick"
                size="small"
        >
            <template #header>
                <div class="flex justify-content-end">
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"/>
                        <InputText v-model="filters['global'].value" placeholder="Name or Phone"/>
                    </span>
                </div>
            </template>

            <template #empty>
                No events found.
            </template>

            <template #loading>
                Loading event data. Please wait.
            </template>

            <Column expander />
            <Column field="id">
                <template #body="slotprops">
                    <employee-avatar :id="slotprops.data.id" />
                </template>
            </Column>
            <Column field="fullName" header="Name" sortable></Column>
            <Column field="parties.length" header="Parties" sortable></Column>
            <Column field="phone" header="phone"></Column>
            <Column field="party.customer.full_name" header="Customer" sortable>
                <template #body="slotprops">
                    <Button icon="pi pi-check" rounded size="small" outlined aria-label="Filter" @click="editScheduleable(slotprops.data)" />
                </template>
            </Column>
            <template #expansion="slotProps">
                <DataTable :value="slotProps.data.parties"
                           sortField="start_time"
                           size="small">

                    <template #empty>
                        No events found.
                    </template>

                    <template #loading>
                        Loading event data. Please wait.
                    </template>
                    <Column field="start_time" header="Date">
                        <template #body="slotprops">
                            {{formatDate(slotprops.data.start_time)}}
                        </template>
                    </Column>
                    <Column field="start_time" header="Start" sortable>
                        <template #body="slotprops">
                            {{formatTime(slotprops.data.start_time)}}
                        </template>
                    </Column>
                    <Column field="end_time" header="End" sortable>
                        <template #body="slotprops">
                            {{formatTime(slotprops.data.end_time)}}
                        </template>
                    </Column>
                    <Column field="party.city" header="City" sortable></Column>
                    <Column field="party.customer.full_name" header="Customer" sortable></Column>
                </DataTable>
            </template>
        </DataTable>
</template>

<script>
import EmployeeAvatar from "@/components/employees/employee-avatar.vue";
import {FilterMatchMode} from "primevue/api";
import dayjs from "dayjs";

export default {
  name: "employee-picker-table",
  components: {EmployeeAvatar},
  data() {
    return {
      filters: {
        global: {value: null, matchMode: FilterMatchMode.CONTAINS},
      },
      expandedRows: []
    };
  },
  computed: {
    employees() {
      return store.get('Employees@employees');
    }
  },
  methods: {
    onRowClick() {},
    formatTime(date){
      return dayjs(date).format('h:mma')
    },
    formatDate(date){
      return dayjs(date).format('MM/DD/YYYY, ddd')
    },
    editScheduleable(data){
      store.dispatch('Parties/Party/editScheduleable', {employee_id:data.id}).then(()=>{
        store.commit("Employees/pickerVisible", false)
      });
    }
  },
};
</script>

<style scoped>
</style>

<template>
  <Card>
    <template #content>
      <div class="flex justify-content-between">
        <div class="flex align-self-center">
          <icon-quickbooks size="36"/>
          <h2 class="px-2 my-1 text-500">{{ customer.DisplayName }}</h2>
        </div>
        <Button icon="pi pi-save" rounded text @click="confirmAssociation" v-if="customer.Id && customer.Id !== current.quickbooks_id"/>
        <Button icon="pi pi-times" rounded text @click="unassociate()" v-else/>
      </div>
      <div>{{ customer.GivenName }} {{ customer.FamilyName }} | {{ customer.PrimaryEmailAddr?.Address }} |
        {{ phone }} | <a :href="`${config.quickbooksUrl}/app/customerdetail?nameId=${customer.Id}`" target="_blank">quickbooks profile</a>
      </div>
    </template>
  </Card>
</template>

<script>
import IconQuickbooks from "@/components/common/icon-quickbooks.vue";
import config from "@/config";
export default {
  name: "customer-quickbooks-item",
  props: {
    customer: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      config: config
    }
  },
  components: {IconQuickbooks},
  computed: {
    current() {
      return store.get('Customers/current');
    },
    party() {
      return store.get('Parties/Party/current');
    },
    phone(){
      return this.customer.PrimaryPhone?.FreeFormNumber || this.customer.Mobile?.FreeFormNumber || this.customer.AlternatePhone?.FreeFormNumber  || '-';
    }
  },
  methods: {
    confirmAssociation() {
      this.$confirm.require({
        message: 'Associate this QuickBooks user with the current user? QuickBooks data will overwrite existing data, except for name, email, and phone.',
        header: 'Overwrite with QuickBooks Data?',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Use QuickBooks Data',
        rejectLabel: 'Start from Scratch',
        iconType: 'warning',
        accept: async () => {
          await this.setCustomer();
          await store.dispatch('Customers/Customer/save');
          await this.associate();
        },
        reject: () => {
        }
      });
    },

    async setCustomer() {
      const customer = this.customer;
      await store.commit('Customers/Customer/company', customer?.CompanyName);
      await store.commit('Customers/Customer/quickbooks_display_name', customer.DisplayName);
      await store.commit('Customers/Customer/check_name', customer.PrintOnCheckName);

      let notes = store.get('Customers/Customer/notes') ?? '';
      let customerNotes = customer.Notes ?? '';

      let compiledNotes = (customerNotes || notes) ? (customerNotes + '\n' + notes) : null;

      await store.commit('Customers/Customer/notes', compiledNotes);

      const billAddr = customer.BillAddr || {};
      await store.commit('Customers/Customer/address', billAddr.Line1 || '');
      await store.commit('Customers/Customer/city', billAddr.City || '');
      await store.commit('Customers/Customer/state', billAddr.CountrySubDivisionCode || 'CA');
      await store.commit('Customers/Customer/zip', billAddr.PostalCode || '');
    },

    async associate() {
      await store.dispatch('Customers/associateQuickbooks', this.customer.Id);
      if(this.party)
        await store.dispatch('Parties/Party/load', this.party.token);
      store.dispatch('Customers/getEmployeeQBOProfile');
    },
    async unassociate() {
      await store.dispatch('Customers/unassociateQuickbooks');
      if(this.party)
        await store.dispatch('Parties/Party/load', this.party.token);
    }
  },
}
</script>

<style scoped lang="scss">

</style>

<template>
  <div v-if="paid">
    <h2>Cannot Edit Bill</h2>
    <p>Bill has been paid.</p>
  </div>


    <accordion id="invoice" :multiple="true" v-model:activeIndex="index" @tab-open="loadTab">
      <accordion-tab>
        <template #header>
          <div class="flex justify-content-between w-full">
            <div>
              <h2 class="p-0 m-0 mt-3 line-height-1">Gigs <span v-if="gigsCount" class="text-xs font-normal" title="n of total selected">({{gigsSelected}} of {{gigsCount}})</span></h2>
              <span class="text-xs font-normal p-0 m-0 line-height-1">Serviced</span>
            </div>
            <h2 class="text-right">{{ formatMoney(itemsTotal) }}</h2>
          </div>
        </template>

        <bills-gigs-table/>

      </accordion-tab>
      <accordion-tab>
        <template #header>
          <div class="flex justify-content-between w-full">
            <h2>Timesheets <span v-if="laborCount" class="text-xs font-normal" title="n of total selected">({{laborSelected}} of {{laborCount}})</span></h2>
            <h2 class="text-right">{{ formatMoney(laborTotal) }}</h2>
          </div>
        </template>

        <bills-labor-table/>
      </accordion-tab>
      <accordion-tab>
        <template #header>
          <div class="flex justify-content-between w-full">
            <h2>Expenses <span v-if="expensesCount" class="text-xs font-normal" title="n of total selected">({{expensesSelected}} of {{expensesCount}})</span></h2>
            <h2 class="text-right">{{ formatMoney(expensesTotal) }}</h2>
          </div>
        </template>

        <bills-expenses-table/>
      </accordion-tab>
      <accordion-tab >
        <template #header>
          <div class="flex justify-content-between w-full">
            <h2>Total</h2>
            <h2 class="text-right">{{ formatMoney(grandTotal) }}</h2>
          </div>
        </template>
      </accordion-tab>
    </accordion>
    <div class="w-full h-15rem"></div>

</template>

<script>
import { formatMoney } from "accounting-js";
import BillsGigsTable from "@/components/team/bills/bills-gigs-table.vue";
import BillsExpensesTable from "@/components/team/bills/bills-expenses-table.vue";
import BillsLaborTable from "@/components/team/bills/bills-labor-table.vue";
import {sync} from "vuex-pathify";
import NotesEditor from "@/components/common/notes-editor.vue";

export default {
  name: "bill-invoice",
  components: {NotesEditor, BillsLaborTable, BillsExpensesTable, BillsGigsTable},
  data() {
    return {
      index: [0,3],
      loaded: [false, false, false],
    }
  },
  computed: {

    paid(){
      return false; // Replace with actual logic to determine if the bill is paid
    },
    grandTotal() {
      return store.get("Bills/Bill/grandTotal"); // Replace with actual logic to calculate the total
    },
    itemsTotal() {
      return store.get("Bills/Bill/itemsTotal");
    },
    laborTotal() {
      return store.get("Bills/Bill/laborTotal");
    },
    expensesTotal() {
      return store.get("Bills/Bill/expensesTotal");
    },

    laborCount(){
      return store.get("Bills/Bill/laborCount");
    },
    laborSelected(){
      let items = store.get("Bills/Bill/labor");
      return items.length;
    },
    expensesCount(){
      return store.get("Bills/Bill/expensesCount");
    },
    expensesSelected(){
      let items = store.get("Bills/Bill/expenses");
      return items.length;
    },
    gigsCount(){
      return store.get("Bills/Bill/gigsCount");
    },
    gigsSelected(){
      let items = store.get("Bills/Bill/items");
      return items.length;
    },

    notes: sync("Bills/Bill/notes", null, false)
  },
  methods: {
    ...{formatMoney},

    loadTab(e) {
      this.loaded[e.index] = true;
    }
  },
};
</script>

<style lang="scss">
#invoice .p-accordion-header-link {
  padding: 0 1.25rem;
  border: none;
  border-radius: 0;
}

#invoice .p-accordion-content {
  padding: 0;
  border: none;
}

#invoice .p-datatable .p-datatable-tfoot > tr > td {
  background: none;
}
</style>

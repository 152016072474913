<template>
      <div class="fluid">
        <div v-if="character">
          <div class="grid">
            <div class="col-6">
              <label class="text-600 text-sm font-bold inline-block my-2">Name</label>
              <InputText v-model="character.name" placeholder="Name" class="full-width" @input="update" />
            </div>
            <div class="col-6">
              <label class="text-600 text-sm font-bold inline-block my-2">Alias</label>
              <InputText v-model="character.alias" placeholder="Alias" class="full-width" @input="update" />
            </div>
            <div class="col-12">
              <label class="text-600 text-sm font-bold inline-block my-2">URL</label>
              <InputText v-model="character.url" placeholder="URL" class="full-width" @input="update" />
              <div class="mt-3">
                <a :href="`/kids-party-characters/${character.url}`">{{`/kids-party-characters/${character.url}`}}</a>
              </div>
            </div>
            <div class="col-12">
              <label class="text-600 text-sm font-bold inline-block my-2">Search Terms</label>
              <InputText v-model="character.search" placeholder="Search Terms" class="full-width" @input="update" />
            </div>
            <div class="col-12">
              <label class="text-600 text-sm font-bold inline-block my-2">Description</label>
              <Textarea v-model="character.description" placeholder="Description" class="full-width" @input="update" />
            </div>
          </div>
          <div class="flex justify-content-between">
            <div class="flex align-content-center">
              <InputSwitch v-model="character.featured" label="Featured" class="yellow-switch" @input="update" />
              <div class="inline-block p-1 ml-2 text-600 text-sm font-bold">Featured</div>
            </div>
            <div class="flex align-content-center">
              <InputSwitch v-model="character.in_house" label="In House" class="blue-switch" @input="update" />
              <div class="inline-block p-1 ml-2 text-600 text-sm font-bold">In House</div>
            </div>
            <div class="flex align-content-center">
              <InputSwitch v-model="character.special_pricing" label="Show Rates" class="blue-switch" @input="update" />
              <div class="inline-block p-1 ml-2 text-600 text-sm font-bold">Special Pricing</div>
            </div>
          </div>
        </div>
      </div>
</template>

<script>
import { sync } from 'vuex-pathify'

export default {
  name: "CharacterProfileGeneral",
  computed: {
    character: sync('Characters/current')
  },
  methods: {
    update() {
      store.dispatch('Characters/update', this.character)
    }
  }
}
</script>

<style scoped>
.full-width {
    width: 100%;
}

.yellow-switch .p-switch .p-switch-slider {
    background-color: yellow;
}

.blue-switch .p-switch .p-switch-slider {
    background-color: blue;
}
</style>

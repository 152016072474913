<template>
  <TabPanel>
    <Card>
      <div class="grid">
        <div v-for="costume in items" :key="costume.id" class="col-12 md-4" @click="showDetails(costume)">
          <costume-card-image :costume="costume">
            <costume-image-context-menu :costume="costume"></costume-image-context-menu>
          </costume-card-image>
        </div>
      </div>
      <ImageUpload :label="'Select Costume Images'" @upload="uploadImages" ref="uploader"></ImageUpload>
    </Card>
    <costume-details-modal></costume-details-modal>
  </TabPanel>
</template>

<script>
export default {
  name: "CharacterProfileCostume",
  computed: {
    character() {
      return store.get('Characters@current');
    },
    items() {
      return this.character.costume;
    }
  },
  methods: {
    uploadImages(files) {
      this.$emit('upload', files);
      // Handling uploads and reflecting changes
      store.dispatch('Characters/Costumes/upload', {id: this.character.id, images: files})
        .then(() => {
          this.$emit('filesUploaded');
          this.$refs.uploader.clear();
        })
        .catch(() => {
          this.$emit('uploadError');
        });
    },
    showDetails(item) {
      // Implement logic to manage showing details
      store.commit('Characters/Costumes/current', item);
      store.commit('Characters/Costumes/showModal', true);
    }
  }
}
</script>

<style scoped>
.grid {
    display: flex;
    flex-wrap: wrap;
}

.col-12 {
    flex: 0 0 100%;
}

.md-4 {
    flex: 0 0 33.33333%;
}
</style>

<template>
  <div class="w-full h-full flex justify-content-center align-content-center">
    <section v-if="loading">
      <progress-spinner class="h-full"/>
    </section>
    <section v-else class="w-full">
      <DataTable class="fadein animation-duration-1000"
                 show-gridlines striped-rows size="small"
                 :value="labor"
                 :rows="10"
                 selection-mode="multiple"
                 v-model:selection="selected"
                 v-model:filters="filters"
                 :globalFilterFields="['category', 'description', 'total']"
                 scrollable
                 scrollHeight="60vh"
                 @rowDblclick="onRowDblClick">
        <template v-if="!isBillView" #header>
          <div class="flex justify-content-end">

            <InputGroup>
              <InputGroupAddon>
                <i class="pi pi-search"/>
              </InputGroupAddon>
              <InputText v-model="filters['global'].value"
                         placeholder="Search"
                         class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border appearance-none outline-none focus:border-primary w-full"/>
              <Button icon="pi pi-times" @click="clearFilter"/>
            </InputGroup>

            <Button :disabled="!selected.length"
                    @click="deleteSelected"
                    icon="pi pi-times"
                    class="ml-3 w-11rem"
                    size="small"
                    label="Delete"
                    type="button"
                    severity="secondary"
                    :badge="selected.length ? ''+selected.length : ''"/>
            <Button
              @click="add"
              icon="pi pi-plus"
              class="ml-3 w-11rem"
              size="small"
              label="New"
              type="button"/>
          </div>
        </template>
        <template #empty>
          No unpaid labor found. Other Unpaid items may be in other bills.
        </template>

        <Column selectionMode="multiple" class="w-2rem"></Column>
        <Column field="date" header="Date" :sortable="true">
          <template #body="{data}">
            {{ formatDate(data.date) }}
          </template>
        </Column>
        <Column field="category" header="Category" :sortable="true"></Column>
        <Column field="description" header="Description" :sortable="true"></Column>
        <Column field="total_work_duration" header="Paid Hrs" :sortable="true">
          <template #body="{data}">
            {{ getPaidHours(data) }}
          </template>
        </Column>
        <Column field="total" header="Total" :sortable="true">
          <template #body="{data}">
            {{ formatMoney(data.total) }}
          </template>
        </Column>
      </DataTable>
    </section>
  </div>
</template>

<script>
import {formatMoney} from "accounting-js";
import {formatDate} from '@/helpers/date.helper';
import {sync} from "vuex-pathify";
import {FilterMatchMode} from "primevue/api";
import Bus from "@/bus";

export default {
  name: "bills-labor-table",

  data() {
    return {
      labor: [],
      filters: {
        global: {value: null, matchMode: FilterMatchMode.CONTAINS},
      },
      loading: false,
    };
  },

  mounted() {
    this.load();
    Bus.$on('labor.update', this.load);
  },

  beforeUnmount() {
    Bus.$off('labor.update', this.load);
  },

  computed: {
    current() {
      return store.get('Bills/current');
    },

    bill_id() {
      return this.current?.id;
    },

    isBillView() {
      return !!this.current;
    },

    selected: sync('Bills/Bill/labor', null, false),

    employee() {
      return store.get('Employees/current');
    }
  },

  methods: {

    formatDate, formatMoney,

    async load() {
      this.loading = true;
      const allLabor = await store.dispatch('Labor/getUnpaidByEmployee', this.employee.id);
      if (this.bill_id) {
        this.labor = allLabor.filter(item => !item.bill_id || item.bill_id === this.bill_id);
        store.commit('Bills/Bill/laborCount', this.labor.length);
      } else {
        this.labor = allLabor;
      }

      this.loading = false;
    },

    getPaidHours(data) {
      return Number((data.total_work_duration - data.total_break_duration) / 60).toFixed(2)
    },

    clearFilter() {
      this.filters['global'].value = '';
    },

    async add() {
      store.commit('Labor/edit', true);
    },

    async onRowDblClick({data}) {
      store.commit('Labor/current', data);
      await store.dispatch('Employees/setEmployeeCurrent', data.employee_id);
      store.commit('Labor/edit', true);
    },

    async deleteSelected() {
      const count = this.selected.length;

      this.$confirm.require({
        header: 'Confirm Deletion',
        message: `Are you sure you want to delete ${count} selected Labor?`,
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          await store.commit('Labor/loading', true);

          const deletePromises = this.selected.map(labor =>
            store.dispatch('Labor/delete', labor.id)
          );
          await Promise.all(deletePromises);

          this.selected = [];
          await this.load();
          store.commit('Labor/loading', false);
        }
      });
    },
  },
}
</script>

<style lang="scss">
/* Removed the .disabled-row class as it's no longer needed */
</style>

<template>
  <section id="main-container">
    <main-menu-bar></main-menu-bar>
    <div class="flex flex-column surface-ground overflow-y-auto" style="height:87vh;">
      <div class="p-5 flex flex-column flex-auto surface-ground">
        <section>
          <events-list></events-list>
        </section>
      </div>
    </div>
    <quick-views/>
    <modals/>
    <toasts/>
    <confirms/>
  </section>
</template>

<script>
import EventsList from "./list/events-list.vue";
import {isEmpty} from "lodash";
import MainMenuBar from "@/components/main-menu/main-menu-bar.vue";
import Toasts from "@/components/common/toasts.vue";
import QuickViews from "@/components/common/quick-views.vue";
import Confirms from "@/components/common/confirms.vue";
import Modals from "@/components/common/modals.vue";

export default {
  name: "events",
  components: {Modals, Confirms, QuickViews, Toasts, MainMenuBar, EventsList},

  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.handleRouteChange(to);
    });
  },

  beforeRouteUpdate(to, from, next) {
    this.handleRouteChange(to);
    next();
  },

  methods: {

    async handleRouteChange(to) {
      if (to.name === 'EventDetail') {
        this.setQuickViewState(to);
      } else if (to.name === 'EventEdit') {
        await this.setQuickViewState(to);
        this.$store.commit('Parties/Party/edit', true);
      }
    },

    async setQuickViewState(to) {
      const token = to.params.token;
      let party = await store.dispatch('Parties/Party/load', token);
      if (!isEmpty(party))
        this.$store.commit('Parties/Party/view', true);
    },
  },
}
</script>

<style scoped lang="scss">
#main-container {
  max-width: 1600px;
  overflow: auto;
  margin: 0 auto;
}
</style>

<template>
        <DataTable
                :value="employees"
                paginator
                :rows="100"
                v-model:expandedRows="expandedRows"
                v-model:filters="filters"
                :globalFilterFields="['fullName', 'phone', 'customer.full_name']"
                showGridlines
                stripedRows
                removableSort
                sortField="fullName"
                dataKey="id"
                size="small"
                :loading="loading"
        >
            <template #header>
                <div class="flex justify-content-end">
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"/>
                        <InputText v-model="filters['global'].value" placeholder="Name or Phone"/>
                    </span>
                </div>
            </template>

            <template #empty>
                No events found.
            </template>

            <Column expander />
            <Column field="fullName" header="Name" sortable></Column>
            <Column field="scheduleables.length" header="Parties" sortable></Column>
            <Column field="phone" header="phone"></Column>
            <template #expansion="slotProps">
                <DataTable :value="slotProps.data.scheduleables"
                           sortField="start_time"
                           size="small"
                           @row-click="setQuickView"
                           @row-dblclick="editEvent"
                >

                    <template #empty>
                        No events found.
                    </template>

                    <template #loading>
                        Loading event data. Please wait.
                    </template>

                    <Column header="Details" sortable>
                      <template #body="{data}">
                        <div v-if="data.character">
                          {{ data.character.name }}
                        </div>
                        <div v-else-if="data.addon">
                          {{ data.addon.title }}
                        </div>
                        <div v-else-if="data.misc">
                          {{ data.misc }}
                        </div>
                      </template>
                    </Column>


                    <Column field="start_time" header="Date" sortable>
                        <template #body="{data}">
                            {{partyDateShort(data.start_time)}}
                        </template>
                    </Column>
                    <Column field="start_time" header="Start">
                        <template #body="{data}">
                            {{formatTime(data.start_time)}}
                        </template>
                    </Column>
                    <Column field="end_time" header="End">
                        <template #body="{data}">
                            {{formatTime(data.end_time)}}
                        </template>
                    </Column>
                    <Column field="party.city" header="City" sortable></Column>
                    <Column field="party.customer.full_name" header="Customer" sortable></Column>
                    <Column field="price" header="Price" sortable></Column>
                    <Column field="cost" header="Cost" sortable></Column>
                </DataTable>
            </template>
        </DataTable>
</template>

<script>
import EmployeeAvatar from "@/components/employees/employee-avatar.vue";
import {FilterMatchMode} from "primevue/api";
import {each,filter,chain} from "lodash";
import CharacterImage from "@/components/events/edit/invoice/character/character-image.vue";
import {formatTime} from "@/helpers/time.helper";
import {partyDateShort} from "@/helpers/date.helper";

export default {
  name: "employee-payup-table",
  components: {CharacterImage, EmployeeAvatar},
  props:['parties','loading'],
  data() {
    return {
      filters: {
        global: {value: null, matchMode: FilterMatchMode.CONTAINS},
      },
      expandedRows: []
    };
  },
  computed: {
    employees(){
      let list = [];

      each(this.parties, (party)=>{
        each(party.scheduleables, (scheduleable)=>{
          scheduleable.party = party;
          if(scheduleable.employee_id !== null)
            list.push(scheduleable);
        });
      });

      let employees = chain(list).map('employee').uniqBy('id').value();

      each(employees, (e)=>{
        e.scheduleables = filter(list, (s)=>{return s.employee_id === e.id})
      })

      return employees;
    }
  },
  methods: {
    formatTime,
    partyDateShort
  },
};
</script>

<style scoped>
</style>

<template>
  <section v-if="loading" class="flex justify-content-center align-content-center h-30rem">
    <progress-spinner class="h-full"/>
  </section>
  <section v-else class="fadein animation-duration-500">
    <div class="grid pb-4">

      <!-- Customer Tenure Highlight -->
      <div class="col-12 md:col-3 text-center">
        <div class="px-2 py-3 surface-card shadow-1">
          <i class="pi pi-clock text-3xl text-blue-600"></i>
          <div class="text-600 font-bold text-4xl line-height-3">{{ customerDuration }}
          </div>
          <p class="text-400 m-0">Customer Since</p>
        </div>
      </div>

      <!-- Total Number of Parties Booked Highlight -->
      <div class="col-12 md:col-3 text-center">
        <div class="px-2 py-3 surface-card shadow-1">
          <i class="pi pi-gift text-3xl text-orange-600"></i>
          <div class="text-600 font-bold text-4xl line-height-3">{{ parties.length }}
          </div>
          <p class="text-400 m-0">Total Parties Booked</p>
        </div>
      </div>

      <!-- Last Party Date Highlight -->
      <div class="col-12 md:col-3 text-center">
        <div class="px-2 py-3 surface-card shadow-1">
          <i class="pi pi-calendar-plus text-3xl text-purple-600"></i>
          <div class="text-600 font-bold text-4xl line-height-3">
            {{ lastPartyDate }}
          </div>
          <p class="text-400 m-0">{{ lastOrNext }}</p>
        </div>
      </div>

      <!-- Total Money Spent Highlight -->
      <div class="col-12 md:col-3 text-center">
        <div class="px-2 py-3 surface-card shadow-1">
          <i class="pi pi-chart-line text-3xl text-green-600"></i>
          <div class="text-600 font-bold text-4xl line-height-3">
            {{ formatMoney(totalSpent) }}
          </div>
          <p class="text-400 m-0">Total Spent</p>
        </div>
      </div>

      <!-- More highlights like Venues, Cities, Characters, etc., can be added similarly -->

    </div>
  </section>
</template>
<script>
import {orderBy, reduce, sumBy} from "lodash";
import dayjs from "dayjs";
import {formatMoney} from "accounting-js";
import {formatDate} from "@/helpers/date.helper";

export default {
  name: "customer-highlights",
  methods: {formatDate, formatMoney},
  computed: {

    loading() {
      return store.get('Customers/loading');
    },

    parties() {
      return store.get('Customers/Customer/parties');
    },

    customer() {
      return store.get('Customers/current');
    },

    customerSince() {
      if (this.parties.length > 0) {
        const sortedParties = orderBy(this.parties, ['date'], ['asc']);
        return sortedParties[0].date;
      }
      return 'N/A';
    },

    customerDuration() {
      const now = dayjs();
      const createdAt = dayjs(this.customerSince);
      const years = now.diff(createdAt, 'year');
      const months = now.diff(createdAt, 'month');

      if (years >= 1) {
        return `${years} ${years === 1 ? 'yr' : 'yrs'}`;
      } else {
        return `${Math.max(months, 1)} ${months === 1 ? 'mo' : 'mos'}`;
      }
    },

    lastParty(){
      if (this.parties.length > 0) {
        const sortedParties = orderBy(this.parties, ['date'], ['desc']);
        return dayjs(sortedParties[0].date);
      }
      return null;
    },

    lastOrNext(){
      if(this.lastParty === null) return '';
      return (this.lastParty.isAfter(dayjs())) ? "next party" : "last party";
    },

    lastPartyDate() {
      return this.lastParty ? this.lastParty.fromNow() : 'N/A';
    },

    totalSpent() {

      return reduce(this.parties, (totalSum, party) => {
        if (!party.scheduleables || party.scheduleables.length === 0) {
          return totalSum;
        }

        const partyTotal = sumBy(party.scheduleables, scheduleable => {
          const price = parseFloat(scheduleable.price);
          return isNaN(price) ? 0 : price;
        });

        return totalSum + partyTotal;
      }, 0);
    },

  },
}
</script>


<style scoped lang="scss">

</style>

<template>
   <InputText
          v-if="isEditing"
          ref="num"
          v-model="startTime"
          @input="formatInput"
          @keydown="handleBackspace"
          @focus="formatOnFocus"
          class="shadow-none border-1 border-300 border-round w-full p-2 m-0"/>

  <div v-else class="cursor-pointer border-1 border-300 border-round p-2" @click="edit">{{ startTime }}</div>
</template>

<script>
import {formatTime, isSameTime, setDBTime} from "@/helpers/time.helper";
import dayjs from "dayjs";
import {sync} from "vuex-pathify";

export default {
  name: "start-time-field",
  data() {
    return {
      editing: false
    }
  },
  computed: {
    startTime: sync('Parties/Party/startTime', null, false),

    isEditing() {
      return this.editing;
    },
    party() {
      return store.get('Parties/Party/current');
    },
    dbTime(){
      return setDBTime(this.party.date, this.startTime);
    },
    isValid(){
      return !!this.dbTime;
    },
    hasChanged() {
      return !isSameTime(this.data[this.field], this.dbTime);
    },
  },
  methods: {
    ...{formatTime},

    edit() {
      this.editing = true;
      this.$nextTick(() => {
        this.$refs.num?.$el.focus()
      });
    },

    handleBackspace(event) {
      if (event.key === 'Backspace') {
        event.preventDefault();
        this.startTime = '';
      }
    },

    selectText(event) {
      this.$nextTick(() => {
        event.target.select();
      });
    },

    formatInput(event) {
      this.startTime = this.autoFormatTime(event);
    },

    formatOnFocus(event) {
      const mysqlDateFormat = 'YYYY-MM-DD HH:mm:ss';

      if (this.startTime && dayjs(this.startTime, mysqlDateFormat).isValid()) {
        this.startTime = dayjs(this.startTime, mysqlDateFormat).format('h:mma');
      }

      this.selectText(event);
    },

    autoFormatTime(event) {
      let value = event.target.value.toUpperCase().replace(/[^0-9AP]/g, '');
      if (value.endsWith('A') || value.endsWith('P')) {
        const ampm = value.slice(-1);
        let time = value.slice(0, -1);
        time = this.formatDigits(time);
        const meridiem = ampm === 'A' ? 'AM' : 'PM';
        return this.formatCompleteTime(time, meridiem);
      }
      return value;
    },

    formatDigits(time) {
      switch (time.length) {
        case 1:
          return `0${time}:00`;
        case 2:
          return `${time}:00`;
        case 3:
          return `0${time.slice(0, 1)}:${time.slice(1)}`;
        case 4:
          return `${time.slice(0, 2)}:${time.slice(2)}`;
        default:
          return time;
      }
    },

    formatCompleteTime(time, meridiem) {
      const formattedTime = dayjs(`${time} ${meridiem}`, 'hh:mm A').format('h:mma');
      return formattedTime !== 'Invalid Date' ? formattedTime : '';
    }
  }
}
</script>


<style scoped lang="scss">

</style>

<template>
  <LaborContextMenu ref="lcm" @hide="rtClickSelectedRow = null" class="w-2" :selected-labor="rtClickSelectedRow"/>

  <section v-if="loading" class="flex justify-content-center align-content-center h-30rem">
    <progress-spinner class="h-full"/>
  </section>
  <section v-else class="fadein animation-duration-500">
    <DataTable
      :value="labors"
      v-model:selection="selectedLabor"

      dataKey="id"

      :sort-field="sortField"
      :sort-order="sortOrder"

      selectionMode="multiple"

      showGridlines
      stripedRows
      size="small"

      :loading="loading"

      @row-dblclick="onRowDblClick"
      @sort="onSort"

      contextMenu
      v-model:contextMenuSelection="rtClickSelectedRow"
      @contextmenu="showMenu"
    >
      <template #header>
        <div class="flex justify-content-end">
          <InputGroup>
            <InputGroupAddon>
              <i class="pi pi-search"/>
            </InputGroupAddon>
            <InputText v-model="filter"
                       placeholder="Search"
                       @keyup.enter="onFilter"
                       class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border appearance-none outline-none focus:border-primary w-full"/>
            <Button icon="pi pi-search" label="filter" @click="onFilter"/>
            <Button icon="pi pi-times" @click="clearFilter"/>
          </InputGroup>

          <Button :disabled="!selectedLabor.length"
                  @click="deleteSelected"
                  icon="pi pi-times"
                  class="ml-3 w-11rem"
                  size="small"
                  label="Delete"
                  type="button"
                  severity="secondary"
                  :badge="selectedLabor.length ? ''+selectedLabor.length : ''"/>
          <Button
            @click="addLabor"
            icon="pi pi-plus"
            class="ml-3 w-11rem"
            size="small"
            label="New"
            type="button"/>
        </div>
      </template>

      <template #empty>
        No labor entries found.
      </template>

      <Column selectionMode="multiple" class="w-2rem"></Column>
      <Column field="employee_id" header="Employee" :sortable="true" >
        <template #body="{data}">
          <div v-if="data.employee" class="flex justify-content-between cursor-pointer" @click="openEmployeeQuickView(data.employee)" @contextmenu="showMenu($event,data)">
            <span class="hover:underline hover:text-primary white-space-nowrap">{{ data.employee?.fullName }}</span>
          </div>
        </template>
      </Column>
      <Column field="category" header="Category" :sortable="true" class="white-space-nowrap"></Column>
      <Column field="total_work_duration" header="Paid Hrs" :sortable="true">
        <template #body="{data}">
          {{ Number((data.total_work_duration - data.total_break_duration) / 60).toFixed(2) }}
        </template>
      </Column>
      <Column field="total" header="Total" :sortable="true">
        <template #body="{data}">
          {{ formatMoney(data.total) }}
        </template>
      </Column>
      <Column field="description" header="Description" :sortable="true"></Column>
      <Column field="bill_id" header="Bill" :sortable="true">
        <template #body="{data}">
          <Button v-if="data.bill_id" :label="data.formatted_id" text @click="showBill(data.bill_id)" class="p-0 m-0"/>
          <span v-else>-</span>
        </template>
      </Column>
      <Column field="paid_on" header="Paid" :sortable="true">
        <template #body="{data}">
          {{ formatDate(data.paid_on) }}
        </template>
      </Column>
      <Column field="created_at" header="Created" :sortable="true">
        <template #body="{data}">
          {{ formatDate(data.created_at) }}
        </template>
      </Column>
    </DataTable>


  </section>
  <Paginator
  :totalRecords="totalRows"
  :rows="rowsPerPage"
  :pageLinkSize="3"
  :alwaysShow="true"
  template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} "
  :rowsPerPageOptions="[10, 25, 50]"
  @page="onPageChange"
/>
</template>

<script>
import {formatDate} from '@/helpers/date.helper'
import {formatMoney} from "accounting-js";
import LaborContextMenu from "@/components/team/labor/labor-context-menu.vue";
import Bus from "@/bus";

export default {
  name: "labors-table-server",
  components: {LaborContextMenu},
  emits: ['update-status'],
  mounted() {
    this.load();
    Bus.$on('labor.delete', this.onDelete);
  },
  beforeUnmount() {
    Bus.$off('labor.delete', this.onDelete);
  },
  data() {
    return {
      selectedLabor: [],
      rtClickSelectedRow: null,

      totalRows: 0,
      rowsPerPage: 10,
      currentPage: 1,

      sortField: 'created_at',
      sortOrder: -1,

      filter: '',
    };
  },
  computed: {
    loading() {
      return store.get('Labor/loading');
    },
    labors() {
      return store.get('Labor/list');
    }
  },
  methods: {
    formatMoney, formatDate,

    showMenu(event) {
      this.$refs.lcm.show(event);
    },

    getSortOrder() {
      return this.sortOrder === 1 ? 'asc' : 'desc';
    },

    getParams() {
      return {
        perPage: this.rowsPerPage,
        page: this.currentPage,
        filter: this.filter,
        sortField: this.sortField,
        sortOrder: this.getSortOrder(),
      };
    },

    async load() {
      let result = await store.dispatch('Labor/load', this.getParams());
      this.bindResult({...result});
    },

    bindResult(result) {
      this.totalRows = result.total;
      this.currentPage = result.current_page;
    },

    onPageChange(event) {
      this.currentPage = event.page + 1;
      this.rowsPerPage = event.rows;
      this.load();
    },

    onSort(event) {
      this.sortField = event.sortField;
      this.sortOrder = event.sortOrder;
      this.load();
    },

    clearFilter() {
      this.filter = '';
      this.load();
    },

    onFilter() {
      this.load();
      this.selectedLabor = [];
    },

    async onRowDblClick({data}) {
      store.commit('Labor/current', data);
      await store.dispatch('Employees/setEmployeeCurrent', data.employee_id);
      store.commit('Labor/edit', true);
    },

    async deleteSelected() {
      const count = this.selectedLabor.length;

      this.$confirm.require({
        header: 'Confirm Deletion',
        message: `Are you sure you want to delete ${count} selected Labor?`,
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          await store.commit('Labor/loading', true);

          const deletePromises = this.selectedLabor.map(labor =>
            store.dispatch('Labor/delete', labor.id)
          );
          await Promise.all(deletePromises);

          this.onDelete();
          await this.load();
          store.commit('Labor/loading', false);
        }
      });
    },

    onDelete() {
      this.selectedLabor = [];
    },

    addLabor() {
      store.commit('Employees/current', null);
      store.commit('Labor/current', null);
      store.commit('Labor/add', true);
    },

    showBill(billId) {
      if (billId)
        store.dispatch('Bills/getBillById', billId);
    },

    openEmployeeQuickView(employee){
      store.commit('Employees/current', employee);
      store.commit('Employees/view', true);
    }
  }
};
</script>

<style scoped>
</style>

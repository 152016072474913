<template>
  <section v-if="loading" class="flex justify-content-center align-content-center h-30rem">
    <progress-spinner class="h-full"/>
  </section>
  <section v-if="!loading && hasUnpaid && !selectionMade">
    <label class="text-600 text-sm font-bold">Unpaid Employees</label>
    <div v-if="hasUnpaid" class="card flex flex-wrap gap-2 my-2">
      <Chip v-for="employee in employees" :key="employee.id" @click="onClick(employee)"
            :label="`${employee.first} ${employee.last}`"
            :title="`Create a bill for ${employee.first}...`"
            class="cursor-pointer hover:bg-primary hover:text-white"
      />
    </div>
  </section>
  <Message v-if="!loading && !hasUnpaid" severity="success" :closable="false">All completed gigs paid!</Message>
</template>

<script>
export default {
  name: "bills-unpaid-employees",
  emits: ['selectEmployee'],
  data() {
    return {
      loading: true,
      employees: [],
      selectionMade: false
    }
  },
  computed: {
    hasUnpaid() {
      return this.employees.length > 0;
    }
  },
  created() {
    this.getUnpaidEmployees();
  },
  methods: {
    async getUnpaidEmployees() {
      this.employees = await store.dispatch('Bills/getUnpaidEmployees');
      this.loading = false;
    },
    onClick(employee) {
      this.$emit('selectEmployee', employee);
      this.selectionMade = true;
    }
  },
}
</script>

<style scoped lang="scss">

</style>

export default {
  domain: 'https://dev.yourmagicalparty.com',
  apiLoginID: '54m9aQAe9XSe',
  clientKey: '77RsJhCd3NBKZQWB9v5Yneu2SFZ7HRszrDsF5s48ZX7832Ajbq9W9s77REzWhp3P',
  companyName: 'Your Magical Party',
  phone: '(818) 491-5195',
  quickbooksUrl:'https://app.sandbox.qbo.intuit.com',
  authorizeUrl:'https://sandbox.authorize.net',
  imgPath: 'https://dev.yourmagicalparty.com/images',
  googlePlacesKey: 'AIzaSyDmQj50JEwKpP2dtQGtK3KD2iPissBZflE',
  dutyArea:2700,
  gasPerGallon: 5.45,
  character:{
    thumbnail: `https://dev.yourmagicalparty.com/images/characters/resized/thumbnail/`,
    full: `https://dev.yourmagicalparty.com/images/characters/`
  },
  service:{
    defaultItem: 68,
    thumbnail: `https://dev.yourmagicalparty.com/images/addons/resized/thumbnail/`,
    full: `https://dev.yourmagicalparty.com/images/addons/`
  },
  employees:{
    full: `https://dev.yourmagicalparty.com/images/staff/`
  },
  durationOptions:[
    {"label": "0.5 hrs", "value": "0.5"},
    {"label": "1 hr", "value": "1.0"},
    {"label": "1.5 hrs", "value": "1.5"},
    {"label": "2 hrs", "value": "2.0"},
    {"label": "2.5 hrs", "value": "2.5"},
    {"label": "3 hrs", "value": "3.0"},
    {"label": "3.5 hrs", "value": "3.5"},
    {"label": "4 hrs", "value": "4.0"},
    {"label": "4.5 hrs", "value": "4.5"},
    {"label": "5 hrs", "value": "5.0"},
    {"label": "5.5 hrs", "value": "5.5"},
    {"label": "6 hrs", "value": "6.0"},
    {"label": "6.5 hrs", "value": "6.5"},
    {"label": "7 hrs", "value": "7.0"},
    {"label": "7.5 hrs", "value": "7.5"},
    {"label": "8 hrs", "value": "8.0"},
    {"label": "8.5 hrs", "value": "8.5"},
    {"label": "9 hrs", "value": "9.0"},
    {"label": "9.5 hrs", "value": "9.5"},
    {"label": "10 hrs", "value": "10.0"},
    {"label": "10.5 hrs", "value": "10.5"},
    {"label": "11 hrs", "value": "11.0"},
    {"label": "11.5 hrs", "value": "11.5"},
    {"label": "12 hrs", "value": "12.0"},
    {"label": "12.5 hrs", "value": "12.5"},
    {"label": "13 hrs", "value": "13.0"},
    {"label": "13.5 hrs", "value": "13.5"},
    {"label": "14 hrs", "value": "14.0"},
    {"label": "14.5 hrs", "value": "14.5"},
    {"label": "15 hrs", "value": "15.0"},
    {"label": "15.5 hrs", "value": "15.5"},
    {"label": "16 hrs", "value": "16.0"},
    {"label": "16.5 hrs", "value": "16.5"},
    {"label": "17 hrs", "value": "17.0"},
    {"label": "17.5 hrs", "value": "17.5"},
    {"label": "18 hrs", "value": "18.0"},
    {"label": "18.5 hrs", "value": "18.5"},
    {"label": "19 hrs", "value": "19.0"},
    {"label": "19.5 hrs", "value": "19.5"},
    {"label": "20 hrs", "value": "20.0"},
    {"label": "20.5 hrs", "value": "20.5"},
    {"label": "21 hrs", "value": "21.0"},
    {"label": "21.5 hrs", "value": "21.5"},
    {"label": "22 hrs", "value": "22.0"},
    {"label": "22.5 hrs", "value": "22.5"},
    {"label": "23 hrs", "value": "23.0"},
    {"label": "23.5 hrs", "value": "23.5"},
    {"label": "24 hrs", "value": "24.0"}
  ],
  occasionOptions: [
    {label: 'Birthday', value: 'birthday'},
    {label: 'General Kids Entertainment', value: 'general'},
    {label: 'Daycare', value: 'daycare'},
    {label: 'Welcome Baby', value: 'welcome baby'},
    {label: 'Christening', value: 'christening'},
    {label: 'Teething', value: 'teathing'},
    {label: 'Bar/Bat Mitzvah', value: 'mitzvah'},
    {label: 'Grand Opening', value: 'opening'},
    {label: 'Wedding', value: 'wedding'},
    {label: 'Play Date', value: 'play date'},
    {label: 'Easter', value: 'Easter'},
    {label: 'Thanksgiving Party', value: 'thanksgiving'},
    {label: 'Halloween Party', value: 'halloween'},
    {label: 'Christmas Eve', value: 'christmas eve'},
    {label: 'Christmas Party', value: 'christmas'},
    {label: 'New Years Eve', value: 'new years eve'},
    {label: 'Corporate Event', value: 'corporate'},
  ],
  guestOptions: [
    {label: '1', value: '1'},
    {label: '2', value: '2'},
    {label: '3', value: '3'},
    {label: '4', value: '4'},
    {label: '5', value: '5'},
    {label: '6', value: '6'},
    {label: '7', value: '7'},
    {label: '8', value: '8'},
    {label: '9', value: '9'},
    {label: '> 10', value: '> 10'},
    {label: '10-14', value: '10-14'},
    {label: '15-24', value: '15-24'},
    {label: '25-29', value: '25-29'},
    {label: '30-49', value: '30-49'},
    {label: '50-74', value: '50-74'},
    {label: '75-99', value: '75-99'},
    {label: '100-149', value: '100-149'},
    {label: '150-200', value: '150-200'},
    {label: '200-299', value: '200-299'},
    {label: '300-499', value: '300-499'},
    {label: '500-9999', value: '500-9999'},
    {label: '1000-1999', value: '1000-1999'},
    {label: '2000+', value: '2000+'},
  ]
}

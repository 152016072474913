<template>
  <div v-if="!party.quickbooks_id">
    <h3>Transaction Association</h3>
    <p>A Quickbooks Invoice is required to associate a payment with a party. Please create an invoice using
      <router-link :to="`/event/${party.token}/edit/quickbooks`">Edit Party</router-link>
    </p>
  </div>
  <div v-else>
    <div>
      <h3>Add Transaction Association</h3>

      <InputGroup>
        <InputText v-model="search" placeholder="Search Authorize.net Transaction ID" @keyup.enter="searchTransaction"/>
        <Button icon="pi pi-times" class="bg-primary-reverse" @click="restart"/>
        <Button label="Find Transaction" @click="searchTransaction" :disabled="search === ''"/>
      </InputGroup>
    </div>

    <section v-if="loading" class="flex justify-content-center align-content-center h-30rem">
      <progress-spinner class="h-full"/>
    </section>
    <section v-else class="fadein animation-duration-500">

      <Card v-if="search && transaction" class="mt-3">
        <template #content>
          <div class="flex justify-content-between">
            <div class="flex align-self-center">
              <h2 class="px-2 my-1 text-500">Authorize.net Transaction</h2>
            </div>
            <div>
              <div>Date: {{ formatDateTime(transaction.submitTimeLocal) }}</div>
              <div>Transaction ID: {{ transaction.transId }}</div>
            </div>
          </div>
          <div class="flex justify-content-between pt-3">
            <h4>Customer: {{ transaction.firstName }} {{ transaction.lastName }}</h4>
            <div>
              <h4>Amount: {{ formatMoney(transaction.amount) }}</h4>
            </div>
          </div>
          <div>
            <div>Transaction Status: {{ transaction.transactionStatus }}</div>
            <div>Card: {{ transaction.accountType }} {{ transaction.accountNumber }}</div>
            <div :class="{'text-red-500':transaction.invoice !== party.token}">Invoice: {{ transaction.invoice }}</div>
          </div>
        </template>
      </Card>

      <p v-else>
        <span v-if="loading!==null">
          No Authorize.net transactions found.
        </span>
      </p>
      <div v-if="transaction?.inDB && !sameInvoice">
        <Message severity="error">This transaction is already associated with an invoice. To associate a transaction
          with this party, please remove the existing association.
        </Message>
      </div>
      <div v-else-if="hasTransaction">
        <Message severity="success">This transaction is already associated with this party.</Message>
      </div>
      <div v-else>
        <div v-if="transaction " class="my-3 justify-content-end flex">
          <Button icon="pi pi-save" label="Associate with Party" rounded @click="save()"/>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {formatMoney} from "accounting-js";
import {formatDateTime} from "@/helpers/date.helper";
import IconQuickbooks from "@/components/common/icon-quickbooks.vue";
import {find, isEmpty} from "lodash";

export default {
  name: "authorize-transaction-association",
  components: {IconQuickbooks},
  data() {
    return {
      loading: null,

      search: '',

      paymentId: '',
      payment: null,
      customer: null,

      transId: '',
      transaction: null
    };
  },
  computed: {
    party() {
      return store.get('Parties/Party/current');
    },
    sameInvoice() {
      return this.transaction?.invoice !== null && this.transaction?.invoice === this.party.token;
    },
    hasTransaction(){
      return find(this.party.transactions, {transId: this.transId});
    },
  },
  methods: {
    formatDateTime,
    formatMoney,

    restart() {
      this.payment = null;
      this.customer = null;
      this.transaction = null;
      this.transId = '';
      this.paymentId = '';
      this.loading = null;
    },

    async searchTransaction() {
      this.restart();

      this.loading = true;
      this.transId = this.search;

      this.transaction = await this.getTransactionByTransID();

      if (isEmpty(this.transaction))
        await this.getTransaction();

      this.loading = false;
    },

    async getTransactionByTransID() {
      return await api.url(`/payments/existing/${this.transId}`).get().json();
    },

    async getTransaction() {
      try {
        this.transaction = await api.url(`/payments/${this.transId}`).get().json();

        if (this.transaction.quickbooks_id) {
          this.paymentId = this.transaction.quickbooks_id;
        }
      } catch (e) {
        this.$toast.add({severity: 'error', summary: 'Authorize.net Error', detail: e.message, life: 5000});
      }
    },

    async associateTransaction() {
      this.loading = true;
      try {

        if (this.payment && this.transaction) {
          await api.url(`/payments/associate/${this.paymentId}`).post({transaction_id: this.transId});
          await api.url(`/quickbooks/payment/${this.paymentId}`).put();

          this.$toast.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Transaction associated successfully.',
            life: 5000
          });
          // stores.dispatch('Interactions/create', `Payment Associated: ${formatMoney(response.amount)} | ${response.accountType} ${response.accountNumber} | Transaction ID:${response.transId}`);
          return;
        }
        if (!this.payment) {//if transaction exists bit no payment
          this.$toast.add({severity: 'error', summary: 'Error', detail: 'No payment data found.', life: 5000});
          return;
        }

      } catch (error) {
        this.$toast.add({severity: 'error', summary: 'Error', detail: error.message, life: 5000});
      }
      this.loading = false;
    },

    async save() {
      if (this.payment) this.transaction.quickbooks_id = this.payment.Id;
      this.transaction.invoice = this.party.token;

      await store.dispatch('Authorize/associatePayment', this.transaction);
    }
  },
}
</script>


<style scoped lang="scss">

</style>

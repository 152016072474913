<template>
  <section id="main-container">
    <main-menu-bar></main-menu-bar>
    <div class="flex flex-column surface-ground overflow-y-auto" style="height:87vh;">
      <div class="p-5 flex flex-column flex-auto surface-ground">
        <div class="surface-overlay p-4">
          <h1 class="text-600 text-4xl">Customers</h1>
          <customers-table-server/>
        </div>
      </div>
    </div>
    <quick-views/>
    <modals/>
    <toasts/>
    <confirms/>
  </section>
</template>

<script>
import CustomersTableServer from "@/components/customers/customers-table-server.vue";
import MainMenuBar from "@/components/main-menu/main-menu-bar.vue";
import QuickViews from "@/components/common/quick-views.vue";
import Modals from "@/components/common/modals.vue";
import Toasts from "@/components/common/toasts.vue";
import Confirms from "@/components/common/confirms.vue";

export default {
  name: "customers",
  components: {Confirms, Toasts, Modals, QuickViews, MainMenuBar, CustomersTableServer}
}
</script>


<style scoped lang="scss">
#main-container {
  max-width: 1600px;
  overflow: auto;
  margin: 0 auto;
}
</style>
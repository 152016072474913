<template>

      <div class="formgrid grid">
        <div class="field col-12 md:col-3">
          <label class="text-600 text-sm font-bold" for="first">First Name</label>
          <InputText v-model.trim="first" id="first"
                     class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"/>
        </div>
        <div class="field col-12 md:col-3">
          <label class="text-600 text-sm font-bold" for="last">Last Name</label>
          <InputText
            class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
            id="last" v-model.trim="last"/>
        </div>

        <div class="field col-6">
          <label class="text-600 text-sm font-bold" for="email">Company:</label>
          <InputText
            class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
            id="company" v-model.trim="company"/>
        </div>

        <div class="field col-12 md:col-6">
          <label class="text-600 text-sm font-bold" for="email">Email:</label>
          <InputText
            class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
            id="email" v-model.trim="email"/>
        </div>

        <div class="field col-12 md:col-6 pb-3">
          <label class="text-600 text-sm font-bold" for="phone">Customer Phone:</label>
          <InputMask :auto-clear="false" mask="999-999-9999" placeholder="999-999-9999" id="phone" v-model.trim="phone"
                     class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"/>
        </div>
      </div>

</template>

<script>
import {sync} from 'vuex-pathify';

export default {
  name: "customer-edit-general",
  computed: {
    ...sync('Customers/Customer/*', null, false),
  }
}
</script>


<style scoped lang="scss">

</style>

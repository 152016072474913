<template>
  <ContextMenu :model="items" ref="cm"></ContextMenu>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "bills-gigs-invoice-context-menu",
  emits:['reload'],
  props: {
    selectedRow: Object,
  },
  computed: {

    startTime(){
      return dayjs(this.selectedRow.start_time);
    },
    endTime(){
      return dayjs(this.selectedRow.end_time);
    },
    duration() {
      const diffInMinutes = dayjs(this.endTime).diff(this.startTime, 'minutes');
      return diffInMinutes / 60; // Convert minutes to hours
    },

    items() {
      return [
        {label: 'Compute Earnings', icon: 'pi pi-fw pi-undo', command: this.computeEarnings},
      ];
    },
  },

  methods: {
    show(event) {
      this.$refs.cm.show(event);
    },

    computeEarnings() {
      let rate = 30;
      let gig = {...this.selectedRow, cost: this.duration * rate};
      store.dispatch('Scheduleables/editScheduleable', gig);
      this.$emit('reload');
    }
  }
};
</script>

<style scoped>
</style>

import wretch from 'wretch';
import FormDataAddon from 'wretch/addons/formData';
import QueryStringAddon from 'wretch/addons/queryString';
import {deleteLStorage, getLStorage} from "@/helpers/storage.helper";
import Bus from "@/bus";

export function partyCannotSave(e) {
  if (e.message === "Party is archived. Unarchive to edit.")
    Bus.$emit('error.archived', e.message);
  if (e.message === 'Party must be New, Booking or Ready to update. Change status to update')
    Bus.$emit('error.wrongStatus', e.message);
}

const token = getLStorage('token');

const apiBase = wretch()
  .addon(FormDataAddon)
  .addon(QueryStringAddon)
  .url(`/api`)
  .auth(`Bearer ${token}`)
  .headers({'Accept': 'application/json'})
  .query({'XDEBUG_SESSION_START': 'PHPSTORM'})
  .catcher(401, () => {
    deleteLStorage('token');
    location.reload();
  })
  .catcher(403, ({json}) => {
    partyCannotSave(json);
    throw json;
  })
  .catcher(422, ({json}) => {
    throw json;
  })
  .catcherFallback(({json})=> {
    throw json;
  });

const api = token
  ? apiBase.auth(`Bearer ${token}`)
  : apiBase;

export default api;

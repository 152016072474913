<template>
    <div v-if="party">

        <Button type="button" @click="open" icon="pi pi-credit-card" severity="secondary" text rounded
                title="Payments"></Button>

        <Dialog v-model:visible="showModal" appendTo="body" modal
                :style="{width: '950px'}" :draggable="false" :resizable="false" id="cc" position="top"
                @dblclick.stop
                @hide="close"
        >
            <template #header>
                <event-header-content/>
            </template>

            <TabView v-model:active-index="activeTab">

                <TabPanel header="Card">
                    <credit-card-form @close="close" @success="onSuccess"></credit-card-form>
                </TabPanel>
                <TabPanel header="Cash">
                    <cash-form @close="close" @success="onSuccess"></cash-form>
                </TabPanel>

                <TabPanel header="Check">
                    <check-form @close="close" @success="onSuccess"></check-form>
                </TabPanel>

                <TabPanel header="View Payments">
                    <section class="pt-4">
                        <ScrollPanel style="width: 100%; height: 60vh">
                            <invoice-transaction-table></invoice-transaction-table>
                        </ScrollPanel>
                    </section>
                </TabPanel>

                <TabPanel>
                    <template #header>
                        <div class="flex align-items-center gap-2">
                            <i class="pi pi-link"></i>
                            <span class="p-tabview-title">Payment</span>
                        </div>
                    </template>
                    <quickbooks-payment-association @success="onSuccess"/>
                </TabPanel>

                <TabPanel>
                    <template #header>
                        <div class="flex align-items-center gap-2">
                            <i class="pi pi-link"></i>
                            <span class="p-tabview-title">Transaction</span>
                        </div>
                    </template>
                    <authorize-transaction-association @success="onSuccess"/>
                </TabPanel>

            </TabView>

        </Dialog>
    </div>
</template>

<script>

import InvoiceTransactionTable from "@/components/events/edit/invoice/invoice-transaction-table.vue";
import CreditCardForm from "@/components/events/quick-view/modals/cc/credit-card-form.vue";
import EventHeaderContent from "@/components/events/common/event-header-content.vue";
import CheckForm from "@/components/events/quick-view/modals/cc/check-form.vue";
import QuickbooksPaymentAssociation from "@/components/events/quick-view/modals/cc/quickbooks-payment-association.vue";
import AuthorizeTransactionAssociation
    from "@/components/events/quick-view/modals/cc/authorize-transaction-association.vue";
import IconQuickbooks from "@/components/common/icon-quickbooks.vue";
import CashForm from "@/components/events/quick-view/modals/cc/cash-form.vue";

export default {
    name: 'credit-card-modal',
    components: {
        CashForm,
        IconQuickbooks,
        AuthorizeTransactionAssociation,
        QuickbooksPaymentAssociation, CheckForm, EventHeaderContent, CreditCardForm, InvoiceTransactionTable
    },
    data() {
        return {
            showModal: false,
            hasCardOnFile: true,
            activeTab: 0,
        };
    },
    computed: {
        party() {
            return store.get('Parties/Party/current');
        },
    },
    methods: {
        open() {
            this.showModal = true;
        },
        close() {
            store.commit('Authorize/resetState');
            this.showModal = false;
        },
        onSuccess() {
            this.activeTab = 3;
        }
    }
};
</script>

<style lang="scss">
#cc .p-tabview-panels {
    padding: 0;
}

#cc .p-dialog-header {
    padding-bottom: 0;
}
</style>

<template>
  <section v-if="loading" class="flex justify-content-center align-content-center h-30rem">
    <progress-spinner class="h-full"/>
  </section>
  <section v-else class="fadein animation-duration-500">
    <h1 class="text-600 text-4xl pb-0 mb-0">Team</h1>
    <p class="p-0 m-1 mb-3">Primary Contractors, Sub Contractors and Vendors</p>
    <DataTable
            :value="employees"
            v-model:selection="selectedEmployees"

            dataKey="id"

            :sort-field="sortField"
            :sort-order="sortOrder"

            selectionMode="multiple"

            showGridlines
            stripedRows
            size="small"

            :loading="loading"

            @row-dblclick="onRowDblClick"
            @sort="onSort"
    >
      <template #header>
        <div class="flex justify-content-end">

          <InputGroup>
            <InputGroupAddon>
              <i class="pi pi-search"/>
            </InputGroupAddon>
            <InputText v-model="filter"
                       placeholder="Search"
                       @keyup.enter="onFilter"
                       class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border appearance-none outline-none focus:border-primary w-full"/>

            <Button icon="pi pi-search" label="Filter" @click="onFilter"/>
            <Button icon="pi pi-times" @click="clearFilter"/>
          </InputGroup>

          <Button :disabled="!selectedEmployees.length"
                  @click="toggleActive"
                  icon="pi pi-times"
                  class="ml-3 w-11rem"
                  size="small"
                  label="Active"
                  type="button"
                  severity="secondary"
                  :badge="selectedEmployees.length ? ''+selectedEmployees.length : ''"/>

          <Button :disabled="!selectedEmployees.length"
                  @click="deleteSelected"
                  icon="pi pi-times"
                  class="ml-3 w-11rem"
                  size="small"
                  label="Delete"
                  type="button"
                  severity="secondary"
                  :badge="selectedEmployees.length ? ''+selectedEmployees.length : ''"/>

          <employee-add></employee-add>
        </div>
      </template>

      <template #empty>
        No Employees Found.
      </template>

      <Column selectionMode="multiple" class="w-2rem"></Column>
      <Column field="first" header="Full Name" :sortable="true">
        <template #body="{data}">
          {{data.fullName }}
        </template>
      </Column>
      <Column field="type" header="type" :sortable="true"></Column>
      <Column field="scheduleables_count" header="Gigs" :sortable="true"></Column>
      <Column field="email" header="Email"></Column>
      <Column field="phone" header="Phone"></Column>
      <Column field="active" header="Active" :sortable="true">
        <template #body="{data}">
          <div class="text-center">
              <span v-if="data.active" class="pi pi-circle-fill text-primary"></span>
              <span v-else class="pi pi-circle"></span>
          </div>
        </template>
      </Column>
    </DataTable>
    <Paginator
            :totalRecords="totalRows"
            :rows="rowsPerPage"
            :pageLinkSize="3"
            :alwaysShow="true"
            template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} "
            :rowsPerPageOptions="[10, 25, 50]"
            @page="onPageChange"
    />
  </section>
</template>

<script>
import {partyDate, formatDate} from '@/helpers/date.helper'
import {calculateAgeing, ageingClass, daysUntilEvent} from '@/helpers/party.helper'
import {sync} from "vuex-pathify";
import Bus from "@/bus";
import EmployeeAdd from "@/components/employees/employee-add.vue";

export default {
  name: "EmployeesTableServer",
  components: {EmployeeAdd},
  emits: ['update-status'],
  mounted() {
    this.load();
    Bus.$on('employees.update', this.load);
  },
  beforeUnmount() {
    Bus.$off('employees.update', this.load);
  },
  data() {
    return {
      selectedEmployees: [],
      selectedStatus: null,

      totalRows: 0,
      rowsPerPage: 10,
      currentPage: 1,

      sortField: 'active',
      sortOrder: -1,

      filter: '',
    };
  },
  computed: {
    loading: sync('Employees/loading', null, false),
    employees() {
      return store.get('Employees/list');
    },
  },
  methods: {

    ...{formatDate, partyDate, calculateAgeing, ageingClass, daysUntilEvent},

    getSortOrder() {
      return this.sortOrder === 1 ? 'asc' : 'desc';
    },

    getParams() {
      return {
        perPage: this.rowsPerPage,
        page: this.currentPage,
        filter: this.filter, // Assuming this.globalFilter holds the global filter value
        sortField: this.sortField, // sort field
        sortOrder: this.getSortOrder(), // sort order
      };
    },

    async load() {
      let result = await store.dispatch('Employees/load', this.getParams());
      this.bindResult({...result});
    },

    bindResult(result) {
      this.totalRows = result.total;
      this.currentPage = result.current_page;
    },

    onPageChange(event) {
      this.currentPage = event.page + 1; // event.page is zero-indexed
      this.rowsPerPage = event.rows;
      this.load();
    },

    onSort(event) {
      if (event.sortField === 'partiesCount') {
        this.sortField = 'parties_count';
      } else {
        this.sortField = event.sortField;
      }
      this.sortOrder = event.sortOrder;
      this.load();
    },

    clearFilter() {
      this.filter = '';
      this.load();
    },

    onFilter() {
      this.load();
      this.selectedEmployees = [];
    },

    onRowDblClick({data, originalEvent}) {
      store.commit('Employees/current', data);

      (originalEvent.ctrlKey)
        ? store.commit('Employees/edit', true)
        : store.commit('Employees/view', true);
    },

    async deleteSelected() {

      await this.$confirm.require({
        message: `Are you sure you want to delete this team member? This cannot be undone.`,
        header: 'Delete Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: async ()=> {
          this.loading = true;

          const deletePromises = this.selectedEmployees.map(customer =>
            store.dispatch('Employees/delete', customer)
          );
          await Promise.all(deletePromises);

          this.selectedEmployees = [];
          this.load();
        }
      });
    },

    async toggleActive() {
      this.loading = true;

      const updatePromises = this.selectedEmployees.map(employee =>
        store.dispatch('Employees/active', {...employee, active:!employee.active})
      );
      await Promise.all(updatePromises);

      this.selectedEmployees = [];
      await this.load();
      this.loading = false;
    }
  },
};
</script>

<style scoped>
</style>

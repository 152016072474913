<template>
  <ContextMenu :model="items" ref="cm"></ContextMenu>
</template>

<script>
import {toastErrors} from "@/helpers/errors.helper";
import dayjs from "dayjs";

export default {
  name: "gigs-context-menu",
  props: ['selectedGig'],
  data() {
    return {
      paid: {label: 'Mark as Paid', icon: 'pi pi-fw pi-check', command: this.markPaid},
      unpaid: {label: 'Mark as Unpaid', icon: 'pi pi-fw pi-undo', command: this.markUnpaid},
    };
  },
  computed: {
    employee() {
      return this.selectedGig?.employee;
    },
    employeeName() {
      return this.employee?.first + ' ' + this.employee?.last;
    },
    items() {
      return [
        this.selectedGig?.paid_on === null? this.paid : this.unpaid,
        {label: `Create Bill for ${this.employeeName}`, icon: 'pi pi-fw pi-calculator', command: this.createBill},
      ];
    },
  },
  methods: {
    show(event) {
      this.$refs.cm.show(event);
    },

    async markPaid() {
      if (this.selectedGig) {
        await store.dispatch('Gigs/markPaid', this.selectedGig.id);
      } else {
        this.$toast.add({severity: 'warn', summary: 'Mark Paid', detail: 'No gig selected to mark as paid.', life: 3000});
      }
    },

    async markUnpaid() {
      if (this.selectedGig) {
        await store.dispatch('Gigs/markUnpaid', this.selectedGig.id);
      } else {
        this.$toast.add({severity: 'warn', summary: 'Mark Paid', detail: 'No gig selected to mark as paid.', life: 3000});
      }
    },

    async createBill() {
      if (this.selectedGig) {
        await store.dispatch('Bills/create', {gig_id: this.selectedGig.id, employee_id: this.employee.id});
      }else {
        this.$toast.add({severity: 'warn', summary: 'Create Bill', detail: 'No gig selected to create bill.', life: 3000});
      }
    },

    async createLabor() {
      if (this.selectedGig) {
          await this.saveTimesheet();
          await store.commit('Labor/edit', true);
          //await this.markPaid();
      }else {
        this.$toast.add({severity: 'warn', summary: 'Create Bill', detail: 'No gig selected to create bill.', life: 3000});
      }
    },

    async createExpense() {
      if (this.selectedGig) {
        await store.commit('Expense/add', true);
      }else {
        this.$toast.add({severity: 'warn', summary: 'Create Bill', detail: 'No gig selected to create bill.', life: 3000});
      }
    },

    async saveTimesheet() {
      try{
      let start_time = dayjs(this.selectedGig.start_time).toISOString();
      let end_time = dayjs(this.selectedGig.end_time).toISOString();
debugger;
      await store.commit('Labor/Timesheet/employee_id', this.employee.id);
      await store.commit('Labor/Timesheet/category', 'Driving - Gig Hours');
      await store.commit('Labor/Timesheet/start_time', start_time);
      await store.commit('Labor/Timesheet/end_time', end_time);
      await store.commit('Labor/Timesheet/gigs', [this.selectedGig.id]);
      await store.dispatch('Labor/Timesheet/save');
      }catch (error) {
        toastErrors(this.$toast, error);
      }
    }
  }
};
</script>

<style scoped>
</style>

<template>
  <div class="flex justify-content-between w-full">
    <div class="flex">
      <h2 class="mr-2 text-500">Characters</h2>
      <AvatarGroup class="avatar-hover-effect">
        <Avatar
                v-for="item in characters.slice(0, 20)"
                :key="item.id"
                :image="getCharacterImage(item.character)"
                size="large"
                shape="circle"
                class="border-200 border-3 bg-white"
                :title="item.character.name"

        />
        <template v-if="characters.length > 20">
          <Avatar :label="`+${characters.length - 20}`"
                  shape="circle"
                  size="large"
                  class="border-200 border-3 bg-white"
          />
        </template>
        <Avatar shape="circle" icon="pi pi-plus" size="normal" @click.stop="addCharacter"
                :pt="{
        root: { class: 'text-sm text-400 hover:bg-primary hover:text-white' },
        icon: { class: 'text-sm' }
      }"
        />
      </AvatarGroup>
    </div>
    <h2 class="text-500">{{ formatMoney(subtotal) }}</h2>
  </div>
</template>

<script>
import {formatMoney} from "accounting-js";
import {getCharacterImage} from "@/helpers/images.helper";

export default {
  name: "invoice-header-character",

  computed: {
    characters() {
      return store.get('Scheduleables/characters');
    },
    subtotal() {
      return store.get('Scheduleables/charactersSubtotal');
    },
  },
  methods: {

    ...{formatMoney, getCharacterImage},

    addCharacter() {
      store.commit('Scheduleables/charactersLoading', true);
      store.dispatch('Scheduleables/addScheduleable', {character_id:49}); //sunshine
    },
  }
}
</script>


<style scoped lang="scss">
.avatar-hover-effect {
  .p-avatar {
    transition: transform 0.3s ease;
  }

  .p-avatar:hover {
    transform: scale(calc(150 / 64)); // Scale based on original 'large' size (64px to 150px)
  }
}
</style>

<template>
    <img :src="image" :width="width" :height="height" :style="containStyle" :class="{'opacity-3': inactive}" alt="Character Image">
</template>

<script>
import { find } from "lodash";
import Config from "@/config";

export default {
  name: "CharacterImage",
  props: {
    'character':{
      type: Object,
      required: true
    },
    'width':{
      type: String,
      default: '100%'
    },
    'height':{
      type: String,
      default: 'auto'
    },
    'listing':{
      type: Boolean,
      default: false
    },
    'full':{
      type: Boolean,
      default: false
    },
  },
  computed: {
    inactive() {
      return !this.character.active;
    },
    primary() {
      return find(this.character.images, {primary: 1});
    },
    prefix() {
      return `${Config.imgPath}/characters`;
    },
    thumb() {
      return `${this.prefix}/resized/thumbnail/${this.primary?.id}.jpg`;
    },
    large() {
      return `${this.prefix}/resized/listing/${this.primary?.id}.jpg`;
    },
    png() {
      return `${this.prefix}/${this.primary?.src}`;
    },
    image() {
      if (this.full !== undefined) return this.png;
      if (this.listing !== undefined) return this.large;
      return this.thumb;
    },
    containStyle() {
      return this.contain ? {objectFit: 'contain', aspectRatio: '1'} : {};
    }
  }
}
</script>

<style lang="scss" scoped>
.opacity-3 {
  opacity: 0.3;
}
</style>

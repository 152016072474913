<template>
  <div class="grid" v-if="current">
    <event-header-content class="col-8"  :key="reloadKey"/>
    <div class="col-4">
      <div class="flex align-items-center justify-content-end">

        <event-interactions  :key="reloadKey"/>

        <email-customer-modal  :key="reloadKey"/>

        <Button type="button" @click="phoneCustomer" icon="pi pi-phone" severity="secondary" text rounded title="Call Customer"></Button>

        <print-event-modal  :key="reloadKey"/>

        <credit-card-modal/>

        <Button type="button" @click="edit" icon="pi pi-pencil" severity="secondary" text rounded title="Edit Party"></Button>

        <Button type="button" @click="goToCustomerView" icon="pi pi-external-link" severity="secondary" text rounded title="Customer View"></Button>

        <Button type="button" @click="close" icon="pi pi-times" severity="secondary" text rounded title="Close Quick View"></Button>
      </div>
    </div>
  </div>
</template>

<script>
import EmailCustomerModal from "../messages/email-customer-modal.vue";
import CreditCardModal from "./modals/credit-card-modal.vue";
import dayjs from "dayjs";
import duration from 'dayjs/plugin/duration';
import {capitalize} from "lodash";
import EventHeaderContent from "@/components/events/common/event-header-content.vue";
import IconQuickbooks from "@/components/common/icon-quickbooks.vue";
import config from '@/config'
import PrintEventModal from "@/components/events/quick-view/modals/print/print-event-modal.vue";
import EventInteractions from "@/components/events/interactions/event-interactions.vue";

dayjs.extend(duration);
export default {
  name: "event-quick-view-header",
  components: {
    EventInteractions,
    PrintEventModal, IconQuickbooks, EventHeaderContent, CreditCardModal, EmailCustomerModal},
  computed: {
    current() {
      return store.get('Parties/Party/current');
    },
    customer() {
      return this.current.customer;
    },
    reloadKey(){
      return store.get('Parties/Party/reloadKey');
    }
  },
  methods: {
    ...{capitalize},

    phoneCustomer() {
      window.location.href = `tel:${this.customer.phone}`;
    },

    edit(){
      store.commit('Parties/Party/edit', true);
    },

    close() {
      store.commit('Parties/Party/view', false);
    },

    openCustomer() {
      store.commit('Customers/current', this.party.customer);
      store.commit('Customers/view', true);
    },

    goToCustomerView(){
      window.open(`${config.domain}/my-party/${this.current.token}`, '_blank');
    },
  }
}
</script>

<style scoped lang="scss">

</style>

<template>
    <ul class="list-none p-0 m-0 flex select-none flex-column lg:flex-row gap-2">
        <li class="lg:flex lg:align-items-end" v-for="(item, index) in menuItems" :key="index">
            <router-link :to="item.route"
                         v-ripple
                         class="border-round-top flex align-items-center px-6 py-3 lg:px-3 lg:py-3 font-medium cursor-point transition-colors transition-duration-150 menu-link text-500"
                         :class="{ 'surface-ground text-900': isActiveRoute(item.route), 'hover:surface-hover': !isActiveRoute(item.route)}"
                         @click="activeTab = index">
                <i :class="item.icon"></i>
                <span>{{ item.title }}</span>
            </router-link>
        </li>
      <li class="lg:flex lg:align-items-end lg:relative">
        <a v-ripple
           class="border-round-top flex align-items-center px-6 py-3 lg:px-3 lg:py-3 font-medium cursor-point transition-colors transition-duration-150 menu-link text-500"
           v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }">
          <i class="pi pi-users mr-2"></i>
          <span>Products</span>
          <i class="pi pi-angle-down ml-auto lg:ml-3"></i>
        </a>
        <ul style="margin-top:70px !important;" class="align-self-start list-none py-3 px-6 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:shadow-2 lg:border-1 border-50 lg:absolute surface-overlay hidden origin-top w-full lg:w-15rem cursor-pointer">
          <character-v1-modal></character-v1-modal>
          <addons-v1-modal></addons-v1-modal>
        </ul>
      </li>
    </ul>
</template>

<script>
import CharacterV1Modal from "@/components/characters/character-v1-modal.vue";
import AddonsV1Modal from "@/components/services/addons-v1-modal.vue";
export default {
  name: "MainMenuItems",
  components: {AddonsV1Modal, CharacterV1Modal},
  data() {
    return {
      activeTab: 0,
      menuItems: [
        { title: 'Events', route: '/events', icon: 'pi pi-gift mr-2' },
        { title: 'Customers', route: '/customers/', icon: 'pi pi-user mr-2' },
        { title: 'Team', route: '/team/', icon: 'pi pi-users mr-2' },
        { title: 'Banking', route: '/banking/', icon: 'pi pi-money-bill mr-2' },
      ],
      products:[
        { title: 'Characters', route: `/characters/`, icon: 'pi pi-star mr-2' },
        { title: 'Services', route: `/addons/`, icon: 'pi pi-truck mr-2' }
      ]
    };
  },
  methods: {
    isActiveRoute(route) {
      return this.$route.path.includes(route);
    }
  },
}
</script>
<style scoped lang="scss">
.menu-link {
  text-decoration: none; /* This will remove the underline */

  &:hover {
    text-decoration: none; /* Also remove underline on hover */
  }
}
</style>


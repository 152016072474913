<template>
  <li>
    <a v-ripple @click="open"
       class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-primary-400 hover:surface-hover font-medium border-round cursor-pointer transition-colors transition-duration-150">
      <i class="pi pi-gift text-base lg:text-2xl mr-2 lg:mr-0" title="New Party"></i>
      <span class="block lg:hidden font-medium">Start Party</span>
    </a>
  </li>

  <Dialog v-model:visible="visible" modal class="h-auto surface-overlay" position="top"
          :style="{width: '800px'}"
          @dblclick.stop>
    <template #container>
      <section class="p-5">
        <div class="flex justify-content-between">
          <h1 class="text-600">New Party</h1>
          <Button type="button" icon="pi pi-times" class="ml-2 align-self-start" size="small" text rounded @click="close" severity="secondary"></Button>
        </div>



        <label class="text-600 text-sm font-bold">Find Customer Or Create New One</label>

        <AutoComplete v-model="term" :suggestions="filteredCustomers" @complete="searchCustomers" field="name" @item-select="selectCustomer" @clear="unselectCustomer"
                      placeholder="first, last, email or phone" input-class="w-full" class="w-full my-2">
          <template #item="{item}">
            <div>{{item.company ? item.company + " : " : ""}}{{ item.first }} {{ item.last }} - {{ item.email }} - {{ item.phone }}</div>
          </template>

          <template #empty>
            <div>No Customers found.</div>
          </template>
        </AutoComplete>

        <h2 class="text-600">Customer Info</h2>
        <block-u-i :blocked="term!==''">
          <div class="formgrid grid">
            <div class="field col-12 md:col-3">
              <label class="text-600 text-sm font-bold" for="first">First Name</label>
              <InputText v-model="customer.first" id="first" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"/>
            </div>
            <div class="field col-12 md:col-3">
              <label class="text-600 text-sm font-bold" for="last">Last Name</label>
              <InputText class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" id="last" v-model="customer.last"/>
            </div>

            <div class="field col-12 md:col-6">
              <label class="text-600 text-sm font-bold" for="last">Company</label>
              <InputText class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" id="last" v-model="customer.company"/>
            </div>

            <div class="field col-12 md:col-6 ">
              <label class="text-600 text-sm font-bold" for="email">Email:</label>
              <InputText class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" id="email" v-model="customer.email"/>
            </div>

            <div class="field col-12 md:col-6 pb-3">
              <label class="text-600 text-sm font-bold" for="phone">Customer Phone:</label>
              <InputMask :auto-clear="false" mask="999-999-9999" placeholder="999-999-9999" id="phone" v-model="customer.phone" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"/>
            </div>
          </div>
        </block-u-i>
        <div class="flex justify-content-end z-depth-1">
          <Button icon="pi pi-times" label="Reset" rounded outlined @click="cancel" class="mr-2" :disabled="loading"/>
          <Button icon="pi pi-chevron-right" label="Next" rounded @click="next" :disabled="loading"/>
        </div>

      </section>
    </template>
  </Dialog>

</template>

<script>
import {debounce, each} from "lodash";
import CustomerEdit from "@/components/customers/customer-edit.vue";
import LogoCompany from "@/components/common/logo-company.vue";

export default {
  name: "new-event-modal",
  components: {LogoCompany, CustomerEdit},
  data() {
    return {
      term: '',
      visible: false,
      customers: [],
      filteredCustomers: [],
      debouncedSearch: null,
      customer: {
        id: '',
        first: '',
        last: '',
        email: '',
        phone: '',
        company: '',
      }
    }
  },
  created() {
    this.debouncedSearch = debounce(this.search, 800);
  },

  computed: {

    current() {
      return store.get('Customers/current');
    },
    loading() {
      return store.get('Customers/loading');
    }
  },

  methods: {

    init() {
      return {
        id: '',
        first: '',
        last: '',
        email: '',
        phone: '',
        company: '',
      }
    },

    cancel() {
      this.customer = this.init();
      this.term = '';
    },

    selectCustomer(customer) {
      this.customer = {...customer.value};
      store.commit('Customers/current', this.customer);
    },

    unselectCustomer() {
      this.customer = this.init();
    },

    open() {
      this.visible = true;
    },

    close() {
      this.visible = false;
      this.cancel();
    },

    searchCustomers(event) {
      if (event.query.trim().length >= 3) {
        this.debouncedSearch(event.query);
      } else {
        this.filteredCustomers = [];
      }
    },

    async search(query) {
      let response = await api.url(`/customers/search?query=${query}`).get().json();
      this.filteredCustomers = response.map(customer => ({
        ...customer,
        name: `${customer.first} ${customer.last}`
      }));
    },

    async next() {

      try {
        if (!this.customer.id)
          this.customer = await store.dispatch('Customers/Customer/create', this.customer);

        await store.dispatch('Parties/Party/create', this.customer);

        this.close();
        store.commit('Parties/Party/edit', true);

      } catch (e) {
        each(e.errors, (error) => {
          this.$toast.add({severity: 'error', summary: 'Error', detail: error[0], life: 3000});
        });
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>

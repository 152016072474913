<template>
  <ContextMenu ref="cm" :model="items"/>
</template>
<script>
import Bus from '@/bus';
import dayjs from "dayjs";
export default {
  name: "character-context-menus",
  data() {
    return {
      items: null,
      selectedRow: null,
      standard:[
        {label: 'Clone', icon: 'pi pi-fw pi-copy', command: this.clone},
        {label: 'Delete', icon: 'pi pi-fw pi-times', command: this.delete}
      ]
    }
  },
  computed: {
    startTime() {
      return store.get('Parties/Party/startDateTime');
    },
    endTime(){
      return store.get('Parties/Party/endTime');
    },
    party() {
      return store.get('Parties/Party/current');
    },
    address() {
      return `${this.party.address}, ${this.party.city}, Ca ${this.party.zip}`;
    }
  },
  mounted() {
    Bus.$on('event.invoice.character.contextmenu', this.showMenu);
  },
  unmounted() {
    Bus.$off('event.invoice.character.contextmenu', this.showMenu);
  },
  methods: {

    showMenu({event, data, field}) {
      this.$nextTick(() => {
        this.selectedRow = data;
        this.items = this.getItems(field);
        this.$refs.cm.show(event);
      });
    },

    getItems(field) {
      switch (field) {
        case 'price':
          return this.getPriceMenu();
        case 'cost':
          return this.getCostMenu();
        case 'employee':
          return this.getEmployeeMenu();
        case 'start_time':
          return this.getTimeMenu();
        case 'end_time':
          return this.getTimeMenu();
        default:
          return this.standard;
      }
    },

    getPriceMenu() {
      return [
         {label: 'Calculate Price', icon: 'pi pi-fw pi-calculator', command: this.calculatePricing},
         {separator: true},
        ...this.standard
      ];
    },

    getCostMenu() {
      return [
        // {label: 'Calculate Costs', icon: 'pi pi-fw pi-calculator', command: this.calculateCost},
        // {separator: true},
        ...this.standard
      ];
    },

    getEmployeeMenu() {

      let employee = (this.selectedRow?.employee_id !== null)
        ? [
          {label: 'Remove Staff', icon: 'pi pi-fw pi-times', command: this.removeStaff},
          {label: 'Add Tip', icon: 'pi pi-fw pi-dollar', command: this.addTip},
          {label: 'Add Travel', icon: 'pi pi-fw pi-truck', command: this.addEmployeeTravel},
          {separator: true},
        ]
        : [];

      return [
        ...employee,
        ...this.standard
      ];
    },

    getTimeMenu(){
      return [
        {label: 'Reset Time', icon: 'pi pi-fw pi-refresh', command: this.resetTime},
        {separator: true},
        ...this.standard
      ];
    },

    viewEmployeeSchedule(){
    },

    removeStaff(){
      this.selectedRow.employee_id = null;
      store.dispatch('Scheduleables/editScheduleable', this.selectedRow);
    },

    resetTime(){
      this.selectedRow.start_time = this.startTime;
      this.selectedRow.end_time = this.endTime;
      store.dispatch('Scheduleables/editScheduleable', this.selectedRow);
    },

    addTip() {
      store.dispatch('Scheduleables/addScheduleable', {misc: `Tip For Performer: ${this.selectedRow.character.name}`, 'employee_id': this.selectedRow.employee_id });
    },

    clone() {
      store.commit('Scheduleables/charactersLoading', true);
      store.dispatch('Scheduleables/cloneScheduleable', this.selectedRow);
    },

    delete() {
      store.commit('Scheduleables/charactersLoading', true);
      store.dispatch('Scheduleables/removeScheduleable', this.selectedRow);
    },

    calculatePricing() {
      let pricing = this.selectedRow.character.pricing;
      let durationMinutes = this.getDurationInMins();

      let closestKey = null;

      for (const key in pricing) {
        if (durationMinutes <= key) {
          closestKey = key;
          break;
        }
      }

      // If no exact match is found, use the highest available key
      if (closestKey === null) {
        closestKey = Math.max(...Object.keys(pricing).map(Number));
      }

      this.selectedRow.price = pricing[closestKey];
      store.dispatch('Scheduleables/editScheduleable', this.selectedRow);
    },

    async addEmployeeTravel() {
      store.commit('Scheduleables/miscLoading', true);

      try {
        let {
          rideTime,
          fee,
          durationText,
          distance
        } = await api.url(`/google/directions/arrival/${this.party.id}/employee/${this.selectedRow.employee_id}`).get().json();

        await store.dispatch('Scheduleables/addScheduleable', {
          misc: `Travel: Performer from ${this.selectedRow.employee.city || 'NoHo'} : ${durationText}`,
          employee_id: this.selectedRow.employee_id,
          price: fee,
          cost: fee
        });

        await store.dispatch('Interactions/create', `Employee Travel Quote $${fee} | duration: ${durationText} (${rideTime}) | distance: ${distance} | ${this.address}`)

      } catch (e) {
        this.$toast.add({severity: 'error', summary: 'Travel Query Error', detail: e.message, life: 5000});
      }
    },

    getDurationInMins() {
      const start = dayjs(this.selectedRow.start_time);
      const end = dayjs(this.selectedRow.end_time);
      return end.diff(start, 'minute');
    },
  },
}
</script>

<style scoped lang="scss">

</style>

<template>
    <div class="grid">
        <div class="col-12 md:col-6">
            <h2>Party Location</h2>

            <div class="formgrid grid position-relative">

                <div class="col-12 field">
                    <label for="Search" class="text-600 text-sm font-bold">Search
                        {{ business ? 'Business' : 'Residential' }}</label>
                    <InputGroup>
                        <Button
                                icon="pi pi-building"
                                :class="{'p-button-primary': business, 'p-button-outlined p-button-secondary': !business}"
                                @click="business=true"
                        />
                        <Button
                                icon="pi pi-home"
                                :class="{'p-button-primary': !business, 'p-button-outlined p-button-secondary': business}"
                                @click="business=false"
                        />

                        <GMapAutocomplete
                                v-if="business"
                                :options="{types: ['establishment']}"
                                placeholder="Business Search"
                                @place_changed="setPlace"
                                id="search"
                                class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round border-noround-left appearance-none outline-none focus:border-primary w-full"
                        >
                        </GMapAutocomplete>
                        <GMapAutocomplete
                                v-else
                                placeholder="Residential Search"
                                @place_changed="setPlace"
                                id="search"
                                class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round border-noround-left appearance-none outline-none focus:border-primary w-full"
                        >
                        </GMapAutocomplete>
                    </InputGroup>
                </div>

                <div class="col-12 md:col-12 field">
                    <label class="text-600 text-sm font-bold" for="venue">Venue Name</label>
                    <InputText id="venue" v-model="venue"
                               class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"/>
                </div>
            </div>
            <div class="formgrid grid">
                <div class="col-12 md:col-12 field">
                    <label class="text-600 text-sm font-bold" for="address">Address of party</label>
                    <InputText id="address" v-model="address"
                               class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"/>
                </div>
                <div class="col-12 md:col-6 field">
                    <label class="text-600 text-sm font-bold" for="city">City</label>
                    <InputText id="city" v-model="city"
                               class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"/>
                </div>
                <div class="col-12 md:col-6 field">
                    <label class="text-600 text-sm font-bold" for="zip">Zip Code</label>
                    <InputText id="zip" v-model="zip"
                               class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"/>
                </div>
                <div class="col-12 md:col-12 field">
                    <label class="text-600 text-sm font-bold" for="venue_instructions">Location special
                        instructions</label>
                    <Textarea id="venue_instructions" v-model="venue_instructions"
                              class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"/>
                </div>
            </div>
        </div>
        <div class="col-12 md:col-6">
            <event-map :address="address" :city="city" :zip="zip" only-map height="31rem"></event-map>
        </div>
    </div>
</template>


<script>
import EventMap from "@/components/events/common/event-map.vue";
import {sync} from 'vuex-pathify';

export default {
  components: {EventMap},
  data() {
    return {
      search: null,
      business: true,
    }
  },
  computed: {
    ...sync([
      'Parties/Party/venue',
      'Parties/Party/address',
      'Parties/Party/city',
      'Parties/Party/zip',
      'Parties/Party/venue_instructions',
    ], null, false)
  },
  methods: {
    setPlace(place) {
      if (place.name) {
        this.venue = this.business ? place.name : "Residence";
      }

      if (place.address_components) {
        let formattedFullAddress = [];

        for (let i = 0; i < place.address_components.length; i++) {
          let component = place.address_components[i];

          if (component.types.includes('street_number')) {
            formattedFullAddress[0] = component.long_name;
          } else if (component.types.includes('route')) {
            formattedFullAddress[1] = " " + component.long_name;
          } else if (component.types.includes('subpremise')) {
            formattedFullAddress[3] = ", " + component.long_name;
          }
        }

        this.address = formattedFullAddress.join('');
      }

      let cityComponent = place.address_components.find(c => c.types.includes('locality'));
      let subCityComponent = place.address_components.find(c => c.types.includes('sublocality') || c.types.includes('neighborhood'));

      // Use sublocality or neighborhood if available, otherwise use locality
      this.city = subCityComponent ? subCityComponent.long_name : cityComponent ? cityComponent.long_name : undefined;

      let postalComponent = place.address_components.find(c => c.types.includes('postal_code'));
      if (postalComponent) {
        this.zip = postalComponent.long_name;
      }
    },
  }
}
</script>

<style lang="scss">
.pac-container {
  z-index: 9999999;
}
</style>


<template>
  <Sidebar v-model:visible="visible" position="left" class="w-6" :modal="false" :dismissable="false">
    <template #container="">
      <div class="h-90vh pt-0" v-if="customer">
        <section v-if="loading" class="flex justify-content-center align-content-center h-30rem">
          <progress-spinner class="h-full"/>
        </section>
        <section v-else class="fadein animation-duration-500">
          <div class="grid p-5 pb-0">
            <div class="col-8">
              <customer-header :customer="customer"/>
            </div>
            <div class="col-4">
              <div class="flex align-items-center justify-content-end">
<!--                <Button type="button" @click="activeTab=1" icon="pi pi-envelope" severity="secondary" text rounded></Button>-->
                <Button type="button" @click="save" icon="pi pi-save" severity="secondary" text rounded></Button>
                <Button type="button" @click="phoneCustomer" icon="pi pi-phone" severity="secondary" text rounded></Button>
                <Button type="button" @click="close" icon="pi pi-times" severity="secondary" text rounded></Button>
              </div>
            </div>
          </div>
          <TabView v-model:active-index="activeTab">

            <TabPanel header="Events">
              <customer-highlights/>
              <customer-parties-table></customer-parties-table>
            </TabPanel>


            <TabPanel header="Items">
              <customer-items-table></customer-items-table>
            </TabPanel>

            <TabPanel header="Payments">
              <customer-transactions/>
            </TabPanel>

            <TabPanel header="Edit">
              <customer-edit/>
            </TabPanel>

            <TabPanel>
              <template #header>
                <div class="flex align-items-center gap-2">
                  <icon-quickbooks v-if="customer.quickbooks_id" size="16"/>
                  <icon-quickbooks v-else size="16" color="#cccccc"/>
                  <span class="p-tabview-title">Quickbooks</span>
                </div>
              </template>
              <customer-quickbooks/>
            </TabPanel>

            <TabPanel header="Notes">
              <h1 class="m-0 mb-2 text-500">Notes about Customer</h1>
              <notes-editor v-model="notes"/>
            </TabPanel>

          </TabView>
        </section>
      </div>
    </template>
  </Sidebar>
</template>

<script>
import {sync} from 'vuex-pathify';
import IconQuickbooks from "@/components/common/icon-quickbooks.vue";
import CustomerQuickbooks from "@/components/customers/quickbooks/customer-quickbooks.vue";
import CustomerEdit from "@/components/customers/customer-edit.vue";
import CustomerHighlights from "@/components/customers/customer-highlights.vue";
import CustomerTransactions from "@/components/customers/customer-transactions.vue";
import CustomerHeader from "@/components/customers/customer-header.vue";
import CustomerItemsTable from "@/components/customers/customer-items-table.vue";
import CustomerItemsContextMenu from "@/components/customers/customer-items-context-menu.vue";
import NotesEditor from "@/components/common/notes-editor.vue";
import Bus from "@/bus";
import {toastErrors} from "@/helpers/errors.helper";
import CustomerPartiesTable from "@/components/customers/customer-parties-table.vue";

export default {
  name: 'customer-quick-view',
  components: {
    CustomerPartiesTable,
    NotesEditor,
    CustomerItemsContextMenu,
    CustomerItemsTable,
    CustomerHeader,
    CustomerTransactions,
    CustomerHighlights, CustomerEdit, CustomerQuickbooks, IconQuickbooks
  },
  mounted() {
    Bus.$on('customers.update', this.load)
  },
  beforeUnmount() {
    Bus.$off('customers.update', this.load)
  },
  data() {
    return {
      activeTab: 0,
      key:0,
    }
  },
  computed: {

    loading() {
      return store.get('Customers/Customer/loading');
    },

    parties() {
      return store.get('Customers/parties');
    },

    visible: sync('Customers/view', null, false),

    customer() {
      return store.get('Customers/current');
    },

    notes: sync('Customers/Customer/notes', null, false)
  },

  methods: {

    load(){
      //stores.dispatch('Customers/getCustomerById', this.customer.id);
      //stores.dispatch('Customers/loadItems', {id: this.customer.id});
    },

    async save() {
      try {
        await store.dispatch('Customers/Customer/save');
        Bus.$emit('customers.update');
        this.$toast.add({severity: 'success', summary: 'Success', detail: 'Customer Saved', life: 3000});
        this.load();
      } catch (e) {
        toastErrors(this.$toast, e);
      }
    },

    close() {
      this.visible = false;
    },

    phoneCustomer() {
      window.location.href = `tel:${this.customer.phone}`;
    }
  }
};
</script>

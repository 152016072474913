<template>
  <section id="main-container">
    <main-menu-bar></main-menu-bar>
    <div class="flex flex-column surface-ground overflow-y-auto" style="height:87vh;">
      <div class="p-5 flex flex-column flex-auto surface-ground">
        <section class="grid">
          <div class="col-6">
            <div class="flex justify-content-end mb-3">
              <InputGroup>
                <InputGroupAddon>
                  <i class="pi pi-search"/>
                </InputGroupAddon>
                <character-search-filter class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border appearance-none outline-none focus:border-primary w-full"/>
              </InputGroup>

              <Button icon="pi pi-plus" class="button-rounded button-info ml-3" @click="addCharacter"/>
            </div>
            <div>
              <character-list v-if="showListView"></character-list>
              <characters-cards v-else></characters-cards>
            </div>
          </div>
          <div class="col-6">
            <character-profile v-if="showProfile"></character-profile>
          </div>
        </section>
      </div>
    </div>
    <quick-views/>
    <modals/>
    <toasts/>
    <confirms/>
  </section>
</template>

<script>
import CharacterList from "@/components/characters/list/character-list";
import CharacterProfile from "@/components/characters/profile/character-profile";
import CharacterSearchFilter from "@/components/characters/common/character-search-filter";
import CharactersCards from "@/components/characters/cards/characters-cards";
import QuickViews from "@/components/common/quick-views.vue";
import MainMenuBar from "@/components/main-menu/main-menu-bar.vue";
import Confirms from "@/components/common/confirms.vue";
import Modals from "@/components/common/modals.vue";
import Toasts from "@/components/common/toasts.vue";
import UnsettledTransactionTable from "@/components/banking/unsettled-transaction-table.vue";
import TransactionTable from "@/components/banking/transaction-table.vue";

export default {
  name: "CharactersView",
  components: {
    TransactionTable, UnsettledTransactionTable, Toasts, Modals, Confirms, MainMenuBar, QuickViews,
    CharacterList,
    CharacterProfile,
    CharacterSearchFilter,
    CharactersCards
  },
  data() {
    return {
      drawer: false,
      showListView: false,
      showProfile: true
    };
  },
  methods: {
    addCharacter() {
      store.dispatch('Character/create');
    },
    toggleView() {
      this.showListView = !this.showListView;
    },
    initData() {
      this.$store.dispatch('Characters/load');
      this.$store.dispatch('Characters/loadTags');
      this.showListView = this.$store.getters['Characters/view'] === 'list';
    }
  },
  created() {
    this.initData();
  }
}
</script>

<style scoped lang="scss">
#main-container {
  max-width: 1600px;
  overflow: auto;
  margin: 0 auto;
}
</style>

<template>
  <Button type="button" @click="visible=true" icon="pi pi-book" severity="secondary" text rounded title="Interactions"></Button>
  <Dialog v-model:visible="visible" modal class="surface-overlay" position="top" id="interactions"
          :style="{width: '950px'}"
          @dblclick.stop>
    <template #container>
      <section class="p-5">

        <div class="flex justify-content-between">
          <h2 class="text-600 m-1"><i class="pi pi-book mr-2"></i> Party Memo</h2>
          <div>
            <Button type="button" :icon="showAddNote?'pi pi-chevron-up':'pi pi-plus'" class="ml-2 align-self-start" size="small" text rounded @click="toggleNewNote" severity="secondary"></Button>
            <Button type="button" icon="pi pi-times" class="ml-2 align-self-start" size="small" text rounded @click="close" severity="secondary"></Button>
          </div>
        </div>

        <div class="my-2">
          <p>Memos are notes and interactions with customer and history of what we have sent the customer and status'
            changing, in order of latest interaction</p>

          <div v-if="showAddNote" class="fadein animation-duration-500">
<!--            <Textarea v-model="draft.notes" class="w-full" ref="draft"></Textarea>-->

            <Editor id="note" v-model="draft.notes" editorStyle="height: 180px" class="w-full" ref="draft"></Editor>
            <div class="flex justify-content-end">
              <Button type="button" icon="pi pi-save" label="Add Note" class="align-self-end my-2" size="small" rounded @click="saveInteraction"></Button>
            </div>
          </div>

          <section v-if="loading" class="flex justify-content-center align-content-center h-30rem">
            <progress-spinner class="h-full"/>
          </section>
          <section v-else class="fadein animation-duration-500">
          <ScrollPanel :style="`width: 100%; height: ${showAddNote? '50vh': '60vh'}`">
            <Timeline :value="interactions" data-key="id">
              <template #content="{item}">
                <event-interactions-item :item="item"/>
              </template>
            </Timeline>
          </ScrollPanel>
          </section>

        </div>
      </section>
    </template>
  </Dialog>
</template>

<script>

import EventInteractionsItem from "@/components/events/interactions/event-interactions-item.vue";

export default {
  name: "event-interactions",
  components: {EventInteractionsItem},
  data() {
    return {
      visible: false,
      draft: {title: "Note", notes: "", id: 0},
      showAddNote: false,
    }
  },

  computed: {
    party() {
      return store.get('Parties/Party/current');
    },
    loading(){
      return store.get('Interactions/loading');
    },
    interactions() {
      return store.get('Interactions/list');
    }
  },

  methods: {

    async saveInteraction() {
      try {
        await store.dispatch('Interactions/save', {party_id: this.party.id, ...this.draft});
        this.$toast.add({severity: 'success', summary: 'Note Added', detail: 'Note has been added.', life: 3000});

        this.draft.notes = "";
        this.showAddNote = false;
      } catch (e) {
        this.$toast.add({severity: 'error', summary: 'Error', detail: e.message, life: 3000});
      }
    },

    toggleNewNote() {
      this.showAddNote = !this.showAddNote;
      if(this.showAddNote){
        this.$nextTick(() => {
          this.$refs.draft.$el.focus();
        });
      }
    },

    close() {
      this.visible = false;
    }
  },
}
</script>

<style lang="scss">
#interactions .p-timeline-event-opposite {
  display: none;
}
</style>

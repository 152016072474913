import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);
dayjs.extend(utc);

export function formatTime(date) {
  let time = dayjs(date);
  return time.isValid() ? time.format('h:mma') : '-';
}
export function formatEndTime(date, duration) {
  const dateTime = dayjs(date).add(dayjs.duration(duration, 'hours'));
  return formatTime(dateTime);
}
export function getFirstStartTime(scheduleables) {
  if (scheduleables.length === 0) {
    return dayjs();
  }
  return scheduleables.reduce((min, gig) => {
    return dayjs(gig.start_time).isBefore(min) ? dayjs(gig.start_time) : min;
  }, dayjs(scheduleables[0].start_time));
}

export function getLastEndTime(scheduleables) {
  if (scheduleables.length === 0) {
    return dayjs();
  }
  return scheduleables.reduce((max, gig) => {
    return dayjs(gig.end_time).isAfter(max) ? dayjs(gig.end_time) : max;
  }, dayjs(scheduleables[0].end_time));
}

export function shortTimeRange(start_time, end_time) {
  const start = dayjs(start_time);
  const end = dayjs(end_time);

  // Calculate the difference in minutes and convert to hours with two decimal places
  const diffInMinutes = end.diff(start, 'minutes');
  const diffInHours = (diffInMinutes / 60).toFixed(2); // Convert to hours and keep two decimal places

  return `${start.format('h:mm')}-${end.format('h:mma')} (${diffInHours} hrs)`;
}

export function getDiffInMins(date1, date2) {
  const start = dayjs(date1);
  const end = dayjs(date2);

  return end.diff(start, 'minute');
}

export function setDBTime(date, time) {
  let parsedTime = dayjs(time, ['YYYY-MM-DD HH:mm:ss', 'HH:mma', 'H:mma']);

  if (!parsedTime.isValid()) return false;

  const hours = parsedTime.hour();
  const minutes = parsedTime.minute();

  return dayjs(date).utc().hour(hours).minute(minutes).format('YYYY-MM-DD HH:mm:ss');
}

export function getDBTime(date) {
  return dayjs(date).format('YYYY-MM-DD HH:mm:ss');
}

export function isSameTime(time1, time2) {
  let a = dayjs(time1, ['YYYY-MM-DD HH:mm:ss', 'HH:mma', 'HH:mm', 'H:mm', 'HHmm', 'Hmm', 'HH', 'H']).format('HH:mm');
  let b =dayjs(time2, ['YYYY-MM-DD HH:mm:ss', 'HH:mma', 'HH:mm', 'H:mm', 'HHmm', 'Hmm', 'HH', 'H']).format('HH:mm');
  return a===b;
}

export function replaceTimeOnDate(date, val) {
  // Parse the time string with AM/PM
  const dateTime = dayjs(date).format('YYYY-MM-DD') + ' ' + val;
  const formattedDateTime = dayjs(dateTime, 'YYYY-MM-DD h:mma');

  if (!formattedDateTime.isValid()) {
    console.error('Invalid date or time value:', date, val);
    return null;
  }

  return formattedDateTime.format('YYYY-MM-DD HH:mm:ss');
}

<template>
  <ScrollPanel v-if="characters"  style="width: 100%; height: 80vh">
    <div class="grid">
      <div v-for="character in characters" :key="character.id" class="col-3">
        <characters-card :character="character"></characters-card>
      </div>
    </div>
  </ScrollPanel>
</template>

<script>
import CharactersCard from "@/components/characters/cards/characters-card";

export default {
  name: "characters-cards",
  components: {
    CharactersCard
  },
  computed: {
    characters(){
      return store.get('Characters/characters');
    }
  }
}
</script>

<style lang="scss" scoped>
.scrollable {
  height: 100vh;
  overflow-y: auto;
}
</style>

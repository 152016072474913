import { createStore } from 'vuex';
import { createLogger } from 'vuex';

import pathify from "./pathify.config";

import Parties from './parties.store';
import Customers from './customers.store';
import Characters from './characters.store';
import Scheduleables from './party.scheduleable.store';
import Banking from './banking.store';
import Calendar from './calendar.store';
import DayCalendar from './dayCalendar.store';

import Employees from './employees.store';
import Services from './services.store';

import Authorize from './authorize.store';
import Interactions from './party.interactions.store';
import Logistics from './logistics.store';
import Bills from './bills.store';
import Labor from './labor.store';
import Expenses from './expenses.store';
import Gigs from './gigs.store';

const plugins = [pathify.plugin];

if (process.env.NODE_ENV === 'development') {
  plugins.push(createLogger({
    logMutations: false,
  }));
}

export const store = createStore({
  plugins,
  modules: {
    Parties,
    Customers,
    Employees,
    Characters,
    Services,
    Scheduleables,
    Banking,
    Calendar,
    DayCalendar,
    Authorize,
    Interactions,
    Logistics,
    Bills,
    Labor,
    Expenses,
    Gigs
  }
});

<script>
export default {
  name: "email-snipit-misc",
  props:{
    showDesc: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    misc() {
      return store.get('Scheduleables/miscVisible');
    }
  }
}
</script>

<template>
  <div v-if="misc.length">
    <h3>Miscellaneous</h3>
    <p v-if="showDesc">
      Some items are not yet listed on our website as we are always adding new things. We use Miscellaneous invoice items
      for not yet listed characters, services and also delivery and other personalized rates.
    </p>
    <ul>
      <li v-for="item in misc" :key="item.id">{{ item.misc }}</li>
    </ul>
  </div>
</template>

<style scoped lang="scss">

</style>
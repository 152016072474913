<template>
  <Dialog v-model:visible="visible" modal class="h-full surface-overlay" position="top"
          :style="{width: '1200px'}"
          @dblclick.stop
          @hide="onHide"
  >
    <template #header>
      <div class="flex justify-content-between align-items-center w-full">
        <div>
          <h2 class="text-600 m-0">
            {{isPaid?'View Bill':'Edit Bill'}} : {{currentBill.formatted_id}} : {{ employee.first }} {{ employee.last }}
          </h2>
          <span class="text-500 text-primary" v-if="isPaid">Paid: {{ currentBill.paid_on }}</span>
        </div>
        <div>
          <Button icon="pi pi-refresh" class="p-button-text" @click="reload" title="Reload Bill"/>
          <Button icon="pi pi-save" class="p-button-text" @click="saveBill" :loading="isSavingBill"/>
        </div>
      </div>
    </template>
    <section class="relative">
      <section v-if="isLoading" class="flex justify-content-center align-content-center h-30rem">
        <progress-spinner class="h-full"/>
      </section>
      <section v-else class="fadein animation-duration-500">
        <section class="p-0">
          <bill-invoice ref="billInvoice" :key="reloadKey"/>
        </section>
      </section>
    </section>
  </Dialog>
</template>

<script>
import {sync} from "vuex-pathify";
import BillInvoice from "@/components/team/bills/bill-invoice.vue";
import {concatErrors} from "@/helpers/errors.helper";

export default {
  name: "bills-modal",

  components: {BillInvoice},

  data() {
    return {
      isLoading: false,
      isSavingBill: false,
    }
  },

  computed: {

    visible: sync('Bills/edit', null, false),

    currentBill() {
      return store.get('Bills/current');
    },

    reloadKey: sync('Bills/reloadKey', null, false),

    employee() {
      return store.get('Employees/current');
    },

    billDate() {
      return this.currentBill?.start_time;
    },
    isPaid() {
      return !!this.currentBill?.paid_on;
    }
  },
  methods: {

    async saveBill() {

      this.isLoading = true;

      try {

        await store.dispatch('Bills/Bill/save');

        this.$toast.add({
          severity: 'success',
          summary: 'Success',
          detail: `Bill updated for ${this.employee.first} ${this.employee.last}`,
          life: 3000
        });

        this.close();

      } catch (error) {
        let msg = concatErrors(error.json);
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: `${msg}`,
          life: 3000
        });
      } finally {
        this.isLoading = false;
      }
    },

    close() {
      this.visible = false;
      store.commit('Bills/current', null);
    },

    onHide() {
      store.commit('Bills/current', null);
    },

    reload() {
      this.reloadKey++;
      store.dispatch("Bills/reload");
    }
  }

}
</script>

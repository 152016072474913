import { createApp } from 'vue'

import { store }  from './stores';
window.store = store;

import router from './router';
window.router = router;

import api from './config.wretch';
window.api = api;

import App from './App.vue'
import PrimeVue from 'primevue/config';
import VueGoogleMaps from '@fawmi/vue-google-maps';

import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Panel from 'primevue/panel';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import Row from 'primevue/row';
import Paginator from 'primevue/paginator';
import InputText from 'primevue/inputtext';
import InputMask from 'primevue/inputmask';
import InputSwitch from "primevue/inputswitch";
import InputGroup from "primevue/inputgroup";
import InputGroupAddon from "primevue/inputgroupaddon";
import InputNumber from "primevue/inputnumber";
import Dropdown from 'primevue/dropdown';
import Sidebar from  'primevue/sidebar';
import Button from  'primevue/button';
import Calendar from 'primevue/calendar';
import Textarea from 'primevue/textarea';
import Avatar from 'primevue/avatar';
import AvatarGroup from 'primevue/avatargroup';
import ListBox from 'primevue/listbox';
import Dialog from "primevue/dialog";
import OverlayPanel from "primevue/overlaypanel";
import ContextMenu from "primevue/contextmenu";
import AutoComplete from "primevue/autocomplete";
import Checkbox from "primevue/checkbox";
import BlockUI from "primevue/blockui";
import SplitButton from "primevue/splitbutton";
import MultiSelect from 'primevue/multiselect';
import ToggleButton from 'primevue/togglebutton';
import Chip from "primevue/chip";
import Inplace from "primevue/inplace";
import Message from "primevue/message";
import PickList from "primevue/picklist";
import Menu from "primevue/menu";

import ConfirmDialog from "primevue/confirmdialog";

import ConfirmationService from 'primevue/confirmationservice';

import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';

import Menubar from "primevue/menubar";
import Timeline from "primevue/timeline";
import Card from "primevue/card";
import SelectButton from "primevue/selectbutton";
import Editor from "primevue/editor";
import ScrollPanel from "primevue/scrollpanel";
import ProgressSpinner from "primevue/progressspinner";
import FileUpload from "primevue/fileupload";

import Ripple from 'primevue/ripple';
import StyleClass from 'primevue/styleclass';
import Badge from 'primevue/badge';
import BadgeDirective from 'primevue/badgedirective';

import './scss/style.scss';

import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
//import 'primevue/resources/themes/lara-light-purple/theme.css';
import './assets/themes/theme.css';

import Quill from 'quill';
import QuillTable from 'quill-table';
import * as QuillTableUI from 'quill-table-ui';
import Config from "./config";

Quill.register(QuillTable.TableCell);
Quill.register(QuillTable.TableRow);
Quill.register(QuillTable.Table);
Quill.register(QuillTable.Contain);

Quill.register('modules/table', QuillTable.TableModule);

Quill.register({
  'modules/tableUI': QuillTableUI
}, true)

const app = createApp(App);

// Register components
app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('AutoComplete', AutoComplete);
app.component('Avatar', Avatar);
app.component('AvatarGroup', AvatarGroup);
app.component('Badge', Badge);
app.component('BlockUI', BlockUI);
app.component('Button', Button);
app.component('Calendar', Calendar);
app.component('Card', Card);
app.component('Checkbox', Checkbox);
app.component('Chip', Chip);
app.component('Column', Column);
app.component('ColumnGroup', ColumnGroup);
app.component('ConfirmDialog', ConfirmDialog);
app.component('ContextMenu', ContextMenu);
app.component('DataTable', DataTable);
app.component('Dialog', Dialog);
app.component('Dropdown', Dropdown);
app.component('Editor', Editor);
app.component('FileUpload', FileUpload);
app.component('Inplace', Inplace);
app.component('InputGroup', InputGroup);
app.component('InputGroupAddon', InputGroupAddon);
app.component('InputMask', InputMask);
app.component('InputNumber', InputNumber);
app.component('InputSwitch', InputSwitch);
app.component('InputText', InputText);
app.component('ListBox', ListBox);
app.component('Menubar', Menubar);
app.component('Menu', Menu);
app.component('Message', Message);
app.component('MultiSelect', MultiSelect);
app.component('OverlayPanel', OverlayPanel);
app.component('Paginator', Paginator);
app.component('Panel', Panel);
app.component('ProgressSpinner', ProgressSpinner);
app.component('Row', Row);
app.component('ScrollPanel', ScrollPanel);
app.component('SelectButton', SelectButton);
app.component('Sidebar', Sidebar);
app.component('SplitButton', SplitButton);
app.component('TabPanel', TabPanel);
app.component('TabView', TabView);
app.component('Textarea', Textarea);
app.component('Timeline', Timeline);
app.component('Toast', Toast);
app.component('ToggleButton', ToggleButton);
app.component('SelectButton ', SelectButton );
app.component('PickList', PickList);

// Register directives
app.directive('badge', BadgeDirective);
app.directive('ripple', Ripple);
app.directive('styleclass', StyleClass);

app.use(router);
app.use(PrimeVue, { ripple: true });

app.use(ConfirmationService);
app.use(ToastService);

app.use(VueGoogleMaps, {
  load: {
    key: Config.googlePlacesKey,
    libraries: "places"
  },
});

app.use(store);
app.mount('#app');

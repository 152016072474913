<template>
  <div v-if="current.archived">
      <h2>Cannot Edit Invoice</h2>
      <p>Party is archived. Unarchive party to edit.</p>
  </div>
  <div v-else-if="!isEditable">
    <h2>Cannot Edit Invoice</h2>
    <p>Party must be New, Booking or Complete to update. Change status to update.</p>
  </div>
  <ScrollPanel v-else style="width: 100%; height: 60vh" class="z-depth-1">
    <accordion id="invoice" :multiple="true" v-model:activeIndex="index" @tab-open="loadTab">
      <accordion-tab>
        <template #header>
          <invoice-header-character/>
        </template>
        <invoice-character-table v-if="loaded[0]"/>
      </accordion-tab>
      <accordion-tab>
        <template #header>
          <invoice-header-services/>
        </template>
        <invoice-services-table v-if="loaded[1]"/>
      </accordion-tab>
      <accordion-tab>
        <template #header>
          <invoice-header-misc/>
        </template>
        <invoice-misc-table v-if="loaded[2]"/>
      </accordion-tab>
      <accordion-tab>
        <template #header>
          <div class="flex justify-content-between w-full">
            <h2>Totals</h2>
            <h2 class="text-right">{{ formatMoney(total) }}</h2>
          </div>
        </template>
        <invoice-transaction-table  v-if="loaded[3]"/>
      </accordion-tab>
    </accordion>
    <employee-picker/>
    <div class="w-full h-15rem"></div>
  </ScrollPanel>
</template>

<script>

import EmployeePicker from "@/components/employees/employee-picker.vue";
import InvoiceCharacterTable from "@/components/events/edit/invoice/character/invoice-character-table.vue";
import InvoiceServicesTable from "@/components/events/edit/invoice/services/invoice-services-table.vue";
import InvoiceMiscTable from "@/components/events/edit/invoice/misc/invoice-misc-table.vue";
import {getCharacterImage, getServiceImage} from "@/helpers/images.helper";
import {formatMoney} from "accounting-js";
import InvoiceTransactionTable from "@/components/events/edit/invoice/invoice-transaction-table.vue";
import InvoiceHeaderCharacter from "@/components/events/edit/invoice/character/invoice-header-character.vue";
import InvoiceHeaderServices from "@/components/events/edit/invoice/services/invoice-header-services.vue";
import InvoiceHeaderMisc from "@/components/events/edit/invoice/misc/invoice-header-misc.vue";

export default {
  name: "event-invoice",
  components: {
    InvoiceHeaderMisc,
    InvoiceHeaderServices,
    InvoiceHeaderCharacter,
    InvoiceTransactionTable, InvoiceMiscTable, InvoiceServicesTable, InvoiceCharacterTable, EmployeePicker
  },
  data() {
    return {
      index: [],
      loaded:[false,false,false],
    }
  },
  computed: {
    total() {
      return store.get('Parties/Party/total');
    },
    current() {
      return store.get('Parties/Party/current');
    },
    status() {
      return store.get('Parties/Party/status');
    },
    isEditable(){
      return ['inquiry', 'booking', 'complete'].includes(this.status);
    }
  },
  methods: {

    ...{getCharacterImage, getServiceImage, formatMoney},

    loadTab(e){
      this.loaded[e.index]=true;
    },
  },
};
</script>

<style lang="scss">
/* Add your styles here */
#invoice .p-accordion-header-link {
  padding: 0 1.25rem;
  border: none;
  border-radius: 0;
}

#invoice .p-accordion-content {
  padding: 0;
  border: none;
}

#invoice .p-datatable .p-datatable-tfoot > tr > td {
  background: none;
}
</style>

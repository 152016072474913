<template>
  <section>
    <div class="my-3 flex justify-content-between">
      <logo-company :height="90"></logo-company>
      <div class="w-15rem py-3 text-500">
        <div>Santa Clarita, Ca 91354</div>
        <div>{{phone}}</div>
      </div>
    </div>
    <div class="flex justify-content-between text-600 mb-3">
      <div>
        <h2 class="text-600">Customer</h2>
        <ul class="list-none m-0 p-0">
          <li v-if="customer.company">{{ customer.company }}</li>
          <li>{{ customer.full_name }}</li>
          <li>{{ party.address }}</li>
          <li>{{ party.city }}, {{ party.state }} {{ party.zip }}</li>
          <li>{{ customer.phone }}</li>
        </ul>
      </div>
      <div>
        <h2 class="text-600">Venue</h2>
        <ul class="list-none m-0 p-0">
          <li>{{ party.venue }}</li>
          <li>{{ party.address }}</li>
          <li>{{ party.city }}, {{ party.state }} {{ party.zip }}</li>
          <li>{{ party.phone2 }}</li>
        </ul>
      </div>
      <div>
        <h2 class="text-600">Invoice</h2>
        <div class="flex justify-content-end">
          <ul class="list-none m-0 p-0 mr-3">
            <li class="text-600 font-bold">Invoice ID:</li>
            <li class="text-600 font-bold">Invoice Date:</li>
            <li class="text-600 font-bold">Event Date:</li>
          </ul>
          <ul class="list-none m-0 p-0">
            <li>{{ party.token }}</li>
            <li>{{ formatWordDate(party.created_at) }}</li>
            <li>{{ formatWordDate(party.date) }}</li>
          </ul>
        </div>
      </div>
    </div>

    <character-table :hide-images="!showImages" :hide-money="!showMoney" :hide-staff="!showStaff" :hide-cost="!showCost"/>
    <services-table :hide-images="!showImages" :hide-money="!showMoney" :hide-staff="!showStaff" :hide-cost="!showCost" :hide-invisible="true"/>
    <misc-table :hide-money="!showMoney" :hide-staff="!showStaff" :hide-cost="!showCost" :hide-invisible="true"/>
    <div class="grid">
      <div class="col-6">
        <div v-if="party.party_notes && showNotes">
          <h2 class="text-600">Notes</h2>
          <p>{{ party.party_notes }}</p>
        </div>
      </div>

      <div v-if="showMoney" class="col-6">
        <div class="flex justify-content-end">
          <h2 class="text-600 mr-3">Total:</h2>
          <h2 class="text-600">{{ total }}</h2>
        </div>

        <div v-if="transactions.length">

          <DataTable :value="transactions" showGridlines stripedRows size="small">
            <template #header>
              <div class="flex flex-wrap align-items-center justify-content-between gap-2">
                <span class="text-xl text-600 font-bold">Payments</span>
              </div>
            </template>
            <Column field="submitTimeLocal" header="Submit Time">
              <template #body="{data}">
                {{ formatDateTime(data.submitTimeLocal) }}
              </template>
            </Column>
            <Column field="transId" header="Transaction ID"></Column>
            <Column field="amount" header="Amount">
              <template #body="{data}">
                {{ formatMoney(data.amount) }}
              </template>
            </Column>
            <ColumnGroup type="footer">
              <Row>
                <Column footer="Total:" colspan="2" footerStyle="text-align:right"/>
                <Column :footer="formatMoney(paymentsTotal)"/>
              </Row>
            </ColumnGroup>
          </DataTable>

          <div class="flex justify-content-end">
            <h2 class="text-600 mr-3">Balance:</h2>
            <h2 class="text-600">{{ formatMoney(balance) }}</h2>
          </div>

        </div>
      </div>
    </div>
  </section>
</template>

<script>
import MiscTable from "@/components/events/quick-view/tables/misc-table.vue";
import LogoCompany from "@/components/common/logo-company.vue";
import ServicesTable from "@/components/events/quick-view/tables/services-table.vue";
import CharacterTable from "@/components/events/quick-view/tables/character-table.vue";
import {formatDate, formatDateTime, formatWordDate} from "@/helpers/date.helper";
import Config from "@/config";
import {formatMoney} from "accounting-js";

import {capitalize} from "lodash";
import dayjs from "dayjs";

export default {
  name: "prints-customer-invoice",
  props: {
    showMoney: {
      type: Boolean,
      default: true
    },
    showCost: {
      type: Boolean,
      default: false
    },
    showImages: {
      type: Boolean,
      default: false
    },
    showStaff: {
      type: Boolean,
      default: false
    },
    showNotes: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      phone: Config.phone
    }
  },
  methods: {
    formatWordDate,
    formatMoney,
    formatDate,
    formatDateTime,
    capitalize,
  },
  components: {CharacterTable, ServicesTable, LogoCompany, MiscTable},
  computed: {
    party() {
      return store.get('Parties/Party/current');
    },
    customer() {
      return this.party.customer;
    },
    transactions() {
      return this.party.transactions;
    },
    total() {
      return formatMoney(store.get('Parties/Party/total'));
    },
    date() {
      return dayjs(this.party.date).format("MM/DD/YYYY, ddd");
    },
    startTime() {
      return dayjs(this.party.date).format('h:mma');
    },
    endTime() {
      return dayjs(this.party.date).add(dayjs.duration(this.party.duration, 'hours')).format('h:mma')
    },
    partyName() {
      return (this.party.for_whom)
        ? `${capitalize(this.party.for_whom)}'s ${capitalize(this.party.occasion) || 'Party'} in ${this.party.city}`
        : `${this.customer.full_name}'s ${capitalize(this.party.occasion) || 'Party'} in ${this.party.city}`;
    },
    balance() {
      return store.get('Parties/Party/balance');
    },
    paymentsTotal() {
      return store.get('Parties/Party/paymentsTotal');
    }
  }
}
</script>

<style scoped lang="scss">

</style>

<template>
  <section @click="closeEditor">
    <div v-if="show">
      <Textarea id="notes-editor" v-model="localNotes" class="w-full" rows="20" @update:model-value="update" @click.stop />
      <Button @click="closeEditor" icon="pi pi-times" label="Close Notes" rounded outlined class="mt-3" size="small"/>
    </div>
    <div v-else>
      <scroll-panel style="width:100%;height:75vh">
      <pre class="border-round border-1 border-300 p-3 mb-3 cursor-pointer" @click.stop="showEditor">{{localNotes}}</pre>
    </scroll-panel>
    </div>
  </section>
</template>

<script>
export default {
  name: 'NotesEditor',
  props: {
    modelValue: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      show: false,
      localNotes: this.modelValue
    };
  },
  // watch: {
  //   // Watch for changes from the parent
  //   modelValue(newVal) {
  //     this.localNotes = newVal;
  //   },
  //   // Watch for changes from the Editor (child)
  //   // and notify the parent
  //   localNotes(newVal) {
  //     this.$emit('update:modelValue', newVal);
  //   }
  // },
  methods: {
    showEditor() {
      this.show = true;
    },
    closeEditor(){
      this.show = false;
    },
    update(newVal) {
      this.$emit('update:modelValue', newVal);
    }
  }
};
</script>

<style scoped>
pre {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
}
</style>

<template>
  <section id="main-container">
    <main-menu-bar></main-menu-bar>
    <div class="flex flex-column surface-ground overflow-y-auto" style="height:87vh;">
      <div class="p-5 flex flex-column flex-auto surface-ground">
        <div class="surface-overlay p-4">

          <TabView :active-index="activeIndex" @tab-change="onTabChange">
            <TabPanel header="Team">
              <employees-table-server v-if="loadedTabs.team"/>
            </TabPanel>
            <TabPanel header="Bills">
              <div class="flex justify-content-between">
                <div>
                  <h1 class="text-600 text-4xl pb-0 mb-0">{{ billsUnpaidOnly ? 'Unpaid Bills' : 'All Bills' }}</h1>
                  <p class="p-0 m-1 mb-3">Collection of Gigs, Labor, and Expenses to be paid to Team Members</p>
                </div>
                <div class="w-2 text-right">
                  <InputSwitch v-model="billsUnpaidOnly" class="inline-block mt-3 mr-0" title="Toggle paid only view" @change="reloadBills"/>
                  <label v-if="billsUnpaidOnly" class="text-600 text-xs text-primary block">Unpaid Only</label>
                  <label v-else class="text-600 text-xs block">Show All</label>
                </div>
              </div>
              <bills-table-server v-if="loadedTabs.bills"/>
            </TabPanel>
            <TabPanel header="Gigs">
              <div class="flex justify-content-between">
                <div>
                  <h1 class="text-600 text-4xl pb-0 mb-0">{{ gigsUnpaidOnly ? 'Unpaid Gigs' : 'All Gigs' }}</h1>
                  <p class="p-0 m-1 mb-3">All event items <span class="font-bold">Serviced</span></p>
                </div>
                <div class="w-2 text-right">
                  <InputSwitch v-model="gigsUnpaidOnly" class="inline-block mt-3 mr-0" title="Toggle paid only view" @change="reloadGigs"/>
                  <label v-if="gigsUnpaidOnly" class="text-600 text-xs text-primary block">Unpaid Only</label>
                  <label v-else class="text-600 text-xs block">Show All</label>
                </div>
              </div>
              <gigs-table-server v-if="loadedTabs.gigs"/>
            </TabPanel>
            <TabPanel header="Timesheets">
              <div class="flex justify-content-between">
                <div>
                  <h1 class="text-600 text-4xl pb-0 mb-0">{{laborUnpaidOnly ? 'Unpaid Timesheets' : 'All Timesheets' }}</h1>
                  <p class="p-0 m-1 mb-3">Time Team Members have worked outside of gigs</p>
                </div>
                <div class="w-2 text-right">
                  <InputSwitch v-model="laborUnpaidOnly" class="inline-block mt-3 mr-0" title="Toggle paid only view" @change="reloadLabor"/>
                  <label v-if="laborUnpaidOnly" class="text-600 text-xs text-primary block">Unpaid Only</label>
                  <label v-else class="text-600 text-xs block">Show All</label>
                </div>
              </div>
              <labor-table-server v-if="loadedTabs.labor"/>
            </TabPanel>
            <TabPanel header="Expenses">
              <div class="flex justify-content-between">
                <div>
                  <h1 class="text-600 text-4xl pb-0 mb-0">{{expensesUnpaidOnly ? 'Unpaid Expenses' : 'All Expenses' }}</h1>
                  <p class="p-0 m-1 mb-3">Reimbursement of Money, Gas, or Other Misc Items to be paid to team members.</p>
                </div>
                <div class="w-2 text-right">
                  <InputSwitch v-model="expensesUnpaidOnly" class="inline-block mt-3 mr-0" title="Toggle paid only view" @change="reloadExpenses"/>
                  <label v-if="expensesUnpaidOnly" class="text-600 text-xs text-primary block">Unpaid Only</label>
                  <label v-else class="text-600 text-xs block">Show All</label>
                </div>
              </div>
              <expenses-table-server v-if="loadedTabs.expenses"/>
            </TabPanel>
          </TabView>

          <new-bill-modal/>
          <bills-modal/>

          <new-labor-modal/>
          <labor-modal/>

          <new-expense-modal/>
          <expense-modal/>
        </div>
      </div>
    </div>
    <quick-views/>
    <modals/>
    <toasts/>
    <confirms/>
  </section>
</template>

<script>
import EmployeesTableServer from "@/components/employees/employees-table-server.vue";
import MainMenuBar from "@/components/main-menu/main-menu-bar.vue";
import QuickViews from "@/components/common/quick-views.vue";
import Modals from "@/components/common/modals.vue";
import Toasts from "@/components/common/toasts.vue";
import Confirms from "@/components/common/confirms.vue";
import BillsTableServer from "@/components/team/bills/bills-table-server.vue";
import NewBillModal from "@/components/team/bills/new-bill-modal.vue";
import BillsModal from "@/components/team/bills/bills-modal.vue";
import LaborTableServer from "@/components/team/labor/labor-table-server.vue";
import ExpensesTableServer from "@/components/team/expenses/expenses-table-server.vue";
import LaborModal from "@/components/team/labor/labor-modal.vue";
import ExpenseModal from "@/components/team/expenses/expense-modal.vue";
import NewLaborModal from "@/components/team/labor/new-labor-modal.vue";
import GigsTable from "@/components/employees/quick-view/gigs-table.vue";
import GigsTableServer from "@/components/team/gigs/gigs-table-server.vue";
import NewExpenseModal from "@/components/team/expenses/new-expense-modal.vue";
import {sync} from "vuex-pathify";

export default {
  name: "employees",
  components: {
    NewExpenseModal,
    GigsTableServer,
    GigsTable,
    NewLaborModal,
    ExpenseModal,
    LaborModal,
    ExpensesTableServer,
    LaborTableServer,
    BillsModal,
    NewBillModal,
    BillsTableServer, Confirms, Toasts, Modals, QuickViews, MainMenuBar, EmployeesTableServer
  },
  mounted() {
    this.onTabChange({index: this.activeIndex});
  },
  data() {
    return {
      activeIndex: 0,
      loadedTabs: {
        team: false,
        bills: false,
        gigs: false,
        labor: false,
        expenses: false
      }
    };
  },
  computed: {
    gigsUnpaidOnly: sync('Gigs/unpaidOnly', null, false),
    billsUnpaidOnly: sync('Bills/unpaidOnly', null, false),
    expensesUnpaidOnly: sync('Expenses/unpaidOnly', null, false),
    laborUnpaidOnly: sync('Labor/unpaidOnly', null, false),
  },
  methods: {
    onTabChange(event) {
      const tabNames = ['team', 'bills', 'gigs', 'labor', 'expenses'];
      const tabName = tabNames[event.index];
      if (!this.loadedTabs[tabName]) {
        this.loadedTabs[tabName] = true;
      }
    },
    reloadBills() {
      store.dispatch('Bills/reload');
    },
    reloadGigs() {
      store.dispatch('Gigs/reload');
    },
    reloadLabor() {
      store.dispatch('Labor/reload');
    },
    reloadExpenses() {
      store.dispatch('Expenses/reload');
    }
  }
}
</script>

<style scoped lang="scss">
#main-container {
  max-width: 1600px;
  overflow: auto;
  margin: 0 auto;
}
</style>

<template>
  <section v-if="isLoading" class="flex justify-content-center align-content-center h-30rem">
    <progress-spinner class="h-full"/>
  </section>
  <section v-else class="fadein animation-duration-500">
    <DataTable
            :value="transactions"
            v-model:expandedRows="expandedRows"
            showGridlines
            stripedRows
            size="small"
            @row-click="openParty"
    >
      <template #empty>
        No Transactions.
      </template>
      <template #loading>
        <ProgressSpinner/>
      </template>

      <Column expander style="width: 5rem"/>
      <Column field="submitTimeLocal" header="Date">
        <template #body="{data}">
          {{ formatDateTime(data.submitTimeLocal) }}
        </template>
      </Column>
      <Column field="transId" header="Transaction Id"></Column>
      <Column field="transactionStatus" header="Status"></Column>
      <Column field="accountNumber" header="Card"></Column>
      <Column field="amount" header="Amount">
        <template #body="{data}">
          {{ formatMoney(data.amount) }}
        </template>
      </Column>
      <Column field="quickbooks_id" header="Quickbooks">
        <template #body="{data}">
          <a v-if="data.quickbooks_id" @click="goToQuickbooksInvoice(data.quickbooks_id)" target="_blank" class="text-primary underline text-sm cursor-pointer">View
            Payment</a>
          <span v-else>-</span>
        </template>
      </Column>
      <template #expansion="{ data }">
        <div class="p-3">
          <div class="flex justify-content-between">
            <div>Transaction Id:</div>
            <div>{{ data.transId }}</div>
          </div>
          <div class="flex justify-content-between">
            <div>Submit Time (UTC):</div>
            <div>{{ data.submitTimeUTC }}</div>
          </div>
          <div class="flex justify-content-between">
            <div>Submit Time (Local):</div>
            <div>{{ data.submitTimeLocal }}</div>
          </div>
          <div class="flex justify-content-between">
            <div>Transaction Status:</div>
            <div>{{ data.transactionStatus }}</div>
          </div>
          <div class="flex justify-content-between">
            <div>Invoice:</div>
            <div>{{ data.invoice }}</div>
          </div>
          <div class="flex justify-content-between">
            <div>Name:</div>
            <div>{{ data.firstName }} {{ data.lastName }}</div>
          </div>
          <div class="flex justify-content-between">
            <div>Amount:</div>
            <div>{{ formatMoney(data.amount) }}</div>
          </div>
          <div class="flex justify-content-between">
            <div>Account Type:</div>
            <div>{{ data.accountType }}</div>
          </div>
          <div class="flex justify-content-between">
            <div>Account Number:</div>
            <div>{{ data.accountNumber }}</div>
          </div>
        </div>
      </template>
    </DataTable>
  </section>
</template>
<script>
import {formatMoney} from 'accounting-js';
import {formatDateTime} from '@/helpers/date.helper'
import config from "@/config";

export default {
  name: "quick-transaction-table",
  props: {
    transactions: {
      type: Array,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    partyActions: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      expandedRows: [],
      selectedTransaction: null,
      unsuccessfulStatuses: [
        'AuthorizedPendingCapture',
        'CapturedPendingSettlement',
        'RefundPendingSettlement',
        'UnderReview',
        'FDSPendingReview',
        'FDSAuthorizedPendingReview',
        'UpdatingSettlement'
      ]
    }
  },
  methods: {
    ...{formatMoney, formatDateTime},

    goToQuickbooksInvoice(id) {
      window.open(`${config.quickbooksUrl}/app/recvpayment?txnId=${id}`, '_blank');
    },

    openParty({data}) {
      if(this.partyActions) {
        store.commit('Parties/Party/view', true);
        store.dispatch('Parties/Party/load', data.invoice);
      }
    }
  },
}
</script>


<style scoped lang="scss">

</style>

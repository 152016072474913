<template>
  <Sidebar v-model:visible="visible" position="left" class="w-6" :modal="false" :dismissable="false">
    <template #container="">
      <div class="h-90vh pt-0">

        <section v-if="loading" class="flex justify-content-center align-content-center w-full h-screen">
          <progress-spinner class="h-full"/>
        </section>
        <section v-else class="fadein animation-duration-500">

          <div class="grid p-4">
            <div class="col-8">
              <employee-quick-view-profile></employee-quick-view-profile>
            </div>
            <div class="col-4">
              <employee-quick-view-action-buttons @save="save" @close="close"/>
            </div>
          </div>
          <TabView class="w-full h-full" v-model:active-index="activeTab">

            <TabPanel header="Events">
              <h2 class="m-0 mb-2 text-500">Upcoming Gigs</h2>
              <gigs-table/>
            </TabPanel>

            <TabPanel header="Gigs">
              <h2 class="m-0 mb-2 text-500">Past Gigs</h2>
              <past-gigs-table/>
            </TabPanel>

            <TabPanel header="Timesheets">
              <h2 class="m-0 mb-2 text-500">Unpaid Timesheets</h2>
              <bills-labor-table></bills-labor-table>
            </TabPanel>

            <TabPanel header="Expenses">
              <h2 class="m-0 mb-2 text-500">Unpaid Expenses</h2>
              <bills-expenses-table></bills-expenses-table>
            </TabPanel>

            <TabPanel header="Edit">
              <scroll-panel style="width: 100%; height: 75vh" class="pr-3">
                <employee-quick-view-edit/>
              </scroll-panel>
            </TabPanel>

            <TabPanel>
              <template #header>
                <div class="flex align-items-center gap-2">
                  <icon-quickbooks v-if="employee.quickbooks_id" size="16"/>
                  <icon-quickbooks v-else size="16" color="#cccccc"/>
                  <span class="p-tabview-title">Quickbooks</span>
                </div>
              </template>
              <employee-quickbooks/>
            </TabPanel>

            <TabPanel header="Notes">
              <h1 class="m-0 mb-2 text-500">Notes about Team Member</h1>
              <notes-editor v-model="notes" />
            </TabPanel>

          </TabView>
        </section>

      </div>
    </template>
  </Sidebar>
  <notes-modal/>
</template>

<script>
import {sync} from 'vuex-pathify';
import GigsTable from "@/components/employees/quick-view/gigs-table.vue";
import PastGigsTable from "@/components/employees/quick-view/past-gigs-table.vue";
import EmployeeQuickViewActionButtons from "@/components/employees/quick-view/employee-quick-view-action-buttons.vue";
import EmployeeQuickViewProfile from "@/components/employees/quick-view/employee-quick-view-profile.vue";
import EmployeeQuickViewEdit from "@/components/employees/quick-view/employee-quick-view-edit.vue";
import NoteModal from "@/components/events/common/notes-modal.vue";
import DayCalendar from "@/components/events/calendar/day-calendar.vue";
import NotesModal from "@/components/events/common/notes-modal.vue";
import EmployeeQuickbooks from "@/components/employees/quickbooks/employee-quickbooks.vue";
import IconQuickbooks from "@/components/common/icon-quickbooks.vue";
import BillsLaborTable from "@/components/team/bills/bills-labor-table.vue";
import BillsExpensesTable from "@/components/team/bills/bills-expenses-table.vue";
import {toastErrors} from "@/helpers/errors.helper";
import NotesEditor from "@/components/common/notes-editor.vue";

export default {
  name: 'employee-quick-view',
  components: {
    NotesEditor,
    BillsExpensesTable,
    BillsLaborTable,
    EmployeeQuickbooks,
    PastGigsTable,
    NotesModal,
    DayCalendar,
    NoteModal,
    EmployeeQuickViewEdit,
    EmployeeQuickViewProfile, EmployeeQuickViewActionButtons, GigsTable, IconQuickbooks
  },

  data() {
    return {
      activeTab: 0
    }
  },

  computed: {

    employee() {
      return store.get('Employees/current');
    },

    notes: sync('Employees/Employee/notes', null, false),

    loading: sync('Employees/Employee/loading', null, false),

    visible: sync('Employees/view', null, false),
  },

  methods: {

    close() {
      this.visible = false;
    },

    async save() {
      try {
        this.loading = true;
        await store.dispatch('Employees/Employee/save');
        this.$toast.add({severity: 'success', summary: 'Success', detail: 'Employee Saved', life: 3000});

      } catch (e) {
        toastErrors(this.$toast, e);
      }finally {
        this.loading = false;
      }
    },
  }
};
</script>

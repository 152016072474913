<template>
  <div>
    <progress-spinner v-if="isLoading" style="width: 12px; height: 12px; padding:0; margin:0;" />
    <div v-else @click="show" class="cursor-pointer">{{ characterName }}</div>
    <div class="w-3 h-3 absolute z-2" v-if="isPickerVisible">
      <character-listbox :scheduleable="scheduleable" @item-selected="updateCharacter"/>
    </div>
  </div>
</template>

<script>
import CharacterListbox from "@/components/events/edit/invoice/character/character-listbox.vue";

export default {
  name: "invoice-character-picker",
  components: {CharacterListbox },

  props: {
    scheduleable: Object,
  },

  data() {
    return {
      isLoading: false,
      isPickerVisible: false,
    };
  },

  computed: {
    characterName() {
      return this.scheduleable.character.name;
    }
  },

  mounted() {
    document.addEventListener('click', this.handleOutsideClick);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleOutsideClick);
  },

  methods: {

    show() {
      this.isPickerVisible = true;
    },

    hide() {
      this.isPickerVisible = false;
    },

    async updateCharacter(data) {
      this.hide();
      this.isLoading = true;
      await store.dispatch('Scheduleables/editScheduleable', data);
      this.isLoading = false;
    },

    handleOutsideClick(event) {
      if (!this.$el.contains(event.target)) {
        this.hide();
      }
    }
  }
};
</script>

<style scoped>
/* Add specific styles for your component here */
</style>

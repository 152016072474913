<script>
import Config from "@/config";

export default {
  name: "email-snipit-services",
  props: {
    showDesc: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    services() {
      return store.get('Scheduleables/servicesVisible');
    }
  },
  methods: {
    getServiceURL(item) {
      return `${Config.domain}/party-addons/${item.addon.url}`;
    }
  }
}
</script>

<template>
  <div v-if="services.length">
    <h3>Services</h3>
    <p v-if="showDesc">
      Many people are surprised when they show up to other customer events and see our logo
      on a food cart or discovery station, "I didn't know you did this too?!" We are a one stop shop for all your party
      needs. If its not listed
      ask. If you want it, we got it.
    </p>
    <p v-if="showDesc">
      Here are some suggestions for your party:
    </p>
    <ul>
      <li v-for="item in services" :key="item.id">
        <a :href="getServiceURL(item)" target="_blank" style="color:#f616c9; text-decoration:underline;">{{ item.addon.title }}</a>
      </li>
    </ul>
  </div>
</template>

<style scoped lang="scss">

</style>
<template>
  <BillsContextMenu ref="ecm" @hide="rtClickSelectedRow = null" class="w-2" :selected-bill="rtClickSelectedRow"/>

  <section v-if="loading" class="flex justify-content-center align-content-center h-30rem">
    <progress-spinner class="h-full"/>
  </section>
  <section v-else class="fadein animation-duration-500">
    <DataTable
      :value="bills"
      v-model:selection="selectedBills"

      dataKey="id"

      :sort-field="sortField"
      :sort-order="sortOrder"

      selectionMode="multiple"

      showGridlines
      stripedRows
      size="small"

      :loading="loading"

      @row-click="onRowClick"
      @row-dblclick="onRowDoubleClick"
      @sort="onSort"

      contextMenu
      v-model:contextMenuSelection="rtClickSelectedRow"
      @contextmenu="showMenu"
    >
      <template #header>
        <div class="flex justify-content-end">

          <InputGroup>
            <InputGroupAddon>
              <i class="pi pi-search"/>
            </InputGroupAddon>
            <InputText v-model="filter"
                       placeholder="Search"
                       @keyup.enter="onFilter"
                       class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border appearance-none outline-none focus:border-primary w-full"/>

            <Button icon="pi pi-search" label="filter" @click="onFilter"/>
            <Button icon="pi pi-times" @click="clearFilter"/>
          </InputGroup>

          <Button
            @click="addBill"
            icon="pi pi-plus"
            class="ml-3 w-11rem"
            size="small"
            label="New"
            type="button"/>

        </div>
      </template>

      <template #empty>
        No Bills found.
      </template>

      <Column selectionMode="multiple" class="w-2rem"></Column>
      <Column field="formatted_id" header="Id" :sortable="true" class="w-2rem"></Column>
      <Column field="employee_id" header="Employee" :sortable="true" >
        <template #body="{data}">
          <div v-if="data.employee" class="flex justify-content-between cursor-pointer" @click.stop="openEmployeeQuickView(data.employee)" @contextmenu="showMenu($event,data)">
            <span class="hover:underline hover:text-primary white-space-nowrap">{{ data.employee?.fullName }}</span>
          </div>
        </template>
      </Column>
      <Column field="notes" header="Summary" :sortable="true" >
        <template #body="{data}">
          <span>{{ truncateText(data.notes, 128) }}</span>
        </template>
      </Column>
      <Column field="quickbooks_id" header="Quickbooks" :sortable="true" class="text-center" >
        <template #body="{data}">
          <a v-if="data.quickbooks_id" :href="`${config.quickbooksUrl}/app/check?&txnId=${data.quickbooks_id}`" target="_blank">{{data.quickbooks_id}}</a>
          <span v-else> - </span>
        </template>
      </Column>
      <Column field="total" header="Total" :sortable="true" ></Column>
      <Column field="paid_on" header="Paid" :sortable="true" >
        <template #body="{data}">
          {{ formatDate(data.paid_on) }}
        </template>
      </Column>
      <Column field="created_at" header="Since" :sortable="true" >
        <template #body="{data}">
          {{ formatDate(data.created_at) }}
        </template>
      </Column>
    </DataTable>

  </section>
  <Paginator
    :totalRecords="totalRows"
    :rows="rowsPerPage"
    :alwaysShow="true"
    template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} "
    :rowsPerPageOptions="[10, 25, 50]"
    @page="onPageChange"
  />
</template>
<script>

import {formatDate} from '@/helpers/date.helper'
import BillsContextMenu from "@/components/team/bills/bills-context-menu.vue";
import NotesBookmark from "@/components/events/quick-view/tables/notes-bookmark.vue";
import Config from "@/config";

export default {
  name: "bills-table-server",
  components: {NotesBookmark, BillsContextMenu},
  mounted() {
    this.load();
  },
  data() {
    return {
      config: Config,
      selectedBills: [],
      rtClickSelectedRow: null,

      totalRows: 0,
      rowsPerPage: 10,
      currentPage: 1,

      sortField: 'created_at',
      sortOrder: -1,

      filter: '',
    };
  },
  computed: {
    bills() {
      return store.get('Bills/list');
    },
    loading() {
      return store.get('Bills/loading');
    },
  },
  methods: {
    formatDate,

    showMenu(event) {
      this.$refs.ecm.show(event);
    },

    getSortOrder() {
      return this.sortOrder === 1 ? 'asc' : 'desc';
    },

    getParams() {
      return {
        perPage: this.rowsPerPage,
        page: this.currentPage,
        filter: this.filter,
        sortField: this.sortField,
        sortOrder: this.getSortOrder(),
      };
    },

    async load() {
      let result = await store.dispatch('Bills/load', this.getParams());
      this.bindResult({...result});
    },

    bindResult(result) {
      this.totalRows = result.total;
      this.currentPage = result.current_page;
    },

    onPageChange(event) {
      this.currentPage = event.page + 1; // event.page is zero-indexed
      this.rowsPerPage = event.rows;
      this.load();
    },

    onSort(event) {
      this.sortField = event.sortField;
      this.sortOrder = event.sortOrder;
      this.load();
    },

    clearFilter() {
      this.filter = '';
      this.load();
    },

    onFilter() {
      this.load();
      this.selectedExpenses = [];
    },

    onRowClick({data, originalEvent}) {
      store.commit('Bills/current', data);

      if (originalEvent.ctrlKey) {
        this.editBill(data)
      }
    },

    onRowDoubleClick({data}) {
      this.editBill(data);
    },

    addBill() {
      store.commit('Employees/current', null);
      store.commit('Bills/add', true);
    },

    editBill(bill) {
      //get bill from server
      store.dispatch('Bills/loadBill', bill.id);
      store.commit('Employees/current', bill.employee);
      store.commit('Bills/edit', true);
    },

    openEmployeeQuickView(employee){
      store.commit('Employees/current', employee);
      store.commit('Employees/view', true);
    },

    truncateText(text, maxLength) {
      if (!text) return '';
      return text.length > maxLength ? text.substring(0, maxLength) + ' [more]' : text;
    },
  }
};

</script>

<style scoped>
</style>

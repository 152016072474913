<template>
  <Dialog v-model:visible="visible" modal class="surface-overlay max-h-90vh" position="top" :draggable="true"
          :style="{width: '800px'}"
          @dblclick.stop
          @show="updateAvailableData"
          @hide="onHide"
  >
    <template #container>
      <section class="relative">
        <section v-if="isLoading" class="flex justify-content-center align-content-center h-30rem">
          <progress-spinner class="h-full"/>
        </section>
        <section v-else class="fadein animation-duration-500">
          <div class="px-5 flex justify-content-between align-content-center">
            <h2 class="text-600">{{ employee.first }} {{ employee.last }}'s labor</h2>
            <div class="p-2 px-0">
              <Button type="button" @click="close" icon="pi pi-times" severity="secondary" text
                      rounded></Button>
            </div>
          </div>
          <section>
            <form @submit.prevent="save" class="grid formgrid m-5 overflow-auto">
              <div class="field col-12">
                <label for="category">Category</label>
                <Dropdown v-model="category" option-value="value" option-label="label"
                          :options="categoryOptions" id="category" class="w-full"
                          @change="updateAvailableData"
                          placeholder="Select a category"/>
              </div>

              <div class="field col-6">
                <label for="start_time">Start Time</label>
                <Calendar v-model="start_time" id="start_time" :showIcon="true" :showButtonBar="true"
                          @clear-click="start_time = new Date()" dateFormat="mm/dd/yy" hourFormat="12" :step-minute="5"
                          showTime class="w-full" required/>
              </div>

              <div class="field col-6">
                <label for="end_time">End Time</label>
                <Calendar v-model="end_time" id="end_time" :showIcon="true" :showButtonBar="true"
                          @clear-click="end_time = new Date()" dateFormat="mm/dd/yy" hourFormat="12" :step-minute="5"
                          showTime class="w-full" required/>
              </div>

              <div v-if="showScheduleables" class="field col-12">
                <label for="scheduleables">Gigs</label>
                <MultiSelect v-model="selectedScheduleables" :options="scheduleables"
                             option-label="label" option-value="value"
                             id="scheduleables"
                             class="w-full" placeholder="Select Gigs To Automatically Subtract from Labor Hours"
                             @change="adjustWorkTimes"/>
              </div>
              <div class="field col-6">
                <label>{{showScheduleables? 'Gig Time + ' : '' }}Break Time</label>
                <InputGroup>
                  <InputNumber v-model="breakTime" id="break_time" mode="decimal" :min="0" :max="480" :step="5" :show-buttons="true" class="w-5"/>
                  <InputGroupAddon class="white-space-nowrap w-5">{{ breakTimeToHours }}</InputGroupAddon>
                </InputGroup>
              </div>

              <div class="field col-6">
                <label for="rate">Hourly Rate</label>
                <InputNumber v-model.number="rate" id="rate" mode="currency" currency="USD"
                             locale="en-US" required class="w-full"/>
              </div>

              <div class="field col-12 mb-5">
                <label for="description">Description</label>
                <InputText v-model="description" id="description" class="w-full"/>
              </div>

              <div class="field col-4">
                <span>Work Hours: {{ totalHoursWorked }}</span>
              </div>
              <div class="field col-4 text-center">
                <span>Break Hours: {{ totalHoursBreaked }}</span>
              </div>
              <div class="field col-4 text-right">
                <span>Paid Hours: {{ paidHours }}</span>
              </div>

              <div class="field col-12 text-right border-top-1 border-300 pt-3">
                <h2 class="m-0">Total: {{ formatMoney(totalEarnings) }}</h2>
              </div>
              <div class="flex justify-content-end mt-4 w-full">
                <Button type="button" label="Reset" icon="pi pi-times" class="mr-2 p-button-secondary" @click="reset"/>
                <Button type="submit" label="Save" icon="pi pi-check"/>
              </div>
            </form>
          </section>
        </section>
      </section>
    </template>
  </Dialog>
</template>

<script>
import {sync} from "vuex-pathify";
import dayjs from "dayjs";
import {formatMoney} from "accounting-js";
import {capitalize} from "lodash";
import {concatErrors} from "@/helpers/errors.helper";
import {formatDate} from "@/helpers/date.helper";
import {getDiffInMins, getFirstStartTime, getLastEndTime, shortTimeRange} from "@/helpers/time.helper"; // Assuming you have a wretch configuration file

export default {
  name: "labor-modal",
  data() {
    return {
      isLoading: false,

      categoryOptions: [
        {label: 'General', value: 'General'},
        {label: 'Storage', value: 'Storage'},
        {label: 'Delivery', value: 'Delivery'},
        {label: 'Driving - Gig Hours', value: 'Driving - Gig Hours'},
        {label: 'Setup and Teardown - Gig Hours', value: 'Setup and Teardown - Gig Hours'}
      ],

      selectedScheduleables: [],
      scheduleables: [],
    }
  },
  computed: {
    visible: sync('Labor/edit', null, false),

    ...sync('Labor/Timesheet/*', null, false),

    current() {
      return store.get('Labor/current');
    },

    employee() {
      return store.get('Employees/current');
    },

    totalHoursWorked() {
      const start = dayjs(this.start_time).second(0);
      const end = dayjs(this.end_time).second(0);
      const durationInHours = end.diff(start, 'hour', true);
      return durationInHours.toFixed(2);
    },

    totalHoursBreaked() {
      //get break time as a decimal
      const breakTimeInMinutes = this.breakTime;
      const breakTimeInHours = breakTimeInMinutes / 60;
      return breakTimeInHours.toFixed(2);
    },

    paidHours() {
      return this.totalHoursWorked - this.totalHoursBreaked;
    },

    totalEarnings() {
      return this.paidHours * this.rate;
    },

    showScheduleables() {
      return ['Driving - Gig Hours', 'Setup and Teardown - Gig Hours'].includes(this.category);
    },

    breakTimeToHours() {
      const hours = Math.floor(this.breakTime / 60);
      const minutes = this.breakTime % 60;

      if (minutes === 0) {
        return `${hours} hr`;
      } else if (hours === 0) {
        return `${minutes} min`;
      }

      return `${hours} hr ${minutes} min`;
    }
  },
  methods: {
    capitalize,
    formatMoney,

    selectSavedGigs() {
      this.selectedScheduleables = [];

      if (this.gigs) {
        this.scheduleables.forEach(gig => {
          if (this.gigs.includes(gig.value.id)) {
            this.selectedScheduleables.push(gig.value);
          }
        });
      }
    },

    async save() {

      try {
        this.isLoading = true;
        this.employee_id = this.employee.id;
        this.setSelectedGigsForSave();
        await store.dispatch('Labor/Timesheet/save');

        this.$toast.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Labor entry has been saved',
          life: 3000
        });

        this.close();

      } catch (error) {
        this.$toast.add({severity: 'error', summary: 'Error', detail: concatErrors(error), life: 5000});
      } finally {
        this.isLoading = false;
      }
    },

    reset(){
      store.dispatch('Labor/Timesheet/reset');
      this.selectedScheduleables = [];
    },

    async close() {
      store.commit('Labor/edit', false);
      store.commit('Labor/current', null);
    },

    async onHide() {
      store.commit('Labor/current', null);
    },

    adjustWorkTimes() {
      this.start_time = getFirstStartTime(this.selectedScheduleables);
      this.end_time = getLastEndTime(this.selectedScheduleables);
      this.breakTime = getDiffInMins(this.start_time, this.end_time);
      this.description = this.selectedScheduleables ? this.getAllDescriptions(this.selectedScheduleables) : null;
    },

    async updateAvailableData() {
      if (this.showScheduleables) {
        await this.getEmployeeGigs();
        this.selectSavedGigs();
      }
    },

    getAllDescriptions(gigs) {
      return gigs.map(gig => this.getGigDesc(gig)).join(', ');
    },

    getGigDesc(gig) {
        let name = gig.addon ? gig.addon.title : gig.misc;
        let shortName = name.substring(0, 24);
        return `${formatDate(gig.party.date)} ${shortTimeRange(gig.start_time, gig.end_time)}: ${gig.party.city} : ${shortName}`;
    },

    setSelectedGigsForSave() {
      this.gigs = this.selectedScheduleables.map(gig => gig.id);
    },

    async getEmployeeGigs() {
      try {
        const response = await store.dispatch('Gigs/getByEmployee', this.employee.id);
        this.scheduleables = response.map(gig => ({label: this.getGigDesc(gig), value: gig}));
      } catch (error) {
        console.error('Error fetching scheduleables:', error);
      }
    }
  }
}
</script>

<template>
  <section v-if="total">
    <h2 class="mr-2 text-600">Highlights</h2>
    <div class="grid px-1 pt-1">
      <div class="col-12 md:col-3 text-center">
        <div class="px-2 py-3 surface-card shadow-1">
          <i class="pi pi-dollar text-3xl text-green-600"></i>
          <div class="text-600 font-bold text-4xl line-height-3">{{ formatMoney(total) }}</div>
          <p class="text-400 m-0">Total</p>
        </div>
      </div>
      <div class="col-12 md:col-3 text-center">
        <div class="px-2 py-3 surface-card shadow-1">
          <i class="pi pi-dollar text-3xl text-red-600"></i>
          <div class="font-bold text-4xl line-height-3"
               :class="{'text-red-600':costs >= total,'text-600':costs < total,}">{{ formatMoney(costs) }}
          </div>
          <p class="text-400 m-0">Costs</p>
        </div>
      </div>
      <div class="col-12 md:col-3 text-center">
        <div class="px-2 py-3 surface-card shadow-1">
          <i class="pi pi-percentage text-3xl text-blue-600"></i>
          <div class="font-bold text-4xl line-height-3"
               :class="{'text-red':margin <= 30,'text-600':margin >= 50,'text-600':margin > 30 && margin < 50}">
            {{ margin }}
          </div>
          <p class="text-400 m-0">Margin</p>
        </div>
      </div>
      <div class="col-12 md:col-3 text-center">
        <div class="px-2 py-3 surface-card shadow-1">
          <i class="pi pi-chart-line text-3xl text-green-500"></i>
          <div class="text-600 font-bold text-4xl line-height-3">{{ formatMoney(profit) }}</div>
          <p class="text-400 m-0">Profit</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {formatMoney} from 'accounting-js';

export default {
  name: "quick-view-highlights",
  computed: {
    total() {
      return store.get('Parties/Party/total');
    },
    costs() {
      return store.get('Parties/Party/costs');
    },
    margin() {
      return store.get('Parties/Party/margin');
    },
    profit() {
      return store.get('Parties/Party/profit');
    },
  },
  methods: {
    ...{formatMoney}
  },
}
</script>

<style scoped lang="scss">

</style>

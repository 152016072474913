<template>
    <h3>Hi, {{ customer.first }}!</h3>

    <p>
        We have received a payment for your party on {{ date }}.
    </p>

    <div v-if="hasTransaction">
        <table style="width: 100%; border-collapse: collapse;">
            <tr style="background-color: #f616c9; color: white;">
                <th style="padding: 12px; text-align: left;">Attribute</th>
                <th style="padding: 12px; text-align: left;">Value</th>
            </tr>
            <tr style="background-color: #f2f2f2;">
                <td style="padding: 8px;">Transaction Id:</td>
                <td style="padding: 8px;">{{ transaction.transId }}</td>
            </tr>
            <tr style="background-color: #ffffff;">
                <td style="padding: 8px;">Submit Time (UTC):</td>
                <td style="padding: 8px;">{{ transaction.submitTimeUTC }}</td>
            </tr>
            <tr style="background-color: #f2f2f2;">
                <td style="padding: 8px;">Submit Time (Local):</td>
                <td style="padding: 8px;">{{ transaction.submitTimeLocal }}</td>
            </tr>
            <tr style="background-color: #ffffff;">
                <td style="padding: 8px;">Transaction Status:</td>
                <td style="padding: 8px;">{{ transaction.transactionStatus }}</td>
            </tr>
            <tr style="background-color: #f2f2f2;">
                <td style="padding: 8px;">Invoice:</td>
                <td style="padding: 8px;">{{ transaction.invoice }}</td>
            </tr>
            <tr style="background-color: #ffffff;">
                <td style="padding: 8px;">Name:</td>
                <td style="padding: 8px;">{{ transaction.firstName }} {{ transaction.lastName }}</td>
            </tr>
            <tr style="background-color: #f2f2f2;">
                <td style="padding: 8px;">Amount:</td>
                <td style="padding: 8px;">{{ formatMoney(transaction.amount) }}</td>
            </tr>
            <tr style="background-color: #ffffff;">
                <td style="padding: 8px;">Account Type:</td>
                <td style="padding: 8px;">{{ transaction.accountType }}</td>
            </tr>
            <tr style="background-color: #f2f2f2;">
                <td style="padding: 8px;">Account Number:</td>
                <td style="padding: 8px;">{{ transaction.accountNumber }}</td>
            </tr>
        </table>
    </div>

    <email-snipit-party-location/>

    <h3>Tipping</h3>
    <p>Many customers ask us about tipping customs. Tips are not expected but greatly appreciated by our staff!
        Customers generally tip around 20%.</p>

    <h3>Party Extension</h3>
    <p>
        Please let your staff member know if you would like to extend your event with them and they will let you know if
        they are available. Costs are per half hour and vary depending upon character or service.
    </p>

    <h3>Deposit and Full Payment</h3>
    <p>
        For events scheduled more than two weeks away, we require a deposit, with a <strong>full payment due before the
        event</strong>.
        Full payment is due at the time of booking for events within two weeks. We no longer do final payments at the
        event.
        We want our customers to enjoy their party with out distraction.
    </p>

    <h3>Non-Refundable. One Year Credit</h3>
    <p>
        When you put down your deposit or pay in full, we set aside that time just for you, saying no to any other
        bookings, now or later. Your payment becomes non-refundable to guarantee your reservation.
    </p>
    <p>
        If some sort of life event arises that cancels your party, you have a whole year to use your credit. You will
        additionally have a priority booking to reschedule.
    </p>
    <email-snipit-divider/>
    <email-snipit-party-url/>
</template>

<script>
import Config from "@/config";
import {emailPartyDate} from "@/helpers/date.helper";
import {formatMoney} from "accounting-js";
import {isEmpty} from "lodash";
import EmailSnipitPartyLocation from "@/components/events/messages/snipits/email-snipit-party-location.vue";
import EmailSnipitPartyUrl from "@/components/events/messages/snipits/email-snipit-party-invoice.vue";
import EmailSnipitDivider from "@/components/events/messages/snipits/email-snipit-divider.vue";
import EmailSnipitPartyInvoice from "@/components/events/messages/snipits/email-snipit-party-invoice.vue";

export default {
    name: "message-payment-received",
    components: {EmailSnipitPartyInvoice, EmailSnipitDivider, EmailSnipitPartyUrl, EmailSnipitPartyLocation},
    props: {
        transaction: {
            type: Object
        }
    },
    computed: {
        customer() {
      return this.party.customer;
        },
        party() {
      return store.get('Parties/Party/current');
        },
        url() {
            return `${Config.domain}/my-party/${this.party.token}`;
        },
        date() {
            return emailPartyDate(this.party.date);
        },
        hasTransaction() {
            return !isEmpty(this.transaction);
        }
    },
    methods: {
        formatMoney
    }
}
</script>

<style scoped lang="scss">

</style>

<script>
export default {
  name: "email-snipit-party-location",
  computed: {
    party() {
      return store.get('Parties/Party/current');
    }
  }
}
</script>

<template>
  <div>
    <h3>Party Location:</h3>
    <div>{{ party.address }}</div>
    <div>{{ party.city }}, {{ party.state }} {{ party.zip }}</div>
  </div>
</template>

<style scoped lang="scss">

</style>

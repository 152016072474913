<script>
import EmailSnipitPartyUrl from "@/components/events/messages/snipits/email-snipit-party-invoice.vue";
import EmailSnipitPartyInvoice from "@/components/events/messages/snipits/email-snipit-party-invoice.vue";
import EmailSnipitDivider from "@/components/events/messages/snipits/email-snipit-divider.vue";
import Config from "@/config";

export default {
  name: "message-thank-you",
  components: {EmailSnipitDivider, EmailSnipitPartyInvoice, EmailSnipitPartyUrl},
  data() {
    return {
      companyName: Config.companyName
    }
  },
}
</script>

<template>
  <div>
    <h3>Thank You!</h3>
    <p>Thanks again for choosing {{companyName}} Inc for your event. We hope your event was spectacular!</p>

    <h3>Feedback</h3>
    <p>
      Your family is our family. Help us keep our event magical for everyone by letting
      us know how our staff performed! We periodically tip our staff for greatness, so let us know who was great!
    </p>

    <h3>On Behalf of the Staff</h3>
    <p>
      Thank you for your generosity in tipping our staff at your event. It wasn't necessary but was appreciated and went a long way in recognizing
      their efforts. If you didn't get a chance to tip and wanted to, just reply and we'll coordinate everything for you.
    </p>

    <h3>Pictures Pictures Pictures</h3>
    <p>
      Did we mention pictures? Do you have any timeless memories you want to share with everyone? Please send them along we'll post
      them on our website or social media. Or, if you loved our services, please help us spread the magic: tag us with
      @yourmagicalparty on instagram and facebook for any post with us we love to see the smiles.
    </p>

    <email-snipit-divider/>
    <email-snipit-party-invoice/>
  </div>
</template>

<style scoped lang="scss">

</style>
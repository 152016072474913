<template>
  <div class="w-full">
    <progress-spinner v-if="isLoading" style="width: 12px; height: 12px; padding:0; margin:0;" />

    <AutoComplete v-else-if="isEditing"
                  v-model="value"
                  ref="ac"
                  optionLabel="name"
                  :suggestions="filtered"
                  :emptySearchMessage="value"
                  @blur="onBlur"
                  @keydown.enter="update"
                  @complete="search"
                  input-class="w-full text-primary"
                  class="absolute m-0 -mt-1 p-0 top-0 left-0 surface-hover z-2 w-full"

    />

    <div v-else @click.stop="edit" class="cursor-pointer w-full">{{!data.visible?' -- ':''}}{{ canBeEmptyValue }}</div>
  </div>
</template>

<script>
import {filter} from "lodash";
import {MiscItems} from '@/helpers/misc.helper';
export default {

  name: "invoice-misc-autocomplete",
  props: {
    data: Object,
    field: String,
  },

  data() {
    return {
      value: this.data[this.field],
      filtered: this.items,
      items: MiscItems,
      loading: false,
      editing: false,
    };
  },

  computed: {

    canBeEmptyValue() {
      if (this.value && this.value.length > 0) {
        return this.value;
      } else if (typeof this.value === 'object' && this.value !== null) {
        return this.value.name;
      } else {
        return '-';
      }
    },

    isLoading() {
      return this.loading;
    },
    isEditing() {
      return this.editing;
    },
    hasChanged() {
      return this.value !== this.data[this.field];
    },
    party() {
      return store.get('Parties/Party/current');
    },
    total(){
      return store.get('Parties/Party/total');
    }
  },

  methods: {

    edit() {
      this.editing = true;
      this.selectText();
    },

    selectText() {
      this.$nextTick(() => {
        const inputElement = this.$refs.ac?.$el.querySelector('input');
        if (inputElement) {
          inputElement.focus();
          inputElement.select();
        }
      });
    },

    search(event) {
      this.filtered = filter(this.items, (item) => {
        return item.name.toLowerCase().includes(event.query.toLowerCase());
      });
    },

    onBlur() {
      setTimeout(() => {
        this.update();
      }, 300);
    },

    async update() {
      this.editing = false;

      if (this.hasChanged) {
        this.loading = true;

        let price;
        if(this.value?.price !== undefined) price = this.value.price;
        else if(this.value?.priceFn) price = this.value.priceFn({party:this.party, item:this.data, total:this.total});

        let name = this.value?.name || this.value;

        await store.dispatch('Scheduleables/editScheduleable', {...this.data, [this.field] : name, 'price': price});
        this.loading = false;
      }
    },
  }
}
</script>
<style scoped lang="scss">

</style>

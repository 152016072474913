<template>
  <div class="flex justify-content-between w-full">
    <div class="flex">
      <h2 class="mr-2">Miscellaneous</h2>
      <AvatarGroup>
        <Avatar
          v-for="item in visible"
          :key="item.id"
          :label="getLabel(item.misc)"
          size="large"
          shape="circle"
          class="border-200 border-3 text-white"
          :class="getRandomColorClass() "
          :title="item.misc"
        />
        <Avatar shape="circle" icon="pi pi-plus" size="normal" @click.stop="addMisc"
            :pt="{
                    root: { class: 'text-sm text-400 hover:bg-primary hover:text-white' },
                    icon: { class: 'text-sm' }
                }"
        />
      </AvatarGroup>
<!--      <ToggleButton v-model="visible" class="ml-2" on-icon="pi pi-eye" off-icon="pi pie-eye-slash" :pt="{root:{class:'text-400'}}" @click.stop="showInvisibleItems" />-->
    </div>
    <h2>{{ formatMoney(subtotal) }}</h2>
  </div>
</template>

<script>
import {formatMoney} from "accounting-js";
import {getServiceImage} from "@/helpers/images.helper";
import {filter} from 'lodash';

export default {
  name: "invoice-header-misc",

  computed: {
    misc() {
      return store.get('Scheduleables/misc');
    },
    visible(){
      return filter(this.misc,{visible: 1});
    },
    subtotal() {
      return store.get('Scheduleables/miscSubtotal');
    },
  },
  methods: {

    ...{formatMoney, getServiceImage},


    getRandomColorClass() {
      const colorClasses = ['bg-red-200', 'bg-blue-200', 'bg-green-200', 'bg-yellow-200', 'bg-purple-200', 'bg-pink-200', 'bg-indigo-200', 'bg-gray-200'];
      const randomIndex = Math.floor(Math.random() * colorClasses.length);
      return colorClasses[randomIndex];
    },

    getLabel(misc) {
      return misc && misc.length > 0 ? misc.charAt(0) : 'M'
    },

    addMisc() {
      store.commit('Scheduleables/miscLoading', true);
      store.dispatch('Scheduleables/addScheduleable', {misc: '-'});
    }
  }
}
</script>


<style scoped lang="scss">

</style>
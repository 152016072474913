<template>
  <div class="flex align-items-center justify-content-end">
    <Button type="button" @click="phoneEmployee" icon="pi pi-phone" severity="secondary" text rounded title="Call"></Button>
    <Button type="button" @click="save" icon="pi pi-save" severity="secondary" text rounded title="Save Edits"></Button>
    <Button type="button" @click="close" icon="pi pi-times" severity="secondary" text rounded title="Close"></Button>
  </div>
  <div class="flex justify-content-end pt-3">
    <InputSwitch v-model="active" class="block" @change="toggleActive" :disabled="loading" :title="active ? 'Set Inactive' : 'Set Active'"/>
  </div>
</template>

<script>
import Bus from "@/bus";
import {sync} from "vuex-pathify";

export default {
  name: "employee-quick-view-action-buttons",
  emits: ['close', 'save'],
  data() {
    return {
      loading: false,
    }
  },
  computed: {

    active: sync("Employees/Employee/active", null, false),

    employee() {
      return store.get("Employees/current");
    }
  },
  methods: {

    phoneEmployee() {
      window.location.href = `tel:${this.employee.phone}`;
    },

    close() {
      this.$emit('close');
    },

    save() {
      this.$emit('save');
    },

    async toggleActive() {
      if(this.loading) return;
      this.loading = true;
      await store.dispatch('Employees/active', {...this.employee, active:!this.employee.active})
      Bus.$emit('employees.update');
      this.loading = false;
    }
  }
}
</script>

<style scoped lang="scss">

</style>

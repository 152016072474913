<template>
  <section class="absolute top-0 left-0 z-2 bg-white-alpha-90 w-full h-full flex justify-content-around items-center">
    <div class="text-center p-4 bg-white rounded shadow w-6">
      <h2 class="mb-3">Unsaved Changes</h2>
      <p>You have unsaved changes. Do you want to save them before leaving?</p>

      <div class="grid text-left text-primary text-sm p-3">

        <div v-for="item in changes" :key="item.field" class="col-6 p-1 m-0 flex align-items-center">
          <Checkbox v-model="selected" :inputId="item.field" :value="item"></Checkbox>
          <label :for="item.field" class="ml-2">{{ labels[item.field] }}</label>
        </div>

      </div>
      <div class="flex justify-content-evenly mt-4">
        <Button label="Go Back" @click="goBack" class="mr-2" icon="pi pi-chevron-left"/>
        <Button label="Save Checked" @click="saveCheckedItems" class="mr-2" icon="pi pi-check"/>
        <Button label="Discard All" @click="discard" class="p-button-secondary" icon="pi pi-times"/>
      </div>
    </div>
  </section>
</template>


<script>
import {some} from "lodash";

export default {
  name: "event-edit-interstitial",
  emits: ['close'],
  data() {
    return {
      selected: [],
      labels: {
        'first': 'First Name',
        'last': 'Last Name',
        'email': 'Email',
        'phone': 'Customer Phone',
        'phone2': 'Event Phone',
        'occasion': 'Occasion',
        'for_whom': 'Guest of Honor',
        'age': 'Age',
        'guests': '# Kids / Guests',
        'referral': 'How did you find us',
        'date': 'Date',
        'startTime': 'Start Time',
        'duration': 'Duration',
        'venue': 'Venue Name',
        'address': 'Address of party',
        'city': 'City',
        'zip': 'Zip Code',
        'venue_instructions': 'Location special instructions',
        'party_notes': 'Notes',
        'status': 'Status',
      },
    }
  },
  computed: {
    party() {
      return store.get('Parties/Party/current');
    },
    isDirty() {
      return store.get('Parties/Party/isDirty');
    },
    changes() {
      return store.get('Parties/Party/changedFields');
    },
  },
  watch:{
    changes() {
      this.selected = this.changes;
    }
  },
  methods: {

    goBack() {
      store.commit('Parties/Party/showInterstitial', false);
    },

    async discard() {
      store.commit('Parties/Party/loading', true);
      store.commit('Parties/Party/showInterstitial', false);

      await store.dispatch('Parties/Party/refresh');

      store.commit('Parties/Party/loading', false);
      store.commit('Parties/Party/edit', false);
    },

    async rewriteParty() {
      for (const {field, old} of this.changes) {
        const isSelected = some(this.selected, {field: field});

        if (!isSelected) {
          await store.commit(`Party/${field}`, old);
        }
      }
    },


    async saveCheckedItems() {

      if(this.party.archived) {
        this.$toast.add({
          severity: 'error',
          summary: 'Cannot Save when Archived',
          detail: 'Party is archived. Unarchive to edit.',
          life: 5000
        });
        return;
      }

      if(!['inquiry','booking','complete'].includes(this.party.status)){
        this.$toast.add({
          severity: 'error',
          summary: 'Cannot Save with Current Status',
          detail: 'Party must be New, Booking or Complete to update. Change status to update.',
          life: 5000
        });
        return;
      }


      store.commit('Parties/Party/loading', true);
      store.commit('Parties/Party/showInterstitial', false);

      await this.rewriteParty();
      await store.dispatch('Parties/Party/save');

      store.commit('Parties/Party/loading', false);
      store.commit('Parties/Party/edit', false);
    },
  },
}
</script>


<style scoped lang="scss">

</style>

<template>
  <div>
    <progress-spinner v-if="isLoading" style="width: 12px; height: 12px; padding:0; margin:0;" />
    <div v-else @click="show" class="cursor-pointer w-full">{{ fullName }}</div>
    <div class="w-3 h-3 absolute z-3" v-if="isPickerVisible">
      <employee-listbox :scheduleable="scheduleable" @item-selected="updateEmployee"/>
    </div>
  </div>
</template>

<script>
import EmployeeListbox from "@/components/employees/employee-listbox.vue";

export default {
  name: "invoice-employee-picker",
  components: { EmployeeListbox },

  props: {
    scheduleable: Object,
  },

  data() {
    return {
      isLoading: false,
      isPickerVisible: false,
    };
  },

  mounted() {
    document.addEventListener('click', this.handleOutsideClick);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleOutsideClick);
  },

  computed:{
    fullName(){
      return this.scheduleable?.employee?.fullName || '-';
    }
  },
  methods: {

    show() {
      this.isPickerVisible = true;
    },

    hide() {
      this.isPickerVisible = false;
    },

    async updateEmployee(data) {
      this.hide();
      this.isLoading = true;
      await store.dispatch('Scheduleables/editScheduleable', data);
      this.isLoading = false;
    },

    handleOutsideClick(event) {
      if (!this.$el.contains(event.target)) {
        this.hide();
      }
    }
  }
};
</script>

<style scoped>
/* Add specific styles for your component here */
</style>

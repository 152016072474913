<template>
  <TabPanel>
    <Card>
      <div v-if="!isCustomRate">
        <Dropdown
                v-model="character.rates_id"
                :options="options"
                optionLabel="name"
                optionValue="id"
                placeholder="Select a Rate Type"
                class="full-width"
        />
      </div>

      <Toast v-if="success" position="bottom-center">Character rate has been updated.</Toast>
    </Card>
  </TabPanel>
</template>

<script>
import RateBus from "@/bus";
import { find } from 'lodash';

export default {
  name: "CharacterProfileRates",

  data() {
    return {
      success: false,
      options: []
    };
  },
  computed: {
    character() {
      return store.get('Characters/current');
    },
    rate() {
      return find(this.options, {id: this.character.rates_id});
    },
    rateName() {
      return `Custom Rate: Character: ${this.character.name}`;
    },
    isCustomRate() {
      return this.rate?.id === 0;
    }
  },
  created() {
    this.loadOptions();
    RateBus.$on('save:rate', (rate) => {
      this.updateCharacterRateId(rate.id);
    });
  },
  methods: {
    async loadOptions() {
      const list = await store.dispatch('Rates/load');
      list.push({ name: "Add Custom Rate", id: 0 });
      this.options = list;
    },
    async updateCharacterRateId(rateId) {
      this.character.rates_id = rateId;
      try {
        await store.dispatch('Characters/update', this.character);
        this.success = true;
      } catch (error) {
        console.error('Update failed', error);
        this.success = false;
      }
    }
  }
}
</script>

<style scoped>
.full-width {
    width: 100%;
}
</style>

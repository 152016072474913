import {make} from 'vuex-pathify';
import api from '@/config.wretch';
import Timesheet from "@/stores/timesheet.store";
import Bus from "@/bus";

const state = {
  loading: false,
  edit: false,
  add: false,
  clone: false,

  params: {},
  unpaidOnly: true,

  list: [],
  current: null
};

const getters = {
  ...make.getters(state),
};

const mutations = {
  ...make.mutations(state),

  current(state, val) {
    state.current = val;
    store.dispatch('Labor/Timesheet/set', state.current);
  }
};

const actions = {
  async load({commit}, params) {
    commit('loading', true);
    commit('params', params);
    let result = await api.url(`/labor`).query({...params, unpaid_only: state.unpaidOnly}).get().json();
    commit('list', result.data);
    commit('loading', false);
    return result;
  },

  async getUnpaidByEmployee(obj, employee_id) {
    return await api.url(`/labor/unpaid/employee/${employee_id}`).get().json();
  },

  async reload({dispatch, state}) {
    await dispatch('load', state.params);
  },

  async delete({dispatch}, id) {
    try {
      await api.url(`/labor/${id}`).delete().res();
      Bus.$emit('labor.delete');
      dispatch('reload');
    } catch (error) {
      console.error('Error deleting labor:', error);
    }
  },

  async markPaid({dispatch}, id) {
    try {
      await api.url(`/labor/${id}/paid`).put().res();
      dispatch('reload');
    } catch (error) {
      console.error('Error marking paid:', error);
    }
  },

  async markUnpaid({dispatch}, id) {
    try {
      await api.url(`/labor/${id}/unpaid`).put().res();
      dispatch('reload');
    } catch (error) {
      console.error('Error marking unpaid:', error);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    Timesheet
  }
};

<template>
  <section v-if="isLoading" class="flex justify-content-center align-content-center h-10rem">
    <progress-spinner class="h-full"/>
  </section>
  <section v-else class="fadein animation-duration-500 pb-8">

    <div v-if="transactions.length">
      <Message severity="success" v-if="allTransactionsInQuickbooks">All Payments are in quickbooks!</Message>

      <Message severity="warn" v-if="!allTransactionsInQuickbooks && hasQuickbooksInvoice">Not all Payments are in quickbooks!</Message>

      <Message severity="warn" v-if="!allTransactionsFinalized" icon="pi pi-clock">
        Not all transaction status are final! May Take 24 hrs.
        <span class="font-bold hover:underline cursor-pointer ml-2" @click="updateAllStatus">Update to Latest Status</span>
      </Message>

      <transaction-card v-for="transaction in sortedTransactions" :key="transaction.id" :transaction="transaction"></transaction-card>
    </div>

    <div v-else>
      <Card class="mt-3 border-2 border-200 border-dashed shadow-none">
        <template #content>
          <div class="flex justify-content-center">
            <h2 class="text-500">No Transactions</h2>
          </div>
        </template>
      </Card>
    </div>

    <ConfirmDialog group="refund" :closable="false">
      <template #message="{message}">
        <div class="flex flex-column w-full align-items-center border-bottom-1 surface-border w-20rem">

          <div class="text-center">{{ message.m.txt }}</div>

          <div class="flex flex-column w-full">
            <div class="text-sm font-bold mb-2">Confirm Refund Amount</div>
            <InputNumber v-model="message.m.refundAmount" mode="currency" currency="USD" :minFractionDigits="2" :max="message.m.amount"/>
          </div>

          <div class="flex flex-column w-full mt-3">
            <div class="text-sm font-bold mb-2">Description</div>
            <Textarea v-model="message.m.description" placeholder="Refund Description" rows="3" class="border-gray-300 p-2 w-full"/>
          </div>

        </div>
      </template>
    </ConfirmDialog>

  </section>
</template>
<script>
import {formatMoney} from 'accounting-js';
import {formatDateTime} from '@/helpers/date.helper'
import {capitalize, groupBy, orderBy} from "lodash";
import TransactionCard from "@/components/events/edit/invoice/transaction-card.vue";

export default {
  name: "invoice-transaction-table",
  components: {TransactionCard},
  data() {
    return {
      expandedRows: [],
      selectedTransaction: null,
      refundAmount: 0
    }
  },
  computed: {

    party() {
      return store.get('Parties/Party/current')
    },

    hasQuickbooksInvoice(){
      return !!this.party.quickbooks_id;
    },

    customer() {
      return this.party.customer;
    },

    transactions() {
      return this.party.transactions;
    },

    sortedTransactions() {
      // Group transactions by their original transaction ID
      const grouped = groupBy(this.transactions, transaction => transaction.refTransId || transaction.transId);

      // Flatten the grouped transactions and sort by the original transaction date
      return orderBy(
        Object.values(grouped).flat(),
        ['date'],
        ['desc']
      );
    },

    allTransactionsInQuickbooks() {
      return this.transactions
        .filter(transaction => transaction.transactionStatus !== 'voided' && transaction.transactionStatus !== 'declined')
        .every(transaction => transaction.quickbooks_id);
    },

    allTransactionsFinalized() {
      return this.transactions.every(transaction => this.isFinalized(transaction));
    },

    relatedRefunds() {
      if (!this.selectedTransaction) return [];

      return this.transactions.reduce((acc, transaction) => {
        if (transaction.refTransId === this.selectedTransaction.transId) {
          acc.push(transaction);
        }
        return acc;
      }, []);
    },

    total() {
      return store.get('Parties/Party/total');
    },
    balance() {
      return store.get('Parties/Party/balance')
    },
    isLoading() {
      return store.get('Banking/loading');
    },
    unfinalizedTransactions() {
      return this.transactions.filter(transaction =>!this.isFinalized(transaction));
    }
  },
  methods: {
    capitalize,
    formatMoney,
    formatDateTime,

    isFinalized(transaction) {
      return transaction.transactionStatus === 'settledSuccessfully'
      || transaction.transactionStatus === 'voided'
      || transaction.transactionStatus === 'declined'
      || transaction.transactionStatus === 'processed'
      || transaction.transactionStatus === 'refundSettledSuccessfully'
    },

    updateAllStatus() {

      store.dispatch('Banking/updateAllTransactionStatus', {ids: this.unfinalizedTransactions.map(transaction => transaction.id)  })
        .then(() => {
          this.$toast.add({
            severity: 'success',
            summary: 'Success',
            detail: 'All Transaction status updated successfully',
            life: 3000
          });

          store.dispatch('Parties/Party/reload');
        })
        .catch(() => {
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'A Transaction status failed to update',
            life: 3000
          });
        })
    }
  }
}
</script>


<style scoped lang="scss">

</style>

<template>
  <section v-if="loading" class="flex justify-content-center align-content-center h-30rem">
    <progress-spinner class="h-full"/>
  </section>
  <section v-else class="fadein animation-duration-500">
    <h1 class="text-600 text-4xl pb-0 mb-0">New Parties</h1>
    <p>Parties are customer created parties from our website.</p>
    <PartiesTable :parties="parties" asc @update-status="updateStatus" :loading="loading" sort-field="updated_at" show-money/>
  </section>
</template>

<script>
import PartiesTable from "@/components/events/tables/parties-table.vue";

export default {
  name: "TabInquiry",
  components: {PartiesTable},

  computed: {
    loading() {
      return store.get('Parties/loading');
    },
    parties() {
      return store.get('Parties/inquiry');
    },
  },
  methods: {
    updateStatus(payload) {
      store.dispatch("Parties/updateStatus", payload)
    }
  },
};
</script>

<style scoped>
</style>

<template>
  <section>

    <div v-if="!current.quickbooks_id">

      <div class="flex justify-content-between mb-2">
        <h2 class="m-0 p-0">Link Quickbooks Customer</h2>
        <Button @click="confirmCreate" rounded size="small" class="quickbooks-button">
          <icon-quickbooks color="#ffffff" class="mr-2"/>
          <span class="text-white">Create New Customer</span>
        </Button>
      </div>

      <customer-quickbooks-search></customer-quickbooks-search>
    </div>
    <div v-else>
      <customer-quickbooks-profile></customer-quickbooks-profile>
    </div>
  </section>
</template>

<script>
import IconQuickbooks from "@/components/common/icon-quickbooks.vue";
import CustomerQuickbooksSearch from "./customer-quickbooks-search.vue";
import CustomerQuickbooksProfile from "./customer-quickbooks-profile.vue";

export default {
  name: "customer-quickbooks",
  components: {CustomerQuickbooksProfile, CustomerQuickbooksSearch, IconQuickbooks},

  computed: {
    current() {
      return store.get('Customers/current');
    },
    party() {
      return store.get('Parties/Party/current');
    }
  },
  methods: {

    async confirmCreate() {
      store.commit('Customers/loading', true);
      this.$confirm.require({
        group: 'quickbooks',
        message: `Make sure you can't find one first otherwise your books can get messy!`,
        header: 'Create a New Quickbooks Customer?',
        icon: 'pi pi-exclamation-triangle',
        confirmLabel: 'Create',
        accept: async () => {
          try{
            await store.dispatch('Customers/createQuickbooksCustomer', this.current);
            if(this.party)
              await store.dispatch('Parties/Party/load', this.party.token);

            this.$toast.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Quickbooks customer created successfully',
              life: 3000

            })
          }catch(e){
            this.$toast.add({
              severity: 'error',
              summary: 'Customer Not Created',
              detail: e.message,
              life: 3000
            })
          }
        },
      });
      store.commit('Customers/loading', false);
    }
  }
}
</script>

<style lang="scss" scoped>
.quickbooks-button {
  color: #ffffff;
  background: #2ca01c;
  border-color: #2ca01c;
  padding: 2px 15px 2px 2px;
}
</style>

<template>
  <img :src="src" alt="Image" :height="height" />
</template>

<script>
import Config from '@/config';
export default {
  name: "logo-company",
  props: {
    height: {type: Number, default: 90},
    logo: {type: String, default: 'logo-pink.png'}
  },
  computed: {
    src() {
      return `${Config.imgPath}/${this.logo}`;
    }
  },
}
</script>

<style scoped lang="scss">

</style>

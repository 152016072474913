<template>
  <ContextMenu ref="cm" :model="items"/>
</template>
<script>
import Bus from '@/bus';
export default {
  name: "misc-context-menus",
  data() {
    return {
      items: null,
      selectedRow: null,
      standard:[
        {label: 'Clone', icon: 'pi pi-fw pi-copy', command: this.clone},
        {label: 'Split', icon: 'pi pi-fw pi-eye-slash', command: this.split},
        {label: 'Delete', icon: 'pi pi-fw pi-times', command: this.delete}
      ]
    }
  },
  computed: {
    startTime() {
      return store.get('Parties/Party/startDateTime');
    },
    endTime(){
      return store.get('Parties/Party/endTime');
    }
  },
  mounted() {
    Bus.$on('event.invoice.misc.contextmenu', this.showMenu);
  },
  unmounted() {
    Bus.$off('event.invoice.misc.contextmenu', this.showMenu);
  },
  methods: {

    showMenu({event, data, field}) {
      this.$nextTick(() => {
        this.selectedRow = data;
        this.items = this.getItems(field);
        this.$refs.cm.show(event);
      });
    },

    getItems(field) {
      switch (field) {
        case 'price':
          return this.getPriceMenu();
        case 'cost':
          return this.getCostMenu();
        case 'employee':
          return this.getEmployeeMenu();
        case 'start_time':
          return this.getTimeMenu();
        case 'end_time':
          return this.getTimeMenu();
        default:
          return this.standard;
      }
    },

    getPriceMenu() {
      return [
        // {label: 'Calculate Price', icon: 'pi pi-fw pi-calculator', command: this.calculatePrice},
        // {separator: true},
        ...this.standard
      ];
    },

    getCostMenu() {
      return [
        // {label: 'Calculate Costs', icon: 'pi pi-fw pi-calculator', command: this.calculateCost},
        // {separator: true},
        ...this.standard
      ];
    },

    getEmployeeMenu() {

      let employee = (this.selectedRow?.employee_id !== null)
        ? [
          {label: 'View Staff Schedule', icon: 'pi pi-fw pi-calendar', command: this.viewEmployeeSchedule},
          {label: 'Remove Staff', icon: 'pi pi-fw pi-times', command: this.removeStaff},
          {label: 'Add Tip', icon: 'pi pi-fw pi-dollar', command: this.addTip},
          {separator: true},
        ]
        : [];

      return [
        ...employee,
        ...this.standard
      ];
    },

    getTimeMenu(){
      return [
        {label: 'Remove Time', icon: 'pi pi-fw pi-times', command: this.setTimeToNull},
        {label: 'Reset Time', icon: 'pi pi-fw pi-refresh', command: this.resetTime},
        {separator: true},
        ...this.standard
      ];
    },


    viewEmployeeSchedule(){

    },

    removeStaff(){
      this.selectedRow.employee_id = null;
      store.dispatch('Scheduleables/editScheduleable', this.selectedRow);
    },

    setTimeToNull(){
      this.selectedRow.start_time = null;
      this.selectedRow.end_time = null;
      store.dispatch('Scheduleables/editScheduleable', this.selectedRow);
    },

    resetTime(){
      this.selectedRow.start_time = this.startTime;
      this.selectedRow.end_time = this.endTime;
      store.dispatch('Scheduleables/editScheduleable', this.selectedRow);
    },


    clone() {
      store.commit('Scheduleables/miscLoading', true);
      store.dispatch('Scheduleables/cloneScheduleable', this.selectedRow);
    },

    split() {
      store.commit('Scheduleables/miscLoading', true);
      store.dispatch('Scheduleables/splitScheduleable', this.selectedRow);
    },

    delete() {
      store.commit('Scheduleables/miscLoading', true);
      store.dispatch('Scheduleables/removeScheduleable', this.selectedRow);
    },

  },
}
</script>

<style scoped lang="scss">

</style>

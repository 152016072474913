import {make} from 'vuex-pathify';
import api from '@/config.wretch';
import {each, has} from "lodash";
import dayjs from 'dayjs';
import Bus from "@/bus";

const state = {
  id: null,
  employee_id: null,
  category: 'General',
  start_time: dayjs().subtract(1, 'hour').toDate(),
  end_time: new Date(),
  timeline: [],
  breakTime: 0,
  total_work_duration: null,
  total_break_duration: null,
  rate: 20,
  total: null,
  paid_on: null,
  description: null,
  gigs: [], // Add gigs to the state
};

const restate = {...state};

const getters = {

  ...make.getters(state),
};

const mutations = {
  ...make.mutations(state),

  start_time(state, value) {
    state.start_time = dayjs(value).toDate();
  },

  end_time(state, value) {
    state.end_time = dayjs(value).toDate();
  }
};

const actions = {

  set({commit, state}, current) {
    each(current, (value, key) => {
      if (has(state, key)) {
        commit(key, value);
      }
    });
    if (current && current.category === null) {
      commit('category', 'General');
    }

    if (current === null) {
      state = {...restate};
    }
  },

  async save({state}) {
    let response;
    let payload = getPayload({...state});

    if(payload.id) {
      response = await api.url(`/labor/${payload.id}`).put(payload).res();
    }else{
      response = await api.url(`/labor`).post(payload).res();
    }

    if (response.status !== 200 && response.status !== 201) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to save timesheet');
    }

    Bus.$emit('labor.update');
    await store.dispatch('Labor/reload');
    return response;
  },


  reset({dispatch, commit, state}) {
    let old = {...state};
    dispatch('set', restate);
    commit('id', old.id);
    commit('employee_id', old.employee_id);
    commit('gigs',[]);
  },

  async clone({state}) {
    try {
      await api.url(`/labor/clone`).post({employee_id:state.employee_id, id: state.id}).res();
      await store.dispatch('Labor/reload');

    } catch (error) {
      console.error('Error creating timesheet:', error);
    }
  }
};

function getPayload(payload) {

  payload.start_time = dayjs(payload.start_time).format('YYYY-MM-DD HH:mm:ss');
  payload.end_time = dayjs(payload.end_time).format('YYYY-MM-DD HH:mm:ss');

  payload.gigs = payload.gigs.map(gig => gig.id || gig);

  return payload;
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

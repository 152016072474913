<template>

  <Button type="button" @click="show" icon="pi pi-print" severity="secondary" text rounded title="Print Options"></Button>

  <Dialog v-model:visible="visible" class="surface-overlay"
          :style="{width: '950px'}" :draggable="false" :resizable="false" id="print" position="top"
          @dblclick.stop>
    <template #container>
      <section class="p-5">
        <div class="flex justify-content-between align-content-end">
          <h2 class="text-600">Printables</h2>
          <div>
            <Dropdown
                    v-model="prints"
                    :options="printOptions"
                    optionLabel="label"
                    option-value="value"
                    id="prints"
                    input-class="text-base text-color surface-overlay p-2 surface-border border-round appearance-none outline-none focus:border-primary w-full"
                    class="w-full"
            />
          </div>
          <div>
            <Button icon="pi pi-file-pdf" @click="pdf" text rounded severity="secondary"></Button>
            <Button icon="pi pi-camera" @click="pic" text rounded severity="secondary"></Button>
            <Button icon="pi pi-print" @click="print" text rounded severity="secondary"></Button>
            <Button icon="pi pi-times" @click="close" text rounded severity="secondary"></Button>
          </div>
        </div>
        <div style="width:100%; height:70vh; overflow-y:auto;" class="print-area border-dashed border-2 border-200 p-3 surface-ground">
          <div class="flex justify-content-center w-full">
            <div class="mb-3 align-items-baseline flex print-filters">
              <label class="font-weight-bold text-sm mr-3">show:</label>
              <ToggleButton v-model="showMoney" onLabel="money" offLabel="money" on-icon="pi pi-check" off-icon="pi pi-times" class="mr-2"></ToggleButton>
              <ToggleButton v-model="showCost" onLabel="costs" offLabel="cost" on-icon="pi pi-check" off-icon="pi pi-times" class="mr-2"></ToggleButton>
              <ToggleButton v-model="showStaff" onLabel="staff" offLabel="staff" on-icon="pi pi-check" off-icon="pi pi-times" class="mr-2"></ToggleButton>
              <ToggleButton v-if="party.party_notes" v-model="showNotes" onLabel="notes" offLabel="notes" on-icon="pi pi-check" off-icon="pi pi-times" class="mr-2"></ToggleButton>
            </div>
          </div>
          <div class="surface-overlay p-3 border-200 border-1">
            <div id="printArea">
              <prints-dispatch v-if="prints==='dispatch'" :show-money="showMoney" :show-cost="showCost" :show-staff="showStaff" />
              <prints-new-invoice v-else-if="prints==='invoice'" :show-money="showMoney" :show-cost="showCost" :show-staff="showStaff"/>
              <prints-transactions v-else />
            </div>
          </div>
        </div>
      </section>
    </template>
  </Dialog>

</template>

<script>
import printJS from "print-js";
import html2canvas from "html2canvas";
import {jsPDF} from "jspdf";

import PrintsCustomerInvoice from "@/components/events/quick-view/modals/print/prints-customer-invoice.vue";
import PrintsDispatch from "@/components/events/quick-view/modals/print/prints-dispatch.vue";
import PrintsNewInvoice from "@/components/events/quick-view/modals/print/prints-new-invoice.vue";
import {kebabCase} from "lodash";
import PrintsTransactions from "@/components/events/quick-view/modals/print/prints-transactions.vue";

export default {
  name: "print-event-modal",
  components: {PrintsTransactions, PrintsNewInvoice, PrintsDispatch, PrintsCustomerInvoice},
  data() {
    return {
      visible: false,
      prints: 'invoice',
      printOptions: [
        {label: 'Invoice', value: 'invoice'},
        {label: 'Dispatch', value: 'dispatch'},
        {label: 'Transactions', value: 'transactions'},
      ],
      showMoney: true,
      showCost: false,
      showImages: false,
      showStaff: true,
      showNotes: true,
    };
  },
  methods: {
    party() {
      return store.get('Parties/Party/current');
    },
    show() {
      this.visible = true;
    },
    close() {
      this.visible = false;
    },

    print() {
      const scaleFactor = 300 / 96;  // Adjust this scale factor for desired DPI
      const printArea = document.getElementById('printArea');

      // Define explicit width and height
      const width = printArea.offsetWidth;
      const height = printArea.offsetHeight;

      html2canvas(printArea, {
        useCORS: true,
        scale: scaleFactor,
        width: width,
        height: height,
        // ... (other options if needed)
      }).then(canvas => {
        let imageData = canvas.toDataURL('image/png');
        printJS({
          printable: imageData,
          type: 'image',
          documentTitle: 'Party Printout',
          imageSize: {width: 1123, height: 794}
          // Add any other printJS options you need
        });
      });
    },

    pdf() {
      const element = document.getElementById('printArea');
      const quality = 2; // Higher for better quality

      html2canvas(element, {
        scale: quality,
        useCORS: true
      }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF({
          orientation: 'p',  // portrait
          unit: 'in',        // inches
          format: 'letter'   // US Letter size
        });

        // Margins
        const topMargin = 0.5; // 0.5 inch top margin
        const leftMargin = 0.5; // 0.5 inch left margin
        const bottomMargin = 0.5; // 0.5 inch bottom margin
        const rightMargin = 0.5; // 0.5 inch right margin

        // PDF dimensions
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const usableWidth = pdfWidth - leftMargin - rightMargin;
        const usableHeight = pdfHeight - topMargin - bottomMargin;

        // Calculate aspect ratio
        const canvasAspectRatio = canvas.width / canvas.height;

        let imgWidth, imgHeight;

        // Scale based on aspect ratio
        if (canvasAspectRatio > 1) {
          imgWidth = usableWidth;
          imgHeight = imgWidth / canvasAspectRatio;
        } else {
          imgHeight = usableHeight;
          imgWidth = imgHeight * canvasAspectRatio;
        }

        // If image height exceeds available height, adjust dimensions
        if (imgHeight > usableHeight) {
          imgHeight = usableHeight;
          imgWidth = imgHeight * canvasAspectRatio;
        }

        // Calculate coordinates with margins
        const x = leftMargin + (usableWidth - imgWidth) / 2;
        const y = topMargin;

        // Add image to PDF
        pdf.addImage(imgData, 'PNG', x, y, imgWidth, imgHeight);
        pdf.save(`${kebabCase(this.partyName)}.pdf`);
      });
    },

    pic() {
      // Clone the element
      const originalElement = document.getElementById('printArea');
      const cloneElement = originalElement.cloneNode(true);

      // Specify width, height, and other styles for the clone
      cloneElement.style.width = '640px';  // Adjust as needed
      cloneElement.style.height = 'auto'; // Adjust as needed
      cloneElement.style.padding = '20px';
      cloneElement.style.position = 'absolute';
      cloneElement.style.left = '-9999px'; // Position off-screen

      // Append the clone to the body
      document.body.appendChild(cloneElement);

      const scaleFactor = 300 / 96;
      html2canvas(cloneElement, {
        useCORS: true,
        scale: scaleFactor // Adjust the scale factor as needed
      }).then(canvas => {
        canvas.toBlob(blob => {
          const item = new ClipboardItem({"image/png": blob});
          navigator.clipboard.write([item])
            .then(() => {
              this.$toast.add({
                severity: 'success',
                summary: 'Image Copied to Clipboard',
                detail: `Open Email or SMS tool and Paste.`,
                life: 3000
              });
            })
            .catch(() => {
              this.$toast.add({severity: 'error', summary: 'Error', detail: `Image Copy Failed`, life: 3000});
            });
        });

        // Clean up: remove the clone from the DOM
        document.body.removeChild(cloneElement);
      });
    }
  }
}
</script>

<style lang="scss">
.print-filters .p-button {
  padding: 5px 10px;
  margin: 0;
  font-size: 14px;
}
</style>

<template>
  <Card @click="setCurrent(character)" :class="{'active': current === character}" class="h-18rem">
    <template #content>
      <character-image :character="character"></character-image>
      <h4 class="text-500">{{ character.name }}</h4>
      <character-context-menu :character="character"></character-context-menu>
    </template>
  </Card>
</template>

<script>
import CharacterImage from "@/components/characters/common/character-image";
import CharacterContextMenu from "@/components/characters/common/character-context-menu.vue";

export default  {
  name: "characters-card",
  props: ['character', 'overrideSetCurrent'],
  components: {CharacterContextMenu, CharacterImage },
  computed: {
    current() {
      return store.get('Characters/current');
    },
    hasOverride() {
      return this.overrideSetCurrent !== undefined;
    }
  },
  methods: {
    setCurrent(character) {
      if (!this.hasOverride) {
        store.dispatch('Characters/setCurrent', character);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.card-shadow {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.transparent-toolbar {
  background-color: rgba(0, 0, 0, 0);
}
</style>

<template>
    <InputText
            v-model="term"
            @input="debouncedFilter"
            placeholder="Example: princess or cinderella"
            class="input-field"
    />
</template>

<script>
import {debounce} from 'lodash';
import InputText from 'primevue/inputtext'; // Ensure InputText is globally registered if not importing here

export default {
  name: "CharacterSearchFilter",
  components: {
    InputText
  },
  data() {
    return {
      term: null,
      debouncedFilter: null,
    };
  },
  created() {
    this.debouncedFilter = debounce(() => {
      store.commit('Characters/filter', this.term);
    }, 500);
  }
}
</script>

<style lang="scss" scoped>
</style>

<template>
  <p>
    You can find your printable invoice and itemized details including your exclusive pricing here:
  </p>
  <div>
    <a :href="`${url}?${email}#invoice`" style="color:#f616c9; text-decoration:underline;">{{ url }}</a>
  </div>
</template>

<script>
import Config from "@/config";

export default {
  name: "email-snipit-party-invoice",
  computed: {
    party() {
      return store.get('Parties/Party/current');
    },
    customer(){
      return this.party.customer;
    },
    url() {
      return `${Config.domain}/my-party/${this.party.token}`;
    },
    email(){
      return `email=${encodeURIComponent(this.customer.email)}`;
    }
  }
}
</script>

<style scoped lang="scss">

</style>

<template>
  <section v-if="loading" class="flex justify-content-center align-content-center h-30rem">
    <progress-spinner class="h-full"/>
  </section>
  <section v-else class="fadein animation-duration-500">
    <Card v-if="current.quickbooks_id">
      <template #content>
        <div class="flex justify-content-between">
          <div class="flex align-self-center">
            <icon-quickbooks size="36"/>
            <h2 class="px-2 my-1 text-500">Syncing With Quickbooks</h2>
          </div>
          <Button icon="pi pi-times" rounded text @click="removeInvoice()"/>
        </div>

        <div>Last Updated: {{updated}} | <a :href="`${config.quickbooksUrl}/app/customerdetail?nameId=${customer.quickbooks_id}`" target="_blank" class="text-primary">Customer Profile</a> |
          <a :href="`${config.quickbooksUrl}/app/invoice?txnId=${this.current.quickbooks_id}`" target="_blank" class="text-primary">View Invoice</a>
        </div>
        <p class="text-xs m-0 mt-3">Event is currently being sync'd with quickbooks invoice #{{current.quickbooks_id}}. Do not edit quickbooks invoice or its contents will be overwritten during next update. </p>
        <p class="text-xs m-0">You can delete the quickbooks invoice and this sync association by choosing the x to the right.</p>
      </template>
    </Card>
    <Card v-else>
      <template #content>
        <div class="flex justify-content-between">
          <div class="flex align-self-center">
            <icon-quickbooks size="36" color="#cccccc"/>
            <h2 class="px-2 my-1 text-500">Add Party Invoice To Quickbooks</h2>
          </div>
          <Button icon="pi pi-plus" label="Add" rounded text @click="confirmCreate()"/>
        </div>

        <p class="text-xs m-0">Upon adding invoice to quickbooks it will be automatically synced with every save button click. Do not edit quickbooks invoice or its contents will be overwritten during next update.</p>
      </template>
    </Card>
  </section>
</template>

<script>
import IconQuickbooks from "@/components/common/icon-quickbooks.vue";
import config from "@/config";
import {formatDateTime} from "@/helpers/date.helper";

export default {
  name: "event-quickbooks",
  components: {IconQuickbooks},
  data() {
    return {
      config: config,
      loading: false,
    }
  },
  computed: {
    current() {
      return store.get('Parties/Party/current');
    },
    customer() {
      return this.current.customer;
    },
    updated() {
      return formatDateTime(this.current.updated_at);
    }
  },
  methods: {

    removeInvoice() {
      this.$confirm.require({
        group: 'quickbooks',
        confirmLabel: 'Delete',
        message: `Are you sure you want to delete this invoice from Quickbooks?`,
        header: 'Delete Invoice',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          this.loading = true;
          await store.dispatch('Parties/Party/deleteQuickbooksInvoice');
          await store.dispatch('Parties/Party/reload');
          this.loading = false;
        }
      });
    },

    confirmCreate() {
      if (!this.customer.quickbooks_id)
        this.$toast.add({
          severity: 'error',
          summary: "No Quickbooks Customer",
          detail: "Customer doesn't exist in quickbooks yet. Go to customer and associate with Quickbooks before you can add this invoice",
          life: 5000
        });
      else
        this.$confirm.require({
          group: 'quickbooks',
          header: 'Create an Invoice in Quickbooks?',
          message: 'After this is created all your edits will be made in Quickbooks as well.',
          confirmLabel: 'Create',
          accept: async () => {
            this.loading = true;
            await store.dispatch('Parties/Party/saveQuickbooksInvoice');
            await store.dispatch('Parties/Party/reload');
            this.loading = false;
          }
        });
    },

  },
}
</script>

<style lang="scss" scoped>
.quickbooks-button {
  background-color: #2ca01c;
  border-color: #2ca01c;
  color: #ffffff;
  padding: 2px 15px 2px 2px;
}
</style>

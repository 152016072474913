<script>
import EmailSnipitPartyInvoice from "@/components/events/messages/snipits/email-snipit-party-invoice.vue";
import EmailSnipitDivider from "@/components/events/messages/snipits/email-snipit-divider.vue";

export default {
  name: "message-payment-due",
  components: {EmailSnipitDivider, EmailSnipitPartyInvoice},
  computed: {
    party(){
      return store.get('Parties/Party/current');
    },
    customer() {
      return this.party.customer;
    }
  }
}
</script>

<template>
  <div>
    <h3>Hi {{ customer.first }}!</h3>

    <p>Let's complete your booking with a payment. Only a payment guarantees your booking.</p>

    <p><strong>Please call us with: Visa, MasterCard or Discover credit card to secure your booking.</strong></p>

    <h3>Deposit and Full Payment</h3>
    <p>
      For events scheduled more than two weeks away, we require a deposit, with a <strong>full payment due before the event</strong>.
      Full payment is due at the time of booking for events within two weeks. We no longer do final payments at the event.
      We want our customers to enjoy their party with out distraction.
    </p>
    <p>
      While we have more staff than most event companies, we still get completely booked. Please act fast: First come
      first serve.
    </p>

    <h3>Non-Refundable. One Year Credit</h3>
    <p>
      When you put down your deposit or pay in full, we set aside that time just for you, saying no to any other bookings, now or later. Your payment becomes non-refundable to guarantee your reservation.
    </p>
    <p>
      If some sort of life event arises that cancels your party, you have a whole year to use your credit. You will
      additionally have a priority booking to reschedule.
    </p>
    <email-snipit-divider/>
    <email-snipit-party-invoice/>
  </div>
</template>

<style scoped lang="scss">

</style>

<template>
  <Dialog v-model:visible="visible" modal class="w-4 h-auto surface-overlay" position="top"
          :style="{width: '950px'}"
          @dblclick.stop>
    <template #container>
      <section class="p-5">

        <div class="flex justify-content-between">
          <div>
            <h3 class="text-600">Staffing {{ type }}</h3>
            <div class="">
              <div v-if="gig.character">
                <img :src="getCharacterImage(gig.character)" :title="gig.character.name" class="m-0 border-200 border-3 border-circle bg-white w-3rem h-3rem"/>
              </div>
              <div v-else-if="gig.addon">
                <img :src="getServiceImage(gig.addon)" :title="gig.addon.title" class="m-0 border-200 border-3 border-rounded bg-white w-3rem h-3rem"/>
              </div>
              <div>
                {{ gig.misc }}
              </div>
            </div>
            <p>{{ date }} <span v-if="isValid(start)">{{ start }} to {{ end }}</span></p>
          </div>
          <div>
            <Button type="button" icon="pi pi-times" class="ml-2 align-self-start" size="small" text rounded @click="close" severity="secondary"></Button>
          </div>
        </div>

        <TabView v-model:activeIndex="activeTab">
          <TabPanel header="Open Requests">
            <scroll-panel class="h-30rem" v-if="requests">
              <Card v-for="request in requests" :key="request.id" :pt="{content:{class:'p-0 m-0'}}" class="mb-3 mx-2">
                <template #content>
                  <div class="flex justify-content-between">
                    <div class="flex justify-content-start">
                      <img :src="getEmployeeImage(request.employee)" class="m-0 border-200 border-3 border-circle bg-white w-3rem h-3rem mr-2"/>
                      <h2 class="text-600 align-self-center m-0 p-0">{{ request.employee.fullName }}</h2>
                    </div>
                    <div>
                      <Button type="button" text rounded icon="pi pi-times" size="small" @click="removeRequest(request)" class="ml-2 align-self-start" severity="secondary" title="remove request"></Button>
                    </div>
                  </div>
                  <div class="flex justify-content-between">
                    <p class="mr-3">{{ howLongAgo(request) }}</p>
                    <Button type="button" label="Message In OpenPhone" icon="pi pi-msg" size="small" text rounded @click="message(request)"></Button>
                  </div>
                </template>
              </Card>
            </scroll-panel>
            <div v-else>
              <h2>You Have No Open Requests</h2>
              <p>Go To <Button type="button" text @click="activeTab=1" class="m-0 p-0 ">Request Tab</Button> to Create Requests</p>
            </div>
          </TabPanel>
          <TabPanel header="Request">
            <div>
              <p>Select one or multiple Team Members and then click the create requests button.</p>
              <ListBox ref="listBox" :options="employees" option-label="fullName" v-model="selectedEmployees" multiple filter :pt="pt">
                <template #option="{option}">
                  <div class="flex align-items-center m-0 p-0 fadein animation-duration-500">
                    <img :src="getEmployeeImage(option)" class="mr-4 mb-2 lg:mb-0 border-circle border-1 img" alt="Employee Image"/>
                    <span>{{ option.fullName }}</span>
                  </div>
                </template>
              </ListBox>
              <div class="flex justify-content-end my-3">
              <Button type="button" label="Create Requests" size="small" rounded @click="request"></Button>
              </div>
            </div>
          </TabPanel>
        </TabView>

      </section>
    </template>
  </Dialog>
</template>

<script>
import {sync} from "vuex-pathify";
import config from "@/config";
import {getCharacterImage, getEmployeeImage, getServiceImage} from "@/helpers/images.helper";
import dayjs from 'dayjs';
import relativeTime from 'dayjs-ext/plugin/relativeTime'

dayjs.extend(relativeTime);
export default {
  name: 'EmployeeStaffingModal',
  data() {
    return {
      activeTab:0,
      selectedEmployees: [],
      filter: '',
      pt: {
        list: {class: 'overflow-y-auto max-h-15rem m-0 p-0'},
        item: {class: 'm-0 p-2'}
      },
    };
  },
  computed: {
    visible: sync('Employees/showStaffingModal', null, false),

    gig() {
      return store.get('Scheduleables/current');
    },

    requests(){
      return this.gig.requests;
    },

    type() {
      if (this.gig.character) return 'Character';
      else if (this.gig.addon) return 'Service';
      return 'Misc Party Item';
    },

    item() {
      if (this.gig.character) return this.gig.character.name;
      else if (this.gig.addon) return this.gig.addon.title;
      return this.gig.misc;
    },

    loading() {
      return store.get('Employees/loading');
    },

    employees() {
      return store.get('Employees/list');
    },

    date() {
      return dayjs(this.gig.party.date).format("ddd, MMM DD");
    },

    start() {
      return dayjs(this.gig.start_time).format('h:mma');
    },

    end() {
      return dayjs(this.gig.end_time).format('h:mma');
    }
  },

  async mounted() {
    if (!this.employees.length) {
      await store.dispatch('Employees/load');
      this.focusOnFilter();
    } else {
      this.focusOnFilter();
    }
  },

  methods: {

    getServiceImage,
    getCharacterImage,
    getEmployeeImage,

    focusOnFilter() {
      const filterInput = this.$refs.listBox?.$el.querySelector('input');
      if (filterInput) {
        filterInput.focus();
      }
    },

    close() {
      this.visible = false;
    },

    howLongAgo(request) {
      return dayjs(request.created_at).fromNow();
    },

    isValid(date){
      return date !== 'Invalid Date';
    },

    async request() {
      await store.dispatch('Employees/requestEmployees', {gig_id: this.gig.id, employees:this.selectedEmployees});
      store.dispatch('Scheduleables/reload');
    },

    async message({employee,hash}) {
      await this.writeToClipboard(employee, hash);
      this.phoneEmployee(employee.phone);
      this.close();
    },

    async writeToClipboard(employee, hash) {
      let times = (this.isValid(this.start)) ? ` from ${this.start} to ${this.end}` : '';
      let message = `[NEW GIG] Hi ${employee.name}!\nWe have a ${this.item} gig on ${this.date}${times}.\nreview:${config.domain}/gig/${hash}`;
      await navigator.clipboard.writeText(message);
    },

    removeRequest(request) {
        this.$confirm.require({
          message: `Removing request will disable any links that you have sent to ${request.employee.fullName}. Are you sure you want to remove this request?`,
          header: 'Remove Request',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            store.dispatch('Employees/removeRequest', request);
          }
        });
    },

    phoneEmployee(phone) {
      if(phone)
        window.location.href = `tel:8184369576`;
      // window.location.href = `tel:${phone}`;
    },
  },
};
</script>

<style scoped>
.img {
    width: 36px;
    height: 36px;
}
</style>

<script>
import EmailSnipitCharacters from "@/components/events/messages/snipits/email-snipit-characters.vue";
import EmailSnipitServices from "@/components/events/messages/snipits/email-snipit-services.vue";
import EmailSnipitMisc from "@/components/events/messages/snipits/email-snipit-misc.vue";
import EmailSnipitPartyInvoice from "@/components/events/messages/snipits/email-snipit-party-invoice.vue";
import EmailSnipitDivider from "@/components/events/messages/snipits/email-snipit-divider.vue";
import EmailSnipitPartyLocation from "@/components/events/messages/snipits/email-snipit-party-location.vue";
import EmailSnipitDate from "@/components/events/messages/snipits/email-snipit-date.vue";

export default {
  name: "message-proposal-update",
  components: {
    EmailSnipitDate,
    EmailSnipitPartyLocation,
    EmailSnipitDivider, EmailSnipitPartyInvoice, EmailSnipitMisc, EmailSnipitServices, EmailSnipitCharacters},
  computed: {

    party() {
      return store.get('Parties/Party/current');
    },

    customer() {
      return this.party.customer;
    }
  }
}
</script>

<template>
  <div>
    <h3>Hi, {{ customer.first }}</h3>

    <p>
      Here you go! We've updated our party proposal with your feedback. When you're ready reply "lets book it" and we'll finalize everything with you.
    </p>

    <email-snipit-divider/>
    <email-snipit-date/>

    <email-snipit-party-location/>
    <p>
      <email-snipit-divider/>
    </p>

    <p>Does everything look right?</p>

    <email-snipit-characters/>

    <email-snipit-services/>

    <email-snipit-misc/>

    <p>Want to see more? or take something off? Just reply with what you need.</p>

    <p>All listed options are available at the time of this email but other customers are currently evaluating their proposals as well. First come, first serve, please finalize
    your booking to guarantee service.</p>

    <p>Reply "lets book it" and we will help you complete the booking or give us a call.</p>

    <email-snipit-divider/>
    <email-snipit-party-invoice/>

  </div>
</template>

<style scoped lang="scss">

</style>

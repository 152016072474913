<template>
  <section>
    <div class="my-3 flex justify-content-between">
      <logo-company :height="90"></logo-company>
      <div class="w-15rem py-3 text-500">
        <div>Invoice #: {{party.token}}</div>
        <div>Created: {{ formatWordDate(party.created_at) }}</div>
        <div>Santa Clarita, Ca 91354</div>
        <div>{{phone}}</div>
      </div>
    </div>

    <div class="flex justify-content-between text-500">
      <div class="w-6">
        <h3 class="text-600">Event Date</h3>
        <div><strong>Date:</strong> {{ formatWordDateWithDay(party.date) }}</div>
        <div><strong>Time:</strong> {{ formatTime(party.date) }} - {{ formatTime(endTime) }} ({{ party.duration }}hrs)</div>
      </div>
      <div class="w-6">
        <h3 class="text-600"><strong>Venue:</strong> {{ party.venue }}</h3>
        <div><strong>Address:</strong> {{ party.address }}</div>
        <div><strong>City:</strong> {{ party.city }}, Ca {{ party.zip }}</div>
      </div>
    </div>

    <div class="flex justify-content-between text-500">
      <div class="w-6">
        <h3 class="text-600">Customer</h3>
        <div v-if="customer.company"><strong>Company:</strong> {{ customer.company }}</div>
        <div><strong>Customer Name:</strong> {{ customer.full_name }}</div>
        <div><strong>Cell Phone:</strong> {{ customer.phone }}</div>
        <div><strong>Event Phone:</strong> {{ party.phone2 || ' - ' }}</div>
      </div>
      <div class="w-6">
        <h3 class="text-600">Party Details</h3>
        <div><strong>Occasion:</strong> {{ party.occasion }}</div>
        <div><strong>Guest of Honor:</strong> {{ party.for_whom }}</div>
        <div><strong>Age:</strong> {{ party.age }}</div>
        <div><strong>No. of Kids/Guests:</strong> {{ party.guests }}</div>
      </div>
    </div>

    <character-table :hide-images="!showImages" :hide-money="!showMoney" :hide-staff="!showStaff" :hide-cost="!showCost"/>
    <services-table :hide-images="!showImages" :hide-money="!showMoney" :hide-staff="!showStaff" :hide-cost="!showCost" :hide-invisible="true"/>
    <misc-table :hide-money="!showMoney" :hide-staff="!showStaff" :hide-cost="!showCost" :hide-invisible="true"/>
    <div class="grid">
      <div class="col-6">
        <div v-if="party.party_notes && showNotes">
          <h2 class="text-600">Notes</h2>
          <p>{{ party.party_notes }}</p>
        </div>
      </div>

      <div v-if="showMoney" class="col-6">
        <div class="flex justify-content-end">
          <h2 class="text-600 mr-3">Total:</h2>
          <h2 class="text-600">{{ total }}</h2>
        </div>

        <div v-if="transactions.length">

          <DataTable :value="transactions" showGridlines stripedRows size="small">
            <template #header>
              <div class="flex flex-wrap align-items-center justify-content-between gap-2">
                <span class="text-xl text-600 font-bold">Payments</span>
              </div>
            </template>
            <Column field="submitTimeLocal" header="Submit Time">
              <template #body="{data}">
                {{ formatDateTime(data.submitTimeLocal) }}
              </template>
            </Column>
            <Column field="transId" header="Transaction ID"></Column>
            <Column field="amount" header="Amount">
              <template #body="{data}">
                {{ formatMoney(data.amount) }}
              </template>
            </Column>
            <ColumnGroup type="footer">
              <Row>
                <Column footer="Total:" colspan="2" footerStyle="text-align:right"/>
                <Column :footer="formatMoney(paymentsTotal)"/>
              </Row>
            </ColumnGroup>
          </DataTable>

          <div class="flex justify-content-end">
            <h2 class="text-600 mr-3">Balance:</h2>
            <h2 class="text-600">{{ formatMoney(balance) }}</h2>
          </div>

        </div>
      </div>
    </div>
  </section>
</template>

<script>
import MiscTable from "@/components/events/quick-view/tables/misc-table.vue";
import LogoCompany from "@/components/common/logo-company.vue";
import ServicesTable from "@/components/events/quick-view/tables/services-table.vue";
import CharacterTable from "@/components/events/quick-view/tables/character-table.vue";
import Config from "@/config";
import {capitalize} from "lodash";
import dayjs from "dayjs";

import {formatMoney} from "accounting-js";
import {formatDate, formatDateTime, formatWordDate, formatWordDateWithDay} from "@/helpers/date.helper";
import {formatTime} from "@/helpers/time.helper";

export default {
  name: "prints-new-invoice",
  props: {
    showMoney: {
      type: Boolean,
      default: true
    },
    showCost: {
      type: Boolean,
      default: false
    },
    showImages: {
      type: Boolean,
      default: false
    },
    showStaff: {
      type: Boolean,
      default: false
    },
    showNotes: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      phone: Config.phone
    }
  },
  methods: {
    formatTime,
    formatWordDateWithDay,
    formatWordDate,
    formatMoney,
    formatDate,
    formatDateTime,
    capitalize,
  },
  components: {CharacterTable, ServicesTable, LogoCompany, MiscTable},
  computed: {
    party() {
      return store.get('Parties/Party/current');
    },
    customer() {
      return this.party.customer;
    },
    transactions() {
      return this.party.transactions;
    },
    total() {
      return formatMoney(store.get('Parties/Party/total'));
    },
    date() {
      return dayjs(this.party.date).format("MM/DD/YYYY, ddd");
    },
    startTime() {
      return dayjs(this.party.date).format('h:mma');
    },
    endTime(){
      return store.get('Parties/Party/endTime');
    },
    partyName() {
      return (this.party.for_whom)
        ? `${capitalize(this.party.for_whom)}'s ${capitalize(this.party.occasion) || 'Party'} in ${this.party.city}`
        : `${this.customer.full_name}'s ${capitalize(this.party.occasion) || 'Party'} in ${this.party.city}`;
    },
    balance() {
      return store.get('Parties/Party/balance');
    },
    paymentsTotal() {
      return store.get('Parties/Party/paymentsTotal');
    }
  }
}
</script>

<style scoped lang="scss">

</style>

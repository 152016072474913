import {make} from 'vuex-pathify';
import {each} from "lodash";

const state = {
  list: [],
  loading: false,
};

const getters = {
  ...make.getters(state),

  formattedEmailTemplates: (state) => (templates) => {
    return templates.map(template => {
      // Count occurrences based on interaction.title including the template name
      const count = state.list.filter(interaction => interaction.title.includes(template.subject)).length;

      let formattedName = template.name;
      if (count > 0) {
        formattedName += ` [sent${count > 1 ? ` x${count}` : ''}]`;
      }

      return {...template, name: formattedName};
    });
  },

  nextEmailToSend: () => (formattedTemplates) => {
    // Find the index of the last template with '[sent]' in its name
    const lastSentIndex = formattedTemplates.reduce((lastIndex, template, index) => {
      return template.name.includes('[sent') ? index : lastIndex;
    }, -1);

    // If no template with '[sent]' was found, or the last template is the one with '[sent]', return null
    if (lastSentIndex === -1 || lastSentIndex === formattedTemplates.length - 1) {
      return formattedTemplates[0];
    }

    // Return the next template after the last one with '[sent]'
    return formattedTemplates[lastSentIndex + 1];
  }
};

const mutations = make.mutations(state);

const actions = {

  async set({commit}, interactions) {

    each(interactions, (interaction) => {
      if (isEmail(interaction)) interaction.type = 'email';
      else if (isNote(interaction)) interaction.type = 'note';
      else interaction.type = null;
    })
    commit('list', interactions);
  },

  async load({commit}) {
    commit('loading', true);
    let data = await api.url(`/event/${this.party.id}/interactions`).get().json();
    commit('list', data);
    commit('loading', false);
  },

  async create({dispatch}, title) {
    let party = await store.get('Parties/Party/current');
    let payload = {
      "party_id": party.id,
      "title": title
    };
    dispatch('save', payload);
  },

  async save({commit}, payload) {
    commit('loading', true);
    let response = await api.url('/event/interactions').post(payload).res();
    await store.dispatch('Parties/Party/reload');
    commit('loading', false);
    return response;
  },

  async remove({commit}, note) {
    commit('loading', true);
    await api.url('/event/interactions/' + note.id).delete().res();
    await store.dispatch('Parties/Party/reload');
    commit('loading', false);
  }
};

function isEmail(item) {
  const lowerCaseTitle = item.title.toLowerCase();
  return lowerCaseTitle.startsWith('sent email:');
}

function isNote(item) {
  const lowerCaseTitle = item.title.toLowerCase();
  return lowerCaseTitle.startsWith('note');
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template>
  <DataTable
          :value="parties"
          paginator
          :rows="5"
          :rowsPerPageOptions="[10, 25, 50]"
          paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          v-model:filters="filters"
          :globalFilterFields="['addon.title', 'character.name', 'employee.name', 'party.city', 'misc']"
          selectionMode="multiple"
          v-model:selection="selectedParties"
          showGridlines
          stripedRows
          removableSort
          sortField="Created"
          dataKey="id"
          :loading="loading"
          @row-click="onRowClick"
          @row-dblclick="onRowDblClick"
          size="small"
  >
    <template #header>
      <div class="flex justify-content-end">

        <InputGroup>
          <InputGroupAddon>
            <i class="pi pi-search"/>
          </InputGroupAddon>
          <InputText v-model="filters['global'].value" placeholder="Search"
                     class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border appearance-none outline-none focus:border-primary w-full"/>
        </InputGroup>
      </div>
    </template>

    <template #empty>
      No events found.
    </template>

    <template #loading>
      <ProgressSpinner/>
    </template>

    <Column>
      <template #body="{data}">
        <div v-if="data.character">
          <img :src="getCharacterImage(data.character)" :title="data.character.name" class="m-0 border-200 border-3 border-circle bg-white w-3rem h-3rem"/>
        </div>
        <div v-else-if="data.addon">
          <img :src="getServiceImage(data.addon)" :title="data.addon.title" class="m-0 border-200 border-3 border-rounded bg-white w-3rem h-3rem"/>
        </div>
        <div>
          {{ data.misc }}
        </div>
      </template>
    </Column>

    <Column field="start_time" header="Date">
      <template #body="{data}">
        {{ formatDate(data.start_time) }}
      </template>
    </Column>

    <Column field="start_time" header="Start" sortable>
      <template #body="{data}">
        {{ formatTime(data.start_time) }}
      </template>
    </Column>

    <Column field="end_time" header="End" sortable>
      <template #body="{data}">
        {{ formatTime(data.end_time) }}
      </template>
    </Column>

    <Column field="party.city" header="City" sortable></Column>

    <Column field="employee_id" header="Staff">
      <template #body="{data}">
        <span v-if="data.employee" class="white-space-nowrap">
            <img :src="getEmployeeImage(data.employee)" @click.stop="openEmployeePicker(data)" :title="data.employee.fullName" class="m-0 border-200 border-3 border-circle bg-white w-3rem h-3rem"/>
            <i class="pi pi-star-fill position-absolute -mt-3 -ml-3 text-pink-300 text-2xl" v-if="data.employee.type !== 'vendor'" title="in house"></i>
            <i class="pi pi-star-fill position-absolute -mt-3 -ml-3 text-blue-300 text-2xl" v-if="data.employee.type === 'vendor'" title="vendor"></i>
        </span>
        <span v-else>
            <Button icon="pi pi-plus" rounded outlined aria-label="Filter" @click="openGigRequester(data)" class="w-2rem h-2rem"/>
        </span>
      </template>
    </Column>

  </DataTable>
</template>

<script>
import {formatMoney} from 'accounting-js';
import {FilterMatchMode} from "primevue/api";
import {ageingClass, calculateAgeing} from "@/helpers/party.helper"
import {formatDate} from "@/helpers/date.helper"
import {formatTime} from "@/helpers/time.helper"
import {getServiceImage, getCharacterImage, getEmployeeImage} from "@/helpers/images.helper"

export default {
  name: "staffing-table",
  props: {
    parties: Array,
    loading: {type: Boolean, default: true}
  },
  data() {
    return {
      selectedParties: [],
      filters: {
        global: {value: null, matchMode: FilterMatchMode.CONTAINS},
        employee_id: {value: null, matchMode: FilterMatchMode.NOT_EQUALS},
      },
    };
  },

  methods: {
    ...{
      formatDate,
      formatTime,
      calculateAgeing,
      ageingClass,
      formatMoney,
      getServiceImage,
      getCharacterImage,
      getEmployeeImage
    },

    onRowClick({data}) {
      store.commit('Parties/Party/current', data.party);
    },

    onRowDblClick() {
      store.commit('Parties/Party/view', true);
    },

    openGigRequester(gig) {
      store.commit('Parties/Party/current', gig.party);
      store.commit('Employees/showStaffingModal', true);
      store.commit('Scheduleables/current', gig);
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <div v-if="misc.length">
    <div class="flex">
      <h2 class="mr-2 text-600">Misc</h2>
    </div>
    <DataTable :value="items" showGridlines stripedRows size="small">
      <Column field="misc" header="Item">
        <template #body="{data}">
          {{!data.visible?' -- ':''}}{{ data.misc }}
        </template>
      </Column>
      <Column field="employee.fullName" header="Staff" class="w-3" v-if="!hideStaff">
        <template #body="{ data }">
          <div v-if="data.employee" class="flex justify-content-between cursor-pointer" @click="openEmployeeQuickView(data.employee)" @contextmenu="showMenu($event,data)">
            <span class="hover:underline hover:text-primary">{{ data.employee?.fullName }}</span>
            <notes-bookmark :data="data"></notes-bookmark>
          </div>
          <div v-else class="text-center">-</div>
        </template>
      </Column>
      <Column field="start_time" header="Start" class="w-1">
        <template #body="{data}">
          {{ formatTime(data.start_time) }}
        </template>
      </Column>
      <Column field="end_time" header="End" class="w-1">
        <template #body="{data}">
          {{ formatTime(data.end_time) }}
        </template>
      </Column>
      <Column field="price" header="Price" v-if="!hideMoney" class="w-1">
        <template #body="{data}">
          {{ formatMoney(data.price) }}
        </template>
      </Column>
      <Column field="cost" header="Cost" v-if="!hideMoney && !hideCost" class="w-1">
        <template #body="{data}">
          {{ formatMoney(data.cost) }}
        </template>
      </Column>

      <ColumnGroup type="footer" v-if="!hideMoney">
        <Row>
          <Column footer="Subtotal:"  :colspan="columns" footerStyle="text-align:right"/>
          <Column :footer="formatMoney(subtotalPrice)"/>
          <Column v-if="!hideCost"  :footer="formatMoney(subtotalCost)"/>
        </Row>
      </ColumnGroup>
    </DataTable>
  </div>
</template>
<script>
import {filter, sumBy} from "lodash";
import {formatTime} from "@/helpers/time.helper";
import {formatMoney} from 'accounting-js';
import NotesBookmark from "@/components/events/quick-view/tables/notes-bookmark.vue";
import Bus from "@/bus";

export default {
  name: "misc-table",
  components: {NotesBookmark},
  props: {
    hideMoney: {
      type: Boolean,
      default: false
    },
    hideStaff: {
      type: Boolean,
      default: false
    },
    hideCost: {
      type: Boolean,
      default: false
    },
    hideInvisible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    party() {
      return store.get('Parties/Party/current')
    },
    misc() {
      return filter(this.party.scheduleables, (item) => {
        return !item.character && !item.addon;
      })
    },
    visible(){
      return filter(this.misc,{visible: 1});
    },
    items(){
      return this.hideInvisible ? this.visible : this.misc;
    },
    subtotalPrice() {
      return sumBy(this.misc, (item) => parseFloat(item.price) || 0);
    },
    subtotalCost() {
      return sumBy(this.misc, (item) => parseFloat(item.cost) || 0);
    },
    columns(){
      let columns = 2;
      if(!this.hideStaff) columns++;
      if(!this.hideMoney) columns++;
      return columns;
    }
  },
  methods: {
    ...{formatTime, formatMoney},

    openEmployeeQuickView(employee) {
      store.commit('Employees/current', employee);
      store.commit('Employees/view', true);
    },
    showMenu(event, data) {
      Bus.$emit('event.notes.contextmenu', {event, data});
    },
  },
}
</script>


<style scoped lang="scss">

</style>

<template>
  <section v-if="loading" class="flex justify-content-center align-content-center h-10rem surface-overlay border-1 surface-border border-round shadow-1">
    <progress-spinner class="h-full"/>
  </section>
  <section v-else class="shadow-1">
    <ListBox ref="listBox" :options="employees" option-label="fullName" v-model="selectedEmployee" filter :pt="pt"
             @keydown.enter="setEmployee"
             @change="setEmployee">
      <template #option="{option}">
        <div class="flex align-items-center m-0 p-0 fadein animation-duration-500">
          <img :src="getEmployeeImage(option)" class="mr-4 mb-2 lg:mb-0 border-circle border-1 img" alt="Employee Image"/>
          <span>{{ option.fullName }}</span>
        </div>
      </template>
    </ListBox>
  </section>
</template>

<script>

import {getEmployeeImage} from "@/helpers/images.helper";

export default {
  name: "employee-listbox",
  emits: ['item-selected'],
  props: {
    scheduleable: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      employees: [],
      selectedEmployee: null,
      filter: '',
      pt:{
        list:{class:'overflow-y-auto max-h-15rem m-0 p-0'},
        item:{class:'m-0 p-2'}
      }
    };
  },
  mounted() {
      this.load();
  },

  methods: {

    ...{getEmployeeImage},

    async load(){
      this.loading = true;
      this.employees = await store.dispatch('Employees/getActiveEmployees');
      this.loading = false;
      this.focusOnFilter();
    },

    setEmployee() {
      if(!this.selectedEmployee) return;
      let payload = {...this.scheduleable, employee_id: this.selectedEmployee.id};
      this.$emit('item-selected', payload);
    },

    focusOnFilter() {
      this.$nextTick(() => {
        const filterInput = this.$refs.listBox.$el.querySelector('input');
        if (filterInput) {
          filterInput.focus();
        }
      });
    },
  }
};
</script>

<style scoped lang="scss">
  .img{
    width:36px;
    height:36px;
  }
</style>

<template>
  <div class="flex justify-content-center w-full">
    <h2 @click.stop="toggleCalendar" class="m-0 p-0">{{ formattedMonthYear }}</h2>
    <Calendar id="navCalendar"
              inline
              v-model="currentDate"
              v-show="showCalendar"

              @date-select="update"
              @month-change="onMonthChange"

              class="absolute w-full z-3 shadow-2"
              dateFormat="yy/mm/dd"
              :show-button-bar="true"
              @click.stop
    />
  </div>
</template>

<script>
import Bus from '@/bus';
import dayjs from "dayjs";

export default {
  name: "calendar-navigation",

  props: {
    'calendar': {
      type: String,
      default: 'Calendar',
      validator(value){
        return ['Calendar', 'DayCalendar'].includes(value);
      },
    }
  },

  mounted(){
    document.addEventListener('click', () => {
      this.showCalendar = false;
    });
  },

  beforeUnmount() {
    document.removeEventListener('click', () => {
      this.showCalendar = false;
    });
  },

  data() {
    return {
      showCalendar: false
    };
  },

  computed: {

    isDayCalendar(){
      return this.calendar === 'DayCalendar';
    },

    currentDate: {
      get() {
        return store.get(`${this.calendar}/currentDate`);
      },
      set(value) {
        store.commit(`${this.calendar}/currentDate`, value);
      }
    },

    formattedMonthYear() {
      return this.isDayCalendar
        ? dayjs(this.currentDate).format('MMMM DD, YYYY')
        :dayjs(this.currentDate).format('MMMM YYYY');
    }
  },

  methods: {
    toggleCalendar() {
      this.showCalendar = !this.showCalendar;
    },
    update() {
      this.showCalendar = false;
      Bus.$emit(`${this.calendar.toLowerCase()}.date`);
    },
    onMonthChange(data){
      this.currentDate = dayjs(this.currentDate).set('month', data.month-1).set('year', data.year).toDate();
      Bus.$emit(`${this.calendar.toLowerCase()}.date`);
    }
  }
}
</script>

<style>
/* Add your styles here */

#navCalendar{
    .p-datepicker-buttonbar > :nth-child(2){
        display: none;
    }
}
</style>

<template>
  <section v-if="loading" class="w-full h-full absolute flex justify-content-center" style="background-color: rgba(255, 255, 255, 0.7)">
    <progress-spinner/>
  </section>
  <section>

    <employee-quick-view-edit-general v-if="isAdd"></employee-quick-view-edit-general>

    <ScrollPanel v-else style="width: 100%; height: 75vh">
      <accordion id="Edit" :multiple="true" v-model:activeIndex="index" class="h-full overflow-auto">
        <accordion-tab header="General">
          <employee-quick-view-edit-general></employee-quick-view-edit-general>

        </accordion-tab>
        <accordion-tab header="Billing Details">
          <div class="formgrid grid">
            <div class="col-12 field">
              <label for="Search" class="text-600 text-sm font-bold">Search
                {{ business ? 'Business' : 'Residential' }}</label>
              <InputGroup>
                <Button
                  icon="pi pi-building"
                  :class="{'p-button-primary': business, 'p-button-outlined p-button-secondary': !business}"
                  @click="business=true"
                />
                <Button
                  icon="pi pi-home"
                  :class="{'p-button-primary': !business, 'p-button-outlined p-button-secondary': business}"
                  @click="business=false"
                />

                <GMapAutocomplete
                  v-if="business"
                  :options="{types: ['establishment']}"
                  placeholder="Business Search"
                  @place_changed="setPlace"
                  id="search"
                  class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round border-noround-left appearance-none outline-none focus:border-primary w-full"
                >
                </GMapAutocomplete>
                <GMapAutocomplete
                  v-else
                  placeholder="Residential Search"
                  @place_changed="setPlace"
                  id="search"
                  class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round border-noround-left appearance-none outline-none focus:border-primary w-full"
                >
                </GMapAutocomplete>
              </InputGroup>
            </div>

            <div class="field col-12">
              <label class="text-600 text-sm font-bold" for="email">Name On Check</label>
              <InputGroup>
                <InputText
                  class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full border-noround-right"
                  id="checkName" v-model.trim="check_name" placeholder="Name On Check"/>
                <Button label="generate" @click="generateCheckName" class="py-0 pr-5"/>
              </InputGroup>
            </div>

            <div class="field col-12">
              <label class="text-600 text-sm font-bold" for="address">Address</label>
              <InputText
                class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                id="address" v-model="address" placeholder=""/>
            </div>

            <div class="field col-12 md:col-6">
              <label class="text-600 text-sm font-bold" for="city">City</label>
              <InputText
                class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                id="city" v-model="city" placeholder=""/>
            </div>

            <div class="field col-12 md:col-3">
              <label class="text-600 text-sm font-bold" for="city">State</label>
              <state-selector id="state" v-model="state" class="w-full p-0"/>
            </div>

            <div class="field col-12 md:col-3">
              <label class="text-600 text-sm font-bold" for="zip">ZIP Code</label>
              <InputText
                class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                id="zip" v-model="zip" placeholder=""/>
            </div>
          </div>
        </accordion-tab>
        <accordion-tab header="User Config">
          <div class="formgrid grid">

            <div class="field col-12">
              <label class="text-600 text-sm font-bold" for="calendar_id">Calendar ID</label>
              <div class="flex align-items-center">
                <InputText id="calendar_id" v-model="calendar_id" class="mr-2" readonly/>
                <Button label="Randomize" @click="changeCalendarId"/>
              </div>
            </div>

            <div class="field col-12 md:col-6">
              <label class="text-600 text-sm font-bold" for="img">Image</label>
              <FileUpload mode="basic" name="employee[]" url="/api/employee/image" accept="image/*" :maxFileSize="1000000"
                          @upload="onUpload"/>
              <!--        <InputText class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" id="img" v-model="img" placeholder="Enter image URL"/>-->
            </div>
          </div>
        </accordion-tab>
        <accordion-tab header="Quickbooks Config">
          <div class="formgrid grid">

            <div class="field col-12 md:col-6">
              <label class="text-600 text-sm font-bold" for="email">Quickbooks Display Name</label>
              <InputGroup>
                <InputText
                  class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full border-noround-right"
                  id="displayName" v-model.trim="quickbooks_display_name" placeholder="Enter Display Name"/>
                <Button label="generate" @click="generateDisplayName" class="py-0 pr-5"/>
              </InputGroup>
            </div>
          </div>
        </accordion-tab>
      </accordion>
    </ScrollPanel>
  </section>
</template>

<script>
import {sync} from 'vuex-pathify';
import StateSelector from "@/components/common/state-selector.vue";
import CustomerEditGeneral from "@/components/customers/customer-edit-general.vue";
import EmployeeQuickViewEditGeneral from "@/components/employees/quick-view/employee-quick-view-edit-general.vue";

export default {
  name: "employee-quick-view-edit",
  components: {EmployeeQuickViewEditGeneral, CustomerEditGeneral, StateSelector},
  data() {
    return {
      search: null,
      business: false,
      index: [0]
    };
  },

  computed: {

    loading() {
      return store.get('Employees/Employee/loading');
    },

    isAdd() {
      return store.get('Employees/add');
    },

    ...sync('Employees/Employee/*', null, false),

  },
  methods: {

    reset() {
      this.$store.dispatch('Employees/Employee/reset');
    },

    onUpload(event) {
      console.log('Uploaded Files:', event.files);
    },

    changeCalendarId() {
      this.$confirm.require({
        header: 'Employee Calendar Subscription Change',
        message: `Changing the calendar ID will disrupt ${this.first}'s existing calendar subscriptions. They will need to resubscribe to a new calendar.
        Do this only if their calander isn't working currently. Do you want to proceed?`,
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.calendar_id = generateRandomId();
        }
      });
    },

    generateName() {
      if (this.company && this.company.trim() !== '') {
        return this.company.trim();
      } else {
        return `${this.first} ${this.last}`.trim();
      }
    },

    generateDisplayName() {
      this.quickbooks_display_name = this.generateName();
    },

    generateCheckName() {
      this.check_name = this.generateName();
    },

    setPlace(place) {

      if (place.address_components) {
        let formattedFullAddress = [];

        for (let i = 0; i < place.address_components.length; i++) {
          let component = place.address_components[i];

          if (component.types.includes('street_number')) {
            formattedFullAddress[0] = component.long_name;
          } else if (component.types.includes('route')) {
            formattedFullAddress[1] = " " + component.long_name;
          } else if (component.types.includes('subpremise')) {
            formattedFullAddress[3] = ", " + component.long_name;
          }
        }

        this.address = formattedFullAddress.join('');
      }

      let cityComponent = place.address_components.find(c => c.types.includes('locality'));
      let subCityComponent = place.address_components.find(c => c.types.includes('sublocality') || c.types.includes('neighborhood'));

      // Use sublocality or neighborhood if available, otherwise use locality
      this.city = subCityComponent ? subCityComponent.long_name : cityComponent ? cityComponent.long_name : undefined;

      let postalComponent = place.address_components.find(c => c.types.includes('postal_code'));
      if (postalComponent) {
        this.zip = postalComponent.long_name;
      }

      let stateComponent = place.address_components.find(c => c.types.includes('administrative_area_level_1'));
      if (stateComponent) {
        this.state = stateComponent.short_name; // or use long_name if you prefer the full state name
      }
    }
  }
};

function generateRandomId() {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}
</script>

<style>
</style>

<template>
  <div v-if="characters.length">
    <div class="flex">
      <h2 class="mr-2 text-600">Characters</h2>
      <AvatarGroup v-if="!hideImages" class="avatar-hover-effect">
        <Avatar v-for="item in characters" :key="item.id" :image="getCharacterImage(item)" size="large" shape="circle" class="border-200 border-3 bg-white"/>
      </AvatarGroup>
    </div>
    <DataTable :value="characters" showGridlines stripedRows size="small">
      <Column field="character.name" header="Character"></Column>
      <Column field="employee.fullName" header="Staff" class="w-3" v-if="!hideStaff">
        <template #body="{data}">
          <div v-if="data.employee" class="flex justify-content-between cursor-pointer" @click="openEmployeeQuickView(data.employee)" @contextmenu="showMenu($event,data)">
            <span class="hover:underline hover:text-primary">{{ data.employee?.fullName }}</span>
            <notes-bookmark :data="data"></notes-bookmark>
          </div>
          <div v-else class="text-center">-</div>
        </template>
      </Column>
      <Column field="start_time" header="Start" class="w-1">
        <template #body="{data}">
          {{ formatTime(data.start_time) }}
        </template>
      </Column>
      <Column field="end_time" header="End" class="w-1">
        <template #body="{data}">
          {{ formatTime(data.end_time) }}
        </template>
      </Column>
      <Column field="price" header="Price" v-if="!hideMoney" class="w-1">
        <template #body="{data}">
          {{ formatMoney(data.price) }}
        </template>
      </Column>
      <Column field="cost" header="Cost" v-if="!hideMoney && !hideCost" class="w-1">
        <template #body="{data}">
          {{ formatMoney(data.cost) }}
        </template>
      </Column>

      <ColumnGroup type="footer" v-if="!hideMoney">
        <Row>
          <Column footer="Subtotal:" :colspan="columns" footerStyle="text-align:right"/>
          <Column :footer="formatMoney(subtotalPrice)"/>
          <Column v-if="!hideCost" :footer="formatMoney(subtotalCost)"/>
        </Row>
      </ColumnGroup>
    </DataTable>
  </div>
</template>
<script>
import {filter, sumBy} from "lodash";
import Config from '@/config';
import {formatTime} from "@/helpers/time.helper";
import {formatMoney} from 'accounting-js';
import Bus from "@/bus";
import NotesBookmark from "@/components/events/quick-view/tables/notes-bookmark.vue";

export default {
  name: "character-table",
  components: {NotesBookmark},
  props: {
    hideMoney: {
      type: Boolean,
      default: false
    },
    hideImages: {
      type: Boolean,
      default: false
    },
    hideStaff: {
      type: Boolean,
      default: false
    },
    hideCost: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    party() {
      return store.get('Parties/Party/current')
    },
    characters() {
      return filter(this.party.scheduleables, (item) => {
        return item.character;
      })
    },
    subtotalPrice() {
      return sumBy(this.characters, (item) => parseFloat(item.price) || 0);
    },
    subtotalCost() {
      return sumBy(this.characters, (item) => parseFloat(item.cost) || 0);
    },
    columns(){
      let columns = 2;
      if(!this.hideStaff) columns++;
      if(!this.hideMoney) columns++;
      return columns;
    }
  },
  methods: {
    ...{formatTime, formatMoney},

    getCharacterImage(item) {
      return Config.character.full + item.character.images[0]?.src;
    },
    showMenu(event, data) {
      Bus.$emit('event.notes.contextmenu', {event, data});
    },
    openEmployeeQuickView(employee) {
      store.commit('Employees/current', employee);
      store.commit('Employees/view', true);
    }
  },
}
</script>

<style scoped lang="scss">
.avatar-hover-effect {
  .p-avatar {
    transition: transform 0.3s ease;
  }

  .p-avatar:hover {
    transform: scale(calc(150 / 64)); // Scale based on original 'large' size (64px to 150px)
  }
}
</style>

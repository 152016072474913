<script>
import {formatTime} from "@/helpers/time.helper";

export default {
  name: "print-dispatch-line-item",
  methods: {formatTime},
  props:['item', 'name']
}
</script>

<template>
  <div class="border-bottom-1 border-200 flex justify-content-between p-2">
    <div class="w-6"><strong>{{ name }}</strong> | <span v-if="item.employee">{{item.employee.first}} ({{item.employee.phone}})</span></div>
    <div class="w-3 text-right">{{ formatTime(item.start_time) }} - {{ formatTime(item.end_time) }}</div>
  </div>
</template>

<style scoped lang="scss">

</style>
<template>
  <DataTable
    :value="parties"
    v-model:selection="selectedParties"

    dataKey="id"

    :sort-field="sortField"
    :sort-order="sortOrder"

    selectionMode="multiple"

    showGridlines
    stripedRows
    size="small"

    :loading="loading"

    @row-dblclick="onRowClick"
    @sort="onSort"
  >
    <template #header>
      <div class="flex justify-content-end">

        <InputGroup>
          <InputGroupAddon>
            <i class="pi pi-search"/>
          </InputGroupAddon>
          <InputText v-model.trim="filter"
                     @keydown.enter="onFilter"
                     placeholder="Search"
                     class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border appearance-none outline-none focus:border-primary w-full"/>

          <Button icon="pi pi-search" label="filter" @click="onFilter"/>
          <Button icon="pi pi-times" @click="clearFilter"/>
        </InputGroup>

        <InputGroup class="ml-3 w-6">
          <InputGroupAddon>
            <i class="pi pi-calendar"/>
          </InputGroupAddon>
          <Calendar v-model="dateRange" selectionMode="range" :manualInput="false" placeholder="Select Party Dates"
                    input-class="cursor-pointer" @hide="onDateRangeChange"/>
          <InputGroupAddon class="hover:bg-primary cursor-pointer" @click="clearDateRange">
            <i class="pi pi-times"/>
          </InputGroupAddon>
        </InputGroup>

        <Button :disabled="!selectedParties.length"
                @click="archiveSelected"
                icon="pi pi-times"
                class="ml-3 w-16rem"
                size="small"
                label="Archive"
                type="button"
                severity="primary"
                :badge="selectedParties.length ? ''+selectedParties.length : ''"/>

        <Button :disabled="!selectedParties.length"
                @click="onDelete"
                icon="pi pi-times"
                class="ml-3 w-16rem"
                size="small"
                :label="showDeleted ? 'Undelete': 'Delete'"
                type="button"
                severity="secondary"
                :badge="selectedParties.length ? ''+selectedParties.length : ''"/>

        <Dropdown
          :disabled="!selectedParties.length"
          v-model="selectedStatus"
          :options="statusOptions"
          @change="changeStatus"
          optionLabel="name"
          placeholder="Change Status"
          input-class="text-base text-color surface-overlay surface-border appearance-none outline-none focus:border-primary w-full"
          class="ml-3"
        />

      </div>
    </template>

    <template #empty>
      No events found.
    </template>

    <template #loading>
      <ProgressSpinner/>
    </template>

    <Column selectionMode="multiple" class="w-2rem"></Column>
    <Column field="date" header="Party Date" :sortable="true">
      <template #body="{data}">
        {{ partyDate(data.date) }}
      </template>
    </Column>
    <Column field="customer" header="Customer" :sortable="true">
      <template #body="{data}">
        <span v-if="data.customer && data.customer.company">{{ data.customer.company }}: </span>{{ data.customer?.first }}
        {{ data.customer?.last }}
      </template>
    </Column>
    <Column field="city" header="City" :sortable="true"></Column>
    <Column field="status" header="Status" :sortable="true"></Column>
    <Column field="archived" header="Archived" :sortable="true">
      <template #body="{data}">
        <div class="text-center">
          <i class="pi pi-check" v-if="data.archived"/>
          <span v-else>-</span>
        </div>
      </template>
    </Column>
    <Column field="created_at" header="Created" :sortable="true">
      <template #body="{data}">
        {{ formatDate(data.created_at) }}
      </template>
    </Column>
    <Column field="deleted_at" header="Deleted" :sortable="true" v-if="showDeleted">
      <template #body="{data}">
        {{ formatDate(data.deleted_at) }}
      </template>
    </Column>
  </DataTable>

  <Paginator
    :totalRecords="totalRows"
    :rows="rowsPerPage"
    :pageLinkSize="3"
    :alwaysShow="true"
    template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} "
    :rowsPerPageOptions="[25, 50, 100]"
    @page="onPageChange"
  />

  <ConfirmDialog group="headless">
    <template #container="{ message, acceptCallback, rejectCallback }">
      <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
        <div class="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
          <i class="pi pi-question text-5xl"></i>
        </div>
        <span class="font-bold text-2xl block mb-2 mt-4">{{ message.header }}</span>
        <p class="mb-0">{{ message.message }}</p>
        <div class="flex align-items-center gap-2 mt-4">
          <Button label="Save" @click="acceptCallback" class="w-8rem"></Button>
          <Button label="Cancel" outlined @click="rejectCallback" class="w-8rem"></Button>
        </div>
      </div>
    </template>
  </ConfirmDialog>
</template>

<script>
import {calculateAgeing, ageingClass, daysUntilEvent} from '@/helpers/party.helper'
import {partyDate, formatDate} from '@/helpers/date.helper'
import dayjs from "dayjs";
import {isEqual} from "lodash";

export default {
  name: "PartiesTableServer",
  emits: ['update-status'],
  mounted() {
    this.load();
  },
  data() {
    return {
      dateRange: null,

      parties: [],
      loading: false,

      selectedParties: [],
      selectedStatus: null,

      paginatorTemplate: "FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown",

      filter: '',

      totalRows: 25,     // Total number of records for pagination
      rowsPerPage: 25,  // Number of rows per page, should match the server setting
      currentPage: 1,
      sortField: 'created_at',
      sortOrder: -1,

      lastParams: null
    };
  },
  computed: {
    statusOptions() {
      return store.get('Parties/statusOptions');
    },
    showDeleted() {
      return store.get('Parties/showDeleted');
    },
  },
  methods: {

    ...{formatDate, partyDate, calculateAgeing, ageingClass, daysUntilEvent},

    getSortOrder() {
      return this.sortOrder === 1 ? 'asc' : 'desc';
    },

    getParams() {
      let params = {
        perPage: this.rowsPerPage,
        page: this.currentPage,
        filter: this.filter, // Assuming this.globalFilter holds the global filter value
        sortField: this.sortField, // sort field
        sortOrder: this.getSortOrder(), // sort order
      };

      if (this.dateRange && this.dateRange.length > 0) {
        params.startDate = dayjs(this.dateRange[0]).format('YYYY-MM-DD');
        params.endDate = dayjs(this.dateRange[1]).format('YYYY-MM-DD');
      }

      params.includeDeleted = this.showDeleted;

      return params;
    },

    bindResult(result) {
      this.parties = result.data;
      this.totalRows = result.total;   // Total number of records
      this.currentPage = result.current_page; // Current page
      this.lastPage = result.last_page; // Last page number
      this.firstPageUrl = result.first_page_url; // URL for the first page
      this.lastPageUrl = result.last_page_url; // URL for the last page
      this.nextPageUrl = result.next_page_url; // URL for the next page
      this.prevPageUrl = result.prev_page_url; // URL for the previous page
      this.from = result.from; // The number of the first record on the page
      this.to = result.to; // The number of the last record on the page
    },

    async load() {
      this.loading = true;
      let params = this.getParams();

      let result = await api.url(`/events`).query(params).get().json();
      this.bindResult({...result});

      this.lastParams = {...params};
      this.loading = false;
    },

    onPageChange(event) {
      this.currentPage = event.page + 1;
      this.rowsPerPage = event.rows;
      this.load();
    },

    onSort(event) {
      this.sortField = event.sortField;
      this.sortOrder = event.sortOrder;
      this.load();
    },

    async onDateRangeChange() {
      if (this.isDirty())
        await this.load();
    },

    clearDateRange() {
      this.dateRange = null;
      this.onDateRangeChange();
    },

    clearFilter() {
      this.filter = '';
      this.load();
    },

    onFilter() {
      this.load();
    },

    async changeStatus() {
      let ids = this.selectedParties.map((item) => item.id);

      await store.dispatch("Parties/updateStatus", {ids: ids, status: this.selectedStatus, noReload: true});
      await this.load();
    },

    edit({data, originalEvent}) {
      originalEvent.stopPropagation();

      let routePath = `/event/${data.token}/edit`;
      this.$router.push(routePath);
    },

    isDirty() {
      return !isEqual(this.lastParams, this.getParams());
    },

    onRowClick({data, originalEvent}) {
      let routePath = `/event/${data.token}`;

      if (originalEvent.ctrlKey) {
        routePath += '/edit/invoice';
      }

      this.$router.push(routePath);
    },

    archiveSelected() {
      this.loading = true;
      this.$confirm.require({
        header: 'Archive or Unarchive Events',
        message: `Are you sure you want to toggle the archive state for ${this.selectedParties.length} events? Events that are archived will become unarchived. Unarchived will become archived.`,

        accept: async () => {
          let ids = this.selectedParties.map((item) => item.id);
          await store.dispatch('Parties/archive', ids);
          this.selectedParties = [];
          await this.load();

          this.$toast.add({
            severity: 'success',
            summary: 'Archive Successful',
            detail: 'The archived state has been toggled for the selected events.',
            life: 5000
          });
        },
        reject: () => {
          this.loading = false;
        },
        onHide: () => {
          this.loading = false;
        }
      })
    },

    onDelete(){
      if(this.showDeleted)
        this.unDelteteSelected();
      else
        this.deleteSelected();
    },

    unDelteteSelected() {
      this.loading = true;
      this.$confirm.require({
        header: 'Undelete Events',
        message: `Are you sure you want to undelete ${this.selectedParties.length} events?`,

        accept: async () => {
          let ids = this.selectedParties.map((item) => item.id);
          await store.dispatch('Parties/undelete', ids);
          this.selectedParties = [];
          await this.load();
        },
        reject: () => {
          this.loading = false;
        },
        onHide: () => {
          this.loading = false;
        }
      })
    },

    deleteSelected() {
      this.loading = true;
      this.$confirm.require({
        header: 'Delete Events',
        message: `Are you sure you want to delete ${this.selectedParties.length} events?`,

        accept: async () => {
          let ids = this.selectedParties.map((item) => item.id);
          await store.dispatch('Parties/remove', ids);
          await this.load();
          this.selectedParties = [];
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style scoped>
</style>

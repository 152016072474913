<template>
  <ContextMenu ref="cm" :model="items"/>
</template>

<script>
import Bus from '@/bus';
import Config from "@/config";
import dayjs from "dayjs";

export default {
  name: "services-context-menus",

  data() {
    return {
      items: null,
      selectedRow: null,
      standard: [
        {label: 'Split', icon: 'pi pi-fw pi-eye-slash', command: this.split},
        {label: 'Add Delivery', icon: 'pi pi-fw pi-truck', command: this.addDelivery},
        {label: 'Add Delivery Return', icon: 'pi pi-fw pi-truck', command: this.addReturnToDutyArea},
        {separator: true},
        {label: 'Clone', icon: 'pi pi-fw pi-copy', command: this.clone},
        {label: 'Delete', icon: 'pi pi-fw pi-times', command: this.delete}
      ]
    }
  },

  computed: {
    startTime() {
      return store.get('Parties/Party/startDateTime');
    },
    endTime() {
      return store.get('Parties/Party/endTime');
    },
    duration(){
      return dayjs(this.endTime).diff(this.startTime, 'hours');
    },
    party() {
      return store.get('Parties/Party/current');
    },
    address() {
      return `${this.party.address}, ${this.party.city}, Ca ${this.party.zip}`;
    }
  },

  mounted() {
    Bus.$on('event.invoice.services.contextmenu', this.showMenu);
  },

  unmounted() {
    Bus.$off('event.invoice.services.contextmenu', this.showMenu);
  },

  methods: {

    showMenu({event, data, field}) {
      this.$nextTick(() => {
        this.selectedRow = data;
        this.items = this.getItems(field);
        this.$refs.cm.show(event);
      });
    },

    getItems(field) {
      switch (field) {
        case 'price':
          return this.getPriceMenu();
        case 'cost':
          return this.getCostMenu();
        case 'employee':
          return this.getEmployeeMenu();
        case 'start_time':
          return this.getTimeMenu();
        case 'end_time':
          return this.getTimeMenu();
        default:
          return this.standard;
      }
    },

    getPriceMenu() {
      return [
        {label: 'Calculate Price', icon: 'pi pi-fw pi-calculator', command: this.calculatePricing},
        {separator: true},
        ...this.standard
      ];
    },

    getCostMenu() {
      return [
        {label: 'Calculate Costs', icon: 'pi pi-fw pi-calculator', command: this.calculateCost},
        {separator: true},
        ...this.standard
      ];
    },

    getEmployeeMenu() {

      let employee = (this.selectedRow?.employee_id !== null)
        ? [
          {label: 'Remove Staff', icon: 'pi pi-fw pi-times', command: this.removeStaff},
          {label: 'Add Tip', icon: 'pi pi-fw pi-dollar', command: this.addTip},
          {label: 'Add Travel', icon: 'pi pi-fw pi-truck', command: this.addEmployeeTravel},
          {separator: true},
        ]
        : [];

      return [
        ...employee,
        ...this.standard
      ];
    },

    getTimeMenu() {
      return [
        {label: 'Reset Time', icon: 'pi pi-fw pi-refresh', command: this.resetTime},
        {separator: true},
        ...this.standard
      ];
    },

    removeStaff() {
      this.selectedRow.employee_id = null;
      store.dispatch('Scheduleables/editScheduleable', this.selectedRow);
    },

    resetTime() {
      this.selectedRow.start_time = this.startTime;
      this.selectedRow.end_time = this.endTime;
      store.dispatch('Scheduleables/editScheduleable', this.selectedRow);
    },

    addTip() {
      store.dispatch('Scheduleables/addScheduleable', {
        misc: `Tip For Staff: ${this.selectedRow.addon.title}`,
        'employee_id': this.selectedRow.employee_id
      });
    },

    async addWearAndTear(distance) {
      return store.dispatch('Scheduleables/addScheduleable', {
        misc: `Delivery: Vehicle Wear and Tear :  ${distance * 2}mi`,
        cost: Math.round(distance * 2 * 0.63 * 100) / 100,
        visible: 0
      });
    },

    async addGasFees(distance) {
      return store.dispatch('Scheduleables/addScheduleable', {
        misc: `Delivery: Gas @ 10mi per $${Config.gasPerGallon} gallon :  ${distance * 2}mi`,
        cost: Math.round(distance * 2 * (Config.gasPerGallon / 10) * 100) / 100,
        visible: 0
      });
    },

    async addDeliveryFees(durationText, hours, price) {
      return store.dispatch('Scheduleables/addScheduleable', {
        misc: `Delivery: ${this.selectedRow.addon.title} : ${durationText}`,
        employee_id: this.selectedRow.employee_id,
        price: price,
        cost: Math.round(hours * 2 * 20 * 100) / 100
      });
    },

    async addDelivery() {
      store.commit('Scheduleables/miscLoading', true);
      try {
        let {
          rideTime,
          hours,
          fee,
          durationText,
          distance
        } = await api.url(`/google/directions/arrival/${this.party.id}/${this.selectedRow.addon.id}`).get().json();

        if (rideTime <= Config.dutyArea) {

          this.$toast.add({
            severity: 'info',
            summary: 'No Delivery Fee',
            detail: `No additional fee for delivery in duty area. Duration: ${durationText}`,
            life: 5000
          });

          await store.dispatch('Scheduleables/addScheduleable', {
            misc: `Delivery: ${this.selectedRow.addon.title} : ${durationText}`,
            employee_id: this.selectedRow.employee_id,
            price: 0,
            cost: Math.round(hours * 2 * 20 * 100) / 100
          });

        } else {

          await store.dispatch('Scheduleables/addScheduleable', {
            misc: `Delivery: ${this.selectedRow.addon.title} : ${durationText}`,
            employee_id: this.selectedRow.employee_id,
            price: fee,
            cost: Math.round(hours * 2 * 20 * 100) / 100
          });
        }

        await this.addWearAndTear(distance);
        await this.addGasFees(distance);

        await store.dispatch('Interactions/create', `Delivery Quote $${fee} | duration: ${durationText} (${rideTime}) | distance: ${distance} | ${this.address}`)

      } catch (e) {

        this.$toast.add({severity: 'error', summary: 'Delivery Query Error', detail: e.message, life: 5000});
      }
    },

    async addEmployeeTravel() {
      store.commit('Scheduleables/miscLoading', true);

      try {
        let {
          rideTime,
          fee,
          durationText,
          distance
        } = await api.url(`/google/directions/arrival/${this.party.id}/employee/${this.selectedRow.employee_id}`).get().json();

        await store.dispatch('Scheduleables/addScheduleable', {
          misc: `Travel: Staff from ${this.selectedRow.employee.city || 'NoHo'} : ${durationText}`,
          employee_id: this.selectedRow.employee_id,
          price: fee,
          cost: fee
        });

        await store.dispatch('Interactions/create', `Employee Travel Quote $${fee} | duration: ${durationText} (${rideTime}) | distance: ${distance} | ${this.address}`)

      } catch (e) {
        this.$toast.add({severity: 'error', summary: 'Travel Query Error', detail: e.message, life: 5000});
      }
    },

    async addReturnToDutyArea() {
      store.commit('Scheduleables/miscLoading', true);

      try {
        let {
          rideTime,
          fee,
          durationText,
          distance
        } = await api.url(`/google/directions/departure/${this.party.id}/${this.selectedRow.addon.id}`).get().json();

        if (rideTime <= Config.dutyArea) {

          this.$toast.add({
            severity: 'info',
            summary: 'No Delivery Fee',
            detail: `Return fee needed in duty area. Duration: ${durationText}`
          });

          store.commit('Scheduleables/miscLoading', false);
        } else {

          store.dispatch('Scheduleables/addScheduleable', {
            misc: `Delivery: Return to Duty Area: ${durationText}`,
            employee_id: this.selectedRow.employee_id,
            price: fee
          });

          await store.dispatch('Interactions/create', `Return to Duty Area Quote $${fee} | duration: ${durationText} (${rideTime}) | distance: ${distance} | ${this.address}`)
        }
      } catch (e) {
        this.$toast.add({severity: 'error', summary: 'Delivery Query Error', detail: e.message, life: 5000});
      }
    },

    clone() {
      store.commit('Scheduleables/servicesLoading', true);
      store.dispatch('Scheduleables/cloneScheduleable', this.selectedRow);
    },

    delete() {
      store.commit('Scheduleables/servicesLoading', true);
      store.dispatch('Scheduleables/removeScheduleable', this.selectedRow);
    },

    split() {
      store.commit('Scheduleables/servicesLoading', true);
      store.dispatch('Scheduleables/splitScheduleable', this.selectedRow);
    },

    calculatePricing() {
      let pricing = this.selectedRow.addon.pricing;

      if (!pricing) {
        this.selectedRow.price = this.selectedRow.addon.price;
        store.dispatch('Scheduleables/editScheduleable', this.selectedRow);

      } else {

        let durationMinutes = this.getDurationInMins();
        let closestKey = null;

        for (const key in pricing) {
          if (durationMinutes <= key) {
            closestKey = key;
            break;
          }
        }

        // If no exact match is found, use the highest available key
        if (closestKey === null) {
          closestKey = Math.max(...Object.keys(pricing).map(Number));
        }

        this.selectedRow.price = pricing[closestKey];
        store.dispatch('Scheduleables/editScheduleable', this.selectedRow);
      }
    },

    calculateCost(){
      let rate = 30;
      this.selectedRow.cost = this.duration * rate;
      store.dispatch('Scheduleables/editScheduleable', this.selectedRow);
    },

    getDurationInMins() {
      const start = dayjs(this.selectedRow.start_time);
      const end = dayjs(this.selectedRow.end_time);
      return end.diff(start, 'minute');
    },
  },
}
</script>

<style scoped lang="scss">

</style>

<template>
  <div class="h-10 px-4 py-8 md:px-6 lg:px-8 flex align-items-center justify-content-center" style="background:linear-gradient(-225deg,#ff00d5,#b04aff 48%,#ff0098)">
    <div class="p-6 shadow-2 text-center lg:w-30rem" style="border-radius: 12px;background-color: rgba(255,255,255,.1);color: rgba(255,255,255,.8)">
      <logo-company logo="logo.png" :height="90" class="mb-3"/>
      <InputText
              id="email"
              type="text"
              class="appearance-none border-none p-3 w-full outline-none text-xl block mb-4 bg-white-alpha-10 text-white-alpha-60"
              v-model.trim="email"
              placeholder="Email"
              style="border-radius: 30px"
      />
      <InputText
              id="password"
              type="password"
              class="appearance-none border-none p-3 w-full outline-none text-xl mb-4 bg-white-alpha-10 text-white-alpha-60"
              placeholder="Password"
              v-model.trip="password"
              style="border-radius: 30px"
      />
      <Button
              @click="login"
              type="button"
              class="appearance-none border-none p-3 w-full outline-none text-xl mb-4 font-medium w-12rem bg-white-alpha-30 hover:bg-white-alpha-40 active:bg-white-alpha-20 text-white-alpha-80 cursor-pointer transition-colors transition-duration-150"
              style="border-radius: 30px" label="Sign In"
              :loading="loading"
      />

      <!--      <a class="cursor-pointer font-medium block text-center">Forgot Password?</a>-->
    </div>
    <toasts/>
  </div>
</template>

<script>
import LogoCompany from "@/components/common/logo-company.vue";
import Toasts from "@/components/common/toasts.vue";
import {setLStorage} from "@/helpers/storage.helper";
import {toastErrors} from "@/helpers/errors.helper";
export default {
  name: "login",
  components: {Toasts, LogoCompany},

  data() {
    return {
      email: '',
      password: '',
      loading: false,
    }
  },

  methods: {
    async login() {
      this.loading = true;
      try {
        let response = await api.url('/login').post({email: this.email, password: this.password}).json();

        await this.setToken(response);

        location.href = router.currentRoute.value?.meta?.redirect || 'events';

      } catch (errors) {
        toastErrors(this.$toast, errors);
      }
      this.loading = false;
    },

    async setToken(response) {
      let token = response.token.split('|')[1];
      await setLStorage('token', token, 24);
    }
  }
}
</script>

<style scoped lang="scss">
#email::placeholder, #password::placeholder {
  color: rgba(255, 255, 255, .6);
}
</style>
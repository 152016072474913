<template>
    <section>
      <div class="flex justify-content-between">
        <div>
          <h1 class="text-600 text-4xl pb-0 mb-0">{{ showDeleted ? 'Deleted Parties' : 'All Parties' }}</h1>
          <p class="p-0 m-1 mb-3">Find all Parties that are not permanently deleted. Select show deleted parties that can be undeleted.</p>
        </div>
        <div class="w-2 text-right">
          <InputSwitch v-model="showDeleted" class="inline-block mt-3 mr-0" title="Toggle show deleted" @change="reloadParties"/>
          <label v-if="showDeleted" class="text-600 text-xs text-primary block">Show Deleted</label>
          <label v-else class="text-600 text-xs block">Show Deleted</label>
        </div>
      </div>

        <PartiesTableServer :key="reloadKey"/>
    </section>
</template>

<script>
import PartiesTableServer from "@/components/events/tables/parties-table-server.vue";
import {sync} from "vuex-pathify";

export default {
  name: "TabAll",
  components: {
    PartiesTableServer,
  },
  data() {
    return {
      reloadKey:0
    };
  },
  computed: {
    showDeleted: sync('Parties/showDeleted', null, false),
  },
  methods: {
    updateStatus(payload) {
      store.dispatch("Parties/updateStatus", payload)
    },
    reloadParties() {
      this.reloadKey++;
    },
  },
};
</script>

<style scoped>
</style>

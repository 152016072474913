import {make} from 'vuex-pathify';
import {each, forOwn, has, isEqual, includes, pick} from 'lodash';

import dayjs from "dayjs";
import {replaceTimeOnDate} from "@/helpers/time.helper";

const saveFields = ["id", "token", "for_whom", "age", "guests", "occasion", "phone2", "referral", "date", "duration", "venue", "address", "city", "zip", "venue_instructions", "party_notes", "startTime"];

const state = {

  id: "",
  token: "",
  for_whom: "",
  age: "",
  guests: "",
  occasion: "",
  phone2: "",
  referral: "",
  date: "",
  duration: "",
  venue: "",
  address: "",
  city: "",
  zip: "",
  venue_instructions: "",
  party_notes: "",
  status: "",
  startTime: "",

  loading: false,
  reloading: false,
  reloadKey: 0,
  current: null,

  showInterstitial: false,
  initialState: null,

  view: false,
  edit: false,
};

const getters = {

  ...make.getters(state),

  changedFields(state) {
    return compareObjects(state.initialState, state);
  },

  isDirty(state, getters) {
    return getters.changedFields.length > 0;
  },

  paymentsTotal(state) {
    if (!state.current || !state.current.transactions) return 0;
   return state.current.transactions.reduce((total, transaction) => {
      let amount = parseFloat(transaction.amount) || 0;
      return transaction.transactionStatus !== 'declined' ? total +  amount : total;
    }, 0);
  },

  balance(state, getters) {
    return getters.total - getters.paymentsTotal;
  },

  total() {
    return store.get('Scheduleables/total');
  },

  costs() {
    return store.get('Scheduleables/costs');
  },

  profit(state, getters) {
    return getters.total - getters.costs;
  },

  margin(state, getters) {
    let total = getters.total;
    let profit = getters.profit;
    let margin = total > 0 ? (profit / total) * 100 : 0;
    return Math.ceil(margin * 100 / 100);
  },

  startDateTime(state){
    return dayjs(state.date).format('YYYY-MM-DD HH:mm:ss');
  },

  endTime(state) {
    const startTime = dayjs(state.date);
    return startTime.add(state.duration, 'hour').format('YYYY-MM-DD HH:mm:ss');
  },
};

const mutations = {
  ...make.mutations(state),

  current(state, current) {
    state.current = current;

    if (!current) return;
    store.dispatch('Scheduleables/setScheduleables', {scheduleables: current.scheduleables, reloading: false});
    store.dispatch('Parties/Party/setParty', current);
    store.dispatch('Interactions/set', current.interactions);
  },

  resetParty(state) {
    let initialState = saveFields.reduce((obj, key) => {
      obj[key] = '';
      return obj;
    }, {});

    Object.assign(state, initialState);
  }
}

const actions = {

  ...make.actions(state),

  async create({commit}, customer) {

    commit('loading', true);
    let party = await api.url('/events').post(customer).json();
    commit('resetParty');
    commit('current', party);

    store.dispatch('Parties/reload');
    commit('loading', false);
    return party;
  },

  async getPartyById({commit}, id) {
    commit('loading', true);
    let party = await api.url(`/event/${id}`).get().json();
    commit('current', party);
    commit('loading', false);
  },

  async load({commit}, token) {
    commit('loading', true);
    let party = await api.url(`/event/${token}`).get().json();
    commit('current', party);
    commit('loading', false);
    return party;
  },

  async reload({state, commit}, onlyScheduleables = false) {
    if (!state.current) return;

    commit('reloading', true);
    let party = await api.url(`/event/${state.current.token}`).get().json();

    if (onlyScheduleables) {
      let scheduleables = party.scheduleables;
      store.dispatch('Scheduleables/setScheduleables', {scheduleables, reloading: true});
    } else {
      commit('current', party);
    }

    commit('reloadKey', ++state.reloadKey);
    commit('reloading', false);
  },

  refresh({dispatch, state}) {
    dispatch('setParty', state.current);
    store.commit('Customers/current', state.current.customer);
    store.dispatch('Scheduleables/setScheduleables', state.current.scheduleables);
    store.dispatch('Interactions/set', state.current.interactions);
  },

  setParty({commit, state, dispatch}, party) {
    each(party, (value, key) => {
      if (has(state, key)) {
        if (key === 'date') {
          commit('date', new Date(value));
          commit('startTime', dayjs(value).format('h:mma'));
        } else {
          commit(key, value);
        }
      }
    });

    dispatch('setInitialState');
  },

  setInitialState({commit, state}) {
    commit('initialState', pick(state, saveFields));
  },

  async save({dispatch, state}) {
    let payload = pick(state, saveFields);

    payload.date = replaceTimeOnDate(state.date, state.startTime);

    let saveResponse = await api.url(`/events/${state.id}`).put(payload).json();

    if (canSaveQuickbooks(state))
      dispatch('saveQuickbooksInvoice');

    dispatch('reload');
    store.dispatch('Parties/reload');

    api.url(`/google/calendar/update/${state.id}`).put();

    return saveResponse;
  },

  async clone(obj, party) {
    let clone = await api.url(`/events/clone`).post(party).json();
    store.dispatch('Parties/reload')
    return clone;
  },

  async deleteParty({commit, state}) {
    await api.url(`/events/${state.id}`).delete().json();
    if (state.id === state.current.id) {
      await commit('quickview', false);
      commit('current', null);
    }
    store.dispatch('Parties/reload');
  },

  async saveQuickbooksInvoice({dispatch, state}) {
    await api.url(`/quickbooks/invoice/${state.id}`).put().res();
    dispatch('load', state.token);
  },

  deleteQuickbooksInvoice({state}) {
    return api.url(`/quickbooks/invoice/${state.id}`).delete().res();
  }
};

function compareObjects(initial, state) {
  const changes = [];

  forOwn(state, (value, key) => {
    if (includes(saveFields, key)) {

      if (key === 'date' && value instanceof Date) {
        // Format the date part only
        let formattedDate = dayjs(value).format('YYYY-MM-DD');
        let initialFormattedDate = initial[key] ? dayjs(initial[key]).format('YYYY-MM-DD') : null;

        if (formattedDate !== initialFormattedDate) {
          changes.push({field: key, value: formattedDate, old: initialFormattedDate});
        }
      } else if (!isEqual(value, initial[key])) {
        changes.push({field: key, value: value, old: initial[key]});
      }
    }
  });

  return changes;
}

function canSaveQuickbooks(state) {
  return state.current.quickbooks_id && state.current.customer.quickbooks_id;
}


export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

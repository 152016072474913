<template>
    <Dialog v-model:visible="visible" modal :dismissableMask="true" header="Select one of our people" :style="{width: '950px'}">
        <template #container="">
            <employee-picker-table/>
        </template>
    </Dialog>
</template>

<script>
import EmployeePickerTable from "@/components/employees/employee-picker-table.vue";

export default {
  name: "employee-picker",
  components: {EmployeePickerTable},
  data() {
    return {
      visible: false
    }
  },
  // computed: {
  //   visible:{
  //     get(){return stores.get("Employees@pickerVisible")},
  //     set(val){ stores.commit("Employees/pickerVisible", val)}
  //   }
  // },
}
</script>


<style scoped lang="scss">

</style>

<template>
  <progress-spinner v-if="isLoading" style="width: 12px; height: 12px; padding:0; margin:0;"/>

  <InputText
          v-else-if="isEditing"
          ref="num"
          v-model="value"
          @input="formatInput"
          @keydown="handleBackspaceAndEnter"
          @focus="formatOnFocus"
          @blur="update"
          class="shadow-none border-none outline-none w-full p-0 m-0"/>

  <div v-else class="cursor-pointer" @click="edit">{{ formatTime(data[field]) }}</div>
</template>

<script>
import {formatTime, isSameTime, setDBTime} from "@/helpers/time.helper";
import dayjs from "dayjs";

export default {
  name: "invoice-time-field",
  props: {
    data: Object,
    field: String,
  },
  data() {
    return {
      editing: false,
      loading: false,
      value: this.data[this.field],
    }
  },
  computed: {
    isEditing() {
      return this.editing && !this.loading;
    },
    isLoading() {
      return this.loading;
    },
    party() {
      return store.get('Parties/Party/current');
    },
    dbTime(){
      return setDBTime(this.party.date, this.value);
    },
    isValid(){
      return !!this.dbTime;
    },
    hasChanged() {
      return !isSameTime(this.data[this.field], this.dbTime);
    },
  },
  methods: {
    ...{formatTime},

    edit() {
      this.editing = true;
      this.$nextTick(() => {
        this.$refs.num?.$el.focus()
      });
    },

    async update() {
      this.editing = false;

      if (this.hasChanged && this.isValid) {
        this.loading = true;
        await store.dispatch('Scheduleables/editScheduleable', {...this.data, [this.field]: this.dbTime});
        this.loading = false;
      }
    },


    handleBackspaceAndEnter(event) {
      if (event.key === 'Backspace') {
        event.preventDefault();
        this.value = '';
      }
      if(event.key === 'Enter'){
        this.update();
      }
    },

    selectText(event) {
      this.$nextTick(() => {
        event.target.select();
      });
    },

    formatInput(event) {
      this.value = this.autoFormatTime(event);
    },

    formatOnFocus(event) {
      const mysqlDateFormat = 'YYYY-MM-DD HH:mm:ss';

      if (this.value && dayjs(this.value, mysqlDateFormat).isValid()) {
        this.value = dayjs(this.value, mysqlDateFormat).format('h:mma');
      }

      this.selectText(event);
    },

    autoFormatTime(event) {
      let value = event.target.value.toUpperCase().replace(/[^0-9AP]/g, '');
      if (value.endsWith('A') || value.endsWith('P')) {
        const ampm = value.slice(-1);
        let time = value.slice(0, -1);
        time = this.formatDigits(time);
        const meridiem = ampm === 'A' ? 'AM' : 'PM';
        return this.formatCompleteTime(time, meridiem);
      }
      return value;
    },

    formatDigits(time) {
      switch (time.length) {
        case 1:
          return `0${time}:00`;
        case 2:
          return `${time}:00`;
        case 3:
          return `0${time.slice(0, 1)}:${time.slice(1)}`;
        case 4:
          return `${time.slice(0, 2)}:${time.slice(2)}`;
        default:
          return time;
      }
    },

    formatCompleteTime(time, meridiem) {
      const formattedTime = dayjs(`${time} ${meridiem}`, 'hh:mm A').format('h:mma');
      return formattedTime !== 'Invalid Date' ? formattedTime : '';
    }
  }
}
</script>


<style scoped lang="scss">

</style>

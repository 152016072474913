<template>

  <BlockUI :blocked="balance<=0">
    <section v-if="loading" class="flex justify-content-center align-content-center h-30rem">
      <progress-spinner class="h-full"/>
    </section>
    <form v-else class="flex flex-column mt-3 form-grid fadein animation-duration-500"
          @submit.prevent="confirmPayment">

      <div class="field">
        <label for="amount" class="text-600 font-bold">Amount</label>
        <div class="flex align-items-center p-inputgroup">
          <InputGroup class="h-3rem">

            <InputNumber v-model="amount"
                         inputId="currency-us"
                         mode="currency"
                         currency="USD"
                         locale="en-US"
                         class="w-full border-right-none border-primary"
                         id="amount"
                         input-class="border-primary"/>


            <Button v-if="balance === total" type="button" label="25% Deposit" @click="amount=twentyFive"
                    class="w-4 hover:bg-primary" outlined></Button>
            <Button v-if="balance === total" type="button" label="50% Deposit" @click="amount=fifty"
                    class="w-4 hover:bg-primary" outlined></Button>
            <Button type="button" :label="`Balance (${formatMoney(balance)})`" @click="amount=balance"
                    class="w-6 hover:bg-primary" outlined></Button>
          </InputGroup>
        </div>
      </div>

      <Accordion :multiple="true" v-model:activeIndex="index" class="h-full overflow-auto">
        <AccordionTab v-if="hasCardOnFile" header="Card On File">
          <section>
            <credit-card-on-file v-for="card in cards" :key="card.id" :card="card" @click="toggleCardOnFile(card)"
                                 :selected="cardOnFile===card"/>
          </section>
        </AccordionTab>
        <AccordionTab header="New Credit Card">
          <section>

            <div class="field">
              <label for="cardholder" class="text-600 font-bold">Cardholder Name</label>
              <div class="p-input-icon-left w-full">
                <InputGroup>
                  <InputGroupAddon>
                    <i class="pi pi-user"></i>
                  </InputGroupAddon>

                  <InputText v-model="fullName" name="cardholder" type="text" class="w-full" id="cardholder"/>
                </InputGroup>
              </div>
            </div>
            <div class="flex gap-2 justify-content-between">
              <div class="w-3 field">
                <label class="text-600 font-bold">Card Type</label>
                <div class="flex gap-2">
                  <Button
                    :label="!isAmex ? 'Use Visa/Mst/Dis' : 'Use Amex'"
                    @click="toggleAmex"
                    class="p-button-outlined p-button-secondary"
                  />
                </div>
              </div>

              <div class="w-10 field">
                <label for="credit-card" class="text-600 font-bold">Credit Card Number</label>
                <div class="p-input-icon-left w-full">
                  <InputGroup>
                    <InputGroupAddon>
                      <i class="pi pi-credit-card"></i>
                    </InputGroupAddon>
                    <InputMask
                      v-model="cc"
                      name="cc"
                      type="text"
                      class="w-full"
                      id="credit-card"
                      :placeholder="ccPlaceholder"
                      :mask="ccMask"
                      @blur="unformatCC"
                    />
                  </InputGroup>
                </div>
              </div>
            </div>

            <div class="flex gap-2">
              <div class="field">
                <label for="expiration" class="text-600 font-bold">Expiration</label>
                <div class="p-input-icon-left w-full">
                  <InputGroup>
                    <InputGroupAddon>
                      <i class="pi pi-clock"></i>
                    </InputGroupAddon>
                    <InputMask v-model="expiry" name="exp" type="text" class="w-full" id="expiration"
                               placeholder="12/29" mask="99/99"/>
                  </InputGroup>
                </div>
              </div>
              <div class="field">
                <label for="cvc" class="text-600 font-bold">{{ isAmex ? 'CID' : 'CVV' }}</label>
                <div class="p-input-icon-left w-full">
                  <InputGroup>
                    <InputGroupAddon>
                      <i class="pi pi-lock"></i>
                    </InputGroupAddon>
                    <InputMask
                      v-model="cardCode"
                      name="cvc"
                      type="text"
                      class="w-full"
                      id="cvc"
                      :placeholder="cvvPlaceholder"
                      :mask="cvvMask"
                    />
                  </InputGroup>
                </div>
              </div>
            </div>
          </section>
        </AccordionTab>
        <AccordionTab header="Advanced">
          <div class="field">
            <label for="cardholder" class="text-600 font-bold">Billing Address</label>
            <div class="p-input-icon-left w-full">
              <InputGroup>
                <InputGroupAddon>
                  <i class="pi pi-home"></i>
                </InputGroupAddon>
                <InputText v-model="address" name="cardholder" type="text" class="w-full" id="address"/>
              </InputGroup>
            </div>
          </div>

          <div class="flex">
            <div class="flex gap-3 justify-content-between w-full">

              <div class="field">
                <label for="city" class="text-600 font-bold">City</label>
                <InputText v-model="city" name="city" type="text" class="w-full" id="city"/>
              </div>

              <div class="field">
                <label for="state" class="text-600 font-bold">State</label>
                <InputText v-model="state" name="state" type="text" class="w-full" id="state"/>
              </div>

              <div class="field">
                <label for="zip" class="text-600 font-bold">Zip</label>
                <InputText v-model="zip" name="zip" type="text" class="w-full" id="zip"/>
              </div>
            </div>
          </div>
        </AccordionTab>
      </Accordion>


      <div class="flex gap-3 justify-content-end pt-5">
        <Button label="Cancel" @click="close" text></Button>
        <Button type="button" v-if="cardReady" @click="saveCard" label="Save Card" rounded outlined/>
        <Button type="submit" :label="balance<=0? 'Requires Balance':'Pay Now'" rounded
                :outlined="!payReady"
                :disabled="!payReady"></Button>
      </div>

    </form>
  </BlockUI>

  <Toast position="bottom-center" group="authorize">
    <template #message="{message}">
      <div>
        <a :href="`https://developer.authorize.net/api/reference/responseCodes.html#search=${message.detail.code}`"
           target="_blank">{{
            message.detail.code
          }}</a>:
        {{ message.detail.text }}
      </div>
    </template>
  </Toast>

</template>
<script>
import {capitalize} from "lodash";
import {formatEndTime, formatTime} from '@/helpers/time.helper'
import {partyDateShort} from '@/helpers/date.helper'
import {formatMoney} from 'accounting-js';
import {sync} from "vuex-pathify";
import Bus from '@/bus'
import {toastErrors} from "@/helpers/errors.helper";
import CreditCardOnFile from "@/components/events/quick-view/modals/cc/credit-card-on-file.vue";

export default {
  name: 'credit-card-form',
  components: {CreditCardOnFile},
  emits: ['close', 'success'],

  created() {
    Bus.$on('authorize.payment-success', this.onPaymentSuccess);
    Bus.$on('authorize.payment-declined', this.onPaymentDeclined);
    Bus.$on('authorize.payment-error', this.onPaymentFailure);
    Bus.$on('creditcard.update', this.getCustomerCards);
  },

  mounted() {
    this.cc = this.cardNumber;
    this.fullName = this.customer.full_name;

    this.getCustomerCards();

  },

  beforeUnmount() {
    Bus.$off('authorize.payment-success', this.onPaymentSuccess);
    Bus.$off('authorize.payment-declined', this.onPaymentDeclined);
    Bus.$off('authorize.payment-error', this.onPaymentFailure);
    Bus.$off('creditcard.update', this.getCustomerCards);
  },

  data() {
    return {
      cards: [],
      cardOnFile: null,
      cc: '',
      cardType: '',
      isAmex: false,
      index: [0],
    }
  },

  computed: {

    ...sync([
      'Authorize/fullName',
      'Authorize/cardNumber',
      'Authorize/month',
      'Authorize/year',
      'Authorize/cardCode',

      'Authorize/address',
      'Authorize/city',
      'Authorize/state',
      'Authorize/zip',
      'Authorize/amount',

      'Authorize/advanced',
      'Authorize/loading'

    ], null, false),

    expiry: {
      get() {
        return this.month + '/' + this.year
      },
      set(val) {
        let [month, year] = val.split('/')
        this.month = month;
        this.year = year;
      }
    },
    party() {
      return store.get('Parties/Party/current');
    },
    balance() {
      return store.get('Parties/Party/balance');
    },
    total() {
      return store.get('Parties/Party/total');
    },
    customer() {
      return this.party.customer;
    },
    hasCardOnFile() {
      return this.cards.length > 0;
    },
    date() {
      return partyDateShort(this.party.date);
    },
    startTime() {
      return formatTime(this.party.date);
    },
    endTime() {
      return formatEndTime(this.party.date, this.party.duration);
    },
    twentyFive() {
      const twentyFivePercent = 0.25 * this.total;
      return Math.ceil(twentyFivePercent / 25) * 25;
    },
    fifty() {
      const twentyFivePercent = 0.5 * this.total;
      return Math.ceil(twentyFivePercent / 25) * 25;
    },

    cardReady() {
      return Boolean(this.fullName && this.cardNumber && this.month && this.year && this.cardCode);
    },

    payReady() {
      return (this.cardReady || !!this.cardOnFile) && (this.amount > 0 && this.amount <= this.balance);
    },

    ccMask() {
      return this.isAmex ? '9999 999999 99999' : '9999 9999 9999 9999'
    },
    ccPlaceholder() {
      return this.isAmex ? '3782 822463 10005' : '4111 1111 1111 1111'
    },
    cvvPlaceholder() {
      return this.isAmex ? '1234' : '123'
    },
    cvvMask() {
      return this.isAmex ? '9999' : '999'
    }
  },
  methods: {
    ...{capitalize, formatMoney},

    async getCustomerCards() {
      this.cardOnFile = null;
      this.cards = await store.dispatch('Authorize/getCustomerCards', this.customer.id);
    },

    toggleCardOnFile(card) {
      this.cardOnFile = this.cardOnFile === card ? null : card;
    },

    close() {
      this.$emit('close');
    },

    confirmPayment() {
      let message = this.cardOnFile ? 'You are about to charge your stored card. Proceed?' : 'You are about to charge a new card. Proceed?';
      this.$confirm.require({
        message: message,
        header: 'Confirm Payment',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.submitPayment();
        }
      });
    },

    async submitPayment() {
      store.commit('Authorize/loading', true);

      if (this.cardOnFile) {
        await store.dispatch('Authorize/chargeStoredProfile', this.cardOnFile);
      } else {
        await store.dispatch('Authorize/submitPayment', this.cardData);
      }
    },

    onPaymentSuccess() {
      this.$toast.add({
        severity: 'success',
        summary: 'Authorize.net Payment Processed',
        detail: 'Your payment has been processed successfully.',
        life: 3000
      });
      store.commit('Authorize/loading', false);
      this.saveCard();

      this.$emit('success');
    },

    onPaymentDeclined() {
      this.$toast.add({
        severity: 'error',
        summary: 'Authorize.net Payment was Declined',
        detail: 'Payment was declined. Please check your card details and try again.',
        life: 5000
      });
      store.commit('Authorize/loading', false);
    },

    onPaymentFailure(errors) {
      toastErrors(this.$toast, errors);
      store.commit('Authorize/loading', false);
    },

    toggleAmex() {
      this.isAmex = !this.isAmex
      this.cc = '' // Clear the current input when switching
    },

    unformatCC() {
      this.cardNumber = this.cc.replace(/[-\s]/g, "");
    },

    findCard() {
      return this.cards.find(card =>
        card.last_four === this.cardNumber.slice(-4) &&
        card.exp_month === this.month &&
        card.exp_year === this.year &&
        card.name === this.fullName
      )
    },

    saveCard() {
      if (this.cardOnFile) {
        return this.cardOnFile = null;
      }
      if (this.findCard()) {
        return this.$toast.add({
          severity: 'warn',
          summary: 'Cannot Save Again',
          detail: 'Card already saved on file',
          life: 3000
        });
      }

      this.$confirm.require({
        message: `Would you like to save this card on file for future payments?`,
        header: 'Save Card on File',
        icon: 'pi pi-credit-card',
        accept: async () => {
          try {
            await store.dispatch('Authorize/createCustomerProfile', {
              email: this.customer.email,
              customer_id: this.customer.id,
              name: this.fullName
            });
            await this.getCustomerCards();
            this.$toast.add({'severity': 'success', 'detail': 'Card saved successfully', 'life': 3000});
          } catch (errors) {debugger;
            toastErrors(this.$toast, errors);
          }
        }
      });
    }
  }
};
</script>

<style lang="scss">
#cc .p-tabview-panels {
  padding: 0;
}

#cc .p-dialog-header {
  padding-bottom: 0;
}

#cc .p-button-label {
  white-space: nowrap;
}
</style>

<script>
import EmailSnipitPartyUrl from "@/components/events/messages/snipits/email-snipit-party-invoice.vue";
import EmailSnipitPartyInvoice from "@/components/events/messages/snipits/email-snipit-party-invoice.vue";
import EmailSnipitDivider from "@/components/events/messages/snipits/email-snipit-divider.vue";
import Config from "@/config";
import EmailSnipitPartyLocation from "@/components/events/messages/snipits/email-snipit-party-location.vue";
import EmailSnipitDate from "@/components/events/messages/snipits/email-snipit-date.vue";

export default {
  name: "message-confirmation",
  components: {
    EmailSnipitDate,
    EmailSnipitPartyLocation,
    EmailSnipitDivider, EmailSnipitPartyInvoice, EmailSnipitPartyUrl},
  data() {
    return {
      companyName: Config.companyName
    }
  },
  computed: {
    customer() {
      return this.party.customer;
    },
    party() {
      return store.get('Parties/Party/current');
    },
  }
}
</script>

<template>
  <div>
    <h3>We will be there!</h3>
    <p>Get Ready!! We are confirming that we will be at:</p>

    <email-snipit-party-location/>
    <email-snipit-date/>

    <email-snipit-divider/>

    <h3>Pictures Pictures Pictures</h3>
    <p>
      Don't forget to take pictures! Do you want to share your timeless memories with everyone?
      Please send your pics and videos along, and we'll highlight your adventure on our website or social media.
      Or, if you love our services, please help us spread the magic: tag us with @yourmagicalparty on Instagram and Facebook. We love to see the smiles.
    </p>

    <h3>Tipping</h3>
    <p>Many customers ask us about tipping customs. Tips are not expected but greatly appreciated by our staff! Customers generally tip around 10-15%.</p>

    <h3>Feedback</h3>
    <p>
      Your family is our family. Help us keep our events spectacular for everyone by letting
      us know how our staff performs! We periodically tip our staff for greatness, so let us know who was great!
    </p>

    <email-snipit-divider/>
    <email-snipit-party-invoice/>
  </div>
</template>

<style scoped lang="scss">

</style>

<template>
  <section v-if="transactions.length" class="p-5">
    <DataTable
            :value="transactions"
            v-model:expandedRows="expandedRows"

            dataKey="transId"

            showGridlines
            stripedRows
            size="small"
            :loading="loading"

            paginator
            :rows="5"
            :rowsPerPageOptions="[10, 25, 50]"
            paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"

    >
        <template #empty>
            No Transactions.
        </template>
        <template #loading>
            <ProgressSpinner/>
        </template>

        <Column expander style="width: 5rem" />
        <Column field="submitTimeLocal" header="Date">
            <template #body="{data}">
                {{ formatDateTime(data.submitTimeLocal) }}
            </template>
        </Column>
        <Column field="transId" header="Transaction Id"></Column>
        <Column field="transactionStatus" header="Status"></Column>
        <Column field="accountNumber" header="Card"></Column>
        <Column field="settleAmount"  header="Amount">
            <template #body="{data}">
                {{ formatMoney(data.settleAmount) }}
            </template>
        </Column>
        <template #expansion="{ data }">
            <div class="p-3">
                <div class="flex justify-content-between">
                    <div>Transaction Id:</div>
                    <div>{{ data.transId }}</div>
                </div>
                <div class="flex justify-content-between">
                    <div>Submit Time (UTC):</div>
                    <div>{{ data.submitTimeUTC }}</div>
                </div>
                <div class="flex justify-content-between">
                    <div>Submit Time (Local):</div>
                    <div>{{ data.submitTimeLocal }}</div>
                </div>
                <div class="flex justify-content-between">
                    <div>Transaction Status:</div>
                    <div>{{ data.transactionStatus }}</div>
                </div>
                <div class="flex justify-content-between">
                    <div>Invoice:</div>
                    <div>{{ data.invoice }}</div>
                </div>
                <div class="flex justify-content-between">
                    <div>Name:</div>
                    <div>{{ data.firstName }} {{ data.lastName }}</div>
                </div>
                <div class="flex justify-content-between">
                    <div>Amount:</div>
                    <div>{{ formatMoney(data.settleAmount) }}</div>
                </div>
                <div class="flex justify-content-between">
                    <div>Account Type:</div>
                    <div>{{ data.accountType }}</div>
                </div>
                <div class="flex justify-content-between">
                    <div>Account Number:</div>
                    <div>{{ data.accountNumber }}</div>
                </div>
            </div>
        </template>

        <ColumnGroup type="footer">
            <Row>
                <Column footer="Balance:" :colspan="5" footerStyle="text-align:right"/>
                <Column :footer="formatMoney(total)"/>
            </Row>
        </ColumnGroup>
    </DataTable>
  </section>
</template>
<script>
import {formatMoney} from 'accounting-js';
import {formatDateTime} from '@/helpers/date.helper'
import {sumBy} from "lodash";

export default {
  name: "unsettled-transaction-table",
  data() {
    return {
      expandedRows:[],
      loading: false,
    }
  },
  mounted(){
    this.load();
  },
  computed: {
    transactions(){
        return store.get('Banking/unsettled');
    },
    total(){
        return sumBy(this.transactions, (item) => parseFloat(item.amount) || 0);
    }
  },
  methods: {
    ...{formatMoney,formatDateTime},

    async load(){
        this.loading = true;
        await store.dispatch('Banking/loadUnsettled');
        this.loading = false;
    }
  },
}
</script>


<style scoped lang="scss">

</style>

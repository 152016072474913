import {make} from 'vuex-pathify';
import Employee from "./employee.store";
import api from "@/config.wretch";


const state = {
  list: [],
  params: {},
  loading: false,

  current: null,

  loadingRequests: false,
  showStaffingModal: false,

  view: false,
  edit: false,
  add: false
};

const getters = {
  ...make.getters(state),
};

const mutations = {
  ...make.mutations(state),

  current(state, val) {
    state.current = val;
    store.dispatch('Employees/Employee/set', state.current);
  }
};

const actions = {

  ...make.actions(state),

  async reload({ dispatch, state }) {
    await dispatch('load', state.params);
  },

  async setEmployeeCurrent({commit, dispatch, state}, id) {
    let employee = getEmployeeById(state.list, id);

    if (employee) {
      commit('current', employee);
    } else {
      await dispatch('loadEmployeeById', id);
    }
  },

  async active(obj, payload) {

    const response =  await api.url(`/employees/active/${payload.id}`).put(payload).res();

    if (response.status !== 200) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to save employee');
    }
  },

  async load({commit}, params) {
    commit('loading', true);
    commit('params', params);
    let result = await api.url(`/employees`).query({...params}).get().json();
    commit('list', result.data);
    commit('loading', false);
    return result;
  },

  async loadEmployeeById({commit}, id) {
    let employee = await api.url(`/employee/${id}`).get().json();
    commit('current', employee);
  },

  async getActiveEmployees() {
    return await api.url('/employees/active').get().json();
  },

  async delete({commit}, employee) {
    commit('loading', true);
    await api.url(`/employees/${employee.id}`).delete();
    commit('loading', false);
  },

  async requestEmployees({commit}, payload) {
    commit('loadingRequests', true);
    await api.url(`/party/request`).post(payload).json();
    commit('loadingRequests', false);
  },

  async removeRequest({commit}, request) {
    commit('loadingRequests', true);
    await api.url(`/party/request/${request.id}`).delete().json();
    commit('loadingRequests', false);
  },

//quickbooks
  getEmployeeQBOProfile({state}) {
    return api.url(`/quickbooks/vendor/${state.current.quickbooks_id}`).get().json()
  },

  async associate({state, commit}, id) {
    commit('loading', true);
    let employee = await api.url('/quickbooks/vendor/associate').post({employee_id: state.current.id, quickbooks_id: id}).json();
    commit('current', employee);
    commit('loading', false);
  },

  async unassociate({state, commit}) {
    commit('loading', true);
    let employee = await api.url(`/quickbooks/vendor/unassociate/${state.current.id}`).put().json();
    commit('current', employee);
    commit('loading', false);
  },

  async createQuickbooksVendor({commit, state}) {
    let employee = await api.url('/quickbooks/vendor/create').post({employee_id: state.current.id}).json();
    commit('current', employee);
  },

  async reinstate({commit}, employee_id){
    let employee = await api.url(`/employee/reinstate/${employee_id}`).put().json();
    commit('current', employee);
  },

  async permanentDelete(obj, employee_id){
    return await api.url(`/employee/permanently-delete/${employee_id}`).delete().res();
  }
};

function getEmployeeById(list, id) {
  return list.find(e => e.id === id);
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules: {
    Employee
  }
};

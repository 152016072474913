<template>
  <h2>{{ current.full_name }} Is Associated With</h2>
  <progress-spinner v-if="loading || !customerProfile"></progress-spinner>
  <div v-else class="fadein animation-duration-500">
    <customer-quickbooks-item :customer="customerProfile"></customer-quickbooks-item>
  </div>
</template>

<script>
import CustomerQuickbooksItem from "./customer-quickbooks-item.vue";

export default {
  name: "customer-quickbooks-profile",
  components: {CustomerQuickbooksItem},
  data() {
    return {
      loading: false,
      customerProfile: null
    }
  },
  mounted() {
    this.load();
  },
  computed: {
    current() {
      return store.get('Customers/current');
    }
  },
  methods: {
    async load() {
      this.loading = true;

      try {
        this.customerProfile = await store.dispatch('Customers/getQuickbooksProfile');
      } catch (e) {
        if (e.message.indexOf('Refresh OAuth 2 Access token with Refresh Token failed') > -1) {
          this.$toast.add({
            severity: 'error',
            summary: 'Quickbooks Token Expired',
            detail: 'Please re-authenticate with Quickbooks',
            life: 3000
          });
        }
      }

      this.loading = false;
    }
  },
}
</script>
<style scoped lang="scss">

</style>

<template>
  <MultiSelect
      v-model="selectedEmployees"
      :options="employees"
      option-label="name"
      option-value="id"
      placeholder="All Staff"
      :resetFilterOnHide="true"
      filter
      @hide="update"
      id="employeeFilter"
      :pt="{wrapper:{style:{maxHeight: '32rem'}}}"
  />
</template>

<script>
import {isEqual} from "lodash";

export default {
  name: "calendar-employee-filter",
  props: {
    calendar: {
      type: String,
      default: 'Calendar',
      validator(value){
        return ['Calendar', 'DayCalendar'].includes(value);
      },
    }
  },

  data() {
    return {
      selectedEmployees: [],
    }
  },
  computed: {
    employees() {
      return [
        {id: null, name: 'Unstaffed'},
        ...store.get(`${this.calendar}/employees`)
      ];
    }
  },
  methods: {
    update() {
      if(isEqual(this.selectedEmployees, store.get(`${this.calendar}/selectedEmployees`))) return;
      store.commit(`${this.calendar}/selectedEmployees`, [...this.selectedEmployees]);
      store.dispatch(`${this.calendar}/load`);
    }
  },
}
</script>

<style lang="scss">

</style>
<template>
  <progress-spinner v-if="loading" style="width: 12px; height: 12px; padding:0; margin:0;"/>
  <div v-else class="cursor-pointer flex justify-content-center">
    <i v-if="value" class="pi pi-eye" aria-hidden="true" @click="update(false)" title="Visible: Click to hide this item from customer invoice"></i>
    <i v-else class="pi pi-eye-slash" aria-hidden="false" @click="update(true)" title="Hidden: Click to show this item in the customer invoice"></i>
  </div>
</template>

<script>

export default {
  name: "invoice-hide-from-customer-view",
  props: {
    data: Object,
    field: String,
  },
  data() {
    return {
      loading: false,
      value: this.data[this.field]
    }
  },
  computed: {
    party() {
      return store.get('Parties/Party/current');
    },
    hasPrice(){
      return this.data.price !== null && parseFloat(this.data.price) > 0;
    }
  },
  methods: {
    async update(val) {
      if (this.hasPrice) {
        this.$toast.add({severity: 'warn', summary: 'No Hiding Prices', detail: 'You cannot hide a line item that has a price. Change Price to zero or create a new line item to do what you intended.', life: 3000});
      } else {
        this.loading = true;
        await store.dispatch('Scheduleables/editScheduleable', {...this.data, [this.field]: val});
        this.value = val;
        this.loading = false;
      }
    }
  }
}
</script>


<style scoped lang="scss">

</style>

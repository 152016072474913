<script>
import EmployeeAdd from "@/components/employees/employee-add.vue";
import EditEventModal from "@/components/events/edit/event-edit-modal.vue";

export default {
  name: "modals",
  components: {EditEventModal, EmployeeAdd}
}
</script>

<template>
  <edit-event-modal/>
</template>

<style scoped lang="scss">

</style>

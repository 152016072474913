<script>
export default {
  name: "toasts"
}
</script>

<template>
  <Toast position="bottom-center" class="w-fit"/>
</template>

<style scoped lang="scss">

</style>
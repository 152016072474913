<script>
import EmailSnipitCharacters from "@/components/events/messages/snipits/email-snipit-characters.vue";
import EmailSnipitServices from "@/components/events/messages/snipits/email-snipit-services.vue";
import EmailSnipitMisc from "@/components/events/messages/snipits/email-snipit-misc.vue";
import EmailSnipitPartyInvoice from "@/components/events/messages/snipits/email-snipit-party-invoice.vue";
import EmailSnipitDivider from "@/components/events/messages/snipits/email-snipit-divider.vue";
import EmailSnipitPartyLocation from "@/components/events/messages/snipits/email-snipit-party-location.vue";
import EmailSnipitDate from "@/components/events/messages/snipits/email-snipit-date.vue";

export default {
  name: "message-proposal",
  components: {
    EmailSnipitDate,
    EmailSnipitPartyLocation,
    EmailSnipitDivider, EmailSnipitPartyInvoice, EmailSnipitMisc, EmailSnipitServices, EmailSnipitCharacters},
  computed: {

    party() {
      return store.get('Parties/Party/current');
    },

    customer() {
      return this.party.customer;
    }
  }
}
</script>

<template>
  <div>
    <h3>Hi, {{ customer.first }}</h3>

    <p>Thank you for giving us the opportunity to share with you what we can do for your event! We are a full service event company.
      We can help you with anything! You just need to ask. We currently have your party date and location as follows:</p>
    <email-snipit-divider/>
    <email-snipit-date/>

    <email-snipit-party-location/>
    <p>
      <email-snipit-divider/>
    </p>

    <p>Here are some items you may be interested in. Don't worry, we know you may want more or less, this is a proposal
      to give you options.</p>

    <email-snipit-characters :show-desc="true"/>

    <email-snipit-services :show-desc="true"/>

    <email-snipit-misc :show-desc="true"/>

    <p>We can also provide certificate of insurance for an added fee. Please reply with your certificate requirements.</p>

    <p>Did we miss something? or got a question about a line item? Please reply with your feedback.</p>

    <email-snipit-divider/>
    <email-snipit-party-invoice/>

  </div>
</template>

<style scoped lang="scss">

</style>

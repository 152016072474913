<template>
  <Sidebar v-model:visible="visible" position="right" class="w-full lg:w-65rem" :modal="false" :dismissable="false" @dblclick.stop>
    <template #container="">
      <div class="h-auto p-4 relative">
        <section v-if="isLoading" class="flex justify-content-center align-content-center h-30rem w-full fadein fadeout animation-duration-500 absolute z-2">
          <progress-spinner class="h-full"/>
        </section>
        <section v-else class="z-1">

          <event-quick-view-header/>

          <last-interaction-message/>

          <scroll-panel style="width: 100%; height: 80vh" class="pr-3 sm:pr-0" v-if="party">
            <div class="grid" :key="reloadKey">

              <event-map class="col-12" :address="party.address" :city="party.city" :zip="party.zip"/>

              <character-table class="col-12"/>

              <services-table class="col-12"/>

              <misc-table class="col-12"/>

              <div class="col-12" v-if="total !== 0">
                <div class="w-full text-right pr-5 border-1 border-200">
                  <h2>Total: {{ formatMoney(total) }}</h2>
                </div>
              </div>

              <div v-if="noItems" class="w-full border-2 border-300 border-dashed flex justify-content-center align-content-center p-5 m-2" @click="edit">
                <h2 class="text-300 text-center">Add Items To Party</h2>
              </div>

              <event-quickview-transaction-table class="col-12"/>

              <div class="col-12" v-if="total !== 0">
                <div class="w-full text-right pr-5 border-1 border-200">
                  <h2>Balance: {{ formatMoney(balance) }}</h2>
                </div>
              </div>

              <quick-view-highlights class="col-12"/>

              <notes-modal/>

              <notes-context-menu/>

            </div>
          </scroll-panel>
        </section>
      </div>
    </template>
  </Sidebar>
</template>


<script>
/* eslint-disable vue/no-mutating-props */

import EventMap from "@/components/events/common/event-map.vue";
import EventQuickViewHeader from "./event-quick-view-header.vue";
import QuickViewHighlights from "./quick-view-highlights.vue";
import CharacterTable from "./tables/character-table.vue";
import ServicesTable from "./tables/services-table.vue";
import MiscTable from "./tables/misc-table.vue";
import EventQuickviewTransactionTable from "@/components/events/quick-view/tables/event-quickview-transaction-table.vue";
import NotesContextMenu from "@/components/events/quick-view/tables/notes-context-menu.vue";
import NotesModal from "@/components/events/common/notes-modal.vue";
import {formatMoney} from "accounting-js";
import {formatDateTime} from "@/helpers/date.helper";
import LastInteractionMessage from "@/components/events/quick-view/last-interaction-message.vue";

export default {
  name: "event-quick-view",
  components: {
    LastInteractionMessage,
    NotesModal,
    NotesContextMenu,
    EventQuickviewTransactionTable,
    MiscTable,
    ServicesTable,
    CharacterTable,
    QuickViewHighlights,
    EventQuickViewHeader,
    EventMap
  },
  computed: {
    visible: {
      get() {
        return store.get('Parties/Party/view');
      },
      set(val) {
        store.commit('Parties/Party/view', val);
      }
    },
    party() {
      return store.get('Parties/Party/current')
    },
    isLoading() {
      return store.get('Parties/Party/loading');
    },
    reloadKey() {
      return store.get('Parties/Party/reloadKey');
    },
    total() {
      return store.get('Parties/Party/total');
    },
    balance() {
      return store.get('Parties/Party/balance');
    },
    noItems() {
      return store.get('Scheduleables/isEmpty');
    }
  },
  methods: {
    formatDateTime,
    formatMoney,
    close() {
      this.visible = false;
    },
    edit() {
      store.commit('Parties/Party/edit', true);
    },
    esc(event) {
      if (event.key === 'Escape') {
        this.close();
      }
    }
  },
}


</script>

<style scoped lang="scss">

</style>

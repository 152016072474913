<template>
  <services-context-menus/>
  <DataTable
          ref="servicesTable"
          :value="services"
          data-key="id"
          showGridlines
          stripedRows
          size="small"
          :loading="loading"
          @rowReorder="reorder"
  >
    <template #empty>
      No Services
    </template>

    <template #loading>
      <progress-spinner class="h-full"/>
    </template>

    <Column rowReorder :reorderableColumn="false" header-style="width:3%;" class="text-center" />

    <Column field="visible" header="" header-style="width:3%;">
      <template #body="{data}">
        <invoice-hide-from-customer-view :data="data" field="visible"/>
      </template>
    </Column>

    <Column field="addon.title" header="Service" class="w-4">
      <template #body="{data,field}">
        <invoice-services-picker :scheduleable="data"  @contextmenu="showMenu($event,data,field)" />
      </template>
    </Column>

    <Column field="employee" header="Staff" class="w-3">
      <template #body="{data,field}">
        <invoice-employee-picker :scheduleable="data"  @contextmenu="showMenu($event,data,field)"/>
      </template>
    </Column>

    <Column field="start_time" header="Start Time" class="w-1">
      <template #body="{data,field}">
        <invoice-time-field :data="data" field="start_time"  @contextmenu="showMenu($event,data,field)"/>
      </template>
    </Column>

    <Column field="end_time" header="End Time" class="w-1">
      <template #body="{data,field}">
        <invoice-time-field :data="data" field="end_time"  @contextmenu="showMenu($event,data,field)"/>
      </template>
    </Column>

    <Column field="price" header="Price" class="w-1">
      <template #body="{data,field}">
        <invoice-money-field :data="data" field="price"  @contextmenu="showMenu($event,data,field)"/>
      </template>
    </Column>

    <Column field="cost" header="Cost" class="w-1">
      <template #body="{data,field}">
        <invoice-money-field :data="data" field="cost"  @contextmenu="showMenu($event,data,field)"/>
      </template>
    </Column>

    <ColumnGroup type="footer">
      <Row>
        <Column footer="Subtotal:" :colspan="6" footerStyle="text-align:right"/>
        <Column :footer="formatMoney(subtotalPrice)"/>
        <Column :footer="formatMoney(subtotalCost)"/>
      </Row>
    </ColumnGroup>
  </DataTable>
</template>
<script>

import {formatMoney} from 'accounting-js';
import {sync} from "vuex-pathify";
import Bus from "@/bus";

import InvoiceEmployeePicker from "@/components/events/edit/invoice/invoice-employee-picker.vue";
import InvoiceTimeField from "@/components/events/edit/invoice/invoice-time-field.vue";
import InvoiceMoneyField from "@/components/events/edit/invoice/invoice-money-field.vue";
import InvoiceServicesPicker from "@/components/events/edit/invoice/services/invoice-services-picker.vue";
import ServicesContextMenus from "@/components/events/edit/invoice/services/services-context-menus.vue";
import InvoiceHideFromCustomerView from "@/components/events/edit/invoice/invoice-hide-from-customer-view.vue";

export default {
  name: "invoice-services-table",
  components: {
    InvoiceHideFromCustomerView,
    ServicesContextMenus, InvoiceServicesPicker, InvoiceMoneyField, InvoiceTimeField, InvoiceEmployeePicker},

  computed: {

    loading() {
      return store.get('Scheduleables/servicesLoading');
    },

    reloading() {
      return store.get('Parties/Party/reloading');
    },

    services: sync('Scheduleables/services', null, false),

    subtotalPrice() {
      return store.get('Scheduleables/servicesSubtotal');
    },

    subtotalCost() {
      return store.get('Scheduleables/servicesCost');
    }
  },
  methods: {
    ...{formatMoney},

    showMenu(event, data ,field) {
      Bus.$emit('event.invoice.services.contextmenu', { event, data ,field });
    },

    reorder(event){
      this.services = event.value;
      store.dispatch('Scheduleables/reorderScheduleables', event.value);
    }
  },
}
</script>


<style scoped lang="scss">

</style>

<template>
  <div class="col-12 md:col-6">
    <h2>Date and Time</h2>
    <div class="formgrid grid">
      <div class="field col-12 md:col-6 pt-1">
        <label for="start_time" class="text-600 text-sm font-bold">Start Time</label>
        <start-time-field></start-time-field>
      </div>

      <div class="field col-12 md:col-6 pt-1">
        <label for="duration" class="text-600 text-sm font-bold">Total Event Duration</label>
        <Dropdown
                v-model="duration"
                :options="durationOptions"
                optionLabel="label"
                option-value="value"
                id="duration"
                input-class="text-base text-color surface-overlay p-2 surface-border border-round appearance-none outline-none focus:border-primary w-full"
                class="w-full"
        />
      </div>
    </div>
    <div class="grid">
      <div class="col-12">
        <Calendar class="w-full" id="date" v-model="date" dateFormat="yy/mm/dd" inline/>
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/config';
import {sync} from 'vuex-pathify';
import StartTimeField from "@/components/events/edit/invoice/start-time-field.vue";

export default {
  name: "event-datetime",
  components: {StartTimeField},
  computed: {
    durationOptions() {
      return config.durationOptions
    },
    ...sync([
      'Parties/Party/current',
      'Parties/Party/date',
      'Parties/Party/duration'
    ], null, false)
  }
}
</script>

<style scoped lang="scss">

</style>

<template>
    <section v-if="images">
      <div class="grid">
<!--        <draggable v-model="images" group="images" class="grid">-->
          <div v-for="image in images" :key="image.id" class="col-4">
            <characters-card-image :image="image"></characters-card-image>
            <character-image-context-menu :image="image"></character-image-context-menu>
          </div>
<!--        </draggable>-->

        <ImageUpload :label="`Select ${character.name} images`" @upload="uploadImages" ref="uploader"></ImageUpload>
      </div>
    </section>
</template>

<script>
import draggable from 'vuedraggable';
import { each, sortBy } from 'lodash';
import CharactersCardImage from "@/components/characters/profile/characters-card-image.vue";
import CharacterImageContextMenu from "@/components/characters/common/character-image-context-menu.vue";
import ImageUpload from "@/components/characters/common/ImageUpload.vue";

export default {
  name: "CharacterProfileImages",
  components: {
    ImageUpload,
    CharacterImageContextMenu,
    CharactersCardImage,
    draggable
  },
  computed: {
    character() {
      return store.get('Characters@current');
    },
    images: {
      get() {
        return sortBy(this.character.images, ['order']);
      },
      set(images) {
        this.character.images = this.assignOrder(images);
      }
    }
  },
  methods: {
    assignOrder(images) {
      each(images, (item, index) => {
        item.order = index;
      });
      return images;
    },
    async uploadImages(files) {
      try {
        await store.dispatch('Characters/Images/upload', { id: this.character.id, images: files });
        this.$emit('filesUploaded');
        this.$refs.uploader.clear();
      } catch (e) {
        this.$emit('uploadError', e);
      }
    }
  }
}
</script>

<style scoped>
.grid {
    display: flex;
    flex-wrap: wrap;
}
.col-4 {
    width: 33.33%;
}
</style>

<template>
  <Card class="text-sm position-relative text-white bg-green-400 ml-3 -mt-1 z-0 border-1 shadow-none border-round-top-none"
        :pt="{ content: { class: 'p-0' }, body: { class: 'p-3' } }">
    <template #content>

      <div v-if="isVoided" class="voided-label">Voided</div>

      <div class="flex justify-content-between">
        <div class="flex align-self-center">

          <icon-quickbooks v-if="transaction.quickbooks_id && isRefund" size="20" class="mr-2 mt-1 cursor-pointer" @click="goToQuickbooksRefund(transaction.quickbooks_id)"></icon-quickbooks>

          <h2 class="my-1 text-lg line-height-1" v-if="isRefund">Refund Transaction</h2>
        </div>
        <div class="flex justify-content-end">
          <div>
            <div>Date: {{ formatDateTime(transaction.submitTimeLocal) }}</div>
            <div v-if="isCreditCard"
                 class="text-green-900 hover:underline cursor-pointer"
                 @click="viewTransaction"
                 title="View Transaction On Authorize.net">ID: {{ transaction.transId }}
            </div>
            <div v-else>ID: {{ transaction.transId }}</div>
          </div>

          <transaction-refund-menu :transaction="transaction"></transaction-refund-menu>

        </div>
      </div>
      <div class="flex justify-content-between">
        <div>
          <h4 class="mb-0">Customer: {{ transaction.firstName }} {{ transaction.lastName }}</h4>
          <div>Transaction Status: {{ transaction.transactionStatus }}</div>
        </div>
        <div>
          <div class="pr-3 text-right">
            <h4 class="pr-5 mb-0">
              Amount: {{ formatMoney(transaction.amount) }}
            </h4>
          </div>
        </div>
      </div>
      <p v-if="transaction.description" class="text-green-600"><i class="pi pi-bookmark-fill pr-1"/>{{transaction.description}}</p>
    </template>
  </Card>
</template>

<script>
import {formatMoney} from 'accounting-js';
import {formatDateTime} from '@/helpers/date.helper';
import {capitalize} from 'lodash';
import IconQuickbooks from "@/components/common/icon-quickbooks.vue";
import {goToAuthorizeTransaction, goToQuickbooksRefund} from "@/helpers/link.helper";
import {isCreditCard, isDeclined, isFraud, isRefund, isVoided} from "@/helpers/transaction.helper";
import TransactionRefundMenu from "@/components/events/edit/invoice/transaction-refund-menu.vue";

export default {
  name: 'transaction-refund-card',
  components: {TransactionRefundMenu, IconQuickbooks},
  props: {
    transaction: {
      type: Object,
      required: true
    }
  },
  computed: {
    party() {
      return store.get('Parties/Party/current')
    },
    customer() {
      return this.party.customer;
    },
    transactions() {
      return this.party.transactions;
    },
    isCreditCard() {
      return isCreditCard(this.transaction);
    },
    isRefund() {
      return isRefund(this.transaction);
    },
    isVoided() {
      return isVoided(this.transaction);
    },
    isDeclined() {
      return isDeclined(this.transaction);
    },
    isFraud() {
      return isFraud(this.transaction);
    },
    hasRefunds() {
      return this.relatedRefunds.length > 0;
    },
    relatedRefunds() {
      if (!this.transaction) return [];

      return this.transactions.reduce((acc, trans) => {
        if (trans.refTransId === this.transaction.transId) {
          acc.push(trans);
        }
        return acc;
      }, []);
    },
    amountRefunded() {
      return this.relatedRefunds.reduce((total, refund) => {
        return total + parseFloat(refund.amount);
      }, 0);
    },
    total() {
      return store.get('Parties/Party/total');
    },
    balance() {
      return store.get('Parties/Party/balance')
    },
    isLoading() {
      return store.get('Banking/loading');
    }
  },
  methods: {
    capitalize,
    formatMoney,
    formatDateTime,
    goToQuickbooksRefund,

    viewTransaction() {
      goToAuthorizeTransaction(this.transaction.transId);
    },
  },
};
</script>

<style scoped>
.border-round-top-none {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.position-relative {
  position: relative;
}

.voided-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-30deg);
  color: #ebebeb;
  padding: 10px 20px;
  font-size: 3rem;
  font-weight: bold;
  border-radius: 5px;
  z-index: 1;
  pointer-events: none;
}
</style>

<template>
    <div class="surface-section flex justify-content-between relative lg:static" style="min-height: 70px">

      <logo-company class="mr-0 lg:mr-6 align-self-center"/>

        <a v-ripple class="cursor-pointer block lg:hidden align-self-center text-700" v-styleclass="{ selector: '@next', enterClass: 'hiddenleaveToClass: hidden', hideOnOutsideClick: true }">
            <i class="pi pi-bars text-4xl"></i>
        </a>

        <div class="flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 top-100 z-1">
            <main-menu-items/>
            <main-menu-user/>
        </div>
    </div>
</template>

<script>

import MainMenuItems from "./main-menu-items.vue";
import MainMenuUser from "./main-menu-user.vue";
import LogoCompany from "@/components/common/logo-company.vue";

export default {
  name: 'main-menu-bar',
  components: {LogoCompany, MainMenuUser, MainMenuItems},

  methods: {
    onUserClick() {
      console.log('User icon clicked!');
      // Add logic to handle user icon click
      // e.g., redirect to user profile, show user menu, etc.
    },
  },
};
</script>

<style scoped lang="scss">
.logo {
  width: 70%;
  height: auto;
}

.pi-user {
  font-size: 1.5em;
  cursor: pointer;
}
</style>

<template>

  <Button
    @click="open"
    icon="pi pi-plus"
    class="ml-3 w-11rem"
    size="small"
    label="New"
    type="button"/>

  <Dialog v-model:visible="visible" :modal="true" :style="{width: '800px'}" :closable="false" position="top" :draggable="false">
    <template #header>
      <div class="flex align-items-center justify-content-between w-full">
        <h2 class="text-600">Add New Employee</h2>
        <div>
          <Button type="button" @click="save" icon="pi pi-save" severity="secondary" text rounded></Button>
          <Button type="button" @click="close" icon="pi pi-times" severity="secondary" text rounded></Button>
        </div>
      </div>
    </template>
    <employee-quick-view-edit-general/>
  </Dialog>
</template>

<script>
import {sync} from 'vuex-pathify';
import {toastErrors} from "@/helpers/errors.helper";
import EmployeeQuickViewEditGeneral from "@/components/employees/quick-view/employee-quick-view-edit-general.vue";

export default {
  name: "employee-add",
  components: {EmployeeQuickViewEditGeneral},

  computed: {
    visible: sync('Employees/add', null, false)
  },

  methods: {

    async open() {
      await store.dispatch("Employees/Employee/reset");
      await store.commit("Employees/view", false);
      this.visible = true;
    },

    close(){
      this.visible = false;
    },

    async save() {

      try {

        await store.dispatch('Employees/Employee/create');
        await store.dispatch('Employees/reload');
        await store.commit('Employees/view', true);
        this.close();

        this.$toast.add({severity: 'success', summary: 'Success', detail: 'Employee Saved', life: 3000});

      } catch (e) {
        if (e.json && e.response.status === 409) {
            this.previousUserFix(e);
        } else {
          toastErrors(this.$toast, e);
        }
      }finally {
        store.commit('Employees/loading', false);
      }
    },

    previousUserFix(e){
      this.$confirm.require({
        message: `An employee with this email already exists and is soft deleted. Delete the previous user and Save new or Reinstate the previous user?`,
        header: 'Cannot Create New Employee',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Reinstate Previous User',
        rejectLabel: 'Delete Previous User and Save New',
        accept: async ()=> {
          try{
            await store.dispatch('Employees/reinstate', e.json.employee_id);
            this.$toast.add({severity:'success', summary: 'Success', detail: 'Employee reinstated', life: 3000});
            this.visible = false;
            await store.dispatch('Employees/reload');
            await store.commit('Employees/view', true);
          }catch(error){
            this.$toast.add({severity: 'error', summary: 'Error', detail: error.message, life: 5000});
          }
        },
        reject: async () => {
          try{
            await store.dispatch('Employees/permanentDelete', e.json.employee_id);
            this.$toast.add({severity:'success', summary: 'Success', detail: 'Employee deleted', life: 3000});
            await this.save();
          }catch(error){
            this.$toast.add({severity: 'error', summary: 'Error', detail: error.message, life: 5000});
          }
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">

</style>

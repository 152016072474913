<template>
  <GigsContextMenu ref="gcm"  class="w-2" :selected-gig="rtClickSelectedRow"/>

  <section v-if="loading" class="flex justify-content-center align-content-center h-30rem">
    <progress-spinner class="h-full"/>
  </section>
  <section v-else class="fadein animation-duration-500">
    <DataTable :value="gigs"
               v-model:selection="selectedGigs"

               dataKey="id"

               :sort-field="sortField"
               :sort-order="sortOrder"

               selectionMode="multiple"

               showGridlines
               stripedRows
               size="small"

               :loading="loading"

               @rowDblclick="onRowDblClick"
               @sort="onSort"

               contextMenu
               v-model:contextMenuSelection="rtClickSelectedRow"
               @contextmenu="showMenu"
    >
      <template #header>
        <div class="flex justify-content-end">

          <InputGroup>
            <InputGroupAddon>
              <i class="pi pi-search"/>
            </InputGroupAddon>
            <InputText v-model="filter"
                       placeholder="Search"
                       @keyup.enter="onFilter"
                       class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border appearance-none outline-none focus:border-primary w-full"/>

            <Button icon="pi pi-search" label="filter" @click="onFilter"/>
            <Button icon="pi pi-times" @click="clearFilter"/>
          </InputGroup>

          <Button
            @click="markAllPaid"
            icon="pi pi-check"
            class="ml-3 w-12rem"
            size="small"
            label="Mark Paid"
            type="button"/>

          <Button
            @click="markAllUnpaid"
            icon="pi pi-times"
            class="ml-3 w-12rem"
            size="small"
            label="Mark Unpaid"
            severity="secondary"
            type="button"/>



        </div>
      </template>

      <template #empty>
        No Gigs found.
      </template>


      <Column selectionMode="multiple" class="w-2rem"></Column>

      <Column field="misc" header="Gig" class="w-4">
        <template #body="{data}">
          <div class="flex justify-content-between">
            <span v-if="data.character">{{ data.character.name }}</span>
            <span v-else-if="data.addon">{{ data.addon.title }}</span>
            <span v-else>{{ data.misc }}</span>
          </div>
        </template>
      </Column>

      <Column field="employee_id" header="Employee" :sortable="true">
        <template #body="{data}">
          <div v-if="data.employee" class="flex justify-content-between cursor-pointer"
               @click.stop="openEmployeeQuickView(data.employee, $event)" @contextmenu="showMenu($event,data)">
            <span class="hover:underline hover:text-primary">{{ data.employee?.fullName }}</span>
          </div>
        </template>
      </Column>

      <Column field="start_time" header="Date" :sortable="true">
        <template #body="{data}">
          {{ partyDateShort(data.start_time) }}
        </template>
      </Column>

      <Column field="start_time" header="Start Time">
        <template #body="{data}">
          {{ formatTime(data.start_time) }}
        </template>
      </Column>

      <Column field="end_time" header="End Time">
        <template #body="{data}">
          {{ formatTime(data.end_time) }}
        </template>
      </Column>

      <Column field="cost" header="Earning">
        <template #body="{data}">
          {{ formatMoney(data.cost) }}
        </template>
      </Column>
      <Column field="bill_id" header="Bill" :sortable="true">
        <template #body="{data}">
          <Button v-if="data.bill_id" :label="data.formatted_id" text @click="showBill(data.bill_id)" class="p-0 m-0"/>
          <span v-else>-</span>
        </template>
      </Column>
      <Column field="paid_on" header="Paid" :sortable="true">
        <template #body="{data}">
          {{ formatDate(data.paid_on) }}
        </template>
      </Column>

    </DataTable>

  </section>
  <Paginator
    :totalRecords="totalRows"
    :rows="rowsPerPage"
    :alwaysShow="true"
    template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} "
    :rowsPerPageOptions="[10, 25, 50, 100, 500]"
    @page="onPageChange"
  />
</template>

<script>
import {formatMoney} from "accounting-js";
import {formatTime} from "@/helpers/time.helper";
import {formatDate, partyDateShort} from "@/helpers/date.helper";
import GigsContextMenu from "@/components/team/gigs/gigs-context-menu.vue";

export default {
  name: "gigs-table-server",
  components: {GigsContextMenu},

  mounted() {
    this.load();
  },

  data() {
    return {
      selectedGigs: [],
      rtClickSelectedRow: null,

      totalRows: 0,
      rowsPerPage: 10,
      currentPage: 1,

      sortField: 'created_at',
      sortOrder: -1,

      filter: '',
    };
  },

  computed: {
    loading() {
      return store.get('Gigs/loading');
    },
    gigs() {
      return store.get('Gigs/list');
    },
  },

  methods: {
    formatDate,
    formatTime,
    formatMoney,
    partyDateShort,

    showMenu(event) {
      this.$refs.gcm.show(event);
    },

    async load() {
      let result = await store.dispatch('Gigs/load', this.getParams());
      this.bindResult({...result});
    },

    getSortOrder() {
      return this.sortOrder === 1 ? 'asc' : 'desc';
    },

    getParams() {
      return {
        perPage: this.rowsPerPage,
        page: this.currentPage,
        filter: this.filter,
        sortField: this.sortField,
        sortOrder: this.getSortOrder(),
      };
    },


    bindResult(result) {
      this.totalRows = result.total;
      this.currentPage = result.current_page;
    },

    onPageChange(event) {
      this.currentPage = event.page + 1; // event.page is zero-indexed
      this.rowsPerPage = event.rows;
      this.load();
    },

    onSort(event) {
      this.sortField = event.sortField;
      this.sortOrder = event.sortOrder;
      this.load();
    },

    clearFilter() {
      this.filter = '';
      this.load();
    },

    onFilter() {
      this.load();
      this.selectedExpenses = [];
    },

    onRowDblClick(event) {
      let party = event.data?.party;
      if (!party) return;

      store.dispatch('Parties/Party/load', party.token);
      store.commit('Parties/Party/view', true);
    },

    openEmployeeQuickView(employee) {
      store.commit('Employees/current', employee);
      store.commit('Employees/view', true);
    },

    showBill(billId) {
      if (billId)
        store.dispatch('Bills/getBillById', billId);
    },

    async markAllPaid() {
      let ids = this.selectedGigs.map(gig => gig.id);
      await store.dispatch('Gigs/bulkMarkPaid', ids);
    },

    async markAllUnpaid() {
      let ids = this.selectedGigs.map(gig => gig.id);
      await store.dispatch('Gigs/bulkMarkUnpaid', ids);
    }
  }
}
</script>

<style scoped lang="scss">
/* Add any additional styling here */
</style>

<template>

    <Button
      @click="visible=true"
      icon="pi pi-users"
      class="ml-3 w-11rem"
      size="small"
      label="Merge"
      :disabled="selectedCustomers.length < 2"
    />

    <Dialog header="Merge Customers" v-model:visible="visible" :style="{ width: '800px' }">
      <PickList
        v-model="customers"

        :showSourceControls="false"
        :showTargetControls="false"

        :sourceStyle="{ height: '300px' }"
        :targetStyle="{ height: '300px' }"
      >
        <template #sourceheader>Merge All Data From</template>
        <template #targetheader>Primary Customer</template>
        <template #item="{item}">
          <div>
            <div>{{ item.full_name }}</div>
            <div class="text-xs">{{ item.email }}</div>
            <div class="text-xs">{{ item.phone }}</div>
          </div>
        </template>
      </PickList>
      <Message v-if="!canMerge" severity="warn">Only one customer can be Primary to merge</Message>
      <Message v-else severity="success">All customers will merge into {{ primary.full_name }}</Message>
      <template #footer>
        <Button label="Cancel" icon="pi pi-times" @click="closeModal" class="p-button-text"/>
        <Button label="Merge" icon="pi pi-check" @click="mergeCustomers" :disabled="!canMerge"/>
      </template>
    </Dialog>

</template>

<script>

export default {
  name: "customer-merge",
  props: {
    selectedCustomers: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      visible: false,
      customers: [[...this.selectedCustomers], []]
    };
  },
  computed: {
    canMerge() {
      return this.customers[1].length === 1 && this.customers[0].length > 0;
    },
    primary() {
      return this.customers[1][0];
    },
    toMerge(){
      return this.customers[0];
    }
  },
  methods: {

    async mergeCustomers() {
      await store.dispatch("Customers/mergeCustomers", {primary: this.primary, merge: this.toMerge});
      await store.dispatch("Customers/reloadList");
      this.closeModal();
    },
    closeModal() {
      this.visible = false;
    }
  }
};
</script>

<style scoped>
/* Add any necessary styles here */
</style>

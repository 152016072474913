<template>
  <Dropdown v-model="partySelection" :options="partyToggle" @change="onPartyChange"
            panel-class="h-15rem"
            :pt="{wrapper:{style:{height: '15rem', maxHeight: '15rem'}}}">
    <template #value="{ value, placeholder }">
      <div v-if="value">
        <span :class="value.icon"></span>
        <span class="ml-2">{{ value.label }}</span>
      </div>
      <div v-else>{{ placeholder }}</div>
    </template>
    <template #option="{ option }">
      <div>
        <span :class="option.icon"></span>
        <span class="ml-2">{{ option.label }}</span>
      </div>
    </template>
  </Dropdown>
</template>

<script>
export default {
  name: "calendar-items-filter",
  props: {
    'calendar': {
      type: String,
      default: 'Calendar',
      validator(value){
        return ['Calendar', 'DayCalendar'].includes(value);
      },
    }
  },

  data() {
    return {
      partySelection: {
        label: 'Party',
        icon: 'pi pi-gift',
        value: 'party'
      },
      partyToggle: [
        {
          label: 'Party',
          icon: 'pi pi-gift',
          value: 'party'
        },
        {
          label: 'Party Items',
          icon: 'pi pi-sitemap',
          value: 'items'
        },
        {
          label: 'Party Characters',
          icon: 'pi pi-sitemap',
          value: 'character'
        },
        {
          label: 'Party Services',
          icon: 'pi pi-sitemap',
          value: 'service'
        },
        {
          label: 'Party Misc',
          icon: 'pi pi-sitemap',
          value: 'misc'
        }
      ],
    }
  },
  methods: {
    onPartyChange({ value }) {
      this.partySelection = value;
      store.commit(`${this.calendar}/itemType`, value.value);
      store.dispatch(`${this.calendar}/load`);
    }
  }
}
</script>

<style scoped>
/* Add styles for your icons and dropdown items here if needed */
</style>

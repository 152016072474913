<template>
  <div class="flex justify-content-between w-full">
    <div class="flex">
      <h2 class="mr-2">Services</h2>
      <AvatarGroup class="avatar-hover-effect">
        <Avatar
                v-for="item in visible.slice(0, 20)"
                :key="item.id"
                :image="getServiceImage(item.addon)"
                size="large"
                shape="circle"
                class="border-200 border-3 bg-white"
                :title="item.addon.title"
        />
        <template v-if="visible.length > 20">
          <Avatar shape="circle" :label="`+${visible.length - 20}`" size="normal"
                  :pt="{
                root: { class: 'hover:bg-primary hover:text-white text-sm text-400'},
                icon: { class: 'text-sm' }
            }"
          />
        </template>
        <Avatar shape="circle" icon="pi pi-plus" size="normal" @click.stop="addService"
                :pt="{
                                        root: { class: 'hover:bg-primary hover:text-white text-sm text-400'},
                                        icon: { class: 'text-sm' }
                                    }"
        />
      </AvatarGroup>
    </div>
    <h2>{{ formatMoney(subtotal) }}</h2>
  </div>
</template>

<script>
import {formatMoney} from "accounting-js";
import {getServiceImage} from "@/helpers/images.helper";
import {filter} from "lodash";
import Config from '@/config';
export default {
  name: "invoice-header-services",

  computed: {
    services() {
      return store.get('Scheduleables/services');
    },
    visible(){
      return filter(this.services,{visible: 1});
    },
    subtotal() {
      return store.get('Scheduleables/servicesSubtotal');
    },
  },
  methods: {

    ...{formatMoney, getServiceImage},

    addService() {
      store.commit('Scheduleables/servicesLoading', true);
      store.dispatch('Scheduleables/addScheduleable', {addon_id:Config.service.defaultItem});
    },
  }
}
</script>


<style scoped lang="scss">
.avatar-hover-effect {
  .p-avatar {
    transition: transform 0.3s ease;
  }

  .p-avatar:hover {
    transform: scale(calc(150 / 64)); // Scale based on original 'large' size (64px to 150px)
  }
}
</style>
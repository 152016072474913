import { createRouter, createWebHistory } from 'vue-router';
import Events from '@/components/events/events.vue';
import Banking from '@/components/banking/banking.vue';
import Customers from "@/components/customers/customers.vue";
import Employees from '@/components/employees/employees.vue';
import Login from '@/components/common/login.vue';
import {getLStorage} from "@/helpers/storage.helper";
import Characters from "@/components/characters/characters.vue";

const routes = [
      {path: '/', redirect: '/events'},
      {
        path: '/login',
        name: 'Login',
        component: Login,
      },
      {
        path: '/events/:tab?',
        name: 'Events',
        component: Events
      },
      {
        path: '/event/:token',
        name: 'EventDetail',
        component: Events
      },
      {
        path: '/event/:token/edit/:editTab?',
        name: 'EventEdit',
        component: Events
      },
      {
        path: '/banking/:tab?',
        name: 'Banking',
        component: Banking,
      },
      {
        path: '/customers/:customerId?',
        name: 'Customers',
        component: Customers,
      },
      {
        path: '/team/:tab?',
        name: 'Team',
        component: Employees,
      },
      {
        path: "/characters",
        component: Characters,
        name: "Characters",
        meta: {
          groupName: "Characters",
        }
      },
      {
        path: '/services/:tab?',
        name: 'Services',
        component: Events,
      },

      {
        path: '/rentals/:tab?',
        name: 'Rentals',
        component: Events,
      }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const isAuthenticated = getLStorage('token');

  if (to.meta.title) {
    document.title = to.meta.title;
  } else {
    // Fallback to a default title if meta title is not provided
    document.title = 'Admin Panel - Your Magical Party Inc.';
  }

  if (!isAuthenticated && to.name !== 'Login') {
    next({ name: 'Login', params: { redirect: to.fullPath } });
  } else {
    await next();
  }
});

export default router;
<template>
  <Dialog v-model:visible="visible" modal class="surface-overlay" position="top"
          :style="{width: '600px'}"
          @dblclick.stop
          @hide="onHide"
  >
    <template #container>
      <section class="relative">
        <section v-if="isLoading" class="flex justify-content-center align-content-center h-30rem">
          <progress-spinner class="h-full"/>
        </section>
        <section v-else class="fadein animation-duration-500">
          <div class="flex justify-content-between align-content-center">
            <h2 class="text-600 ml-5">Expense For: {{ employee.first }} {{ employee.last }}</h2>
            <Button type="button" @click="close" icon="pi pi-times" severity="secondary" text rounded class="m-3"></Button>
          </div>
          <section class="p-5 pt-0">
            <form @submit.prevent="save">
              <div class="field">
                <label for="date">Date of Expense</label>
                <Calendar id="date" v-model="date" class="w-full" dateFormat="mm/dd/yyyy" :showTime="false" required/>
              </div>
              <div class="field">
                <label for="amount">Amount</label>
                <InputText id="amount" v-model="amount" class="w-full" required/>
              </div>
              <div class="field">
                <label for="category">Category</label>
                <Dropdown id="category" v-model="category" :options="categories" option-label="label"  option-value="value"  class="w-full"/>
              </div>
              <div class="field">
                <label for="description">Expense Description</label>
                <Textarea id="description" v-model="description" class="w-full" rows="3"/>
              </div>

              <div class="flex justify-content-end mt-4">
                <Button type="button" label="Cancel" icon="pi pi-times" class="mr-2 p-button-secondary" @click="close"/>
                <Button type="submit" label="Save" icon="pi pi-check" class=""/>
              </div>
            </form>
          </section>
        </section>
      </section>
    </template>
  </Dialog>
</template>

<script>
import {sync} from "vuex-pathify";
import dayjs from "dayjs";
import {concatErrors} from "@/helpers/errors.helper";

export default {
  name: "expense-modal",

  data() {
    return {
      isLoading: false,
      categories: [
        {label: 'General', value: 'General'},
        {label: 'Travel', value: 'Travel'},
        {label: 'Food', value: 'Food'},
        {label: 'Gas', value: 'Gas'},
        {label: 'Uber/Lyft', value: 'Uber/Lyft'},
        {label: 'Supplies', value: 'Supplies'},
        {label: 'Per Diem', value: 'Per Diem'}
      ],
    }
  },
  computed: {
    visible: sync('Expenses/edit', null, false),

    id: sync('Expenses/Expense/id', null, false),

    employee() {
      return store.get('Employees/current');
    },

    date: {
      get() {
        let date = store.get('Expenses/Expense/date');
        return date ? dayjs(date).format('MM/DD/YYYY') : null;
      },
      set(value) {
        store.commit('Expenses/Expense/date', value);
      }
    },

    employee_id: sync('Expenses/Expense/employee_id', null, false),
    amount: sync('Expenses/Expense/amount', null, false),
    category: sync('Expenses/Expense/category', null, false),
    description: sync('Expenses/Expense/description', null, false),
  },
  methods: {
    async save() {
      try {
        this.isLoading = true;
        this.employee_id = this.employee.id;
        await store.dispatch('Expenses/Expense/save');

        this.$toast.add({severity: 'success', summary: 'Success', detail: 'Expense saved successfully', life: 3000});

        this.close();

      } catch (error) {
        this.$toast.add({severity: 'error', summary: 'Error', detail: concatErrors(error) , life: 5000});
      } finally {

        this.isLoading = false;
      }
    },
    close() {
      this.visible = false;
      store.commit('Expenses/current', null);
    },
    onHide() {
      store.commit('Expenses/current', null);
    }
  }
}
</script>

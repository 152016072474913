import {each} from "lodash";

export function concatErrors(error) {
  let errorMessages = [];
  if (error.errors) {
    for (const messages of Object.values(error.errors)) {
      errorMessages.push(...messages);
    }
  } else {
    errorMessages.push(error.message);
  }
  return errorMessages.join('\n');
}
export function toastWarning($toast, detail, summary = 'Success') {
  $toast.add({
    severity: 'warn',
    summary: summary,
    detail: detail,
    life: 3000
  });
}

export function toastSuccess($toast, detail, summary = 'Success') {
  $toast.add({
    severity: 'success',
    summary: summary,
    detail: detail,
    life: 3000
  });
}
export function toastErrors($toast, errors) {
  let err = errors?.errors || errors;

  if (Array.isArray(err)) {
    toastCustomExceptions($toast, err);
  } else if (err?.exception) {
    toastException($toast, err);
  } else {
    toastValidation($toast, err);
  }
}

export function toastCustomExceptions($toast, errors) {
  each(errors, ({ detail, title }) => {
    $toast.add({ severity: 'error', summary: title, detail, life: 5000 });
  });
}

export function toastValidation($toast, errors) {
  each(errors, (msgs, key) => {
    if (!Array.isArray(msgs)) {
      msgs = [msgs];
    }
    each(msgs, msg => {
      $toast.add({ severity: 'error', summary: key, detail: msg, life: 5000 });
    });
  });
}

export function toastException($toast, error) {
  $toast.add({
    severity: 'error',
    summary: `Exception: ${error.exception}`,
    detail: error.message,
    life: 5000
  });
}

export function formatErrors(errors) {
  let formattedErrors = [];
  //[{"errorCode":"11","errorText":"A duplicate transaction has been submitted."}]
  if (Array.isArray(errors) && errors.length > 0 && errors[0].errorCode && errors[0].errorText) {
    formattedErrors = errors.map(err => err.errorText);
  } else if (errors && errors.errors) {
    for (const messages of Object.values(errors.errors)) {
      formattedErrors.push(...messages);
    }
  } else if (typeof errors === 'object' && errors !== null) {
    formattedErrors.push(errors);
  } else {
    formattedErrors = errors || [];
  }
  return formattedErrors;
}


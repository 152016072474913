<template>
  <Menu model="items" popup ref="menu" :appendTo="body">
    <template #activator="{ on, attrs }">
      <Button icon @click="on.click" v-bind="attrs" class="p-button-rounded p-button-text">
        <i class="pi pi-ellipsis-v"></i>
      </Button>
    </template>
  </Menu>
</template>

<script>
export default {
  name: "CharacterImageContextMenu",
  props: ["image"],
  data() {
    return {
      items: [
        {
          label: 'Set As Primary Image',
          icon: 'pi pi-check-circle',
          command: () => {
            this.setPrimary();
          }
        },
        {
          label: 'Delete Image',
          icon: 'pi pi-trash',
          command: () => {
            this.deleteImage();
          }
        }
      ]
    };
  },
  methods: {
    async deleteImage() {
      try {
        await store.dispatch("Characters/Images/remove", this.image);
        this.$toast.add({severity: 'success', summary: 'Success', detail: 'Image Deleted', life: 3000});
      } catch (e) {
        this.$toast.add({severity: 'error', summary: 'Error', detail: e.message, life: 3000});
      }
    },
    async setPrimary() {
      try {
        await store.dispatch("Characters/Images/setPrimary", this.image);
        this.$toast.add({severity: 'success', summary: 'Primary Set', detail: 'Image set as primary', life: 3000});
      } catch (e) {
        this.$toast.add({severity: 'error', summary: 'Error', detail: e.message, life: 3000});
      }
    }
  }
}
</script>

<style scoped lang="scss">
/* Scoped styles */
</style>

<template>
  <SplitButton :label="statusLabel" :model="items" size="small" outlined :pt="{
    button: {class: 'p-0 m-0'},
  }"/>
</template>

<script>
import {sync} from "vuex-pathify";
import {getStatusLabel} from "@/helpers/status.helper";
import {capitalize} from "lodash";
export default {
  name: "status-change-button",
  data() {
    return {
      items: [
        {label: 'New', command: () => this.change('inquiry')},
        {label: 'Booking', command: () => this.change('booking')},
        {label: 'Complete', command: () => this.change('complete')},
        {label: 'Serviced', command: () => this.change('serviced')},
        {label: 'Archived', command: () => this.cannotChange()},
        {label: 'Cancel', command: () => this.change('cancelled')},
        {label: 'Credit', command: () => this.change('credit')},
      ],
    }
  },
  computed: {
    party() {
      return store.get('Parties/Party/current');
    },

    status: sync('Parties/Party/status', null, false),

    statusLabel() {
      return capitalize(getStatusLabel(this.status));
    }
  },
  methods: {
    cannotChange(){
      this.$toast.add({
        severity: 'warn',
        summary: 'Archive is no longer a status',
        detail: 'Archive the party from the party list using the archive button. Archive is no longer a status but a state that remembers the status as well.',
        life: 8000
      });
    },
    async change(value) {

      try{
        await store.dispatch('Parties/updateStatus', {ids: [this.party.id], status: {value:value}});
        this.status = value;
        this.$toast.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Status updated',
          life: 3000
        });
      } catch (e) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: e.message,
          life: 3000
        });
      }
    }
  },
}
</script>

<style scoped lang="scss">

</style>

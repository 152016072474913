<template>
  <div>
    <Button type="button" icon="pi pi-ellipsis-v" @click="toggle" text class="p-1 right text-white"/>
    <Menu ref="menu" id="overlay_menu" :model="menuModel" :popup="true" :pt="{ root: { class: 'custom-menu' } }"/>
  </div>
</template>

<script>
import {formatMoney} from "accounting-js";
import {toastErrors, toastSuccess} from "@/helpers/errors.helper";
import {isCreditCard, isDeclined, isRefund, isSettled, isSuccessful, isVoided} from "@/helpers/transaction.helper";
import {find} from "lodash";

export default {
  name: 'transaction-refund-menu',
  props: {
    transaction: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      refundAmount: 0,
      showSecretOptions: false,
      options: {
        voidTransaction: [{
          label: 'Void Transaction',
          icon: 'pi pi-trash',
          command: () => this.voidTransaction(this.transaction)
        }],
        refundTransaction: [{
          label: 'Refund Transaction',
          icon: 'pi pi-trash',
          command: () => this.showRefundDialog(this.transaction)
        }],
        deleteTransaction: [{
          label: 'Delete Transaction',
          icon: 'pi pi-trash',
          command: () => this.deleteTransaction(this.transaction)
        }],
        getTransactionStatus: [{
          label: 'Check Status',
          icon: 'pi pi-refresh',
          command: () => this.updateTransactionStatus(this.transaction)
        }],
        addQuickbooksPayment: [{
          label: 'Add QuickBooks Payment',
          icon: 'pi pi-plus',
          command: () => this.addQuickbooksPayment(this.transaction)
        }],
        unlinkQuickbooksPayment: [{
          label: 'Unlink Quickbooks Payment',
          icon: 'pi pi-link',
          command: () => this.removeQuickbooksAssociation(this.transaction)
        }],
        deleteQuickbooksPayment: [{
          label: 'Delete Quickbooks Payment',
          icon: 'pi pi-trash',
          command: () => this.deleteQuickbooksPayment(this.transaction)
        }],
        addQuickbooksRefund: [{
          label: 'Add QuickBooks Refund',
          icon: 'pi pi-plus',
          command: () => this.addQuickbooksRefund(this.transaction)
        }],
        unlinkQuickbooksRefund: [{
          label: 'Unlink Quickbooks Refund',
          icon: 'pi pi-link',
          command: () => this.removeQuickbooksAssociation(this.transaction)
        }],
        deleteQuickbooksRefund: [{
          label: 'Delete Quickbooks Refund',
          icon: 'pi pi-trash',
          command: () => this.deleteQuickbooksRefund(this.transaction)
        }]
      }
    }
  },
  computed: {

    party() {
      return store.get('Parties/Party/current')
    },

    customer() {
      return this.party.customer;
    },

    canAddToQuickbooks() {
      return !!this.customer.quickbooks_id && !!this.party.quickbooks_id;
    },

    isSuccessful() {
      return isSuccessful(this.transaction);
    },

    transactions() {
      return this.party.transactions;
    },

    isVoided() {
      return isVoided(this.transaction);
    },

    isRefund() {
      return isRefund(this.transaction);
    },

    isDeclined() {
      return isDeclined(this.transaction);
    },

    isSettled() {
      return isSettled(this.transaction);
    },

    isCreditCard() {
      return isCreditCard(this.transaction);
    },

    inQuickbooks(){
      return !!this.transaction?.quickbooks_id;
    },

    originalTransaction() {
      return find(this.transactions, {transId:this.transaction.refTransId});
    },

    relatedRefunds() {
        return this.transactions.reduce((acc, trans) => {
        if (trans.refTransId === this.originalTransaction.transId) {
          acc.push(trans);
        }
        return acc;
      }, []);
    },

    fullyRefunded() {
      return this.amountRefunded === this.originalTransaction.amount;
    },

    amountRefunded() {
      return this.relatedRefunds.reduce((total, refund) => {
        return total + Math.abs(parseFloat(refund.amount));
      }, 0);
    },

    menuModel() {
      return [
        ...this.getVoidOptions(),
        ...this.getDeleteTransactionOption(),
        ...this.getCreditCardOptions(),
        ...this.getAddQuickBooksRefundOptions(),
        ...this.getQuickBooksRefundOptions()
      ];
    }
  },
  methods: {

    toggle(event) {
      this.showSecretOptions = event.ctrlKey || event.metaKey; // Check if control/cmd key is pressed
      this.$refs.menu.toggle(event);
    },

    getVoidOptions() {
      if (this.isDeclined) return [];
      if (this.isVoided) return [];

      if (!this.isSettled && this.isCreditCard)
        return this.options.voidTransaction;

      return [];
    },

    getRefundOptions() {
      if (this.fullyRefunded) return [];
      if (this.isVoided) return [];

      if (this.isSuccessful)
        return this.options.refundTransaction;

      return [];
    },

    getDeleteTransactionOption() {
      if (!this.showSecretOptions && !this.isVoided) return [];
      return this.options.deleteTransaction;
    },

    getCreditCardOptions() {
      if (!this.isCreditCard) return [];

      return this.options.getTransactionStatus;
    },

    getAddQuickBooksPaymentOption() {
      if (this.inQuickbooks) return [];
      if (this.isVoided) return [];
      if (this.isDeclined) return [];
      if (this.isRefund) return [];

      return [
        {separator: true},
        ...this.options.addQuickbooksPayment
      ];
    },

    getQuickBooksPaymentOptions() {
      if (!this.inQuickbooks) return [];
      if (this.isVoided) return [];
      if (this.isDeclined) return [];
      if (this.isRefund) return [];

      return [
        {separator: true},
        ...this.options.unlinkQuickbooksPayment,
        ...this.options.deleteQuickbooksPayment
      ];
    },

    getAddQuickBooksRefundOptions() {
      if (this.inQuickbooks) return [];
      if (this.isVoided) return [];
      if (this.isDeclined) return [];

      return [
        {separator: true},
        ...this.options.addQuickbooksRefund
      ];
    },

    getQuickBooksRefundOptions() {
      if (!this.inQuickbooks) return [];
      if (this.isVoided) return [];
      if (this.isDeclined) return [];
      if (!this.isRefund) return [];

      return [
        {separator: true},
        ...this.options.unlinkQuickbooksRefund,
        ...this.options.deleteQuickbooksRefund
      ];
    },


    voidTransaction() {
      this.$confirm.require({
        message: `Are you sure you want to void this transaction?`,
        header: 'Refund Transaction',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          try {
            await store.dispatch('Banking/voidTransaction', {id: this.transaction.id, amount: this.transaction.amount});
            toastSuccess(this.$toast, 'Transaction voided successfully.');
          } catch (e) {
            toastErrors(this.$toast, e.errors);
          }
        }
      });
    },

    deleteTransaction() {
      this.$confirm.require({
        message: `Are you sure you want to delete this transaction? This will delete any associated Quickbooks Payment as well.`,
        header: 'Delete Transaction',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          try {
            await store.dispatch('Banking/deleteTransaction', this.transaction);
            toastSuccess(this.$toast, 'Transaction deleted successfully.');
          } catch (e) {
            toastErrors(this.$toast, e.errors);
          }
        }
      });
    },

    showRefundDialog() {
      this.refundAmount = this.transaction.amount - this.amountRefunded;

      if (this.refundAmount <= 0) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Transaction has already been refunded.',
          detail: 'Review transaction history for more details.',
          life: 3000
        });
        return;
      }

      let message = {
        txt: (this.relatedRefunds.length > 0) ? `${this.relatedRefunds.length} other refunds have been processed for a total of ${formatMoney(this.amountRefunded)} refunded.` : '',
        refundAmount: this.refundAmount,
        transactionAmount: this.transaction.amount
      };

      this.$confirm.require({
        group: 'refund',
        header: 'Refund Confirmation',
        icon: 'pi pi-exclamation-circle',
        m: message,
        rejectLabel: 'Cancel',
        acceptLabel: 'Refund',
        accept: async () => {

          let refundAmount = message.refundAmount;

          try {
            await store.dispatch('Banking/refundTransaction', {id: this.transaction.id, amount: refundAmount});
            this.$toast.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Refund processed successfully',
              life: 3000
            });
          } catch (e) {
            toastErrors(this.$toast, e.errors);
          }
        }
      });
    },

    toastCantAddPayment(msg) {
      return this.$toast.add({
        severity: 'warn',
        summary: 'Cannot Add Quickbooks Payment Yet',
        detail: msg,
        life: 3000
      });
    },

    async addQuickbooksPayment() {

      if (!this.canAddToQuickbooks)
        return this.toastCantAddPayment('You must have a Quickbooks Invoice and Customer to add a Quickbooks Payment');
      if (!this.isSuccessful)
        return this.toastCantAddPayment('You must have a Successful Transaction to Add a Quickbooks Payment');

      try {
        await store.dispatch('Banking/addQuickbooksPayment', this.transaction)
        toastSuccess(this.$toast, 'Quickbooks payment added successfully.');
      } catch (e) {
        toastErrors(this.$toast, e.errors);
      } finally {
        store.commit('Banking/loading', false);
      }
    },

    async addQuickbooksRefund() {
      try {
        await store.dispatch('Banking/addQuickbooksRefund', this.transaction)
        toastSuccess(this.$toast, 'Quickbooks refund was successful.');
      } catch (e) {
        toastErrors(this.$toast, e.errors);
      } finally {
        store.commit('Banking/loading', false);
      }
    },

    async updateTransactionStatus() {
      try {
        await store.dispatch('Banking/updateTransactionStatus', this.transaction)
        toastSuccess(this.$toast, 'Transaction status updated successfully.');
      } catch (e) {
        toastErrors(this.$toast, e);
      }finally {
        store.commit('Banking/loading', false);
      }
    },

    async removeQuickbooksAssociation() {
      try {
        await store.dispatch('Banking/removeQuickbooksAssociation', this.transaction);
        toastSuccess(this.$toast, 'Quickbooks association removed successfully.');
      } catch (e) {
        toastErrors(this.$toast, e);
      }finally {
        store.commit('Banking/loading', false);
      }
    },

    async deleteQuickbooksRefund() {
      this.$confirm.require({
        group: 'quickbooks',
        message: `Are you sure you want to delete this Quickbooks refund?`,
        header: 'Delete Quickbooks Refund',
        icon: 'pi pi-exclamation-triangle',
        confirmLabel: 'Delete',
        accept: async () => {
          try {
            await store.dispatch('Banking/deleteQuickbooksRefund', this.transaction);
            toastSuccess(this.$toast, 'Quickbooks refund deleted successfully.');
          } catch (e) {
            toastErrors(this.$toast, e.errors);
          }finally {
            store.commit('Banking/loading', false);
          }
        }
      });
      store.dispatch('Parties/Party/reload');
    }
  }
}
</script>

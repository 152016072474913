// EventBus.js
class EventBus {
  constructor() {
    this.events = {};
  }

  $on(eventName, fn) {
    this.events[eventName] = this.events[eventName] || [];
    this.events[eventName].push(fn);
  }

  $emit(eventName, data) {
    if (this.events[eventName]) {
      this.events[eventName].forEach(fn => {
        fn(data);
      });
    }
  }

  $off(eventName, fn) {
    if (this.events[eventName]) {
      const index = this.events[eventName].indexOf(fn);
      if (index !== -1) {
        this.events[eventName].splice(index, 1);
      }
    }
  }
}

export default new EventBus();

<template>
  <section v-if="loading" class="w-full h-full absolute flex justify-content-center"
           style="background-color: rgba(255, 255, 255, 0.7)">
    <progress-spinner/>
  </section>
  <section>

    <customer-edit-general v-if="isAdding"></customer-edit-general>

    <ScrollPanel v-else style="width: 100%; height: 75vh" >
      <accordion id="Edit" :multiple="true" v-model:activeIndex="index" class="h-full overflow-auto">
        <accordion-tab header="General">
          <customer-edit-general></customer-edit-general>
        </accordion-tab>
        <accordion-tab header="Billing Details">
          <div v-if="!isAdding" class="formgrid grid">
            <div class="col-12 field">
              <label for="Search" class="text-600 text-sm font-bold">Search
                {{ business ? 'Business' : 'Residential' }}</label>
              <InputGroup>
                <Button
                  icon="pi pi-building"
                  :class="{'p-button-primary': business, 'p-button-outlined p-button-secondary': !business}"
                  @click="business=true"
                />
                <Button
                  icon="pi pi-home"
                  :class="{'p-button-primary': !business, 'p-button-outlined p-button-secondary': business}"
                  @click="business=false"
                />

                <GMapAutocomplete
                  v-if="business"
                  :options="{types: ['establishment']}"
                  placeholder="Business Search"
                  @place_changed="setPlace"
                  id="search"
                  class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round border-noround-left appearance-none outline-none focus:border-primary w-full"
                >
                </GMapAutocomplete>
                <GMapAutocomplete
                  v-else
                  placeholder="Residential Search"
                  @place_changed="setPlace"
                  id="search"
                  class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round border-noround-left appearance-none outline-none focus:border-primary w-full"
                >
                </GMapAutocomplete>
              </InputGroup>
            </div>


            <div class="field col-12" v-if="!isAdding">
              <label class="text-600 text-sm font-bold" for="email">Name On Check</label>
              <InputGroup>
                <InputText
                  class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full border-noround-right"
                  id="checkName" v-model.trim="check_name" placeholder="Name On Check"/>
                <Button label="generate" @click="generateCheckName" class="py-0 pr-5"/>
              </InputGroup>
            </div>

            <div class="field col-12">
              <label class="text-600 text-sm font-bold" for="address">Address</label>
              <InputText
                class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                id="address" v-model="address" placeholder=""/>
            </div>

            <div class="field col-12 md:col-6">
              <label class="text-600 text-sm font-bold" for="city">City</label>
              <InputText
                class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                id="city" v-model="city" placeholder=""/>
            </div>

            <div class="field col-12 md:col-3">
              <label class="text-600 text-sm font-bold" for="city">State</label>
              <state-selector id="state" v-model="state" class="w-full p-0"/>
            </div>

            <div class="field col-12 md:col-3">
              <label class="text-600 text-sm font-bold" for="zip">ZIP Code</label>
              <InputText
                class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                id="zip" v-model="zip" placeholder=""/>
            </div>
          </div>
        </accordion-tab>
        <accordion-tab header="Quickbooks Config">
          <div class="formgrid grid">
            <div class="field col-12 md:col-6" v-if="!isAdding">
              <label class="text-600 text-sm font-bold" for="email">Quickbooks Display Name</label>
              <InputGroup>
                <InputText
                  class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full border-noround-right"
                  id="displayName" v-model.trim="quickbooks_display_name" placeholder="Enter Display Name"/>
                <Button label="generate" @click="generateDisplayName" class="py-0 pr-5"/>
              </InputGroup>
            </div>
          </div>
        </accordion-tab>
      </accordion>

    </ScrollPanel>
  </section>
</template>

<script>
import {sync} from 'vuex-pathify';
import StateSelector from "@/components/common/state-selector.vue";
import InvoiceMiscTable from "@/components/events/edit/invoice/misc/invoice-misc-table.vue";
import InvoiceHeaderMisc from "@/components/events/edit/invoice/misc/invoice-header-misc.vue";
import InvoiceServicesTable from "@/components/events/edit/invoice/services/invoice-services-table.vue";
import InvoiceHeaderServices from "@/components/events/edit/invoice/services/invoice-header-services.vue";
import InvoiceTransactionTable from "@/components/events/edit/invoice/invoice-transaction-table.vue";
import CustomerEditGeneral from "@/components/customers/customer-edit-general.vue";

export default {
  name: "customer-edit",
  components: {
    CustomerEditGeneral,
    InvoiceTransactionTable, InvoiceHeaderServices,
    InvoiceServicesTable, InvoiceHeaderMisc, InvoiceMiscTable, StateSelector
  },
  data() {
    return {
      search: null,
      business: false,
      index: [0]
    }
  },
  computed: {

    current() {
      return store.get('Customers/current');
    },

    customerReload: sync('Customers/reloadKey', null, false),

    ...sync('Customers/Customer/*', null, false),

    loading() {
      return store.get('Customers/Customer/loading');
    },

    isAdding() {
      return store.get('Customers/add');
    }
  },
  methods: {

    cancel() {
      store.dispatch('Customers/Customer/reset');
    },

    generateName() {
      if (this.company && this.company.trim() !== '') {
        return this.company.trim();
      } else {
        return `${this.first} ${this.last}`.trim();
      }
    },

    generateDisplayName() {
      this.quickbooks_display_name = this.generateName();
    },
    generateCheckName() {
      this.check_name = this.generateName();
    },

    setPlace(place) {

      if (place.address_components) {
        let formattedFullAddress = [];

        for (let i = 0; i < place.address_components.length; i++) {
          let component = place.address_components[i];

          if (component.types.includes('street_number')) {
            formattedFullAddress[0] = component.long_name;
          } else if (component.types.includes('route')) {
            formattedFullAddress[1] = " " + component.long_name;
          } else if (component.types.includes('subpremise')) {
            formattedFullAddress[3] = ", " + component.long_name;
          }
        }

        this.address = formattedFullAddress.join('');
      }

      let cityComponent = place.address_components.find(c => c.types.includes('locality'));
      let subCityComponent = place.address_components.find(c => c.types.includes('sublocality') || c.types.includes('neighborhood'));

      // Use sublocality or neighborhood if available, otherwise use locality
      this.city = subCityComponent ? subCityComponent.long_name : cityComponent ? cityComponent.long_name : undefined;

      let postalComponent = place.address_components.find(c => c.types.includes('postal_code'));
      if (postalComponent) {
        this.zip = postalComponent.long_name;
      }

      let stateComponent = place.address_components.find(c => c.types.includes('administrative_area_level_1'));
      if (stateComponent) {
        this.state = stateComponent.short_name; // or use long_name if you prefer the full state name
      }
    },
  },
}
</script>


<style scoped lang="scss">

</style>

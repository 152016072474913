<template>
  <div class="w-full h-full flex justify-content-center align-content-center">
    <section v-if="loading">
      <progress-spinner class="h-full"/>
    </section>
    <section v-else class="w-full">
      <DataTable class="fadein animation-duration-1000"
                 show-gridlines striped-rows size="small"
                 :value="gigs" :paginator="true" :rows="10" :rowsPerPageOptions="[10,25,50,100]"
                 selection-mode="single"
                 v-model:selection="selected"
                 @row-click="onRowClick"
                 scrollable
                 scrollHeight="60vh"

                 v-model:filters="filters"
                 :globalFilterFields="[ 'character.name','party.city','addon.title', 'misc', 'start_time', 'end_time']"

      >

        <template #header>
          <div class="flex justify-content-end">

            <InputGroup>
              <InputGroupAddon>
                <i class="pi pi-search"/>
              </InputGroupAddon>
              <InputText v-model="filters['global'].value" placeholder="Search"
                         class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border appearance-none outline-none focus:border-primary w-full"/>
            </InputGroup>

          </div>
        </template>

        <template #empty>
          No events found.
        </template>

        <Column field="start_time" header="Date"  :sortable="true">
          <template #body="{data}">
            {{ partyDateShort(data.start_time) }}
          </template>
        </Column>
        <Column field="misc" header="Gig" class="w-3">
          <template #body="{data}">
            <div class="flex justify-content-between">
              <span v-if="data.character">{{ data.character.name }}</span>
              <span v-else-if="data.addon">{{ data.addon.title }}</span>
              <span v-else>{{ data.misc }}</span>
              <notes-bookmark :data="data"></notes-bookmark>
            </div>
          </template>
        </Column>


        <Column field="party.city" header="City" class="w-2"  :sortable="true"></Column>

        <Column field="start_time" header="Start Time">
          <template #body="{data}">
            {{ formatTime(data.start_time) }}
          </template>
        </Column>
        <Column field="end_time" header="End Time">
          <template #body="{data}">
            {{ formatTime(data.end_time) }}
          </template>
        </Column>

        <Column field="cost" header="Earned">
          <template #body="{data}">
            {{ formatMoney(data.cost) }}
          </template>
        </Column>
        <Column field="paid_on" header="Paid" :sortable="true">
          <template #body="{data}">
            <span v-if="data.paid_on">{{ formatDate(data.paid_on) }}</span>
            <span v-else>-</span>
          </template>
        </Column>
      </DataTable>
    </section>
  </div>
</template>

<script>
import {formatMoney} from "accounting-js";
import {formatTime} from "@/helpers/time.helper";
import {formatDate, partyDateShort} from "@/helpers/date.helper";
import {FilterMatchMode} from "primevue/api";
import NotesBookmark from "@/components/events/quick-view/tables/notes-bookmark.vue";

export default {
  name: "gigs-table",
  components: {NotesBookmark},

  data() {
    return {
      loading: false,
      gigs: [],
      selected: [],
      filters: {
        global: {value: null, matchMode: FilterMatchMode.CONTAINS},
      },
    }
  },

  computed: {
    employee() {
      return store.get('Employees/current');
    }
  },

  watch: {
    employee: {
      immediate: true,
      handler(newEmployee) {
        if (newEmployee && !this.loading) {
          this.load();
        }
      }
    }
  },

  methods: {
    formatDate,

    ...{formatTime, formatMoney, partyDateShort},

    async load() {
      if (!this.employee) return;
      this.loading = true;
      this.gigs = await api.url(`/employee/${this.employee?.id}/gigs/past`).get().json();
      this.loading = false;
    },

    onRowClick(event) {
      let party = event.data?.party;
      if (!party) return;

      store.dispatch('Parties/Party/load', party.token);
      store.commit('Parties/Party/view', true);
    },

    showPayments(){}
  },
}
</script>

<style scoped lang="scss">

</style>

 <template>
    <div v-if="services.length">
      <qv-services-context-menus ref="cm"/>
        <div class="flex">
            <h2 class="mr-2 text-600">Services</h2>
            <AvatarGroup v-if="!hideImages" class="avatar-hover-effect">
                <Avatar v-for="item in visible" :key="item.id" :image="getServiceImage(item)" size="large" shape="circle" class="border-200 border-3 bg-white"/>
            </AvatarGroup>
        </div>
        <DataTable :value="items" showGridlines stripedRows size="small">
            <Column field="addon.title" header="Service">
                <template #body="{data}">
                  <div @contextmenu.prevent="showMenu($event, data)">{{!data.visible?' -- ':''}}{{ data.addon.title }}</div>
                </template>
            </Column>
            <Column field="employee.fullName" header="Staff" class="w-3" v-if="!hideStaff">
              <template #body="{ data }">
                <div v-if="data.employee"
                     class="flex justify-content-between cursor-pointer"
                     @click="openEmployeeQuickView(data.employee)"
                     @contextmenu.prevent="showNotes($event, data)"
                >
                  <span class="hover:underline hover:text-primary">{{ data.employee?.fullName }}</span>
                  <notes-bookmark :data="data"></notes-bookmark>
                </div>
                <div v-else class="text-center">-</div>
              </template>
            </Column>
            <Column field="start_time" header="Start" class="w-1">
                <template #body="{data}">
                    {{ formatTime(data.start_time) }}
                </template>
            </Column>
            <Column field="end_time" header="End" class="w-1">
                <template #body="{data}">
                    {{ formatTime(data.end_time) }}
                </template>
            </Column>
            <Column field="price" header="Price" class="w-1" v-if="!hideMoney">
                <template #body="{data}">
                    {{ formatMoney(data.price) }}
                </template>
            </Column>
            <Column field="cost" header="Cost" class="w-1" v-if="!hideMoney && !hideCost">
                <template #body="{data}">
                    {{ formatMoney(data.cost) }}
                </template>
            </Column>

            <ColumnGroup type="footer" v-if="!hideMoney">
                <Row>
                    <Column footer="Subtotal:" :colspan="columns" footerStyle="text-align:right" />
                    <Column :footer="formatMoney(subtotalPrice)" />
                    <Column v-if="!hideCost"  :footer="formatMoney(subtotalCost)" />
                </Row>
            </ColumnGroup>
        </DataTable>
    </div>
</template>
<script>
import {filter, sumBy} from "lodash";
import Config from '@/config';
import {formatTime} from "@/helpers/time.helper";
import {formatMoney} from 'accounting-js';
import Bus from "@/bus";
import NotesBookmark from "@/components/events/quick-view/tables/notes-bookmark.vue";
import QvServicesContextMenus from "@/components/events/quick-view/tables/qv-services-context-menus.vue";

export default {
  name: "services-table",
  components: {QvServicesContextMenus, NotesBookmark},
  props: {
    hideImages: {
      type: Boolean,
      default: false
    },
    hideMoney: {
      type: Boolean,
      default: false
    },
    hideStaff: {
      type: Boolean,
      default: false
    },
    hideCost: {
      type: Boolean,
      default: false
    },
    hideInvisible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    party() {
      return store.get('Parties/Party/current')
    },
    services() {
      return filter(this.party.scheduleables, (item) => {
        return item.addon;
      })
    },
    items(){
      return this.hideInvisible ? this.visible : this.services;
    },
    visible(){
      return filter(this.services, (item) => item.visible);
    },
    subtotalPrice(){
      return sumBy(this.services, (item) => parseFloat(item.price) || 0);
    },
    subtotalCost(){
      return sumBy(this.services, (item) => parseFloat(item.cost) || 0);
    },
    columns(){
      let columns = 2;
      if(!this.hideStaff) columns++;
      if(!this.hideMoney) columns++;
      return columns;
    }
  },
  methods: {
    ...{formatTime, formatMoney},

    getServiceImage(item) {``
      return Config.service.thumbnail + item.addon.images[0].id + '.jpg';
    },

    showNotes(event, data) {
      Bus.$emit('event.notes.contextmenu', {event, data});
    },

    showMenu(event, data) {
      Bus.$emit('event.qv.services.contextmenu', {event, data});
    },

    openEmployeeQuickView(employee){
      store.commit('Employees/current', employee);
      store.commit('Employees/view', true);
    }
  },
}
</script>

 <style scoped lang="scss">
 .avatar-hover-effect {
   .p-avatar {
     transition: transform 0.3s ease;
   }

   .p-avatar:hover {
     transform: scale(calc(150 / 64)); // Scale based on original 'large' size (64px to 150px)
   }
 }
 </style>

import config from "@/config";

export function getEmployeeImage(employee) {
  let image = employee.img;
  return `${config.employees.full}${image}`;
}

export function getCharacterImage(character) {
  let image = character.images[0]?.src;
  return `${config.character.full}${image}`;
}

export function getServiceImage(service) {
  let image = service.images[0]?.src;
  return `${config.service.full}${image}`;
}
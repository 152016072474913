<template>
    <BlockUI :blocked="balance<=0">
    <form @submit.prevent="submitCashPayment" class="p-fluid">
        <div class="grid formgrid mt-5">
            <div class="field col-12 md:col-6">
                <label for="firstname" class="text-600 font-bold">First Name:</label>
                <InputText id="firstname" v-model="firstname" required/>
            </div>

            <div class="field col-12 md:col-6">
                <label for="lastname" class="text-600 font-bold">Last Name:</label>
                <InputText id="lastname" v-model="lastname" required/>
            </div>

            <div class="field col-12 md:col-6">
                <label for="payment-type" class="text-600 font-bold">Payment Type:</label>
                <Dropdown
                    id="payment-type"
                    v-model="paymentType"
                    :options="paymentTypes"
                    optionLabel="label"
                    optionValue="value"
                    placeholder="Select a payment type"
                    required
                />
            </div>

            <div class="field col-12 md:col-6">
                <label for="amount" class="text-600 font-bold">Amount:</label>
                <InputGroup>
                    <InputNumber id="amount" v-model="amount" mode="currency" currency="USD" :minFractionDigits="2" required/>
                    <InputGroupAddon @click="amount=balance" class="white-space-nowrap px-5 cursor-pointer hover:bg-primary">
                        <span class="inline-block px-5">{{`Balance (${formatMoney(balance)})`}}</span>
                    </InputGroupAddon>
                </InputGroup>
            </div>

            <div class="col-5 col-offset-7 flex ">
                <Button label="Cancel" @click="close" class="p-button-text"></Button>
                <Button type="submit" :label="balance<=0? 'Requires Balance':'Add Payment'" class="p-button-rounded" :loading="loading"
                        :disabled="balance<=0"></Button>
            </div>
        </div>
    </form>
    </BlockUI>
</template>

<script>
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {formatMoney} from "accounting-js";

dayjs.extend(utc);

export default {
    name: 'CashForm',
    data() {
        return {
            amount: 0,
            paymentType: 'cash',
            firstname: '',
            lastname: '',
            loading: false,
            paymentTypes: [
                { label: 'Cash', value: 'cash' },
                { label: 'Zelle', value: 'zelle' },
                { label: 'Venmo', value: 'venmo' }
            ]
        };
    },
    computed: {
        party() {
            return store.get('Parties/Party/current')
        },
        balance() {
            return store.get('Parties/Party/balance');
        },
    },
    methods: {
      formatMoney,
        async submitCashPayment() {
            // Check if all fields are filled
            if (!this.paymentType || !this.firstname || !this.lastname || this.amount <= 0) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Validation Error',
                    detail: 'All fields are required and must be filled out correctly.',
                    life: 5000
                });
                return;
            }

            this.loading = true;
            try {
                const transaction = {
                    amount: this.amount,
                    invoice: this.party.token,
                    transactionStatus: 'processed',
                    accountType: this.paymentType,
                    firstName: this.firstname,
                    lastName: this.lastname,
                    submitTimeUTC: dayjs().utc().format('YYYY-MM-DD HH:mm:ss'),
                    submitTimeLocal: dayjs().format('YYYY-MM-DD HH:mm:ss')
                };

                await store.dispatch('Banking/createTransaction', transaction);

                // Reset form
                this.amount = 0;
                this.paymentType = 'cash';
                this.firstname = '';
                this.lastname = '';

                this.$toast.add({
                    severity: 'success',
                    summary: 'Payment Processed',
                    detail: 'The payment has been recorded successfully.',
                    life: 3000
                });
            } catch (error) {
                console.error('Error creating cash transaction:', error);
                this.$toast.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'There was an error processing the payment.',
                    life: 5000
                });
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>

<style scoped>
</style>

<template>
  <Dialog v-model:visible="visible" modal class="h-auto surface-overlay" position="top"
          :style="{width: '640px'}"
          @dblclick.stop>
    <template #container>
      <section class="p-5">
        <div class="flex justify-content-between">
          <h1 class="text-600" v-if="isCloning">Clone Labor</h1>
          <h1 class="text-600" v-else>New Labor</h1>
          <Button type="button" icon="pi pi-times" class="ml-2 align-self-start" size="small" text rounded @click="close" severity="secondary"></Button>
        </div>

        <label class="text-600 text-sm font-bold">Find Team Member</label>

        <AutoComplete
          v-model="term"
          field="name"
          :suggestions="filteredEmployees"
          :autoOptionFocus="true"
          @complete="searchEmployees"
          @item-select="selectEmployee"
          @clear="unselectEmployee"
          placeholder="first, last, email or phone"
          input-class="w-full"
          class="w-full my-2"
        >
          <template #item="{item}">
            <div>{{item.company ? item.company + " : " : ""}} {{ item.first }} {{ item.last }} - {{ item.email }} - {{ item.phone }}</div>
          </template>

          <template #empty>
            <div>No Team Members Found.</div>
          </template>
        </AutoComplete>

        <Card v-if="employeeIsLoaded" class="mb-5">
          <template #content>
            <div class="flex justify-content-between">
              <div>
                <h2 class="text-600" v-if="isCloning">Clone Expense For</h2>
                <h2 class="text-600" v-else>Create Labor For</h2>

                <h3 class="text-600">{{employee.first}} {{employee.last}}</h3>
                <p>{{employee.email}}</p>
                <p>{{employee.phone}}</p>
              </div>
              <Button icon="pi pi-times" class="p-button-rounded p-button-text" @click="unselectEmployee"/>
            </div>
          </template>
        </Card>

        <div class="flex justify-content-end z-depth-1">
          <Button icon="pi pi-times" label="Reset" rounded outlined @click="cancel" class="mr-2" :disabled="loading"/>
          <Button icon="pi pi-chevron-right" label="New Labor" rounded @click="next" :disabled="disable"/>
        </div>

      </section>
    </template>
  </Dialog>

</template>

<script>
import {debounce, each} from "lodash";
import CustomerEdit from "@/components/customers/customer-edit.vue";
import LogoCompany from "@/components/common/logo-company.vue";
import {sync} from "vuex-pathify";

export default {
  name: "new-labor-modal",
  components: {LogoCompany, CustomerEdit},
  data() {
    return {
      term: '',
      employees: [],
      filteredEmployees: [],
      debouncedSearch: null,
    }
  },
  created() {
    this.debouncedSearch = debounce(this.search, 800);
  },

  computed: {

    disable(){
      return this.employee === null;
    },

    employee: sync('Employees/current', null, false),

    loading() {
      return store.get('Employees/loading');
    },

    visible: sync('Labor/add', null, false),

    isCloning() {
      return store.get('Labor/clone');
    },
    employeeIsLoaded() {
      return !!this.employee?.id;
    }
  },

  methods: {

    selectEmployee(event) {
      this.employee = event.value;
    },

    unselectEmployee() {
      this.employee =  null;
    },

    close() {
      this.visible = false;
      store.commit('Labor/clone', false);
      this.employee = null;
      this.cancel();
    },

    cancel() {
      store.commit('Labor/current', null);
      this.term = '';
    },

    searchEmployees(event) {
      if (event.query.trim().length >= 3) {
        this.debouncedSearch(event.query);
      } else {
        this.filteredEmployees = [];
      }
    },

    async search(query) {
      let response = await api.url(`/employee/search?query=${query}`).get().json();
      this.filteredEmployees = response.map(employee => ({
        ...employee,
        name: `${employee.first} ${employee.last}`
      }));
    },

    async next() {

      try {
        if(this.isCloning){
          store.commit('Labor/Timesheet/employee_id', this.employee.id);
          await store.dispatch('Labor/Timesheet/clone');
          store.commit('Labor/clone', false);

        }else {
          await store.dispatch('Labor/Timesheet/reset');
          store.commit('Labor/edit', true);
        }

        this.cancel();
        this.visible = false;

      } catch (e) {
        each(e.errors, (error) => {
          this.$toast.add({severity: 'error', summary: 'Error', detail: error[0], life: 3000});
        });
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>

<template>
  <div>
    <p>Search for a employee by {{ lastName ? 'Last name' : 'Display Name' }} in QuickBooks and associate the
      quickbooks employee with {{ current.fullName }}.</p>

    <InputGroup class="mb-5">
      <Button
          label="Display"
          :class="{'p-button-primary': !lastName, 'p-button-outlined p-button-secondary': lastName}"
          @click="setDisplayName"
      />
      <Button
          label="Last"
          :class="{'p-button-primary': lastName, 'p-button-outlined p-button-secondary': !lastName}"
          @click="setLastName"
      />
      <InputText
          type="text"
          v-model.trim="term" :placeholder="`Search by ${lastName?'Last name': 'Display Name'}...`"
          text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round
          appearance-none outline-none focus:border-primary w-full
      />
      <Button @click="search" outlined>Search</Button>
    </InputGroup>

    <div v-if="noResults">
      <h3>No Results for {{ term }}</h3>
    </div>

    <progress-spinner v-if="loading"></progress-spinner>
    <div v-else class="fadein animation-duration-500">
      <scroll-panel style="max-height: 60vh" class="pb-8">
        <employee-quickbooks-item v-for="employee in qboEmployees" :employee="employee" :key="employee.Id" class="mb-3"/>
      </scroll-panel>
    </div>
  </div>
</template>

<script>
import EmployeeQuickbooksItem from "./employee-quickbooks-item.vue";

export default {
  name: "employee-quickbooks-search",
  components: {EmployeeQuickbooksItem},
  data() {
    return {
      qboEmployees: [],
      term: '',
      lastName: false,
      loading: false
    };
  },
  computed: {
    current() {
      return store.get('Employees/current');
    },
    noResults() {
      return !this.qboEmployees.length && !this.loading
    }
  },
  methods: {
    async search() {
      this.loading = true;
      this.qboEmployees = await api.url(`/quickbooks/vendor/${this.lastName ? 'last-name' : 'display-name'}/${this.term}`).get().json();
      this.loading = false;
    },
    setLastName() {
      this.lastName = true;
      this.term = this.current.last;
    },
    setDisplayName() {
      this.lastName = false;
      this.term = this.current.quickbooks_display_name ? this.current.quickbooks_display_name : this.current.fullName;
    }
  }
}
</script>

<style scoped lang="scss">

</style>

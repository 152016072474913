<template>

  <customer-items-context-menu/>
  <section v-if="loading" class="flex justify-content-center align-content-center h-30rem">
    <progress-spinner class="h-full"/>
  </section>
  <section v-else class="fadein animation-duration-500">
    <scroll-panel style="width: 100%;height:80vh">
      <DataTable
        :value="items"
        :sortField="sortField"
        :sortOrder="sortOrder"
        @sort="customSort"
        paginator
        :rows="10"
        :rowsPerPageOptions="[10, 25, 50]"
        paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="{first} to {last} of {totalRecords}"
        v-model:filters="filters"
        :globalFilterFields="['character.name','addon.title', 'misc', 'start_time', 'end_time','employee.fullName']"
        showGridlines
        stripedRows
        removableSort
        dataKey="id"
        size="small"
        v-model:selection="selectedRow"
        selectionMode="single"

        @row-Dblclick="edit"
        @row-click="onRowClick"

        contextMenu
        v-model:contextMenuSelection="rtClickSelectedRow"
        @contextmenu="showMenu"
      >
        <template #header>
          <div class="flex justify-content-end">
            <InputGroup>
              <InputGroupAddon>
                <i class="pi pi-search"/>
              </InputGroupAddon>
              <InputText v-model="filters['global'].value" placeholder="Search"
                         class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border appearance-none outline-none focus:border-primary w-full"/>
            </InputGroup>
          </div>
        </template>
        <template #empty>
          No event items found.
        </template>

        <Column field="Item" header="Item" :sortable="true" :sortFunction="customSort">
          <template #body="{data}">
            <span v-if="data.addon">{{ !data.visible ? ' -- ' : '' }}{{ data.addon.title }}</span>
            <span v-else-if="data.character">{{ !data.visible ? ' -- ' : '' }}{{ data.character.name }}</span>
            <span v-else>{{ !data.visible ? ' -- ' : '' }}{{ data.misc }}</span>
          </template>
        </Column>

        <Column field="employee.fullName" header="Staff" class="w-3" :sortable="true"></Column>

        <Column field="start_time" header="Start" class="w-1">
          <template #body="{data}">
            {{ formatTime(data.start_time) }}
          </template>
        </Column>

        <Column field="end_time" header="End" class="w-1">
          <template #body="{data}">
            {{ formatTime(data.end_time) }}
          </template>
        </Column>

        <Column field="price" header="Price" class="w-1" :sortable="true">
          <template #body="{data}">
            {{ formatMoney(data.price) }}
          </template>
        </Column>

        <Column field="cost" header="Cost" class="w-1">
          <template #body="{data}">
            {{ formatMoney(data.cost) }}
          </template>
        </Column>

      </DataTable>
    </scroll-panel>
  </section>
</template>

<script>
import {FilterMatchMode} from "primevue/api";
import Bus from "@/bus";
import NotesBookmark from "@/components/events/quick-view/tables/notes-bookmark.vue";
import {formatTime} from "@/helpers/time.helper";
import {formatMoney} from "accounting-js";
import CustomerItemsContextMenu from "@/components/customers/customer-items-context-menu.vue";

export default {
  name: "CustomerItemsTable",
  components: {CustomerItemsContextMenu, NotesBookmark},
  data() {
    return {
      selectedRow: null,
      rtClickSelectedRow: null,
      sortField: null,
      sortOrder: null,
      filters: {
        global: {value: null, matchMode: FilterMatchMode.CONTAINS},
      },
    };
  },
  computed: {
    isEditing() {
      return store.get('Parties/Party/edit');
    },
    loading() {
      return store.get("Customers/Customer/loadingParties");
    },
    parties() {
      return store.get('Customers/Customer/parties')
    },
    items() {
      return store.get('Customers/Customer/items')
    }
  },
  methods: {
    formatMoney,
    formatTime,

    customSort(event) {
      const { sortField, sortOrder } = event;
      this.sortField = sortField;
      this.sortOrder = sortOrder;

      this.items.sort((a, b) => {
        let valueA, valueB;

        if (a.addon) {
          valueA = a.addon.title;
        } else if (a.character) {
          valueA = a.character.name;
        } else {
          valueA = a.misc;
        }

        if (b.addon) {
          valueB = b.addon.title;
        } else if (b.character) {
          valueB = b.character.name;
        } else {
          valueB = b.misc;
        }

        if (valueA < valueB) return -1 * sortOrder;
        if (valueA > valueB) return 1 * sortOrder;
        return 0;
      });
    },

    showMenu(event) {
      Bus.$emit('customer.items.contextmenu', { event, data: this.rtClickSelectedRow });
    },

    async edit({data, originalEvent}) {
      if (this.isEditing) {
        this.$toast.add({
          severity: 'warn',
          summary: 'In Edit Mode',
          detail: 'You cannot edit a new event when you are in edit mode',
          life: 3000
        });
      } else {
        originalEvent.stopPropagation();
        await store.dispatch('Parties/Party/getPartyById', data.party_id);
        store.commit('Parties/Party/view', true);
        store.commit('Parties/Party/edit', true);
      }
    },

    async onRowClick({data}) {
      if (this.isEditing) {
        this.$toast.add({
          severity: 'warn',
          summary: 'In Edit Mode',
          detail: 'You cannot view a new event when you are in edit mode',
          life: 3000
        });
      } else {
        await store.dispatch('Parties/Party/getPartyById', data.party_id);
        store.commit('Parties/Party/view', true);
      }
    }
  },
};
</script>

<style scoped>
.ellipsis {
  display: block; /* or inline-block */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%; /* Adjust as needed */
}
</style>

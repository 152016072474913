<template>
  <ContextMenu :model="items" ref="cm"></ContextMenu>
</template>

<script>
export default {
  name: "labor-context-menu",
  props: ['selectedLabor'],
  emits: ['refresh'],
  data() {
    return {
      paid: {label: 'Mark as Paid', icon: 'pi pi-fw pi-check', command: this.markPaid},
      unpaid: {label: 'Mark as Unpaid', icon: 'pi pi-fw pi-undo', command: this.markUnpaid},
    };
  },
  computed: {
    employee() {
      return this.selectedLabor?.employee;
    },
    employeeName() {
      return this.employee?.first + ' ' + this.employee?.last;
    },
    items() {
      let i = [
        {label: `Create Bill for ${this.employeeName}`, icon: 'pi pi-fw pi-calculator', command: this.createBill},
        this.selectedLabor?.paid_on === null? this.paid : this.unpaid,
        {label: 'Delete', icon: 'pi pi-fw pi-times', command: this.deleteLabor}
      ];

      if (this.selectedLabor?.gigs.length === 0) {
        i.unshift({label: 'Clone Labor', icon: 'pi pi-fw pi-copy', command: this.cloneLabor})
      }

      return i;
    },
  },
  methods: {
    show(event) {
      this.$refs.cm.show(event);
    },

    async deleteLabor() {
      if (this.selectedLabor) {
        await store.dispatch('Labor/delete', this.selectedLabor.id);
      } else {
        this.$toast.add({severity: 'warn', summary: 'Delete Labor', detail: 'No timesheet selected to delete.', life: 3000});
      }
    },

    async cloneLabor() {
      if (this.selectedLabor) {
        const clone = {...this.selectedLabor};
        await store.dispatch('Labor/Timesheet/set', clone);
        await store.dispatch('Employees/setEmployeeCurrent', clone.employee_id);
        await store.commit('Labor/clone',true);
        store.commit('Labor/add',true);
        this.$emit('refresh');
      } else {
        this.$toast.add({severity: 'warn', summary: 'Clone Labor', detail: 'No labor selected to clone.', life: 3000});
      }
    },

    async markPaid() {
      if (this.selectedLabor) {
        await store.dispatch('Labor/markPaid', this.selectedLabor.id);
      } else {
        this.$toast.add({severity: 'warn', summary: 'Mark Paid', detail: 'No labor selected to mark as paid.', life: 3000});
      }
    },

    async markUnpaid() {
      if (this.selectedLabor) {
        await store.dispatch('Labor/markUnpaid', this.selectedLabor.id);
      } else {
        this.$toast.add({severity: 'warn', summary: 'Mark Paid', detail: 'No labor selected to mark as paid.', life: 3000});
      }
    },

    async createBill() {
      if (this.selectedLabor) {
        await store.dispatch('Bills/create', {gig_id: this.selectedLabor.id, employee_id: this.employee.id});
      }else {
        this.$toast.add({severity: 'warn', summary: 'Create Bill', detail: 'No Labor selected to create bill.', life: 3000});
      }
    }
  }
};
</script>

<style scoped>
</style>

<template>
  <li>
    <a  @click="showDialog = true"
            href="javascript:void(0)"
            v-ripple
            class="border-round-top flex align-items-center lg:px-3 lg:py-3 font-medium cursor-point transition-colors transition-duration-150 menu-link text-500 no-underline hover:text-white hover:bg-primary">
      <i class="pi pi-star"></i>
      <span class="inline-block p-2">Characters</span>
    </a>
  </li>
  <div>
    <Dialog v-model:visible="showDialog" :content-style="{ width: '75vw', height: '80vh'}" modal>
      <template #header>
        <div class="flex justify-between items-center">
          <h3 class="text-3xl font-bold text-500 p-0 m-0 ml-2">Characters</h3>
        </div>
      </template>
      <div class="w-full h-full">
        <iframe ref="iframe" :src="iframeSrc" style="width: 100%; height: 100%;" frameborder="0"></iframe>
      </div>
    </Dialog>
  </div>
</template>

<script>
import Config from "@/config";

export default {
  name: 'character-v1-modal',
  data() {
    return {
      showDialog: false,
      iframeSrc: `${Config.domain}/admin/characters`
    };
  }
};
</script>

<style scoped>
/* You can add custom styles here */
</style>

<template>
  <Sidebar v-model:visible="visible" position="left" class="w-6" :modal="false" :dismissable="false">
    <template #container>
      <day-calendar/>
    </template>
  </Sidebar>
</template>

<script>
import {sync} from 'vuex-pathify';
import DayCalendar from "@/components/events/calendar/day-calendar.vue";

export default {

  name: 'day-calendar-quick-view',

  components: {DayCalendar},

  computed: {
    visible: sync('DayCalendar/showDay', null, false),
  },
};
</script>

<style lang="scss">


</style>
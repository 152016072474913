<template>
  <customer-parties-context-menu/>
  <clone-party-modal/>
  <section v-if="loading" class="flex justify-content-center align-content-center h-30rem">
    <progress-spinner class="h-full"/>
  </section>
  <section v-else class="fadein animation-duration-500">
    <scroll-panel style="width: 100%;height:60vh">
      <DataTable
        :value="parties"
        paginator
        :rows="10"
        :rowsPerPageOptions="[10, 25, 50]"
        paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="{first} to {last} of {totalRecords}"

        v-model:filters="filters"
        :globalFilterFields="['partyDate', 'howLong', 'customer.first', 'customer.last', 'customer.company', 'venue', 'city', 'created', 'search']"

        showGridlines
        stripedRows
        removableSort
        sortField="Created"
        dataKey="id"
        size="small"

        v-model:selection="selectedRow"
        selectionMode="single"

        @row-click="onRowClick"

        contextMenu
        v-model:contextMenuSelection="rtClickSelectedRow"
        @contextmenu="showMenu"
      >
        <template #header>
          <div class="flex justify-content-end">

            <InputGroup>
              <InputGroupAddon>
                <i class="pi pi-search"/>
              </InputGroupAddon>
              <InputText v-model="filters['global'].value" placeholder="Search"
                         class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border appearance-none outline-none focus:border-primary w-full"/>
            </InputGroup>
          </div>
        </template>
        <template #empty>
          No events found.
        </template>

        <Column field="date" header="Party Date" sortable class="w-14rem">
          <template #body="{data}">
            {{ partyDate(data.date) }}
          </template>
        </Column>

        <Column field="duration" header="How Long" class="w-6rem">
          <template #body="{data}">
            {{ data.duration }} hrs
          </template>
        </Column>

        <Column field="venue" header="Venue">
          <template #body="{data}">
            <span class="ellipsis w-15rem" :title="data.venue">{{ data.venue }}</span>
          </template>
        </Column>

        <Column field="city" header="City" sortable class="w-10rem">
          <template #body="{data}">
            <span class="ellipsis w-10rem" :title="data.city">{{ data.city }}</span>
          </template>
        </Column>

      </DataTable>

    </scroll-panel>

    <ConfirmDialog group="headless">
      <template #container="{ message, acceptCallback, rejectCallback }">
        <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
          <div
            class="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
            <i class="pi pi-question text-5xl"></i>
          </div>
          <span class="font-bold text-2xl block mb-2 mt-4">{{ message.header }}</span>
          <p class="mb-0">{{ message.message }}</p>
          <div class="flex align-items-center gap-2 mt-4">
            <Button label="Save" @click="acceptCallback" class="w-8rem"></Button>
            <Button label="Cancel" outlined @click="rejectCallback" class="w-8rem"></Button>
          </div>
        </div>
      </template>
    </ConfirmDialog>
  </section>
</template>

<script>
import {FilterMatchMode} from "primevue/api";
import {partyDate, formatDate} from '@/helpers/date.helper'
import {calculateAgeing, ageingClass, daysUntilEvent} from '@/helpers/party.helper'
import Bus from "@/bus";
import CustomerPartiesContextMenu from "@/components/customers/customer-parties-context-menu.vue";
import ClonePartyModal from "@/components/customers/clone-party-modal.vue";

export default {
  name: "CustomerPartiesTable",
  components: {ClonePartyModal, CustomerPartiesContextMenu},
  emits: ['update-status'],
  data() {
    return {
      selectedRow: [],
      rtClickSelectedRow: null,
      filters: {
        global: {value: null, matchMode: FilterMatchMode.CONTAINS},
      },
    };
  },
  mounted() {
    this.load();
  },
  computed: {
    parties() {
      return store.get("Customers/Customer/parties");
    },
    loading() {
      return store.get("Customers/Customer/loadingParties");
    }
  },
  methods: {

    ...{formatDate, partyDate, calculateAgeing, ageingClass, daysUntilEvent},

    async load() {
      await store.dispatch('Customers/Customer/loadParties');
    },

    showMenu(event) {
      Bus.$emit('customer.parties.contextmenu', {event, data: this.rtClickSelectedRow});
    },

    onRowClick({data}) {
      store.dispatch('Parties/Party/load', data.token);
      store.commit('Parties/Party/view', true);
    }
  },
};
</script>

<style scoped>
.ellipsis {
  display: block; /* or inline-block */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%; /* Adjust as needed */
}
</style>

<template>
  <character-context-menus/>
  <DataTable
            ref="characterTable"
            :value="characters"
            data-key="id"
            showGridlines
            stripedRows
            size="small"
            :loading="loading"
            @rowReorder="reorder"
  >

        <template #empty>
            No Characters.
        </template>

        <template #loading>
          <progress-spinner class="h-full"/>
        </template>

        <Column rowReorder :reorderableColumn="false" header-style="width:3%;" class="text-center" />

        <Column field="character.name" header="Character" class="w-4">
          <template #body="{data,field}">
            <invoice-character-picker :scheduleable="data"  @contextmenu="showMenu($event,data,field)"/>
          </template>
        </Column>

        <Column field="employee" header="Staff" class="w-3">
            <template #body="{data,field}">
              <invoice-employee-picker :scheduleable="data" @contextmenu="showMenu($event,data,field)" />
            </template>
        </Column>

        <Column field="start_time" header="Start Time" class="w-1">
          <template #body="{data,field}">
            <invoice-time-field :data="data" field="start_time" @contextmenu="showMenu($event,data,field)"/>
          </template>
        </Column>

        <Column field="end_time" header="End Time" class="w-1">
          <template #body="{data,field}">
            <invoice-time-field :data="data" field="end_time"  @contextmenu="showMenu($event,data,field)"/>
          </template>
        </Column>

        <Column field="price" header="Price" class="w-1">
          <template #body="{data,field}">
            <invoice-money-field :data="data" field="price" @contextmenu="showMenu($event,data,field)"/>
          </template>
        </Column>

        <Column field="cost" header="Cost" class="w-1">
          <template #body="{data,field}">
            <invoice-money-field :data="data" field="cost" @contextmenu="showMenu($event,data,field)"/>
          </template>
        </Column>

        <ColumnGroup type="footer">
            <Row>
                <Column footer="Subtotal:" :colspan="5" footerStyle="text-align:right"/>
                <Column :footer="formatMoney(subtotalPrice)"/>
                <Column :footer="formatMoney(subtotalCost)"/>
            </Row>
        </ColumnGroup>
    </DataTable>

</template>
<script>
import {formatMoney} from 'accounting-js';

import InvoiceEmployeePicker from "@/components/events/edit/invoice/invoice-employee-picker.vue";
import InvoiceMoneyField from "@/components/events/edit/invoice/invoice-money-field.vue";
import InvoiceTimeField from "@/components/events/edit/invoice/invoice-time-field.vue";
import InvoiceCharacterPicker from "@/components/events/edit/invoice/character/invoice-character-picker.vue";

import {sync} from "vuex-pathify";
import CharacterContextMenus from "@/components/events/edit/invoice/character/character-context-menus.vue";
import Bus from "@/bus";
export default {
  name: "invoice-character-table",

  components: {CharacterContextMenus, InvoiceCharacterPicker, InvoiceTimeField, InvoiceMoneyField, InvoiceEmployeePicker},

  computed: {

    loading(){
      return store.get('Scheduleables/charactersLoading');
    },

    reloading() {
      return store.get('Parties/Party/reloading');
    },

    characters: sync('Scheduleables/characters', null, false),

    subtotalPrice() {
      return store.get('Scheduleables/charactersSubtotal');
    },
    subtotalCost() {
      return store.get('Scheduleables/charactersCost');
    }
  },

  methods: {
    ...{formatMoney},

    showMenu(event, data ,field) {
      Bus.$emit('event.invoice.character.contextmenu', { event, data ,field });
    },

    reorder(event){
      this.characters = event.value;
      store.dispatch('Scheduleables/reorderScheduleables', event.value);
    }
  },
}
</script>


<style scoped lang="scss">

</style>

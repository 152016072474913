<template>
  <div class="grid">
    <div class="col-10">
      <FileUpload
              mode="basic"
              v-model="files"
              :auto="false"
              :multiple="true"
              :customUpload="true"
              :chooseLabel="chooseLabel"
              accept="image/*"
              @upload="uploadFiles"
              @clear="clearFiles"
              class="upload-button"
      />
    </div>
    <div class="col-2">
      <Button @click="uploadFiles" label="Upload" icon="pi pi-upload" />
    </div>
  </div>
</template>

<script>
import FileUpload from 'primevue/fileupload';
import Button from 'primevue/button';

export default {
  name: 'ImageUpload',
  components: {
    FileUpload,
    Button
  },
  props: {
    label: String
  },
  data() {
    return {
      files: [],
      chooseLabel: this.label || 'Choose'
    };
  },
  methods: {
    uploadFiles() {
      if (this.files.length > 0) {
        // Implement the upload logic here
        console.log('Uploading files:', this.files);
        // Reset the file upload component
        this.files = [];
        this.$emit('uploaded', this.files);
      }
    },
    clearFiles() {
      this.files = [];
      this.$emit('cleared');
    }
  }
}
</script>

<style scoped>
.upload-button .p-button {
    width: 100%;
}
</style>

<template>
  <section v-if="loading" class="flex justify-content-center align-content-center h-30rem">
    <progress-spinner class="h-full"/>
  </section>
  <section v-else class="fadein animation-duration-500">
    <h1 class="text-600 text-4xl pb-0 mb-0">Cancelled</h1>
    <p>Party has been cancelled by us because the customer may be interested later and we don't want to lose the
      work. Periodically come here and clean up old events that have aged or are past due.</p>
    <PartiesTable :parties="parties" @update-status="updateStatus" :loading="loading"/>

    <h1 class="text-600 text-4xl pb-0 mb-0">Past Due</h1>
    <p>These parties never were completely booked and their event date has past. Delete or Archive.</p>
    <PartiesTable :parties="pastDue" @update-status="updateStatus" :loading="loading" show-status/>
  </section>
</template>

<script>
import PartiesTable from "@/components/events/tables/parties-table.vue";

export default {
  name: "TabCancelled",
  components: {
    PartiesTable,
  },
  computed: {
    loading() {
      return store.get('Parties/loading');
    },
    parties() {
      return store.get('Parties/cancelled');
    },
    pastDue() {
      return store.get('Parties/pastDue');
    }
  },
  methods: {
    updateStatus(payload) {
      store.dispatch("Parties/updateStatus", payload)
    },
  },
};
</script>

<style scoped>
</style>

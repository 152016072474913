<template>

  <div class="formgrid grid">

    <div class="field col-12 md:col-3">
      <label class="text-600 text-sm font-bold" for="name">First Name</label>
      <InputText
        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
        id="name" v-model="first" placeholder=""/>
    </div>

    <div class="field col-12 md:col-3">
      <label class="text-600 text-sm font-bold" for="fullName">Last Name</label>
      <InputText
        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
        id="fullName" v-model="last" placeholder=""/>
    </div>

    <div class="field col-12 md:col-6">
      <label class="text-600 text-sm font-bold" for="email">Company</label>
      <InputText
        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
        id="company" v-model.trim="company" placeholder="Enter Vendor Company Name"/>
    </div>

    <div class="field col-12 md:col-6">
      <label class="text-600 text-sm font-bold" for="email">Email</label>
      <InputText
        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
        id="email" v-model="email" placeholder=""/>
    </div>

    <div class="field col-12 md:col-6">
      <label class="text-600 text-sm font-bold" for="phone">Phone</label>
      <InputMask :auto-clear="false" mask="999-999-9999" placeholder="999-999-9999" id="phone" v-model.trim="phone"
                 class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"/>
    </div>

    <div class="field col-12 md:col-6">
      <label class="text-600 text-sm font-bold" for="gender">Gender</label>
      <Dropdown class="w-full" id="gender" v-model="gender" :options="genders" option-label="label"
                option-value="value" placeholder="Select Gender" input-class="p-2"/>
    </div>
    <div class="field col-12 md:col-6">
      <label class="text-600 text-sm font-bold" for="type">Type</label>
      <Dropdown class="w-full" id="type" v-model="type" :options="types" option-label="label" option-value="value"
                placeholder="Select Type" input-class="p-2"/>
    </div>
  </div>

</template>

<script>
import {sync} from 'vuex-pathify';

export default {
  name: "employee-quick-view-edit-general",

  data() {
    return {
      genders: [
        {label: 'Male', value: 'male'},
        {label: 'Female', value: 'female'},
        {label: 'Vendor', value: 'vendor'},
      ],
      types: [
        {label: 'Primary', value: 'primary'},
        {label: 'Sub', value: 'sub'},
        {label: 'Vendor', value: 'vendor'},
      ]
    }
  },

  computed: {
    ...sync('Employees/Employee/*', null, false),
  }
}
</script>

<style>
</style>

<template>
  <div class="card-shadow" :class="{'active elevation-4': image.primary}">
    <character-profile-image :image="image">
      <div class="app-bar">
        <div class="spacer"></div>
        <slot></slot>
      </div>
    </character-profile-image>
    <div class="card-text">
      <h4>{{ image.src }}</h4>
    </div>
  </div>
</template>

<script>
import CharacterProfileImage from "@/components/characters/profile/character-profile-image";

export default {
  name: "characters-card-image",
  props: ['image'],
  components: {
    CharacterProfileImage
  }
}
</script>

<style scoped>
.card-shadow {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    transition: box-shadow 0.3s;
}

.card-shadow.active.elevation-4 {
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}

.app-bar {
    background-color: rgba(0, 0, 0, 0);
    padding: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card-text {
    padding: 1rem;
}
</style>

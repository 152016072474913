<template>
  <section v-if="loading">
    <progress-spinner class="tiny-spinner"/>
  </section>
  <section v-else class="fadein animation-duration-500">
      <i class="pi pi-bookmark" v-if="data.notes" @click.stop="showNotes($event)"></i>
      <OverlayPanel ref="op" class="w-2" @click.stop>
        <template #container="{closeCallback}">

          <div class="flex justify-content-between m-0">
            <h4 class="p-0 m-0 pt-3 pl-3"><i class="pi pi-bookmark"></i> Party Note</h4>
            <Button class="m-0 p-0" icon="pi pi-times" text size="small" severity="secondary" rounded @click="closeCallback"></Button>
          </div>
          <div v-html="data.notes" class="p-3 pt-0"></div>

        </template>
      </OverlayPanel>
  </section>
</template>

<script>

export default {
  name: "notes-bookmark",
  props: {
    data: Object,
  },
  computed: {
    selectedRow() {
      return store.get('Scheduleables/selectedRow');
    },
    reloading() {
      return store.get('Scheduleables/noteLoading');
    },
    loading() {
      return this.reloading && this.selectedRow?.id === this.data.id
    }
  },
  methods: {
    showNotes(event) {
      this.$refs.op.toggle(event);
    },
  },
}
</script>

<style scoped lang="scss">

</style>
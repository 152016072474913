<template>
    <img :src="src" class="characterPNG" v-if="!avatar"/>
    <avatar :image="src" shape="circle" size="xlarge" v-if="avatar"/>
</template>

<script>
import Config from '@/config'
export default {
    name: "character-image",
    props: ['image', 'width', 'height', 'listing', 'full', 'avatar'],
    computed: {
        prefix() {
            return `${Config.imgPath}/characters`;
        },
        thumb() {
            return `${this.prefix}/resized/thumbnail/${this.image.id}.jpg`;
        },
        large() {
            return `${this.prefix}/resized/listing/${this.image?.id}.jpg`;
        },
        png() {
            return `${this.prefix}/${this.image?.src}`;
        },
        src() {
            if (this.full !== undefined) return this.png;
            if (this.listing !== undefined) return this.large;
            return this.thumb;
        }
    }
}
</script>

<style lang="scss">
    .characterPNG{
        max-height: 640px;
    }
</style>

<template>
  <section v-if="character" class="surface">
      <div class="flex justify-content-between align-content-start">

      <h3 class="text-600 text-4xl m-0 mb-4">{{ character.name }}</h3>

      <InputSwitch v-model="character.active"
                   class="pt-5"
                   inputId="activeSwitch"
                   :true-value="1"
                   :false-value="0"
                   @input="update"/>
      </div>
      <TabView v-model:activeIndex="activeTabIndex" class="self-stretch">
        <TabPanel header="General Information">
          <character-profile-general />
        </TabPanel>
        <TabPanel header="Images">
          <character-profile-images />
        </TabPanel>
        <TabPanel header="Relationships">
          <character-profile-relationships />
        </TabPanel>
      </TabView>
  </section>
</template>

<script>
import CharacterProfileCostume from "@/components/characters/profile/character-profile-costume.vue";
import CharacterProfileRelationships from "@/components/characters/profile/character-profile-relationships.vue";
import CharacterProfileImages from "@/components/characters/profile/character-profile-images.vue";
import CharacterProfileGeneral from "@/components/characters/profile/character-profile-general.vue";
import CharacterProfileRates from "@/components/characters/profile/character-profile-rates.vue";
import CharacterImage from "@/components/characters/common/character-image";

export default {
  name: "CharacterProfile",
  components: {
    CharacterImage,
    CharacterProfileRates,
    CharacterProfileGeneral,
    CharacterProfileImages,
    CharacterProfileRelationships,
    CharacterProfileCostume
  },
  data() {
    return {
      activeTabIndex: 0
    };
  },
  computed: {
    character() {
      return store.get('Characters/current');
    }
  },
  methods: {
    update() {
      store.dispatch('Characters/update', this.character);
    }
  }
}
</script>

<style scoped>
.self-stretch {
    flex: 1;
}
</style>

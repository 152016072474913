<template>
  <section>
    <div class="my-3 flex justify-content-between">
      <logo-company :height="90"></logo-company>
      <div class="w-5 text-500">
        <div><strong>Invoice #:</strong> {{party.token}}</div>
        <div v-if="customer.company"><strong>Company:</strong> {{ customer.company }}</div>
        <div><strong>Customer:</strong> {{ customer.full_name }}</div>
        <div><strong>Event Date:</strong> {{ formatWordDateWithDay(party.date) }}</div>
        <div><strong>Time:</strong> {{ formatTime(party.date) }} - {{ formatTime(endTime) }} ({{ party.duration }}hrs)</div>
      </div>
    </div>

    <div class="grid">
      <div class="col-12">

        <div class="flex justify-content-end">
          <h2 class="text-600 mr-3">Total:</h2>
          <h2 class="text-600">{{ total }}</h2>
        </div>

        <div v-if="transactions.length">
          <Card v-for="transaction in transactions" :key="transaction.id" class="mt-3 border-1 border-200 shadow-none">
            <template #content>
              <div class="flex justify-content-between">
                <div class="flex align-self-center">
                  <h2 class="px-2 my-1 text-500">Authorize.net Transaction</h2>
                </div>
                <div>
                  <div>Date: {{ formatDateTime(transaction.submitTimeLocal) }}</div>
                  <div>Transaction ID: {{ transaction.transId }}</div>
                </div>
              </div>
              <div class="flex justify-content-between pt-3">
                <h4>Customer: {{ transaction.firstName }} {{ transaction.lastName }}</h4>
                <div>
                  <h4>Amount: {{ formatMoney(transaction.amount) }}</h4>
                </div>
              </div>
              <div>
                <div>Transaction Status: {{ transaction.transactionStatus }}</div>
                <div>Card: {{ transaction.accountType }} {{ transaction.accountNumber }}</div>
                <div :class="{'text-red-500':transaction.invoice !== party.token}">Invoice: {{ transaction.invoice }}</div>
              </div>
            </template>
          </Card>
        </div>
        <div v-else>
          <Card class="mt-3 border-2 border-200 border-dashed shadow-none">
            <template #content>
              <div class="flex justify-content-center">
                <h2 class="text-500">No Transactions</h2>
              </div>
            </template>
          </Card>
        </div>

        <div class="flex justify-content-end" v-if="balance>=0">
          <h2 class="text-600 mr-3">Balance:</h2>
          <h2 class="text-600">{{ formatMoney(balance) }}</h2>
        </div>
        <div class="flex justify-content-end" v-else>
          <h2 class="text-green-300 mr-3">Credit:</h2>
          <h2 class="text-green-300">{{ formatMoney(Math.abs(balance)) }}</h2>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import MiscTable from "@/components/events/quick-view/tables/misc-table.vue";
import LogoCompany from "@/components/common/logo-company.vue";
import ServicesTable from "@/components/events/quick-view/tables/services-table.vue";
import CharacterTable from "@/components/events/quick-view/tables/character-table.vue";
import Config from "@/config";
import {capitalize} from "lodash";
import dayjs from "dayjs";

import {formatMoney} from "accounting-js";
import {formatDate, formatDateTime, formatWordDate, formatWordDateWithDay} from "@/helpers/date.helper";
import {formatTime} from "@/helpers/time.helper";

export default {
  name: "prints-transactions",
  data() {
    return {
      phone: Config.phone
    }
  },
  methods: {
    formatWordDateWithDay,
    formatTime,
    formatWordDate,
    formatMoney,
    formatDate,
    formatDateTime,
    capitalize,
  },
  components: {CharacterTable, ServicesTable, LogoCompany, MiscTable},
  computed: {
    party() {
      return store.get('Parties/Party/current');
    },
    customer() {
      return this.party.customer;
    },
    transactions() {
      return this.party.transactions;
    },
    total() {
      return formatMoney(store.get('Parties/Party/total'));
    },
    date() {
      return dayjs(this.party.date).format("MM/DD/YYYY, ddd");
    },
    startTime() {
      return dayjs(this.party.date).format('h:mma');
    },
    endTime(){
      return store.get('Parties/Party/endTime');
    },
    partyName() {
      return (this.party.for_whom)
        ? `${capitalize(this.party.for_whom)}'s ${capitalize(this.party.occasion) || 'Party'} in ${this.party.city}`
        : `${this.customer.full_name}'s ${capitalize(this.party.occasion) || 'Party'} in ${this.party.city}`;
    },
    balance() {
      return store.get('Parties/Party/balance');
    },
    paymentsTotal() {
      return store.get('Parties/Party/paymentsTotal');
    }
  }
}
</script>

<style scoped lang="scss">

</style>

<template>
  <router-view></router-view>
</template>

<script>
import Bus from '@/bus';
export default {
  name: 'App',
  created() {
     store.dispatch('Characters/load');
     store.dispatch('Services/load');

      Bus.$on('error.archived', () => {
        this.$toast.add({
          severity: 'error',
          summary: 'Cannot Save when Archived',
          detail: 'Party is archived. Unarchive to edit.',
          life: 5000
        });
      });

      Bus.$on('error.wrongStatus', () => {
        this.$toast.add({
          severity: 'error',
          summary: 'Cannot Save with Current Status',
          detail: 'Party must be New, Booking or Ready to update. Change status to update.',
          life: 5000
        });
      });
  },
  beforeUnmount() {
    Bus.$off('error.archived');
    Bus.$off('error.wrongStatus');
  }
};
</script>

<style lang="scss" scoped>

</style>

import {make} from 'vuex-pathify';
import api from '@/config.wretch';

const state = {
  loading: false,
  edit: false,
  add: false,
  clone:false,

  params: {},

  unpaidOnly: true,
  list: [],
  current: null
};

const getters = {
  ...make.getters(state),
};

const mutations = {
    ...make.mutations(state)
};

const actions = {
  async load({commit, state}, params) {
    commit('loading', true);
    commit('params', params);
    let result = await api.url(`/scheduleable/payable`).query({...params, unpaid_only: state.unpaidOnly}).get().json();
    commit('list', result.data);
    commit('loading', false);
    return result;
  },

  async getUnpaidByEmployee(obj, {employee_id,bill_id}) {
    return await api.url(`/scheduleable/unpaid/employee/${employee_id}/${bill_id}`).get().json();
  },

  async getByEmployee(obj, employee_id) {
    return await api.url(`/scheduleable/employee/${employee_id}`).get().json();
  },


  async reload({ dispatch, state }) {
    await dispatch('load', state.params);
  },

  async markPaid({dispatch}, id) {
    try {
      await api.url(`/scheduleable/${id}/paid`).put().res();
      dispatch('reload');
    } catch (error) {
      console.error('Error marking paid:', error);
    }
  },

  async bulkMarkPaid({dispatch}, ids) {
    try {
      await api.url('/scheduleable/paid').post({ids}).res();
      dispatch('reload');
    } catch (error) {
      console.error('Error marking paid:', error);
    }
  },
  async bulkMarkUnpaid({dispatch}, ids) {
    try {
      await api.url('/scheduleable/unpaid').post({ids}).res();
      dispatch('reload');
    } catch (error) {
      console.error('Error marking unpaid:', error);
    }
  },

  async markUnpaid({dispatch}, id) {
    try {
      await api.url(`/scheduleable/${id}/unpaid`).put().res();
      dispatch('reload');
    } catch (error) {
      console.error('Error marking unpaid:', error);
    }
  }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

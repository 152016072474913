import {make} from 'vuex-pathify';
import {each, has} from "lodash";
import api from "@/config.wretch";

const state = {
  id: null,
  first: '',
  last: '',
  company: '',
  gender: 'female',
  type: 'primary',
  email: '',
  phone: '',
  address: '',
  city: '',
  state: 'Ca',
  zip: '',
  active: false,
  img: '',
  calendar_id: null,
  quickbooks_display_name: null,
  check_name:null,
  notes: null,

  loading: false,
  loadingUpcoming: false,
  loadingPast: false,
};

const restate = {...state};

const getters = {
  ...make.getters(state),
};

const mutations = {
  ...make.mutations(state),
};

const actions = {

  set({commit, state}, current) {
    each(current, (value, key) => {
      if (has(state, key)) {
        commit(key, value);
      }
    });

    if(current === null){
      state = {...restate};
    }
  },

  async create({ state }) {
      let response = await api.url(`/employee`).post(state).json();
      store.commit('Employees/current', response);
  },

  async save({state, dispatch }) {
    const response =  await api.url(`/employee/${state.id}`).put(state).res();

    if (response.status !== 200) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to save employee');
    }

    await dispatch('set', response);
    await store.dispatch('Employees/reload');
  },

  async loadPastGigs({commit, state}) {
    commit('loadingPast', true);
    let pastGigs = await api.url(`/employee/${state.current.id}/gigs/past`).get().json();
    commit('pastGigs', pastGigs);
    commit('loadingPast', false);
  },

  async reset({dispatch}) {
    dispatch('set', restate);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<template>
  <MultiSelect
          v-model="selectedStatus"
          :options="status"
          option-label="label"
          option-value="value"
          placeholder="All Status"
          class="w-full md:w-10rem mr-2"
          panel-class="h-22rem"
          @hide="update"
          :pt="{wrapper:{style:{height: '18rem', maxHeight: '18rem'}}}"
  >
    <template #option="{option}">
        <div :class="getStatus(option.label)" class="px-2 py-1 border-round">{{ option.label }}</div>
    </template>
    </MultiSelect>
</template>

<script>
import {isEqual} from "lodash";
import {getStatus} from "@/helpers/status.helper";

export default {
  name: "calendar-status-filter",
  props: {
    'calendar': {
      type: String,
      default: 'Calendar',
      validator(value){
        return ['Calendar', 'DayCalendar'].includes(value);
      },
    }
  },
  data() {
    return {
      selectedStatus: [],
      status: [
        {label: 'New', value: 'inquiry'},
        {label: 'Booking', value: 'booking'},
        {label: 'Complete', value: 'complete'},
        {label: 'Serviced', value: 'serviced'}
      ],
    }
  },
  methods: {
    getStatus,
    update() {
      if(isEqual(this.selectedStatus, store.get(`${this.calendar}/selectedStatus`))) return;
      store.commit(`${this.calendar}/selectedStatus`, [...this.selectedStatus]);
      store.dispatch(`${this.calendar}/load`);
    }
  },
}
</script>

<style scoped lang="scss">

</style>
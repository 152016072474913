<template>
  <Dialog v-model:visible="visible" modal class="h-full surface-overlay" position="top"
          :style="{width: '1200px'}"
          @dblclick.stop
  >
    <template #container>
      <section class="relative">

        <event-edit-interstitial v-if="showIntersititial"/>

        <section v-if="isLoading" class="flex justify-content-center align-content-center h-30rem">
          <progress-spinner class="h-full"/>
        </section>

        <section v-else class="fadein animation-duration-500">
          <div class="grid p-4 pb-0 text-700">
            <event-header-content class="col-8"/>
            <div class="col-4">
              <div class="flex align-items-center justify-content-end">
                <status-change-button class="mr-3"/>
                <Button type="button" @click="save" icon="pi pi-save" :severity="isDirty?'primary':'secondary'" :text="!isDirty" rounded :title="isDirty?'Save Changes':'No Changes'"></Button>
                <Button type="button" @click="close" icon="pi pi-times" severity="secondary" text rounded></Button>
              </div>
            </div>
          </div>
          <TabView v-model:activeIndex="index">
            <TabPanel>
              <template #header>
                <div class="flex align-items-center gap-2">
                  <i class="pi pi-clock"></i>
                  <span class="font-bold white-space-nowrap">Event Details</span>
                </div>
              </template>
              <div class="grid">
                <customer-details/>
                <event-datetime/>
              </div>
            </TabPanel>
            <TabPanel>
              <template #header>
                <div class="flex align-items-center gap-2">
                  <i class="pi pi-home"></i>
                  <span class="font-bold white-space-nowrap">Venue</span>
                </div>
              </template>
              <event-venue/>
            </TabPanel>
            <TabPanel>
              <template #header>
                <div class="flex align-items-center gap-2">
                  <i class="pi pi-calculator"></i>
                  <span class="font-bold white-space-nowrap">Invoice</span>
                </div>
              </template>
              <event-invoice/>
            </TabPanel>
            <TabPanel>
              <template #header>
                <div class="flex align-items-center gap-2">
                  <i class="pi pi-bookmark"></i>
                  <span class="font-bold white-space-nowrap">Notes</span>
                </div>
              </template>
              <div class="col">
                <h2>Notes / Instructions / Itinerary:</h2>
                <p>Notes are visible to client and are printed on calendar detail.</p>
                <Textarea v-model="party_notes" rows="5" class="w-full"/>
              </div>
            </TabPanel>
            <TabPanel>
              <template #header>
                <div class="flex align-items-center gap-2">
                  <icon-quickbooks v-if="party.quickbooks_id" size="16"/>
                  <icon-quickbooks v-else size="16" color="#cccccc"/>
                  <span class="font-bold white-space-nowrap">Quickbooks</span>
                </div>
              </template>
              <event-quickbooks/>
            </TabPanel>
          </TabView>
        </section>

      </section>
    </template>
  </Dialog>
</template>

<script>
import CustomerDetails from "./sections/customer-details.vue";
import EventDatetime from "./sections/event-datetime.vue";
import EventVenue from "./sections/event-venue.vue";
import EventInvoice from "./invoice/event-invoice.vue";

import {capitalize} from "lodash";
import {formatTime, formatEndTime} from "@/helpers/time.helper";
import {partyDateShort} from "@/helpers/date.helper";
import {get, sync} from "vuex-pathify";
import Numeral from 'numeral';
import EventHeaderContent from "@/components/events/common/event-header-content.vue";
import EventEditInterstitial from "@/components/events/edit/event-edit-interstitial.vue";
import IconQuickbooks from "@/components/common/icon-quickbooks.vue";
import EventQuickbooks from "@/components/events/edit/sections/event-quickbooks.vue";
import StatusChangeButton from "@/components/events/common/status-change-button.vue";


export default {
  name: "event-edit-modal",
  components: {
    StatusChangeButton,
    EventQuickbooks,
    IconQuickbooks,
    EventEditInterstitial,
    EventHeaderContent,
    EventInvoice,
    EventVenue,
    EventDatetime,
    CustomerDetails
  },

  data() {
    return {
      loaded: false,
      index: [0],
      tabMap: {
        'event-details': 0,
        'venue': 1,
        'invoice': 2,
        'notes': 3,
        'quickbooks': 4,
      }
    }
  },

  mounted() {
    this.index = this.tabMap[this.$route.params.editTab] || 0;
  },

  computed: {
    visible: sync('Parties/Party/edit', null, false),

    party() {
      return store.get('Parties/Party/current')
    },

    customer() {
      return this.party.customer;
    },

    isDirty() {
      return store.get('Parties/Party/isDirty');
    },

    showIntersititial() {
      return store.get('Parties/Party/showInterstitial');
    },

    isLoading() {
      return store.get('Parties/Party/loading');
    },

    shortDate() {
      return partyDateShort(this.date);
    },

    startTime() {
      return formatTime(this.date);
    },

    endTime() {
      return formatEndTime(this.date, this.duration);
    },

    full_name() {
      return this.first + ' ' + this.last;
    },

    ageWord() {
      return (this.occasion === 'birthday') ? Numeral(this.age).format('Oo') : '';
    },

    isEditable() {
      return this.party.status === 'inquiry' || this.party.status === 'booking' && !this.party.archived;
    },

    ...get([
      'Customers/last',
      'Customers/first',
      'Parties/Party/date',
      'Parties/Party/for_whom',
      'Parties/Party/age',
      'Parties/Party/guests',
      'Parties/Party/occasion',
      'Parties/Party/duration',
      'Parties/Party/city'], null, false),

    ...sync(['Parties/Party/party_notes'], null, false)
  },
  methods: {

    ...{capitalize},

    close() {
      if (this.isDirty){
          store.commit('Parties/Party/showInterstitial', true);
      } else {
        this.visible = false;
      }
    },

    async save() {

      if(this.party.archived) {
        this.$toast.add({
          severity: 'error',
          summary: 'Cannot Save when Archived',
          detail: 'Party is archived. Unarchive to edit.',
          life: 5000
        });
        return;
      }

      if(!['inquiry','booking','complete'].includes(this.party.status)){
        this.$toast.add({
          severity: 'error',
          summary: 'Cannot Save with Current Status',
          detail: 'Party must be New, Booking or Complete to update. Change status to update.',
          life: 5000
        });
       return;
      }

      store.commit('Parties/Party/loading', true);

      await store.dispatch('Parties/Party/save');
      store.commit('Parties/Party/loading', false);

      this.$toast.add({
        severity: 'success',
        summary: 'Success',
        detail: `${this.customer.full_name}'s Party Has been Saved`,
        life: 3000
      });

    },

    goToNoteTab() {
      this.index = 3;
    },

  }
}
</script>


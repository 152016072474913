import {make} from 'vuex-pathify';
import api from '@/config.wretch';
import {each, has} from "lodash";
import Bus from "@/bus";

const state = {
  id: null,
  employee_id: null,
  date: new Date(),
  amount: 0,
  category: 'General',
  description: '',
  bill_id: null,
  paid_on: null
};

const restate = {...state};

const getters = {
  ...make.getters(state),
};

const mutations = {
  ...make.mutations(state),
};

const actions = {

    set({commit, state}, current) {
      each(current, (value, key) => {
        if (has(state, key)) {
          commit(key, value);
        }
      });

      if (current && current.category === null) {
        commit('category', 'General');
      }

      if (current === null) {
        state = {...restate};
      }
    },

    async save({state}) {
      let response;
      if (state.id) {
        response = await api.url(`/expenses/${state.id}`).put(state).res();
      } else {
        response = await api.url(`/expenses`).post(state).res();
      }
      if (response.status !== 200 && response.status !== 201) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to save expense');
      }

      Bus.$emit('expenses.update');
      await store.dispatch('Expenses/reload');
    },

    reset({dispatch, commit, state}) {
      let old = {...state};
      dispatch('set', restate);
      commit('id', old.id);
      commit('employee_id', old.employee_id);
    },

    async clone({state}) {

      try {
        await api.url(`/expenses/clone`).post({employee_id: state.employee_id, id: state.id}).res();
        await store.dispatch('Expenses/reload');

      } catch (error) {
        console.error('Error creating expense:', error);
      }
    }
  }
;

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<script>
import Config from "@/config";

export default {
  name: "email-snipit-characters",
  props:{
    showDesc: {
      type: Boolean,
      default: false
    }
  },
  computed:{
    characters() {
      return store.get('Scheduleables/characters');
    }
  },
  methods:{
    getCharacterURL(item) {
      return `${Config.domain}/kids-party-characters/${item.character.url}`;
    }
  }
}
</script>

<template>
  <div v-if="characters.length">
    <h3>Characters</h3>
    <p v-if="showDesc">
      Our characters make the magic happen. They can do a variety of fun filled activities with your guests. Here are some suggestions
      for your party:
    </p>
    <ul>
      <li v-for="item in characters" :key="item.id">
        <a :href="getCharacterURL(item)" target="_blank" style="color:#f616c9; text-decoration:underline;">{{ item.character.alias }}</a>
      </li>
    </ul>
  </div>
</template>

<style scoped lang="scss">

</style>
import config from "@/config";

export function goToQuickbooksInvoice(invoiceId) {
  window.open(`${config.quickbooksUrl}/app/invoice?txnId=${invoiceId}`, '_blank');
}

export function goToQuickbooksPayment(paymentId) {
  window.open(`${config.quickbooksUrl}/app/recvpayment?txnId=${paymentId}`, '_blank');
}

export function goToQuickbooksCreditMemo(creditMemoId) {
  window.open(`${config.quickbooksUrl}/app/creditmemo?txnId=${creditMemoId}`, '_blank');
}

export function goToQuickbooksRefund(creditMemoId) {
  window.open(`${config.quickbooksUrl}/app/refundreceipt?txnId=${creditMemoId}`, '_blank');
}

export function goToQuickbooksCustomer(customerId) {
  window.open(`${config.quickbooksUrl}/app/customerdetail?nameId=${customerId}`, '_blank');
}


export function goToQuickbooksVendor(vendorId) {
  window.open(`${config.quickbooksUrl}/app/vendordetail?nameId=${vendorId}`, '_blank');
}


export async function goToAuthorizeTransaction(transactionId) {
  await navigator.clipboard.writeText(transactionId);
  window.open(`${config.authorizeUrl}/UI/themes/sandbox/merch.aspx?page=search&sub=batchlist`, '_blank');
}

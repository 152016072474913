<template>
    <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
        <new-event-modal></new-event-modal>
        <main-calendar></main-calendar>
        <day-calendar-quick-view></day-calendar-quick-view>
<!--        <li>-->
<!--            <a v-ripple-->
<!--               class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-cyan-600 hover:surface-hover font-medium border-round cursor-pointer transition-colors transition-duration-150">-->
<!--                <i class="pi pi-bell text-base lg:text-2xl mr-2 lg:mr-0" v-badge.danger></i>-->
<!--                <span class="block lg:hidden font-medium">Notifications</span>-->
<!--            </a>-->
<!--        </li>-->
<!--        <li class="border-top-1 surface-border lg:border-top-none">-->
<!--            <a v-ripple-->
<!--               class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center hover:surface-hover font-medium border-round cursor-point transition-colors transition-duration-150">-->
<!--                <avatar icon="pi pi pi-user" shape="circle" class="mr-3 lg:mr-0  bg-primary-400 text-white" style="width: 32px; height: 32px" @click="logout"/>-->
<!--                <div class="block lg:hidden">-->
<!--                    <div class="text-900 font-medium">Elizabeth Martin</div>-->
<!--                    <span class="text-600 font-medium text-sm">Owner</span>-->
<!--                </div>-->
<!--            </a>-->
<!--        </li>-->

      <li class="h-full relative">
        <a v-ripple class="cursor-pointer h-full inline-flex align-items-center text-400 py-3 lg:py-0 px-3 border-left-2 lg:border-left-none lg:border-bottom-2 border-transparent transition-colors transition-duration-150 p-ripple"
           v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }">
          <avatar icon="pi pi pi-user" shape="circle" class="mr-3 lg:mr-0  bg-primary-400 text-white" style="width: 32px; height: 32px"/>
          <span class="mx-2 font-medium text-400">Admin</span>
          <i class="pi pi-angle-down"></i>
        </a>
        <div class="hidden static lg:absolute w-full surface-overlay left-0 top-100 z-1 shadow-none lg:shadow-2 origin-top border-round pl-3 lg:pl-0">
          <ul class="list-none p-0 m-0">
            <li>
              <a v-ripple class="cursor-pointer h-full inline-flex align-items-center text-400 border-left-2 border-transparent hover:border-primary-300 transition-colors transition-duration-150 p-3 p-ripple">
                <span class="pi pi-cog mr-2"></span>
                <span class="font-medium" @click="loginQuickbooks">Login Quickbooks</span>
              </a>
            </li>
            <li>
              <a v-ripple class="cursor-pointer h-full inline-flex align-items-center text-400 border-left-2 border-transparent hover:border-primary-300 transition-colors transition-duration-150 p-3 p-ripple">
                <span class="pi pi-sign-out mr-2"></span>
                <span class="font-medium" @click="logout">Sign Out</span>
              </a>
            </li>
          </ul>
        </div>
      </li>
    </ul>
</template>
<script>
import MainCalendar from "@/components/events/calendar/main-calendar.vue";
import NewEventModal from "@/components/events/new/new-event-modal.vue";
import DayCalendarQuickView from "@/components/events/calendar/day-calendar-quick-view.vue";
import Config from "@/config";

export default {
  name: 'main-menu-user',
  components: {DayCalendarQuickView, NewEventModal, MainCalendar},
  methods: {
    newParty() {
      store.dispatch('Parties/Party/create');
    },
    async logout(){
      await api.url('/logout').post().res();
      localStorage.removeItem('token');
      location.reload();
    },
    loginQuickbooks(){
      window.open(`${Config.domain}/api/quickbooks/connect`, '_blank');
    }
  }
}
</script>

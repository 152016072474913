<template>
  <Button icon="pi pi-envelope" @click="showDialog = true" severity="secondary" text rounded title="Email Options" @close="resetForm"/>

  <Dialog v-model:visible="showDialog" :modal="true" :style="{width: '950px'}" position="top" :dismissable-mask="false" :draggable="false">

    <template #header>
      <div class="inline-flex align-items-center justify-content-evenly gap-2">
        <div>
          <h2 class="m-0">Send Email to {{ customerName }}</h2>
          <div>{{ to }}</div>
        </div>
      </div>
    </template>

    <section v-if="isLoading" class="h-20rem justify-content-evenly flex">
      <progress-spinner class="h-full"/>
    </section>
    <section v-else class="fadein animation-duration-500 formgrid grid">
      <div class="field col-12">
        <label class="text-600 text-sm font-bold" for="subject">Template</label>
        <Dropdown
                class="w-full"
                input-class="text-base text-color surface-overlay p-2 border-round appearance-none outline-none focus:border-primary"
                id="template" v-model="selection" :options="templates" optionLabel="name" @change="onTemplateChange"/>
      </div>

      <div class="field col-12" v-if="isEditing">
        <label class="text-600 text-sm font-bold" for="subject">Subject</label>
        <InputText id="subject" v-model="subject"
                   class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"/>
      </div>

      <div class="field col-12" v-if="is('payments') && hasTransactions">
        <label class="text-600 text-sm font-bold" for="subject">Payments</label>
        <Dropdown
                class="w-full"
                input-class="text-base text-color surface-overlay p-2 border-round appearance-none outline-none focus:border-primary"
                id="template" v-model="transaction" :options="transactions">
          <template #value="{value}">
              <div>{{ value.submitTimeLocal }} - {{ value.transId }} - {{formatMoney(value.amount)}}</div>
          </template>
          <template #option="{option}">
            <div>{{ option.submitTimeLocal }} - {{ option.transId }} - {{formatMoney(option.amount)}}</div>
          </template>
        </Dropdown>
      </div>

      <div class="field col-12">
        <section v-if="!isEditing">
          <Panel :header="subject">
            <scroll-panel class="h-24rem" >
            <section ref="msg">
              <message-thank-you v-if="is('thanks')"></message-thank-you>
              <message-proposal v-else-if="is('proposal')"></message-proposal>
              <message-proposal-update v-else-if="is('update')"></message-proposal-update>
              <message-invoice v-else-if="is('invoice')"></message-invoice>
              <message-payment-received v-else-if="is('payments')" :transaction="transaction"></message-payment-received>
              <message-payment-due v-else-if="is('due')"></message-payment-due>
              <message-confirmation v-else-if="is('confirmation')"></message-confirmation>
            </section>
            </scroll-panel>
          </Panel>
        </section>
        <section v-else class="fadein animation-duration-1000">
          <Editor id="Editor" v-model="message" editorStyle="height: 320px" />
        </section>
      </div>
      <div class="flex justify-content-end w-full">
        <Button label="Cancel" icon="pi pi-times" @click="close" class="p-button-text" rounded/>
        <Button label="Send" icon="pi pi-check" @click="send" rounded />
      </div>
    </section>
  </Dialog>

</template>

<script>
import MessageThankYou from "@/components/events/messages/message-thank-you.vue";
import MessageProposal from "@/components/events/messages/message-proposal.vue";
import MessageInvoice from "@/components/events/messages/message-invoice.vue";
import MessagePaymentReceived from "@/components/events/messages/message-payment-received.vue";
import MessagePaymentDue from "@/components/events/messages/message-payment-due.vue";
import {last} from "lodash";
import {formatMoney} from "accounting-js";
import MessageProposalUpdate from "@/components/events/messages/message-proposal-update.vue";
import MessageConfirmation from "@/components/events/messages/message-confirmation.vue";

export default {
  components: {
    MessageConfirmation,
    MessageProposalUpdate,
    MessagePaymentDue,
    MessagePaymentReceived, MessageInvoice, MessageProposal, MessageThankYou
  },
  data() {
    return {
      editing: false,
      showDialog: false,
      subject: '',
      message: '',
      loading:false,
      tmpl: [
        {id:'proposal', name: 'Proposal', subject: 'Your Proposal'},
        {id:'update', name: 'Proposal Update', subject: 'Your Updated Proposal'},
        {id:'due', name: 'Payment Due', subject: 'Payment Due'},
        {id:'payments', name: 'Payment Received', subject: 'Payment Received'},
        {id:'invoice', name: 'Invoice', subject: 'Your Invoice'},
        {id:'confirmation', name: 'Confirmation', subject: 'Confirmation: See You Soon!'},
        {id:'thanks', name: 'Thank You', subject: 'Thank You!'},
        {id:'custom', name: 'custom'}
      ],
      transaction: null,
      selection: null
    };
  },
  mounted: function () {
    this.transaction = last(this.transactions);
    this.setTemplates();
    this.onTemplateChange();
  },
  computed: {
    party() {
      return store.get('Parties/Party/current');
    },
    transactions(){
      return this.party.transactions;
    },
    hasTransactions(){
      return this.transactions.length > 0;
    },
    customerName() {
      return this.party.customer.full_name;
    },
    to() {
      return this.party.customer.email;
    },
    isEditing() {
      return this.editing;
    },
    isLoading() {
      return this.loading;
    },
  },
  methods: {
    formatMoney,
    onTemplateChange() {
      this.editing = false;
      this.subject = this.selection.subject || '';

      if (this.selection.id === 'custom') {
        this.message = '';
        this.editing = true;
      }
    },

    is(id) {
      return this.selection.id === id;
    },

    edit() {
      this.message = this.$refs.msg.innerHTML;
      this.editing = true;
    },

    async send() {
      this.loading = true;
      if (!this.isEditing) {
        this.message = this.$refs.msg.innerHTML;
      }

      const email = {
        party_id: this.party.id,
        to: this.to,
        subject: this.subject,
        message: this.message
      }

      let response = await api.url('/mail/send').post(email).res();

      if (response.status === 200) {
        await store.dispatch('Parties/Party/reload');
        store.dispatch('Parties/reload');

        this.$toast.add({
          severity: 'success',
          summary: 'Email Sent',
          detail: 'Your email has been sent.',
          life: 3000
        });
      } else {
        this.$toast.add({
          severity: 'error',
          summary: 'Email Failed',
          detail: 'Your email failed to send.',
          life: 3000
        });
      }

      this.loading = false;
      this.showDialog = false;
      this.resetForm();
    },

    close() {
      this.showDialog = false;
      this.resetForm();
    },

    setTemplates(){
      this.templates = store.get('Interactions/formattedEmailTemplates', this.tmpl);
      this.selection = store.get('Interactions/nextEmailToSend', this.templates);
    },

    resetForm() {
      this.setTemplates();
      this.to = '';
      this.subject = '';
      this.message = '';
      this.editing = false;
    },
  }
};
</script>
<style lang="scss">
#editor .not-editable{
  display:none;
}
</style>

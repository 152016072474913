<template>
  <div class="col-12 md:col-6">
    <h2>Customer Info</h2>

    <div class="formgrid grid">
      <!--Customer Details Card-->
      <div class="col-12 pb-3 mb-5">
        <div class="border-1 border-300 border-round p-3">
          <customer-header :customer="customer"/>
        </div>
      </div>

      <!-- Event Phone -->
      <div class="field col-12 md:col-6">
        <label class="text-600 text-sm font-bold" for="phone2">Event Phone</label>
        <InputMask :class="{'border-green-200': dirty.phone2}" :auto-clear="false" mask="999-999-9999" id="phone2" v-model="phone2" @input="setDirty('phone2')" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" />
      </div>

      <!-- Occasion -->
      <div class="field col-12 md:col-6">
        <label class="text-600 text-sm font-bold" for="occasion">Occasion</label>
        <Dropdown :class="{'border-green-200': dirty.occasion}" id="occasion" v-model="occasion" @change="setDirty('occasion')" :options="occasionOptions" option-label="label" option-value="value" input-class="text-base text-color surface-overlay p-2 surface-border border-round appearance-none outline-none focus:border-primary w-full" class="w-full"/>
      </div>

      <!-- Guest of Honor -->
      <div class="field col-12 md:col-6">
        <label class="text-600 text-sm font-bold" for="for_whom">Guest of Honor</label>
        <InputText :class="{'border-green-200': dirty.for_whom}" v-model="for_whom" id="for_whom" @input="setDirty('for_whom')" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" />
      </div>

      <!-- Age -->
      <div class="field col-12 md:col-2">
        <label class="text-600 text-sm font-bold" for="age">Age</label>
        <InputText :class="{'border-green-200': dirty.age}" v-model="age" id="age" @input="setDirty('age')" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" />
      </div>

      <!-- Number of Kids/Guests -->
      <div class="field col-12 md:col-4">
        <label class="text-600 text-sm font-bold" for="guests"># Kids / Guests</label>
        <Dropdown :class="{'border-green-200': dirty.guests}" id="guests" v-model="guests" @change="setDirty('guests')" :options="guestOptions" option-label="label" option-value="value" input-class="text-base text-color surface-overlay p-2 surface-border border-round appearance-none outline-none focus:border-primary w-full"
                  class="w-full" />
      </div>

      <!-- Referral -->
      <div class="field col-12">
        <label class="text-600 text-sm font-bold" for="referral">How did you find us</label>
        <InputText :class="{'border-green-200': dirty.referral}" v-model="referral" id="referral" @input="setDirty('referral')" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" />
      </div>
    </div>
  </div>
</template>


<script>
import {sync} from 'vuex-pathify';
import config from '@/config';
import CustomerHeader from "@/components/customers/customer-header.vue";

export default {
  name: "customer-details",
  components: {CustomerHeader},
  data() {
    return {
      dirty: {},
    };
  },
  computed: {
    guestOptions(){return config.guestOptions;},
    occasionOptions(){return config.occasionOptions;},

    party() {
      return store.get('Parties/Party/current')
    },

    customer(){
      return this.party.customer;
    },

    ...sync([
      'Parties/Party/for_whom',
      'Parties/Party/age',
      'Parties/Party/guests',
      'Parties/Party/occasion',
      'Parties/Party/phone2',
      'Parties/Party/referral',
    ], null, false),
  },
  methods: {
    setDirty(fieldName) {
      this.dirty[fieldName] = true;
    },
  },
};
</script>


<style scoped lang="scss">

</style>

<script>
import Config from "@/config";
import {emailPartyDate} from "@/helpers/date.helper";
import EmailSnipitPartyLocation from "@/components/events/messages/snipits/email-snipit-party-location.vue";
import EmailSnipitDate from "@/components/events/messages/snipits/email-snipit-date.vue";
import EmailSnipitPartyInvoice from "@/components/events/messages/snipits/email-snipit-party-invoice.vue";
import EmailSnipitCharacters from "@/components/events/messages/snipits/email-snipit-characters.vue";
import EmailSnipitServices from "@/components/events/messages/snipits/email-snipit-services.vue";
import EmailSnipitMisc from "@/components/events/messages/snipits/email-snipit-misc.vue";
import EmailSnipitDivider from "@/components/events/messages/snipits/email-snipit-divider.vue";

export default {
  name: "message-invoice",
  components: {
    EmailSnipitDivider,
    EmailSnipitMisc,
    EmailSnipitServices,
    EmailSnipitCharacters, EmailSnipitPartyInvoice, EmailSnipitDate, EmailSnipitPartyLocation},
  computed: {
    customer() {
      return this.party.customer;
    },
    party() {
      return store.get('Parties/Party/current');
    },
    characterURL() {
      return `${Config.domain}/kids-party-characters`;
    },
    servicesURL() {
      return `${Config.domain}/party-addons`;
    },
    date() {
      return emailPartyDate(this.party.date);
    }
  }
}
</script>

<template>
  <h3>Hi, {{ customer.first }}</h3>

  <p>Thank you for booking your party with us.</p>
  <email-snipit-divider/>

  <email-snipit-date/>

  <email-snipit-party-location/>

  <p>
    <email-snipit-divider/>
  </p>

  <email-snipit-characters/>

  <email-snipit-services/>

  <email-snipit-misc/>

  <email-snipit-divider/>

  <email-snipit-party-invoice/>

</template>

<style scoped lang="scss">

</style>

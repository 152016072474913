<script>
import {emailPartyDate} from "@/helpers/date.helper";

export default {
  name: "email-snipit-date",
  computed: {
    party() {
      return store.get('Parties/Party/current');
    },
    date() {
      return emailPartyDate(this.party.date);
    }
  }
}
</script>

<template>
  <h3>Date</h3>
  <div>{{ date }}</div>
</template>

<style scoped lang="scss">

</style>

import {countBy, filter, flatMap, includes, map, orderBy, some, toPairs, uniqBy} from "lodash";

export function getEmployeesFromItems(items) {
  // Filter out items without a valid employee object
  const validItems = items.filter(item => item.employee && typeof item.employee === 'object' && 'id' in item.employee);

  const uniqueEmployees = uniqBy(validItems.map(item => item.employee), 'id');
  const employeeCounts = countBy(validItems, item => item.employee.id);

  return map(orderBy(toPairs(employeeCounts), 1, 'desc'), ([id, count]) => {
    const employee = uniqueEmployees.find(emp => emp.id.toString() === id);
    if (!employee) return null; // Skip if no matching employee is found

    const name = `${employee.fullName}`;
    return {
      id: employee.id,
      name: count > 1 ? `${name} (${count})` : name
    };
  }).filter(emp => emp); // Filter out null values
}

export function getEmployeesFromParties(parties) {
  const allEmployees = flatMap(parties, 'employees');
  const employeeCounts = countBy(allEmployees, 'id');
  const employeeMap = new Map(allEmployees.map(employee => [employee.id, employee]));

  let data =  map(orderBy(toPairs(employeeCounts), 1, 'desc'), ([id, count]) => {
    const employee = employeeMap.get(parseInt(id));
    const name = `${employee.fullName}`;
    return {
      id: employee.id,
      name: count > 1 ? `${name} (${count})` : name
    };
  });
  return data;
}

export function filterEmployees(selectedEmployeeIds, parties) {
  return filter(parties, party => {
    if(selectedEmployeeIds.includes(null) && party.employees.length === 0){return true;}
    return some(party.employees, employee => selectedEmployeeIds.includes(employee.id));
  });
}

export function filterEmployeesItems(selectedEmployeeIds, items) {
  return filter(items, item =>{
      if(selectedEmployeeIds.includes(null) && item.employee === null){return true;}
      return item.employee && includes(selectedEmployeeIds, item.employee.id)
    }
  );
}

export function filterStatus(selectedStatus, parties){
  return filter(parties, party =>{
    return includes(selectedStatus, party.status)
  });
}

export function filterByType(itemType, items) {
  if(itemType === 'items')
    return items
  else
    return filter(items, {'type': itemType});
}

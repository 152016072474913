<template>
  <div v-if="!editing">
    <progress-spinner v-if="loading" class="tiny-spinner"/>
    <div v-else class="cursor-pointer" @click.stop="edit">{{ formatMoney(data[field]) }}</div>
  </div>
  <div v-else>
    <InputNumber
            v-model.number="value"
            ref="num"
            inputId="currency-us"
            mode="currency"
            currency="USD"
            locale="en-US"
            @update:modelValue="update"
            :pt="{input: { root : { class: 'shadow-none border-none outline-none w-full p-0 m-0'}}}"
    />
  </div>
</template>

<script>
import {formatMoney} from "accounting-js";
import {each} from "lodash";

export default {
  name: "invoice-money-field",
  emits:["reload"],
  props: {
    data: Object,
    field: String,
  },

  data() {
    return {
      loading: false,
      editing: false,
      value: parseFloat(this.data[this.field]) || 0,
    }
  },

  computed: {
    hasChanged() {
      return parseFloat(this.value) !== parseFloat(this.data[this.field]);
    }
  },

  methods: {
    formatMoney,

    edit() {
      this.editing = true;
      this.selectText();
    },

    selectText() {
      this.$nextTick(() => {
        const inputElement = this.$refs.num?.$el.querySelector('input');
        if (inputElement) {
          inputElement.focus();
          inputElement.select();
        }
      });
    },

    async update(newValue) {

      this.value = parseFloat(newValue) || 0;
      this.editing = false;

      if (this.hasChanged && !this.loading) {
        try{
          this.loading = true;
          await store.dispatch('Scheduleables/editScheduleable', {...this.data, [this.field]: this.value});
          this.$emit('reload');
        }catch(e){
          this.value = parseFloat(this.data[this.field]) || 0;
          each(e.errors, (error) => {
            this.$toast.add({severity: 'error', summary: 'Error', detail: error[0], life: 3000});
          });
        }
        this.loading = false;
      }
    },
  },
}

</script>

<style scoped lang="scss">

</style>

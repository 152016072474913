<template>
  <Card>
    <DataTable :value="characters">
      <Column field="" header=""></Column>
      <Column field="name" header="Name"></Column>
      <Column field="category" header="Category"></Column>
      <Column header="Active">
        <template #body="{data}">
          <i v-if="data.active" class="pi pi-check" />
          <i v-else class="pi pi-times" />
        </template>
      </Column>
    </DataTable>
  </Card>
</template>

<script>
export default {
  name: "CharacterList",
  computed: {
    characters() {
      return store.get('Characters/list');
    }
  }
}
</script>

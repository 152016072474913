/**
 * Stores data in local storage with an expiration time.
 * @param {string} key The key under which to stores the data.
 * @param {any} data The data to be stored.
 * @param {number} expirationTimeInHours Number of hours after which the data should expire.
 */
export function setLStorage(key, data, expirationTimeInHours) {
  const now = new Date().getTime();
  const expirationTime = now + expirationTimeInHours * 60 * 60 * 1000; // Convert hours to milliseconds

  localStorage.setItem(key, JSON.stringify(data));
  localStorage.setItem(`${key}Expiration`, expirationTime);
}

/**
 * Retrieves data from local storage if available and not expired.
 * @param {string} key The key under which the data is stored in local storage.
 * @returns {any|null} The stored data or null if not available or expired.
 */
export function getLStorage(key) {
  const now = new Date().getTime();
  const storedData = localStorage.getItem(key);
  const expirationTime = localStorage.getItem(`${key}Expiration`);

  // Check if expirationTime is a valid number
  const isExpirationValid = expirationTime && !isNaN(expirationTime);

  if (storedData && isExpirationValid && now < Number(expirationTime)) {
    try {
      return JSON.parse(storedData);
    } catch (e) {
      console.error('Error parsing stored data:', e);
      return null; // Invalid JSON
    }
  } else {
    return null; // Data is not available, invalid, or is expired
  }
}

/**
 * Deletes data from local storage based on the provided key.
 * @param {string} key The key under which the data is stored in local storage.
 */
export function deleteLStorage(key) {
  localStorage.removeItem(key);
  localStorage.removeItem(`${key}Expiration`);
}

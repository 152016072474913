<template>
  <section v-if="loading" class="flex justify-content-center align-content-center h-30rem">
    <progress-spinner class="h-full"/>
  </section>
  <section v-else class="fadein animation-duration-500">
    <div class="flex justify-content-end flex-wrap">
      <div class="flex-1">
        <h1 class="text-600 text-4xl pb-0 mb-0">Booking</h1>
        <p>
          <span v-if="!selectedFilter && !staffFilter ">{{parties.length }} Parties | Total {{formatMoney(bookingTotal)}} | Collected {{formatMoney(bookingBalance)}} | Not Collected {{formatMoney(Math.abs(bookingRemainder))}}</span>
          <span v-if="selectedFilter">{{ selectedFilter }}</span>
          <span v-if="selectedFilter && staffFilter"> And </span>
          <span v-if="staffFilter">{{ staffFilter }}</span>
        </p>
      </div>
      <div class="flex justify-content-end">
        <SelectButton :options="filterOptions" option-value="label" v-model="selectedFilter" class="mr-3">
          <template #option="{option}">
            {{ option.label }} ({{ option.count }})
          </template>
        </SelectButton>
        <SelectButton :options="staffOptions" option-value="label" v-model="staffFilter" title="All items with start and end times have staff assigned.">
          <template #option="{option}">
           {{ option.label }} ({{ option.count }})
          </template>
        </SelectButton>
      </div>
    </div>
    <PartiesTable asc sort-field="updated_at" :parties="filteredParties" :key="`${selectedFilter}:${staffFilter}`" @update-status="updateStatus" :rows="50" show-money/>
  </section>
</template>

<script>
import PartiesTable from "@/components/events/tables/parties-table.vue";
import {filter, sumBy} from 'lodash';
import {formatMoney} from "accounting-js";

export default {
  name: "TabBooking",
  components: {
    PartiesTable,
  },
  data() {
    return {
      selectedFilter: null,
      staffFilter: null,
    }
  },
  computed: {
    loading(){
      return store.get('Parties/loading');
    },
    parties() {
      return store.get('Parties/booking');
    },
    bookingTotal(){
      return sumBy(this.parties, (party) => party.total);
    },
    bookingBalance(){
      let balance = sumBy(this.parties, (party) => party.balance);
      return this.bookingTotal - balance;
    },
    bookingRemainder(){
      return this.bookingTotal - this.bookingBalance;
    },
    filterOptions() {
      return [
        {label: 'Needs Payment', count: this.hasPayment.length},
        {label: 'Paid', count: this.paidInFull.length},
      ]
    },
    staffOptions() {
      return [
        {label: 'Needs Staff', count: this.needsStaff.length},
        {label: 'Staffed', count: this.fullyStaffed.length},
      ]
    },
    hasPayment() {
      return filter(this.parties, (party) => {
        return party.balance > 0;
      })
    },
    paidInFull() {
      return filter(this.parties, (party) => {
        return party.balance <= 0;
      })
    },
    needsStaff() {
      return filter(this.moneyFilter, {needsStaff:true});
    },
    fullyStaffed() {
      return filter(this.moneyFilter, {needsStaff:false});
    },
    moneyFilter() {
      let filter = this.parties;

      if (this.selectedFilter === 'Needs Payment') {
        filter = [...this.hasPayment];
      } else if (this.selectedFilter === 'Paid') {
        filter = [...this.paidInFull];
      }
      return filter;
    },
    employeeFilter() {
      let filter = this.moneyFilter;

      if (this.staffFilter === 'Needs Staff') {
        filter = [...this.needsStaff];
      } else if (this.staffFilter === 'Staffed') {
        filter = [...this.fullyStaffed];
      }

      return filter;
    },

    filteredParties() {
      return this.employeeFilter;
    }
  },
  methods: {
    formatMoney,
    updateStatus(payload) {
      store.dispatch("Parties/updateStatus", payload)
    },
  },
};
</script>

<style scoped>
</style>

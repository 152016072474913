<template>
  <div
    class="relative flex flex-column border-round border-1 surface-border mb-3 p-0 cursor-pointer transition-duration-150"
    :class="{ 'bg-green-400 text-white border-green-600 hover:shadow-3 hover:border-white': selected }"
  >

    <div v-if="selected" class="voided-label">Use Card</div>

    <div class="flex justify-content-end">
      <Button type="button" icon="pi pi-times" size="small" text rounded @click="removeCardOnFile"
              title="delete card" class="mr-2 mt-2" :class="{'text-white':selected}"></Button>
    </div>
    <div class="p-3 pt-0">
      <div class="flex justify-content-between align-items-baseline">
        <div class="inline-block text-lg lg:text-xl">**** **** **** {{ card.last_four }}</div>
        <div class="inline-block">
          <span class="inline-block">CVV :</span>
          <span class="inline-block text-right font-bold ml-1">{{ card.card_code }}</span>
        </div>
      </div>
      <div class="flex justify-content-between align-items-center mt-2">
        <span class="inline-block text-sm">Cardholder Name</span>
        <span class="inline-block text-sm text-right">Expiry Date</span>
      </div>

      <div class="flex justify-content-between align-items-center mt-1">
        <span class="inline-block font-bold">{{ card.name }}</span>
        <span class="inline-block font-bold">{{ expires }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import Bus from "@/bus";

export default {
  name: 'credit-card-on-file',
  props: ['card', 'selected'],
  computed: {
    expires() {
      return `${this.card.exp_month}/${this.card.exp_year}`;
    }
  },
  methods: {
    removeCardOnFile() {
      //confirm
      this.$confirm.require({
        title: 'Confirm Delete',
        message: 'Are you sure you want to delete this card?',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          await store.dispatch('Authorize/deleteCard', this.card.id);
          Bus.$emit('creditcard.update');
          this.$toast.add({severity: 'success', summary: 'Success', detail: 'Card deleted successfully.', life: 3000});
        }
      })
    },
  }
}
</script>
<style scoped>
.voided-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-30deg);
  color: white;
  padding: 10px 20px;
  font-size: 2.5rem;
  font-weight: bold;
  border-radius: 5px;
  z-index: 1;
  pointer-events: none;
}
</style>

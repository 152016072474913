<template>
  <ContextMenu ref="cm" :model="items"/>
</template>
<script>
import Bus from '@/bus';
export default {
  name: "customer-parties-context-menu",
  data() {
    return {
      party: null,
      items: [
        {label: 'Clone', icon: 'pi pi-fw pi-copy', command: this.saveClone},
        {label: 'Delete', icon: 'pi pi-fw pi-times', command: this.deleteParty},
      ]
    }
  },
  mounted() {
    Bus.$on('customer.parties.contextmenu', this.showMenu);
  },
  unmounted() {
    Bus.$off('event.parties.contextmenu', this.showMenu);
  },
  methods: {

    showMenu({ event, data }) {
      this.$nextTick(() => {
        this.party = data;
        this.$refs.cm?.show(event);
      });
    },

    async saveClone(){
      store.commit('Parties/Party/current', this.party)
      store.commit('Parties/Party/loading', true);
      store.commit('Parties/cloning', true);
    },

    deleteParty(){

      this.$confirm.require({
        message: `Are you sure you want to delete this party? This cannot be undone.`,
        header: 'Delete Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: ()=> {
          store.dispatch('Parties/Party/deleteParty', this.party.id);
          store.dispatch('Customers/load');
        },
        reject: () => {
        }
      });
    }
  },
}
</script>

<style scoped lang="scss">

</style>

<template>

  <li>
    <a v-ripple @click="visible=true"
       class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-primary-400 hover:surface-hover font-medium border-round cursor-pointer transition-colors transition-duration-150">
      <i class="pi pi-calendar text-base lg:text-2xl mr-2 lg:mr-0"></i>
      <span class="block lg:hidden font-medium">Calendar</span>
    </a>
  </li>

  <Sidebar v-model:visible="visible" position="full" :showCloseIcon="false" :blockScroll="true" class="h-screen flex" @click="closeQuickView">
    <template #container>
      <section class="px-3">

        <div class="flex align-items-center justify-content-between w-full">

          <div class="w-2">
            <logo-company :height="90" class="mr-0 lg:mr-6 align-self-center"/>
          </div>

          <div class="h-4rem p-2 pb-3 line-height-1 w-9  justify-content-center flex">
            <calendar-view-filter/>

            <calendar-items-filter class="mx-2 w-12rem"/>

            <div class="w-4 relative">
                <calendar-navigation class="pt-2 line-height-2 cursor-pointer hover:text-primary hover:underline"/>
                <div class="text-sm money-color text-center">
                    {{ formatMoney(monthlyTotal) }}
                </div>
            </div>


            <calendar-employee-filter class="mx-2 w-12rem"/>

            <calendar-status-filter/>

            <div class="w-9rem"></div>
          </div>

          <div class="w-1 justify-content-end flex">
            <Button type="button" @click="updateGoogleCalendar" icon="pi pi-sync" text rounded title="Update Google Calendar Manually"></Button>
            <Button type="button" @click="visible=false" icon="pi pi-times" text rounded></Button>
          </div>
        </div>

        <section v-if="loading" class="flex justify-content-center align-content-center h-full w-full absolute bg-white-alpha-90 z-5">
          <progress-spinner class="h-full"/>
        </section>

        <section class="fadein animation-duration-500 pt-1 h-6">
          <FullCalendar :options="calendarOptions" :key="view" ref="fullCalendar" id="mainCalendar">
            <template #eventContent="e">
              <!-- Custom event rendering -->
              <div :title="`${e.event.title} (${e.event.extendedProps.status})`"
                   class="cursor-pointer text-overflow-ellipsis overflow-hidden whitespace-nowrap w-full px-1 custom-event"
                   @click.stop="onEventClick(e)"
              >
                {{ e.timeText }} {{ e.event.title }}
              </div>
              <!-- You can add more custom HTML or components here -->
            </template>
          </FullCalendar>
        </section>

      </section>
    </template>
  </Sidebar>

</template>

<script>
/* eslint-disable */
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import multiMonthPlugin from '@fullcalendar/multimonth'
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list';

import {getDBTime} from "@/helpers/time.helper";

import LogoCompany from "@/components/common/logo-company.vue";

import Bus from "@/bus";

import CalendarItemsFilter from "@/components/events/calendar/calendar-header/calendar-items-filter.vue";
import CalendarStatusFilter from "@/components/events/calendar/calendar-header/calendar-status-filter.vue";
import CalendarNavigation from "@/components/events/calendar/calendar-header/calendar-navigation.vue";
import CalendarEmployeeFilter from "@/components/events/calendar/calendar-header/calendar-employee-filter.vue";
import CalendarViewFilter from "@/components/events/calendar/calendar-header/calendar-view-filter.vue";
import dayjs from "dayjs";
import {formatMoney} from "accounting-js";

export default {
  name: 'main-calendar',

  components: {
    CalendarViewFilter, CalendarEmployeeFilter, CalendarNavigation, CalendarStatusFilter, CalendarItemsFilter,
    LogoCompany,
    FullCalendar
  },

  mounted() {
    Bus.$on('calendar.date', this.goToDate);
  },

  beforeUnmount() {
    Bus.$off('calendar.date', this.goToDate);
  },

  data() {
    return {
      visible: false
    };
  },

  computed: {

    currentDate() {
      return store.get('Calendar/currentDate');
    },
    isPartyItems() {
      return store.get('Calendar/isPartyItems');
    },

    view() {
      return store.get('Calendar/view');
    },

    loading() {
      return store.get('Calendar/loading');
    },

    events() {
      return store.get('Calendar/events');
    },

    monthlyTotal() {
      const currentMonth = dayjs(this.currentDate).month();
      const currentYear = dayjs(this.currentDate).year();

      return this.events
          .filter(event => {
              const eventDate = dayjs(event.start);
              return eventDate.month() === currentMonth && eventDate.year() === currentYear;
          })
          .reduce((sum, event) => sum + (event.amount || 0), 0);
    },

    calendarOptions() {
      return {
        plugins: [dayGridPlugin, timeGridPlugin, listPlugin, multiMonthPlugin, interactionPlugin],
        headerToolbar: false,

        selectable: true,
        height: '90vh',

        eventClick: this.onEventClick,
        dateClick: this.onDateClick,
        datesSet: this.onDatesSet,

        dayCellContent: (date) => {
          const dayEvents = this.events.filter(event => {
            const eventDateStr = dayjs(event.start).format('YYYY-MM-DD');
            const dateStr = dayjs(date.date).format('YYYY-MM-DD');
            return eventDateStr === dateStr;
          });

          console.log(dayEvents);
          const totalAmount = dayEvents.reduce((sum, current) => sum + current.amount, 0);

          return {
            html: `
              <div style="display: flex; justify-content: space-between; align-items: center; width: 100%;">
                <div>${dayjs(date.date).format('DD')}</div>
                  ${
                    totalAmount > 0
                      ? `<div style="font-size: 0.8em; margin-left: 4px; color: #67b944;">${formatMoney(totalAmount)}</div>`
                      : ''
                  }
              </div>
  `
          };

        },

        eventDidMount: (info) => {
          const status = info.event.extendedProps.status;
          info.el.classList.add('event-container');
          info.el.classList.add(status);

          const token = info.event.extendedProps.token;
          info.el.classList.add(`E${token}`);
          info.el.addEventListener('mouseenter', () => this.highlightPartyEvents(token));
          info.el.addEventListener('mouseleave', () => this.removeHighlightPartyEvents(token));
        },

        initialView: this.view,
        events: this.events,
        initialDate: this.currentDate,
      }
    },

  },

  methods: {

    formatMoney,

    async updateGoogleCalendar(event) {
      const button = event.currentTarget;
      button.classList.add('rotating');
      try {
        await store.dispatch('Calendar/updateGoogleCalendar');
        this.$toast.add({ severity: 'success', summary: 'Google Calendar', detail: 'Google Calendar has been updated successfully.', life: 5000 });
      } catch (e) {
        this.$toast.add({ severity: 'error', summary: 'Google Calendar', detail: 'Google Calendar has not been updated successfully.', life: 5000 });
      } finally {
        button.classList.remove('rotating');
      }
    },

    highlightPartyEvents(token) {
      document.querySelectorAll(`.E${token}`).forEach(el => {
        el.classList.add('highlight');
      });
    },

    removeHighlightPartyEvents(token) {
      document.querySelectorAll(`.E${token}`).forEach(el => {
        el.classList.remove('highlight');
      });
    },

    closeQuickView() {
      store.commit('Parties/Party/view', false);
    },

    async onDatesSet(info) {
      store.commit('Calendar/payload', {start: getDBTime(info.start), end: getDBTime(info.end)});
      await store.dispatch('Calendar/load');
    },

    onDateClick(info) {
      store.commit('DayCalendar/showDay', true);
      store.commit('DayCalendar/currentDate', info.date);
      store.commit('Calendar/currentDate', info.date);
    },

    async onEventClick({event}) {
      await store.dispatch('Parties/Party/load', event.extendedProps.token);
      store.commit('Parties/Party/view', true);
    },

    calendar() {
      return this.$refs.fullCalendar.getApi();
    },

    goToToday() {
      this.calendar().today();
    },

    goToDate() {
      this.calendar().gotoDate(this.currentDate);
    }
  }
};
</script>

<style lang="scss">

.money-color{
    color: #67b944 !important;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotating {
  animation: rotate 1s linear infinite;
}

.fc .fc-daygrid-day-number {
  width: 100%;
}
</style>

import {each, flatMap, groupBy, isEmpty, map, some, sumBy, filter} from "lodash";
import {make} from 'vuex-pathify'
import Bus from '@/bus';
import dayjs from "dayjs";
import {statusOptions} from "@/helpers/status.helper";
import Party from "./party.store";

Bus.$on('authorize.payment-success', () => {
  store.dispatch('Parties/loadParties')
});

const state = {
  working: [],
  inquiry: [],
  booking: [],
  complete: [],
  serviced: [],
  cancelled: [],
  credit: [],

  loading: false,
  cloning: false,
  showDeleted:false,

  pay: [],
  selectedStatus: null,
  statusOptions: statusOptions,
  sidebarVisible: false,
  selected: null,
  globalFilter: null
};

const getters = {
  ...make.getters(state),

  pastDue(state) {
    let today = dayjs().startOf('day');

    return state.working.filter(party => {
      return dayjs(party.date).isBefore(today) && (party.status === 'booking' || party.status === 'inquiry');
    });
  },

}

const mutations = {
  ...make.mutations(state),

  sidebarVisible(state, val) {
    if (state.sidebarVisible !== val)
      state.sidebarVisible = val;
  }
}

const actions = {

  ...make.actions(state),

  async reload({dispatch}){
    await dispatch('loadParties');
  },

  async loadParties({commit}) {
    commit('loading', true);
    let parties = await api.url(`/events/status/working`).get().json();

    each(parties, (party) => {
      party.paymentTotal = getTotalPayments(party);
      party.total = getPartyTotal(party);
      party.balance = party.total - party.paymentTotal;
      party.needsStaff = needsStaff(party);
      party.paid = party.balance === 0;
    });

    commit('working', parties);
    commit('inquiry', filter(parties, {status: 'inquiry'}));
    commit('booking', filter(parties, {status: 'booking'}));
    commit('complete', filter(parties, {status: 'complete'}));
    commit('serviced', filter(parties, {status: 'serviced'}));
    commit('cancelled', filter(parties, {status: 'cancelled'}));
    commit('credit', filter(parties, {status: 'credit'}));
    commit('loading', false);
  },

  loadPartiesForPay({commit}) {
    return api
      .url(`/events/done/employee/scheduleables`)
      .get()
      .json()
      .then((data) => {

        const scheduleables = flatMap(data, 'scheduleables');
        const groupedByEmployee = groupBy(scheduleables, 'employee.id');

        const results = map(groupedByEmployee, (scheduleables) => {
          const employee = scheduleables[0].employee;
          return {
            ...employee,
            scheduleables: scheduleables,
          };
        });

        commit('pay', results);
      })
  },

  async updateStatus({dispatch}, payload) {

    await api.url('/events/status/update').post(payload).res();

    dispatch('reload');
  },

  async delete({dispatch}, ids) {
    await Promise.all(ids.map(id =>
      api.url(`/events/${id}`).delete().res()
    ));

    await dispatch('reload');
  },

  async undelete({dispatch}, ids) {
    await api.url(`/events/undelete`).put({ids:ids}).res();
    await dispatch('reload');
  },


  async archive({dispatch}, ids) {
    await Promise.all(ids.map(id =>
      api.url(`/events/${id}/archive`).put().res()
    ));

    await dispatch('reload');
  },

  async remove({dispatch}, ids) {
      await api.url(`/events/remove`).put({ids:ids}).res();
      await dispatch('reload');
  }
};

function getTotalPayments(party) {
  return party.transactions.reduce((total, transaction) => {
    let amount = parseFloat(transaction.amount) || 0;
    return transaction.status !== 'declined' ? total +  amount : total;
  }, 0);
}

function getPartyTotal(party) {
  return sumBy(party.scheduleables, (item) => parseFloat(item.price) || 0)
}

function needsStaff(party) {
  return some(party.scheduleables, (gig) => {
    return !isEmpty(gig.start_time) && isEmpty(gig.employee)
  });
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    Party
  }
};

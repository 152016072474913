<template>
  <Card class="text-sm position-relative shadow-none"
        :class="{'mt-3 border-1 border-200 z-1 shadow-3': !hasContainer }"
        :pt="{ content: { class: 'p-0' }, body: { class: 'p-3' } }">
    <template #content>

      <div v-if="isVoided" class="voided-label">Voided</div>
      <div v-if="isDeclined" class="voided-label">Declined</div>
      <div v-if="hasRefunds" class="voided-label">Refunded</div>

      <div class="flex justify-content-between">
        <div class="flex align-self-center">
          <icon-quickbooks v-if="transaction.quickbooks_id" size="20" class="mr-2 mt-1 cursor-pointer" @click="goToQuickbooksPayment(transaction.quickbooks_id)"></icon-quickbooks>
          <h2 class="my-1 text-lg line-height-1">
            {{ capitalize(transaction.accountType) }} Transaction</h2>
        </div>
        <div class="flex justify-content-end">
          <div>
            <div>Date: {{ formatDateTime(transaction.submitTimeLocal) }}</div>
            <div v-if="isCreditCard"
                 class="text-primary hover:underline cursor-pointer"
                 @click="viewTransaction"
                 title="View Transaction On Authorize.net">ID: {{ transaction.transId }}
            </div>
            <div v-else>ID: {{ transaction.transId }}</div>
          </div>
          <transaction-card-menu :transaction="transaction"></transaction-card-menu>
        </div>
      </div>
      <div class="flex justify-content-between">
        <div>
          <h4>Customer: {{ transaction.firstName }} {{ transaction.lastName }}</h4>
          <div>Transaction Status: {{ transaction.transactionStatus }}</div>
          <div>Card: {{ transaction.accountType }} {{ transaction.accountNumber }}</div>
          <div :class="{'text-red-500': transaction.invoice !== party.token}">Invoice: {{ transaction.invoice }}</div>
        </div>
        <div>
          <div class="pr-3 text-right" v-if="hasRefunds">
            <h4 class="pr-5 mb-0">
              Amount: {{ formatMoney(transaction.amount) }}
            </h4>

            <div class="pr-5 mt-0">
              Refunded: {{ formatMoney(amountRefunded) }}
            </div>
            <div class="pr-5 mt-0">
              Remaining: {{ formatMoney(transaction.amount + amountRefunded) }}
            </div>
          </div>
          <div class="pr-3 text-right" v-else>
            <h4 class="pr-5">
              Amount: {{ formatMoney(transaction.amount) }}
              <span class="strike-through text-500 font-normal" v-if="transaction.voided_amount"
                    title="Voided Amount">{{ formatMoney(transaction.voided_amount) }} </span>
            </h4>
          </div>
        </div>
      </div>
    </template>


  </Card>
  <Card v-if="isDeclined"
        class="text-sm position-relative text-white bg-red-400 ml-3 -mt-1 z-0 border-1 shadow-none border-round-top-none"
        :pt="{ content: { class: 'p-0' }, body: { class: 'p-3' } }">
    <template #content>
      <div class="font-bold">Transaction has been declined. Contact the customer and fix credit card issue.</div>
    </template>
  </Card>
  <Card v-if="isFraud"
        class="text-sm position-relative text-white bg-orange-400 ml-3 -mt-1 z-0 border-1 shadow-none border-round-top-none"
        :pt="{ content: { class: 'p-0' }, body: { class: 'p-3' } }">
    <template #content>
      <div class="font-bold">Transaction may be fraud. Contact your system admin to review. Payment has not been collected.</div>
    </template>
  </Card>

  <transaction-refund-card v-for="refund in relatedRefunds" :transaction="refund" :key="refund.transId"></transaction-refund-card>
</template>

<script>
import {formatMoney} from 'accounting-js';
import {formatDateTime} from '@/helpers/date.helper';
import {capitalize} from 'lodash';
import {goToAuthorizeTransaction, goToQuickbooksPayment} from "@/helpers/link.helper";
import {isCreditCard, isDeclined, isFraud, isRefund, isVoided} from "@/helpers/transaction.helper";

import TransactionCardMenu from "@/components/events/edit/invoice/transaction-card-menu.vue";
import IconQuickbooks from "@/components/common/icon-quickbooks.vue";
import TransactionRefundCard from "@/components/events/edit/invoice/transaction-refund-card.vue";

export default {
  name: 'transaction-card',
  components: {TransactionRefundCard, IconQuickbooks, TransactionCardMenu},
  props: {
    transaction: {
      type: Object,
      required: true
    },
    hasContainer: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    party() {
      return this.transaction.party? this.transaction.party : store.get('Parties/Party/current');
    },
    customer() {
      return this.party ? this.party.customer : null;
    },
    transactions() {
      return this.party ? this.party.transactions : [];
    },
    isCreditCard() {
      return isCreditCard(this.transaction);
    },
    isRefund() {
      return isRefund(this.transaction);
    },
    isVoided() {
      return isVoided(this.transaction);
    },
    isDeclined() {
      return isDeclined(this.transaction);
    },
    isFraud() {
      return isFraud(this.transaction);
    },
    relatedRefunds() {
      return this.transactions.reduce((acc, trans) => {
        if (trans.refTransId === this.transaction.transId) {
          acc.push(trans);
        }
        return acc;
      }, []);
    },
    hasRefunds() {
      return this.relatedRefunds.length > 0;
    },
    amountRefunded() {
      return this.relatedRefunds.reduce((total, refund) => {
        return total + parseFloat(refund.amount);
      }, 0);
    },
    total() {
      return store.get('Parties/Party/total');
    },
    balance() {
      return store.get('Parties/Party/balance')
    },
    isLoading() {
      return store.get('Banking/loading');
    }
  },
  methods: {
    capitalize,
    formatMoney,
    formatDateTime,
    goToQuickbooksPayment,

    viewTransaction() {
      goToAuthorizeTransaction(this.transaction.transId);
    },
  },
};
</script>

<style scoped>
.position-relative {
  position: relative;
}

.voided-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-30deg);
  color: #ebebeb;
  padding: 10px 20px;
  font-size: 3rem;
  font-weight: bold;
  border-radius: 5px;
  z-index: 1;
  pointer-events: none;
}
</style>

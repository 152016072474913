<template>
  <section class="text-600">

    <div class="mt-3 mb-2 flex justify-content-between">
        <logo-company/>
    </div>

    <div class="flex justify-content-between">
      <div class="w-6">
        <h2>Event Date</h2>
        <div><strong>Date of Event:</strong> {{ partyDateShort(party.date) }}</div>
        <div><strong>Start Time:</strong> {{ formatTime(party.date) }}</div>
        <div><strong>End Time:</strong> {{ formatTime(endTime) }} ({{ party.duration }}hrs)</div>
      </div>
      <div class="w-6">
        <h2>Location</h2>
        <div v-if="party.venue"><strong>Venue:</strong> {{ party.venue }}</div>
        <div><strong>Address:</strong> {{ party.address }}</div>
        <div><strong>City:</strong> {{ party.city }}, Ca {{ party.zip }}</div>
      </div>
    </div>

    <div class="flex justify-content-between">
      <div class="w-6">
        <h2>Customer</h2>
        <div v-if="customer.company"><strong>Company:</strong> {{ customer.company }}</div>
        <div><strong>Customer Name:</strong> {{ customer.full_name }}</div>
        <div><strong>Cell Phone:</strong> {{ customer.phone }}</div>
        <div v-if="party.phone2"><strong>Event Phone:</strong> {{ party.phone2 }}</div>
      </div>
      <div class="w-6">
        <h2>Party Details</h2>
        <div><strong>Occasion:</strong> {{ party.occasion }}</div>
        <div><strong>Guest of Honor:</strong> {{ party.for_whom }}</div>
        <div><strong>Age:</strong> {{ party.age }}</div>
        <div><strong>No. of Kids/Guests:</strong> {{ party.guests }}</div>
      </div>
    </div>

    <div>
      <div v-if="characters.length">
        <h3>Characters:</h3>
        <print-dispatch-line-item v-for="item in characters" :key="item.id" :item="item" :name="item.character.name"/>
      </div>
      <div v-if="services.length">
        <h3>Services:</h3>
        <print-dispatch-line-item v-for="item in services" :key="item.id"  :item="item" :name="item.addon.title"/>
      </div>
      <div v-if="misc.length">
        <h3>Misc:</h3>
        <print-dispatch-line-item v-for="item in misc" :key="item.id"  :item="item" :name="item.misc"/>
      </div>
    </div>

    <div v-if="party.notes">
      <h2>Notes</h2>
      <div class="notes">{{ party.notes }}</div>
    </div>

  </section>
</template>

<script>
import LogoCompany from "@/components/common/logo-company.vue";
import Config from "@/config";
import IconQuickbooks from "@/components/common/icon-quickbooks.vue";
import {formatMoney} from "accounting-js";
import {formatTime} from "@/helpers/time.helper";
import {filter} from 'lodash';
import {formatDateTime, partyDateShort} from "@/helpers/date.helper";
import PrintDispatchLineItem from "@/components/events/quick-view/modals/print/print-dispatch-line-item.vue";

export default {
  name: "prints-dispatch",
  components: {PrintDispatchLineItem, IconQuickbooks, LogoCompany},
  data() {
    return {
      phoneNumber: Config.phoneNumber,
    };
  },
  computed: {
    party() {
      return store.get('Parties/Party/current');
    },
    endTime(){
      return store.get('Parties/Party/endTime');
    },
    customer() {
      return this.party.customer;
    },
    characters() {
      return store.get('Scheduleables/characters');
    },
    services() {
      return store.get('Scheduleables/services');
    },
    misc() {
      let items = store.get('Scheduleables/misc');
      return filter(items, item => item.start_time !== null);
    },
    total() {
      return store.get('Scheduleables/total');
    },
    transactions() {
      return this.party.transactions;
    },
  },
  methods: {formatDateTime, partyDateShort, formatTime, formatMoney}
}
</script>

<style scoped lang="scss">

</style>

import {make} from 'vuex-pathify';
import api from '@/config.wretch';
import Bill from './bill.store';

const state = {
  loading: false,
  edit: false,
  add: false,
  clone: false,
  reloadKey: 0,

  params: {},
  unpaidOnly: true,

  list: [],
  current: null
};

const getters = {
  ...make.getters(state),
};

const mutations = {
  ...make.mutations(state),

  current(state, val) {
    state.current = val;
    store.dispatch('Bills/Bill/set', state.current);
  }
};

const actions = {
  async load({commit}, params) {
    commit('loading', true);
    commit('params', params);
    let result = await api.url(`/bills`).query({...params, unpaid_only: state.unpaidOnly}).get().json();
    commit('list', result.data);
    commit('loading', false);
    return result;
  },

  async loadBill({commit}, id) {
    let result = await api.url(`/bills/${id}`).get().json();
    commit('current', result);
  },

  async reload({dispatch, state}) {
    await dispatch('load', state.params);
  },

  async create({dispatch, commit}, payload) {

    let bill = await api.url(`/bills`).post(payload).json();
    commit('current', bill);
    dispatch('reload');

  },

  async delete({dispatch}, {id}) {
    await api.url(`/bills/${id}`).delete().res();
    dispatch('reload');
  },

  async writeCheck({commit, dispatch}, {id}) {
    commit('loading', true);
    try {
      await api.url(`/bills/pay/${id}`).post().json();
      await dispatch('load');
    } finally {
      commit('loading', false);
    }
  },

  async removeCheck({commit, dispatch}, {id}) {
    commit('loading', true);
    try {
      await api.url(`/bills/unpay/${id}`).delete().res();
      await dispatch('load');
    } finally {
      commit('loading', false);
    }
  },

  async getBillById({commit}, id) {
    commit('loading', true);
    try {
      const bill = await api.url(`/bills/${id}`).get().json();
      commit('current', bill);
      commit('edit', true);
    } catch (error) {
      console.error('Error getting bill by ID:', error);
    } finally {
      commit('loading', false);
    }
  },

  async getUnpaidEmployees() {
    return await api.url('/bills/unpaid-employees').get().json();
  },

  async markPaid({dispatch}, {id}) {
    try {
      await api.url(`/bills/${id}/paid`).put().res();
      dispatch('reload');
    } catch (error) {
      console.error('Error marking paid:', error);
    }
  },

  async markUnpaid({dispatch}, {id}) {
    try {
      await api.url(`/bills/${id}/unpaid`).put().res();
      dispatch('reload');
    } catch (error) {
      console.error('Error marking unpaid:', error);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules:{
    Bill
  }
};

<template>
    <Avatar :image="src" size="" shape="circle" />
</template>

<script>
export default {
  name: "employee-avatar",
  props:['id','img'],
  computed: {
    employee(){
        return (this.id) ? store.get('Employees/getEmployeeById', this.id) : undefined;
    },
    src() {
      let img = this.employee?.img || this.img;
      return `http://127.0.0.1:8000/assets/images/staff/${img}`;
    }
  },
  methods: {
    openEmployeeSidebar() {
      store.commit('Employees/employeeSidebarVisible', true);
    }
  },
}
</script>

<style scoped lang="scss">

</style>

import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);
dayjs.extend(utc);

export function calculateAgeing(createdDate) {
  const created = dayjs(createdDate);
  const now = dayjs();
  const diffMinutes = now.diff(created, 'minute');

  // Determine the output based on the length of the duration
  if (diffMinutes < 60) {
    return `${diffMinutes}m`;
  } else if (diffMinutes < 1440) { // Less than 24 hours, show in hours
    const diffHours = now.diff(created, 'hour');
    return `${diffHours}hr`;
  } else { // More than 24 hours, show in days
    const diffDays = now.diff(created, 'day');
    return `${diffDays}d`;
  }
}


export function daysUntilEvent(date) {
  const due = dayjs(date);
  const now = dayjs();
  return due.diff(now, 'day');
}

export function hoursUntilEvent(date) {
  const due = dayjs(date);
  const now = dayjs();
  return due.diff(now, 'hours');
}

export function isSamePrice(price1,price2){
  return parseFloat(price1) === parseFloat(price2);
}

export function ageingClass(createdDate) {
  const days = calculateAgeing(createdDate);
  if (days > 7) {
    return 'text-red-500';
  } else if (days > 3) {
    return 'text-orange-400';
  }
  return '';
}

export function calculateTimeOptions() {
  let timeOptions = [];

  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 15) {
      const date = dayjs.utc(new Date(Date.UTC(1970, 0, 1, hour, minute)));
      const label = date.format('h:mm A');
      const value = date.format('HH:mm');
      timeOptions.push({label, value});
    }
  }

  return timeOptions;
}
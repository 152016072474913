<template>
  <ContextMenu ref="cm" :model="items"/>
</template>

<script>
import Bus from '@/bus';
import dayjs from "dayjs";

export default {
  name: "qv-services-context-menus",

  data() {
    return {
      items: null,
      selectedRow: null,
      standard: [
        {label: 'Gig Request Msg', icon: 'pi pi-fw pi-megaphone', command: this.requestEmployee},
      ]
    }
  },

  computed: {
    startTime() {
      return store.get('Parties/Party/startDateTime');
    },
    endTime() {
      return store.get('Parties/Party/endTime');
    },
    duration(){
      return dayjs(this.endTime).diff(this.startTime, 'hours');
    },
    party() {
      return store.get('Parties/Party/current');
    },
    address() {
      return `${this.party.city}, Ca ${this.party.zip}`;
    }
  },

  mounted() {
    Bus.$on('event.qv.services.contextmenu', this.showMenu);
  },

  unmounted() {
    Bus.$off('event.qv.services.contextmenu', this.showMenu);
  },

  methods: {

    showMenu({event, data, field}) {
      this.$nextTick(() => {
        this.selectedRow = data;
        this.items = this.getItems(field);
        this.$refs.cm.show(event);
      });
    },

    getItems(field) {
      switch (field) {
        default:
          return this.standard;
      }
    },

    async requestEmployee() {

      try {
        let gig = `We have a ${this.selectedRow.addon.title} Gig for you in ${this.address} on ${dayjs(this.startTime).format('MM/DD/YYYY')} from ${dayjs(this.startTime).format('hh:mm A')} to ${dayjs(this.endTime).format('hh:mm A')}. Please let me know if you are available. Thank you!`;

        await navigator.clipboard.writeText(gig);
        this.$toast.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Gig Request Message Copied to Clipboard Paste to Text Message',
          life: 3000
        });
      } catch (e) {
        console.error(e);
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Gig Request failed to copy to clipboard',
          life: 3000
        });
      }
    }
  },
}
</script>

<style scoped lang="scss">

</style>

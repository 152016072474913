<template>
  <div>
    <p>Search for a customer by {{ lastName ? 'Last name' : 'Display Name' }} in QuickBooks and associate the
      quickbooks customer with {{ current.full_name }}.</p>

    <InputGroup class="mb-5">
      <Button
        label="Display"
        :class="{'p-button-primary': !lastName, 'p-button-outlined p-button-secondary': lastName}"
        @click="setDisplayName"
      />
      <Button
        label="Last"
        :class="{'p-button-primary': lastName, 'p-button-outlined p-button-secondary': !lastName}"
        @click="lastName=true; term=current.last"
      />
      <InputText
        type="text"
        v-model.trim="term" :placeholder="`Search by ${lastName?'Last name': 'Display Name'}...`"
        text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round
        appearance-none outline-none focus:border-primary w-full
      />
      <Button @click="search" outlined>Search</Button>
    </InputGroup>

    <div v-if="noResults">
      <h3>No Results for {{ term }}</h3>
    </div>

    <progress-spinner v-if="loading"></progress-spinner>
    <div v-else class="fadein animation-duration-500">
      <scroll-panel style="max-height: 65vh">
        <customer-quickbooks-item v-for="customer in qboCustomers" :customer="customer" :key="customer.Id" class="mb-3"/>
      </scroll-panel>
    </div>
  </div>
</template>

<script>
import CustomerQuickbooksItem from "./customer-quickbooks-item.vue";

export default {
  name: "customer-quickbooks-search",
  components: {CustomerQuickbooksItem},
  data() {
    return {
      qboCustomers: [],
      term: '',
      lastName: false,
      loading: false
    };
  },
  computed: {
    current() {
      return store.get('Customers/current');
    },
    noResults() {
      return !this.qboCustomers.length && !this.loading
    }
  },
  methods: {

    async search() {
      this.loading = true;
      this.qboCustomers = await api.url(`/quickbooks/customer/${this.lastName ? 'last-name' : 'display-name'}/${this.term}`).get().json();
      this.loading = false;
    },

    setDisplayName() {
      this.lastName = false;
      this.term = this.current.quickbooks_display_name ? this.current.quickbooks_display_name : this.current.full_name;
    }
  }
}
</script>

<style scoped lang="scss">

</style>

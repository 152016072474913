<template>
  <Button
    @click="open"
    icon="pi pi-plus"
    class="ml-3 w-11rem"
    size="small"
    label="New"
    type="button"/>
  <Dialog  v-model:visible="visible" modal class="h-auto surface-overlay" style="width:800px" position="top">
    <template #container>
      <section class="p-5">

        <div class="flex justify-content-between mb-5">
          <h2 class="text-600 m-1">Add New Customer</h2>
          <div>
            <Button icon="pi pi-save" rounded @click="save" size="small" text severity="secondary"/>
            <Button type="button" icon="pi pi-times" class="ml-2 align-self-start" size="small" text rounded @click="close" severity="secondary"></Button>
          </div>
        </div>
        <customer-edit @save="save"/>
      </section>
    </template>
  </Dialog>
</template>

<script>
import CustomerEdit from "@/components/customers/customer-edit.vue";
import {sync} from 'vuex-pathify';
import Bus from "@/bus";
import {toastErrors} from "@/helpers/errors.helper";

export default {
  name: "customer-add",
  components: {CustomerEdit},
  computed: {
    visible: sync('Customers/add', null, false),
    loading: sync('Customers/loading', null, false)
  },
  methods: {

    close() {
      this.visible = false;
    },

    async open() {
      await store.dispatch("Customers/Customer/reset");
      await store.commit("Customers/view", false);
      this.visible = true;
    },

    async save() {
      this.loading = true;

      try {
        await store.dispatch("Customers/Customer/create")
        Bus.$emit("customers.update");
        this.visible = false;

        this.$toast.add({severity: 'success', summary: 'Success', detail: 'Customer Saved', life: 3000});
      } catch (e) {
        if (e.json && e.response.status === 409) {
          this.previousUserFix(e);
        } else {
          toastErrors(this.$toast, e);
        }
      } finally {
        this.loading = false;
      }
    },

    previousUserFix(e) {
      this.$confirm.require({
        message: `A customer with this email already exists and is soft deleted. Delete the previous user and Save New or Reinstate the previous user?`,
        header: 'Cannot Create New Customer',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Reinstate Previous Customer',
        rejectLabel: 'Delete Previous Customer and Save New',
        accept: async () => {
          try {
            await store.dispatch('Customers/reinstate', e.json.customer_id);
            this.$toast.add({severity: 'success', summary: 'Success', detail: 'Customer reinstated', life: 3000});
            this.visible = false;
            await store.dispatch('Customers/Customer/reload');
            await store.commit('Customers/view', true);
          } catch (error) {
            this.$toast.add({severity: 'error', summary: 'Error', detail: error.message, life: 5000});
          }
        },
        reject: async () => {
          try {
            await store.dispatch('Customers/permanentDelete', e.json.customer_id);
            this.$toast.add({severity: 'success', summary: 'Success', detail: 'Customer deleted', life: 3000});
            await this.save();
          } catch (error) {
            this.$toast.add({severity: 'error', summary: 'Error', detail: error.message, life: 5000});
          }
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">

</style>

<template>
  <section>
    <div @click="showMap=!showMap" v-if="!onlyMap && address" class="text-primary underline cursor-pointer hover:no-underline text-xl mb-2" title="click to view map">
      <strong><i class="pi pi-map pr-2"></i>{{ address }}, {{ city }}, Ca {{ zip }}</strong>
    </div>

    <div v-if="showMap" :style="{height:height}" class="relative w-full">

      <section v-if="loading" class="flex justify-content-center align-content-center h-30rem w-full absolute z-2 bg-white">
        <progress-spinner class="h-full"/>
      </section>

      <GMapMap class="map"
               :center="center"
               :zoom="zoomLevel"
               map-type-id="roadmap"
               :options="mapOptions"
               style="width: 100%; height: 100%;"
               @tilesloaded="handleTilesLoaded"
      >
        <GMapMarker :position="center">
          <GMapInfoWindow v-if="address">
            <div>
              <strong>{{ address }}, {{ city }}, {{ zip }}</strong>
              <div><a :href="directionsLink" target="_blank">Directions</a></div>
            </div>
          </GMapInfoWindow>
        </GMapMarker>
      </GMapMap>

      <div v-if="showOverlay" class="overlay flex flex-column align-content-center justify-content-center">
        <div class="text-center">
          <strong>Address has changed, reload the map</strong>
        </div>
        <div class="text-center">
          <Button @click="reloadMap" class="mt-3" rounded>Reload</Button>
        </div>
      </div>

    </div>
  </section>
</template>


<script>

export default {
  name: "EventMap",
  props: {
    address: String,
    city: String,
    zip: String,
    onlyMap: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: '24rem'
    }
  },
  data() {
    return {
      showMap:false,
      center: {lat: 0, lng: 0},
      zoomLevel: 18,
      mapOptions: {
        disableDefaultUI: false,
        zoomControl: true,
        streetViewControl: true,
        scaleControl: true,
        fullscreenControl: true
      },
      mapLoaded: false,
      showOverlay: false
    };
  },
  mounted() {
    if (this.onlyMap || this.showMap) {
      this.showMap = true;
      this.centerMapOnAddress(); // Make sure to call this to set initial position
    }
  },

  updated() {
    if (this.showMap && !this.mapLoaded) {
      this.centerMapOnAddress();  // Retry centering if the map has not loaded initially
    }
  },

  watch: {
    address(newVal, oldVal) {
      if (this.onlyMap && newVal !== oldVal) {
        this.showOverlay = true;
      }
    },
    city(newVal, oldVal) {
      if (this.onlyMap && newVal !== oldVal) {
        this.showOverlay = true;
      }
    },
    zip(newVal, oldVal) {
      if (this.onlyMap && newVal !== oldVal) {
        this.showOverlay = true;
      }
    }
  },
  computed: {
    directionsLink() {
      const destination = encodeURIComponent(`${this.address}, ${this.city}, ${this.zip}`);
      return `https://www.google.com/maps/dir/?api=1&destination=${destination}`;
    },
    loading(){
      return this.center.lat === 0;
    }
  },
  methods: {

    handleTilesLoaded() {
      this.mapLoaded = true;
      this.centerMapOnAddress();
    },

    centerMapOnAddress() {
      if (!this.mapLoaded || !this.address) return;  // Check if the map is loaded before centering

      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address: `${this.address}, ${this.city}, ${this.zip}` }, (results, status) => {
        if (status === 'OK') {
          this.center = {lat:results[0].geometry.location.lat(), lng: results[0].geometry.location.lng()};
          this.showOverlay = false;  // Hide overlay after map is updated
        } else {
          console.error('Geocode was not successful for the following reason: ' + status);
        }
      });
    },
    reloadMap() {
      this.centerMapOnAddress();  // Ensure the map is centered on address change
    }
  }

};
</script>

<style scoped>

.map {
    width: 100%;
    height: 100%;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    z-index: 2;
}

</style>


import {make} from 'vuex-pathify';

const state = {
  settled: [],
  unsettled: [],
  paid: [],
  unpaid: [],
  sidebarVisible: true,
  filter: "",

  loading: false,
};

const getters = {
  ...make.getters(state),
};

const mutations = make.mutations(state);

const actions = {

  async loadUnsettled({commit}) {
    let list = await api.url(`/payments/unsettled`).get().json();
    commit('unsettled', list);
  },

  async createTransaction({commit}, transaction) {
    commit('loading', true);
    await api.url(`/payments/transaction`).post(transaction).res();
    await store.dispatch('Parties/Party/reload');
    commit('loading', false);
  },

  async deleteTransaction({commit}, transaction) {
    commit('loading', true);
    await api.url(`/payments/transaction/${transaction.id}`).delete().res();
    await store.dispatch('Parties/Party/reload');
    commit('loading', false);
  },

  async voidTransaction({commit}, payload) {
    try {
      commit('loading', true);
      await api.url(`/payments/void/${payload.id}`).put().res();
      await store.dispatch('Parties/Party/reload');
      commit('loading', false);
    } finally {
      commit('loading', false);
    }
  },

  async refundTransaction({commit}, payload) {
    try {
      commit('loading', true);
      await api.url(`/payments/refund`).put(payload).res();
      await store.dispatch('Parties/Party/reload');
    } finally {
      commit('loading', false);
    }
  },

  async updateTransactionStatus({commit}, transaction) {
    commit('loading', true);
    await api.url(`/payments/transaction/${transaction.id}`).put().json();
    await store.dispatch('Parties/Party/reload');
    commit('loading', false);
  },

  async updateAllTransactionStatus({commit}, payload) {
    commit('loading', true);
    await api.url(`/payments/transaction/update-all`).put(payload).json();
    await store.dispatch('Parties/Party/reload');
    commit('loading', false);
  },

  async deleteQuickbooksPayment({commit}, transaction) {
    commit('loading', true);
    await api.url(`/quickbooks/payment/${transaction.id}`).delete().res();
    await store.dispatch('Parties/Party/reload');
    commit('loading', false);
  },

  async deleteQuickbooksRefund({commit}, transaction) {
    commit('loading', true);
    await api.url(`/quickbooks/refund/${transaction.id}`).delete().res();
    await store.dispatch('Parties/Party/reload');
    commit('loading', false);
  },

  async addQuickbooksPayment({commit}, transaction) {
    commit('loading', true);
    await api.url(`/quickbooks/payment/${transaction.id}`).put().json();
    await store.dispatch('Parties/Party/reload');
    commit('loading', false);
  },

  async addQuickbooksRefund({commit}, payload) {
    commit('loading', true);
    await api.url(`/quickbooks/refund`).put(payload).json();
    await store.dispatch('Parties/Party/reload');
    commit('loading', false);
  },

  async addQuickbooksCheckPayment({commit}, check) {
    commit('loading', true);
    let transaction = await api.url(`/quickbooks/check-payment`).post(check).json();
    await store.dispatch('Parties/Party/reload');
    commit('loading', false);
    return transaction;
  },

  async removeQuickbooksAssociation({commit}, transaction) {
    commit('loading', true);
    let response = await api.url(`/quickbooks/payment/unassociate/${transaction.id}`).put().json();
    await store.dispatch('Parties/Party/reload');
    commit('loading', false);
    return response;
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

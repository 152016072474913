import { make } from 'vuex-pathify';
import {
  getEmployeesFromItems,
  getEmployeesFromParties,
  filterEmployees,
  filterStatus,
  filterEmployeesItems, filterByType
} from "@/components/events/calendar/helper";

const state = {

  events:[],
  payload:{},

  employees:[],
  selectedEmployees:[],
  selectedStatus:[],

  view: 'dayGridMonth',
  views: ['dayGridMonth', 'timeGridWeek', 'listYear', 'listMonth', 'listWeek', 'multiMonthYear'],

  loading:false,

  itemType:'party',

  currentDate: new Date(),
  sidebarVisible:true,
  showDay:false,
  day:null,
};

const getters = {
  ...make.getters(state),
};

const mutations = {
  ...make.mutations(state),
};

const actions = {

  async load({commit,dispatch,state}){
    commit('loading', true);

    (state.itemType === 'party')
      ? await dispatch('loadParties')
      : await dispatch('loadPartyItems')
      ;

    commit('loading', false);
  },

  async loadParties({commit, state}){

    let parties = await api.url(`/calendar/parties`).query(state.payload).get().json();

    commit('employees', getEmployeesFromParties(parties));

    if (state.selectedEmployees.length)
      parties = filterEmployees(state.selectedEmployees, parties);

    if(state.selectedStatus.length)
      parties = filterStatus(state.selectedStatus, parties);

    commit('events', parties);
  },

  async loadPartyItems({commit, state}){

    let items = await api.url('/calendar/parties/items').query(state.payload).get().json();

    items = filterByType(state.itemType, items);

    commit('employees', getEmployeesFromItems(items));

    if (state.selectedEmployees.length)
      items = filterEmployeesItems(state.selectedEmployees, items);

    if(state.selectedStatus.length)
      items = filterStatus(state.selectedStatus, items);

    commit('events', items);
  },

  async updateGoogleCalendar() {
    return await api.url('/google/calendar/update').put().res();
  }
};

export default {
  namespaced:true,
  state,
  getters,
  actions,
  mutations,
};

<template>
  <span class="text-xs text-400">{{ relativeDate }}</span>
  <div>
    <div class="mt-3 border-1 border-300 mb-2 p-3 border-round bg">
      <div class="flex justify-content-between">
        <div>
          <h4 class="m-0 text-600">{{ item.title }}</h4>
          <div class="text-sm text-600">{{ date }}</div>
        </div>
        <Button v-if="isNote" type="button" icon="pi pi-times" size="small" text rounded @click="removeNote" severity="secondary"></Button>
      </div>

      <Inplace v-if="isEmail" :closable="true" :display-props="{class:'inline-block p-0 m-0 my-2 text-primary hover:bg-white hover:underline'}" :closeButtonProps="{class:'p-1'}">
        <template #display>View Message</template>
        <template #content>
          <div class="m-3" v-html="item.notes"></div>
        </template>
      </Inplace>

      <div class="my-2" v-else v-html="item.notes"></div>

    </div>
  </div>
</template>

<script>
import {formatDateTime, relativeDate} from "@/helpers/date.helper";

export default {
  name: "event-interactions-item",
  props: {
    item: Object
  },
  computed:{
    isEmail(){
      return this.item.type === 'email';
    },
    isNote(){
      return this.item.type === 'note';
    },
    date(){
      return formatDateTime(this.item.created_at);
    },
    relativeDate(){
      return relativeDate(this.item.created_at)
    },
  },
  methods: {

    removeNote(){
      this.$confirm.require({
        message: `Are you sure you want to delete this note?`,
        header: 'Remove Note',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          try {
            await store.dispatch('Interactions/remove', this.item);
            await store.dispatch('Parties/Party/reload');
            this.$toast.add({severity: 'success', summary: 'Note Removed', detail: 'Note has been removed.', life: 3000});
          } catch (e) {
            this.$toast.add({severity: 'error', summary: 'Error', detail: e.message, life: 3000});
          }
        }
      });
    }
  }
}
</script>


<style scoped lang="scss">

</style>

<template>

  <Dialog v-model:visible="visible" modal class="h-auto surface-overlay" position="top"
          :style="{width: '640px'}"
          @dblclick.stop>
    <template #container>
      <section v-if="loading" class="flex justify-content-center align-content-center h-30rem">
        <progress-spinner class="h-full"/>
      </section>
      <section v-else class="fadein animation-duration-500 p-5">
        <div class="flex justify-content-between">
          <h1 class="text-600">Clone Event</h1>
          <Button type="button" icon="pi pi-times" class="ml-2 align-self-start" size="small" text rounded @click="close" severity="secondary"></Button>
        </div>

        <label class="text-600 text-sm font-bold">Find Another Customer</label>

        <AutoComplete
          v-model="term"
          :suggestions="filteredcustomers"
          :autoOptionFocus="true"
          @complete="searchcustomers"
          field="name"
          @item-select="selectcustomer"
          @clear="unselectcustomer"
          placeholder="first, last, email or phone" input-class="w-full" class="w-full my-2">
          <template #item="{item}">
            <div>{{ item.first }} {{ item.last }} - {{ item.email }} - {{ item.phone }}</div>
          </template>

          <template #empty>
            <div>No Team Members Found.</div>
          </template>
        </AutoComplete>

        <Card class="mb-5">
          <template #content>
            <div class="flex justify-content-between">
              <div>
                <h2 class="text-600">Clone Event For</h2>
                <h3 class="text-600">{{customer.first}} {{customer.last}}</h3>
                <p>{{customer.email}}</p>
                <p>{{customer.phone}}</p>
              </div>
              <Button icon="pi pi-times" class="p-button-rounded p-button-text" @click="unselectcustomer"/>
            </div>
          </template>
        </Card>

        <div class="flex justify-content-end z-depth-1">
          <Button icon="pi pi-times" label="Reset" rounded outlined @click="cancel" class="mr-2" :disabled="loading"/>
          <Button icon="pi pi-chevron-right" label="Clone Party" rounded @click="save" :disabled="disable"/>
        </div>

      </section>
    </template>
  </Dialog>

</template>

<script>
import {debounce} from "lodash";
import CustomerEdit from "@/components/customers/customer-edit.vue";
import LogoCompany from "@/components/common/logo-company.vue";
import {sync} from "vuex-pathify";
import {toastErrors} from "@/helpers/errors.helper";

export default {
  name: "clone-party-modal",
  components: {LogoCompany, CustomerEdit},
  data() {
    return {
      term: '',
      selectedCustomer: null,
      customers: [],
      filteredcustomers: [],
      debouncedSearch: null,
    }
  },

  created() {
    this.debouncedSearch = debounce(this.search, 800);
  },

  computed: {

    disable(){
      return this.customer === null;
    },

    loading() {
      return store.get('Customers/loading');
    },

    visible: sync('Parties/cloning', null, false),

    currentCustomer() {
      return store.get('Customers/current');
    },

    customer() {
      return this.selectedCustomer || this.currentCustomer;
    },

    party() {
      return store.get('Parties/Party/current');
    }
  },

  methods: {

    cancel() {
      this.term = '';
    },

    selectcustomer(event) {
      this.selectedCustomer = event.value;
    },

    unselectcustomer() {
      this.selectedCustomer = null;
    },

    close() {
      this.visible = false;
      this.cancel();
    },

    searchcustomers(event) {
      if (event.query.trim().length >= 3) {
        this.debouncedSearch(event.query);
      } else {
        this.filteredcustomers = [];
      }
    },

    async search(query) {
      let response = await api.url(`/customers/search?query=${query}`).get().json();
      this.filteredcustomers = response.map(customer => ({
        ...customer,
        name: `${customer.first} ${customer.last}`
      }));
    },

    async showClone(clone){
      await store.dispatch('Parties/Party/load', clone.token);
      store.commit('Parties/Party/view', true);
      store.commit('Parties/Party/edit', true);
      store.commit('Parties/Party/loading', false);
    },

    async save() {

      try {
        let clone = await store.dispatch('Parties/Party/clone', {id:this.party.id, customer_id: this.customer.id});
        this.visible = false;
        await store.commit('Parties/Party/view', true);
        this.$toast.add({ severity:'success', summary: 'Success', detail: 'Party cloned successfully', life: 3000 });
        await this.showClone(clone);
        this.cancel();

      } catch (errors) {
        toastErrors(this.$toast, errors);
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>

<template>
  <h2>{{ current.full_name }} Is Associated With</h2>
  <progress-spinner v-if="loading || !employeeProfile"></progress-spinner>
  <div v-else class="fadein animation-duration-500">
    <employee-quickbooks-item :employee="employeeProfile"></employee-quickbooks-item>
  </div>
</template>

<script>
import EmployeeQuickbooksItem from "./employee-quickbooks-item.vue";

export default {
  name: "employee-quickbooks-profile",
  components: {EmployeeQuickbooksItem},
  data() {
    return {
      loading: false,
      employeeProfile: null
    }
  },
  mounted() {
    this.load();
  },
  computed: {
    current() {
      return store.get('Employees/current');
    }
  },
  methods: {
    async load() {
      this.loading = true;
      this.employeeProfile = await store.dispatch('Employees/getEmployeeQBOProfile');
      this.loading = false;
    }
  },
}
</script>
<style scoped lang="scss">

</style>

import { make } from 'vuex-pathify';
import api from '@/config.wretch';
import {each, has} from "lodash";

const state = {
  id: null,
  date: new Date(),
  employee_id: null,
  quickbooks_id: null,
  status: '',
  notes: '',
  paid_on: null,

  items: [],
  gigsCount: 0,

  expenses: [],
  expensesCount: 0,

  labor: [],
  laborCount: 0,

  total: 0,
};

const restate = {...state};

const getters = {

  ...make.getters(state),

  grandTotal(state, getters) {
    return getters.itemsTotal + getters.expensesTotal + getters.laborTotal;
  },
  itemsTotal(state) {
    return (state.items && state.items.length > 0)
      ? state.items.reduce((sum, item) => sum + parseFloat(item.cost||0), 0)
      : 0;
  },
  expensesTotal(state) {
    return (state.expenses && state.expenses.length > 0)
    ? state.expenses.reduce((sum, expense) => sum + parseFloat(expense.amount||0), 0)
      :0;
  },
  laborTotal(state) {
    return (state.labor && state.labor.length > 0) ?
    state.labor.reduce((sum, labor) => sum + parseFloat(labor.total||0), 0)
      :0;
  },
};

const mutations = {
  ...make.mutations(state)
};

const actions = {

  set({commit, state}, current) {

    commit('items', []);
    commit('expenses', []);
    commit('labor', []);

    each(current, (value, key) => {
      if (has(state, key)) {
        commit(key, value);
      }
    });

    if(current === null){
        state = {...restate};
    }
  },


  async save({ state, getters, commit }) {
    // Calculate the total before saving
    commit('total', getters.grandTotal);

    await api.url(`/bills/${state.id}`).put(state).res();

    await store.dispatch('Bills/reload');
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

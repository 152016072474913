<script>
export default {
  name: "email-snipit-divider"
}
</script>

<template>
  <hr style="height: 1px; border: 0; background-color: #e5e5e5; margin: 0;">
</template>

<style scoped lang="scss">

</style>
<template>
  <section v-if="loading" class="flex justify-content-center align-content-center h-30rem">
    <progress-spinner class="h-full"/>
  </section>
  <section v-else class="fadein animation-duration-500">

    <scroll-panel style="width: 100%;height:80vh">
      <quick-transaction-table :transactions="transactions" :is-loading="loading" :party-actions="true"/>
    </scroll-panel>
  </section>
</template>

<script>
import QuickTransactionTable from "@/components/banking/quick-transaction-table.vue";

export default {
  name: "customer-transactions",
  components: {QuickTransactionTable},
  computed: {
    loading() {
      return store.get('Customers/Customer/loadingParties');
    },
    transactions() {
      return store.get('Customers/Customer/transactions');
    },
  },
}
</script>

<style scoped lang="scss">

</style>

<template>
  <DataTable
          :value="parties"
          paginator
          :rows="rows"
          :rowsPerPageOptions="[10, 25, 50]"
          paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          v-model:filters="filters"
          :globalFilterFields="['partyDate', 'howLong', 'customer.first', 'customer.last', 'customer.company', 'venue', 'city', 'created']"

          selectionMode="multiple"
          v-model:selection="selectedParties"
          :meta-key-selection="true"

          showGridlines
          stripedRows

          removableSort
          :sortField="sortField"
          :sort-order="sortOrder"

          dataKey="id"
          size="small"

          @rowDblclick="edit"
          @row-click="onRowClick"
  >
    <template #header>
      <div class="flex justify-content-end">

        <InputGroup>
          <InputGroupAddon>
            <i class="pi pi-search"/>
          </InputGroupAddon>
          <InputText v-model="filters['global'].value" placeholder="Search"
                     class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border appearance-none outline-none focus:border-primary w-full"/>
        </InputGroup>

        <Button
                :disabled="!selectedParties.length"
                @click="archiveSelected"
                icon="pi pi-times"
                class="ml-3 w-11rem"
                size="small"
                label="Archive"
                type="button"
                severity="primary"
                :badge="selectedParties.length ? ''+selectedParties.length : ''"/>

        <Button :disabled="!selectedParties.length"
                @click="deleteSelected"
                icon="pi pi-times"
                class="ml-3 w-11rem"
                size="small"
                label="Delete"
                type="button"
                severity="secondary"
                :badge="selectedParties.length ? ''+selectedParties.length : ''"/>


        <Dropdown
                :disabled="!selectedParties.length"
                v-model="selectedStatus"
                :options="statusOptions"
                @change="changeStatus"
                optionLabel="name"
                placeholder="Change Status"
                input-class="text-base text-color surface-overlay p-2 surface-border appearance-none outline-none focus:border-primary w-full"
                class="ml-3"
        />

      </div>
    </template>
    <template #empty>
      No events found.
    </template>
    <Column selectionMode="multiple" class="w-2rem"></Column>
    <Column field="date" header="Party Date" :sortable="true" class="w-14rem">
      <template #body="{data}">
        {{ partyDate(data.date) }}
      </template>
    </Column>
    <Column field="duration" header="How Long" class="w-6rem">
      <template #body="{data}">
        {{ data.duration }} hrs
      </template>
    </Column>

    <Column field="customer.full_name" header="Customer" :sortable="true" class="w-20rem">
      <template #body="{data}">
        <span class="ellipsis w-20rem" :title="data.customer.full_name">
          <span v-if="data.customer.company">{{ data.customer.company }} : </span>{{ data.customer.full_name }}
        </span>
      </template>
    </Column>

    <Column field="venue" header="Venue" class="w-15rem" v-if="!showMoney">
      <template #body="{data}">
        <span class="ellipsis w-15rem" :title="data.venue">{{ data.venue }}</span>
      </template>
    </Column>

    <Column field="city" header="City" :sortable="true" class="w-10rem">
      <template #body="{data}">
        <span class="ellipsis w-10rem" :title="data.city">{{ data.city }}</span>
      </template>
    </Column>


    <Column field="total" header="Total" :sortable="true" class="w-7rem" v-if="showMoney">
      <template #body="{data}">
        {{ formatMoney(data.total) }}
      </template>
    </Column>

    <Column field="balance" header="Balance" :sortable="true" class="w-7rem" v-if="showMoney">
      <template #body="{data}">
        <span :class="{'text-primary':data.balance<data.total}" :title="data.balance<data.total?'Deposit has been given':''">{{ formatMoney(data.balance)
          }}</span>
      </template>
    </Column>

    <Column field="created_at" header="Created" :sortable="true" class="w-6rem">
      <template #body="{data}">
        {{ formatDate(data.created_at) }}
      </template>
    </Column>

    <Column v-if="!showStatus" field="updated_at" header="Ageing" :sortable="true" class="w-6rem">
      <template #body="{data}">
                    <span :class="ageingClass(data.updated_at)" :title="`Last interaction with this party happened ${calculateAgeing(data.updated_at)} ago`">
                    {{ calculateAgeing(data.updated_at) }}
                    </span>
      </template>
    </Column>

    <Column v-else field="status" header="Status" :sortable="true" class="w-6rem"/>

    <Column field="date" header="Due" :sortable="true" class="w-6rem">
      <template #body="{data}">
        <span v-if="daysUntilEvent(data.date) > 0 || hoursUntilEvent(data.date) < -24" :title="`This event will be over in ${daysUntilEvent(data.date)} days`">{{ daysUntilEvent(data.date) }} days</span>
        <span v-else :title="`This event will be over in ${hoursUntilEvent(data.date)} days`">{{ hoursUntilEvent(data.date) }} hrs</span>
      </template>
    </Column>
  </DataTable>
  <ConfirmDialog group="headless">
    <template #container="{ message, acceptCallback, rejectCallback }">
      <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
        <div class="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
          <i class="pi pi-question text-5xl"></i>
        </div>
        <span class="font-bold text-2xl block mb-2 mt-4">{{ message.header }}</span>
        <p class="mb-0">{{ message.message }}</p>
        <div class="flex align-items-center gap-2 mt-4">
          <Button label="Save" @click="acceptCallback" class="w-8rem"></Button>
          <Button label="Cancel" outlined @click="rejectCallback" class="w-8rem"></Button>
        </div>
      </div>
    </template>
  </ConfirmDialog>
</template>

<script>
import {FilterMatchMode} from "primevue/api";
import {partyDate, formatDate} from '@/helpers/date.helper'
import {calculateAgeing, ageingClass, daysUntilEvent, hoursUntilEvent} from '@/helpers/party.helper'
import {formatMoney} from "accounting-js";

export default {
  name: "PartiesTable",
  emits: ['update-status', 'loading'],
  props: {
    parties: Array,
    loading: {type: Boolean, default: true},
    rows: {type: Number, default: 10},
    showMoney: {type: Boolean, default: false},
    showStatus: {type: Boolean, default: false},
    sortField: {type: String, default: "date"},
    asc: {type: Boolean, default: false},
    desc: {type: Boolean, default: true}
  },
  data() {
    return {
      selectedParties: [],
      selectedStatus: null,
      filters: {
        global: {value: null, matchMode: FilterMatchMode.CONTAINS},
      },
    };
  },
  computed: {
    statusOptions() {
      return store.get('Parties/statusOptions');
    },
    currentTab() {
      return this.$route.params.tab || 'all';
    },
    sortOrder() {
      return this.asc ? -1 : 1;
    }
  },
  methods: {
    formatMoney,

    ...{formatDate, partyDate, calculateAgeing, ageingClass, daysUntilEvent, hoursUntilEvent},

    changeStatus() {
      let ids = this.selectedParties.map((item) => item.id);
      this.$emit("update-status", {
        ids: ids,
        status: this.selectedStatus,
      });
    },

    edit({data, originalEvent}) {
      originalEvent.stopPropagation();

      let routePath = `/event/${data.token}/edit/invoice`;
      router.push(routePath);
    },

    onRowClick({data, originalEvent}) {
      let routePath = `/event/${data.token}`;

      if (originalEvent.ctrlKey) {
        routePath += '/edit/invoice';
      }

      router.push(routePath);
      store.commit('Parties/Party/view', true);
    },

    deleteSelected() {
      this.$emit('loading', true);
      this.$confirm.require({
        header: 'Delete Events',
        message: `Are you sure you want to delete ${this.selectedParties.length} events? You will no longer be able to reference them. IF your archive you can still see them in All Events.`,

        accept: async () => {
          let ids = this.selectedParties.map((item) => item.id);
          await store.dispatch('Parties/remove', ids);
          this.selectedParties = [];
          this.$emit('loading', false);
        },
        reject: () => {
        }
      });
    },

    archiveSelected() {
      this.$emit('loading', true);
      this.$confirm.require({
        header: 'Archive or Unarchive Events',
        message: `Are you sure you want to toggle the archive state for ${this.selectedParties.length} events? Events that are archived will become unarchived. Unarchived will become archived. Archived events will only be available in "all" tab.`,

        accept: async () => {
          let ids = this.selectedParties.map((item) => item.id);
          await store.dispatch('Parties/archive', ids);
          this.selectedParties = [];

          this.$emit('loading', false);
          this.$toast.add({
            severity: 'success',
            summary: 'Archive Successful',
            detail: 'The archived state has been toggled for the selected events.',
            life: 5000
          });
        },
        reject: () => {
          this.$emit('loading', false);
        },
        onHide: () => {
          this.$emit('loading', false);
        }
      });
    },
  }
}
</script>

<style scoped>
.ellipsis {
    display: block; /* or inline-block */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%; /* Adjust as needed */
}
</style>

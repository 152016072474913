<template>
  <section v-if="loading" class="flex justify-content-center align-content-center h-30rem">
    <progress-spinner class="h-full"/>
  </section>
  <section v-else class="fadein animation-duration-500">
    <DataTable
      :value="customers"
      v-model:selection="selectedCustomers"

      dataKey="id"

      :sort-field="sortField"
      :sort-order="sortOrder"

      selectionMode="multiple"

      showGridlines
      stripedRows
      size="small"

      @row-dblclick="onRowDBLClick"
      @sort="onSort"
    >
      <template #header>
        <div class="flex justify-content-end">

          <InputGroup>
            <InputGroupAddon>
              <i class="pi pi-search"/>
            </InputGroupAddon>
            <InputText v-model="filter"
                       placeholder="Search"
                       @keyup.enter="onFilter"
                       class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border appearance-none outline-none focus:border-primary w-full"/>

            <Button icon="pi pi-search" label="filter" @click="onFilter"/>
            <Button icon="pi pi-times" @click="clearFilter"/>
          </InputGroup>

          <Button :disabled="!selectedCustomers.length"
                  @click="deleteSelected"
                  icon="pi pi-times"
                  class="ml-3 w-11rem"
                  size="small"
                  label="Delete"
                  type="button"
                  severity="secondary"
                  :badge="selectedCustomers.length ? ''+selectedCustomers.length : ''"/>

          <customer-merge :selected-customers="selectedCustomers" :key="selectedCustomers"></customer-merge>
          <customer-add></customer-add>

        </div>
      </template>

      <template #empty>
        No events found.
      </template>

      <Column selectionMode="multiple" class="w-2rem"></Column>
      <Column field="first" header="First" sortable></Column>
      <Column field="last" header="Last" sortable></Column>
      <Column field="company" header="Company" sortable></Column>
      <Column field="parties_count" header="Parties" sortable></Column>
      <Column field="email" header="Email"></Column>
      <Column field="phone" header="Phone"></Column>
      <Column field="created_at" header="Since" sortable>
        <template #body="{data}">
          {{ formatDate(data.created_at) }}
        </template>
      </Column>
    </DataTable>

  </section>
  <Paginator
  :totalRecords="totalRows"
  :rows="rowsPerPage"
  :pageLinkSize="3"
  :alwaysShow="true"
  template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} "
  :rowsPerPageOptions="[10, 25, 50]"
  @page="onPageChange"
  />
</template>

<script>
import {calculateAgeing, ageingClass, daysUntilEvent} from '@/helpers/party.helper'
import {partyDate, formatDate} from '@/helpers/date.helper'
import CustomerMerge from "@/components/customers/customer-merge.vue";
import Bus from "@/bus";
import CustomerAdd from "@/components/customers/customer-add.vue";

export default {
  name: "CustomersTableServer",
  components: {CustomerAdd, CustomerMerge},
  emits: ['update-status'],
  mounted() {
    this.load();
    Bus.$on('customers.update', this.load);
  },
  beforeUnmount() {
    Bus.$off('customers.update', this.load);
  },
  data() {
    return {

      selectedCustomers: [],
      selectedStatus: null,

      paginatorTemplate: "FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown",

      filter: '',

      rowsPerPage: 10,  // Number of rows per page, should match the server setting

      sortField: 'created_at',
      sortOrder: -1,
    };
  },
  computed: {
    list() {
      return store.get('Customers/list');
    },
    loading() {
      return store.get('Customers/listLoading');
    },
    customers() {
      return this.list.data;
    },
    totalRows() {
      return this.list.total;
    },
    currentPage() {
      return this.list.current_page;
    },
    lastPage() {
      return this.list.last_page;
    },
    sort() {
      return this.sortOrder === 1 ? 'asc' : 'desc';
    },
    params() {
      return {
        perPage: this.rowsPerPage,
        page: this.currentPage,
        filter: this.filter,
        sortField: this.sortField,
        sortOrder: this.sort,
      };
    }
  },
  methods: {

    ...{formatDate, partyDate, calculateAgeing, ageingClass, daysUntilEvent},

    async load() {
      store.commit('Customers/listLoading', true);
      await store.commit('Customers/listParams', this.params);
      await store.dispatch('Customers/loadList');
      store.commit('Customers/listLoading', false);
    },

    onPageChange(event) {
      this.currentPage = event.page + 1; // event.page is zero-indexed
      this.rowsPerPage = event.rows;
      this.load();
    },

    onSort(event) {
      if (event.sortField === 'partiesCount') {
        this.sortField = 'parties_count';
      } else {
        this.sortField = event.sortField;
      }
      this.sortOrder = event.sortOrder;
      this.load();
    },

    clearFilter() {
      this.filter = '';
      this.load();
    },

    onFilter() {
      this.load();
      this.selectedCustomers = [];
    },

    async onRowDBLClick({data}) {
      await store.commit('Customers/current', data);
      store.commit('Customers/view', true);
    },

    async deleteSelected() {
      await store.commit('Customers/listLoading', true);

      const deletePromises = this.selectedCustomers.map(customer =>
        store.dispatch('Customers/delete', customer)
      );
      await Promise.all(deletePromises);

      this.selectedCustomers = [];
      await store.dispatch('Customers/reloadList');
      store.commit('Customers/listLoading', false);
    },

    addCustomer() {
      store.commit('Customers/add', true);
    },
  },
};
</script>

<style scoped>
</style>

<template>
  <ContextMenu :model="items" ref="cm"></ContextMenu>
</template>

<script>
import {toastErrors, toastSuccess, toastWarning} from "@/helpers/errors.helper";

export default {
  name: "bills-context-menu",
  props: ['selectedBill'],
  data() {
    return {
      paid: {label: 'Mark as Paid', icon: 'pi pi-fw pi-check', command: this.markPaid},
      unpaid: {label: 'Mark as Unpaid', icon: 'pi pi-fw pi-undo', command: this.markUnpaid},
      writeCheck: {label: 'Write Check', icon: 'pi pi-fw pi-money-bill', command: this.addCheckToQuickbooks},
      removeCheck: {label: 'Delete Check', icon: 'pi pi-fw pi-trash', command: this.removeCheckFromQuickbooks},
    };
  },
  computed: {
    employee() {
      return this.selectedBill?.employee;
    },
    employeeName() {
      return this.employee?.first + ' ' + this.employee?.last;
    },
    items() {
      return [
        this.selectedBill?.paid_on === null? this.paid : this.unpaid,
        this.selectedBill?.quickbooks_id === null? this.writeCheck: this.removeCheck,
        {label: 'Delete', icon: 'pi pi-trash', command: this.deleteBill},
      ];
    },
  },
  methods: {
    show(event) {
      this.$refs.cm.show(event);
    },

    async addCheckToQuickbooks() {
      if (this.selectedBill) {
        try {
          await store.dispatch('Bills/writeCheck', this.selectedBill);
          toastSuccess(this.$toast, 'Check Written Successfully. Print via Quickbooks', 'Writing Check in Quickbooks')
        } catch (errors) {
          toastErrors(this.$toast, errors)
        }
      } else {
        toastWarning(this.$toast, 'No bill selected to write check.', 'Write Check');
      }
    },

    async removeCheckFromQuickbooks() {
      if (this.selectedBill) {
        try {
          await store.dispatch('Bills/removeCheck', this.selectedBill);
          toastSuccess(this.$toast, 'Check Removed Successfully', 'Removing Check')
        } catch (errors) {
          toastErrors(this.$toast, errors)
        }
      } else {
        toastWarning(this.$toast, 'No bill selected to remove check.', 'Pay Bill');
      }
    },

    async markPaid() {
      if (this.selectedBill) {
        await store.dispatch('Bills/markPaid', this.selectedBill);
      } else {
        this.$toast.add({severity: 'warn', summary: 'Mark Paid', detail: 'No bill selected to mark as paid.', life: 3000});
      }
    },

    async markUnpaid() {
      if (this.selectedBill) {
        await store.dispatch('Bills/markUnpaid', this.selectedBill);
      } else {
        this.$toast.add({severity: 'warn', summary: 'Mark Paid', detail: 'No bill selected to mark as paid.', life: 3000});
      }
    },

    async deleteBill() {
      if (this.selectedBill) {
        try {
          await store.dispatch('Bills/delete', this.selectedBill);
          toastSuccess(this.$toast, 'Bill Deleted Successfully', 'Delete Bill')
        } catch (errors) {
          toastErrors(this.$toast, errors)
        }
      } else {
        toastWarning(this.$toast, 'No bill selected to delete.', 'Delete Bill');
      }
    }
  }
};
</script>

<style scoped>
</style>

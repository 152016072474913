<template>
  <misc-context-menus/>
  <DataTable
          ref="miscTable"
          data-key="id"
          :value="misc"
          showGridlines
          stripedRows
          size="small"
          :loading="loading"
          @rowReorder="reorder"
  >
    <template #empty>
      No Misc Items.
    </template>

    <template #loading>
      <progress-spinner class="h-full"/>
    </template>

    <Column rowReorder :reorderableColumn="false" header-style="width:3%;" class="text-center"/>

    <Column field="visible" header="" header-style="width:3%;">
      <template #body="{data}">
        <invoice-hide-from-customer-view :data="data" field="visible"/>
      </template>
    </Column>

    <Column field="misc" header="Item" class="relative">
      <template #body="{data,field}">
        <invoice-misc-autocomplete :data="data" field="misc" @contextmenu="showMenu($event,data,field)" :key="sorting"/>
      </template>
    </Column>

    <Column field="employee" header="Staff" class="w-3">
      <template #body="{data,field}">
        <invoice-employee-picker :scheduleable="data" @contextmenu="event=>showMenu(event,data,field)"/>
      </template>
    </Column>

    <Column field="start_time" header="Start" class="w-1">
      <template #body="{data,field}">
        <invoice-time-field :data="data" field="start_time" @contextmenu="event=>showMenu(event,data,field)"/>
      </template>
    </Column>

    <Column field="end_time" header="End" class="w-1">
      <template #body="{data,field}">
        <invoice-time-field :data="data" field="end_time" @contextmenu="event=>showMenu(event,data,field)"/>
      </template>
    </Column>

    <Column field="price" header="Price" class="w-1">
      <template #body="{data,field}">
        <invoice-money-field :data="data" field="price" @contextmenu="event=>showMenu(event,data,field)"/>
      </template>
    </Column>

    <Column field="cost" header="Cost" class="w-1">
      <template #body="{data,field}">
        <invoice-money-field :data="data" field="cost" @contextmenu="event=>showMenu(event,data,field)"/>
      </template>
    </Column>

    <ColumnGroup type="footer">
      <Row>
        <Column footer="Subtotal:" :colspan="6" footerStyle="text-align:right"/>
        <Column :footer="formatMoney(subtotalPrice)"/>
        <Column :footer="formatMoney(subtotalCost)"/>
      </Row>
    </ColumnGroup>
  </DataTable>
</template>
<script>
import Bus from '@/bus';
import {formatTime} from "@/helpers/time.helper";
import {formatMoney} from 'accounting-js';
import InvoiceEmployeePicker from "@/components/events/edit/invoice/invoice-employee-picker.vue";
import InvoiceTimeField from "@/components/events/edit/invoice/invoice-time-field.vue";
import InvoiceMoneyField from "@/components/events/edit/invoice/invoice-money-field.vue";
import {sync} from "vuex-pathify";
import InvoiceMiscAutocomplete from "@/components/events/edit/invoice/misc/invoice-misc-autocomplete.vue";
import MiscContextMenus from "@/components/events/edit/invoice/misc/misc-context-menus.vue";
import InvoiceHideFromCustomerView from "@/components/events/edit/invoice/invoice-hide-from-customer-view.vue";

export default {
  name: "invoice-misc-table",
  components: {
    InvoiceHideFromCustomerView,
    MiscContextMenus, InvoiceMiscAutocomplete, InvoiceMoneyField, InvoiceTimeField, InvoiceEmployeePicker},
  data() {
    return {
      sorting: 0
    }
  },
  computed: {

    loading() {
      return store.get('Scheduleables/miscLoading');
    },

    reloading() {
      return store.get('Parties/Party/reloading');
    },

    misc: sync('Scheduleables/misc', null, false),

    subtotalPrice() {
      return store.get('Scheduleables/miscSubtotal');
    },

    subtotalCost() {
      return store.get('Scheduleables/miscCost');
    }

  },
  methods: {
    ...{formatTime, formatMoney},

    showMenu(event, data ,field) {
      Bus.$emit('event.invoice.misc.contextmenu', { event, data ,field });
    },

    reorder(event) {
      this.misc = event.value;
      this.sorting++;
      store.dispatch('Scheduleables/reorderScheduleables', event.value);
    }

  },
}
</script>


<style scoped lang="scss">

</style>

<template>
  <section>

    <div v-if="!current.quickbooks_id">

      <div class="flex justify-content-between mb-2">
        <h2 class="m-0 p-0">Link Quickbooks Vendor</h2>
        <Button @click="confirmCreate" rounded size="small" class="quickbooks-button">
          <icon-quickbooks color="#ffffff" class="mr-2"/>
          <span class="text-white">Create New Vendor</span>
        </Button>
      </div>

      <employee-quickbooks-search></employee-quickbooks-search>
    </div>
    <div v-else>
      <employee-quickbooks-profile></employee-quickbooks-profile>
    </div>
  </section>
</template>

<script>
import IconQuickbooks from "@/components/common/icon-quickbooks.vue";
import EmployeeQuickbooksSearch from "./employee-quickbooks-search.vue";
import EmployeeQuickbooksProfile from "./employee-quickbooks-profile.vue";

export default {
  name: "employee-quickbooks",
  components: {EmployeeQuickbooksProfile, EmployeeQuickbooksSearch, IconQuickbooks},

  computed: {
    current() {
      return store.get('Employees/current');
    },
    party() {
      return store.get('Parties/Party/current');
    }
  },
  methods: {

    async confirmCreate() {
      store.commit('Employees/loading', true);
      this.$confirm.require({
        group: 'quickbooks',
        message: `Make sure you can't find one first otherwise your books can get messy!`,
        header: 'Create a New Quickbooks Vendor?',
        icon: 'pi pi-exclamation-triangle',
        confirmLabel: 'Create',
        accept: async () => {
          try{
            await store.dispatch('Employees/createQuickbooksVendor', this.current);
            if(this.party)
              await store.dispatch('Parties/Party/load', this.party.token);

            this.$toast.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Quickbooks vendor created successfully',
              life: 3000

            })
          }catch(e){
            this.$toast.add({
              severity: 'error',
              summary: 'Vendor Not Created',
              detail: e.message,
              life: 3000
            })
          }
        },
      });
      store.commit('Employees/loading', false);
    }
  }
}
</script>

<style lang="scss" scoped>
.quickbooks-button {
  color: #ffffff;
  background: #2ca01c;
  border-color: #2ca01c;
  padding: 2px 15px 2px 2px;
}
</style>

import { make } from 'vuex-pathify';
import {filter, sumBy} from "lodash";
import dayjs from "dayjs";

const state = {
  characters:[],
  charactersSubtotal:0.00,
  charactersCost:0.00,
  charactersLoading:false,

  services:[],
  servicesVisible:[],
  servicesSubtotal:0.00,
  servicesCost:0.00,
  servicesLoading:false,

  misc:[],
  miscVisible:[],
  miscSubtotal:0.00,
  miscCost:0.00,
  miscLoading:false,

  reloaded:false,

  current:null,

  showNotes: false,
  selectedRow: null,
  noteLoading: false,
};

const getters = {
  ...make.getters(state),
  charactersSubtotal(state){
    return sumBy(state.characters, (item) => parseFloat(item.price) || 0)
  },
  charactersCost(state){
    return sumBy(state.characters, (item) => parseFloat(item.cost) || 0)
  },
  servicesSubtotal(state){
    return sumBy(state.services, (item) => parseFloat(item.price) || 0)
  },
  servicesCost(state){
    return sumBy(state.services, (item) => parseFloat(item.cost) || 0)
  },
  miscSubtotal(state){
    return sumBy(state.misc, (item) => parseFloat(item.price) || 0)
  },
  miscCost(state){
    return sumBy(state.misc, (item) => parseFloat(item.cost) || 0)
  },

  total(state, getters) {
    return getters.charactersSubtotal + getters.servicesSubtotal + getters.miscSubtotal;
  },

  costs(state, getters) {
    return getters.charactersCost + getters.servicesCost + getters.miscCost;
  },

  isDirty(state) {
    return state.reloaded;
  },

  isEmpty(state){
    return !state.characters.length && !state.services.length && !state.misc.length;
  }
};

const mutations = make.mutations(state);

const actions = {
  ...make.actions(state),

  async setScheduleables({commit}, payload){

    let characters= filter(payload.scheduleables, (item) => item.character );
    let services = filter(payload.scheduleables, (item) => item.addon );
    let misc = filter(payload.scheduleables, (item) => !item.addon && !item.character);

    commit('characters', characters);
    commit('services', services);
    commit('servicesVisible', filter(services, (item) =>item.visible));
    commit('misc', misc);
    commit('miscVisible', filter(misc, (item) => item.visible));

    commit('reloaded', payload.reloading);
  },

  async reload({commit}){
    await store.dispatch('Parties/Party/reload',true);

    commit('charactersLoading', false);
    commit('servicesLoading', false);
    commit('miscLoading', false);
  },

  async addScheduleable({dispatch}, payload){
    let party = await store.get('Parties/Party/current');

    const startTime = dayjs(party.date)
    const endTime = startTime.add(party.duration, 'hour');

    let scheduleable = {
      party_id: party.id,
      start_time: !payload.misc ? startTime.format('YYYY-MM-DD HH:mm:ss') : null,
      end_time: !payload.misc ? endTime.format('YYYY-MM-DD HH:mm:ss') : null,
      ...payload
    };

    await api.url(`/scheduleable`).post(scheduleable).json();
    await dispatch('reload');
  },

  async cloneScheduleable({dispatch}, scheduleable){

    await api.url(`/scheduleable`).post(scheduleable).json();
    await dispatch('reload');
  },

  async splitScheduleable({dispatch}, scheduleable){
    let split = {...scheduleable, price:0, visible:false, employee_id:null};
    await api.url(`/scheduleable`).post(split).json();
    await dispatch('reload');
  },

  async editScheduleable({dispatch}, scheduleable, reload = true){

    await api.url(`/scheduleable/${scheduleable.id}`).put(scheduleable).json();
    if(reload) await dispatch('reload');
  },

  async removeScheduleable({dispatch}, scheduleable){
    await api.url(`/scheduleable/${scheduleable.id}`).delete().res();
    await dispatch('reload');
  },

  async reorderScheduleables({dispatch}, scheduleables){

    let ordered = scheduleables.map((item, index) => ({
      id: item.id,
      order_id: index
    }));

    await api.url(`/scheduleable/reorder`).post({order: ordered}).json();
    await dispatch('reload');
  },

  async addNote({dispatch,commit,state}, note){
    commit('noteLoading', true);
    await dispatch('editScheduleable', {...state.selectedRow, notes: note}, false);
    await store.dispatch('Parties/Party/reload');
    commit('selectedRow', null);
    commit('noteLoading', false);
  },

  async deleteNote({commit, dispatch}){
    commit('noteLoading', true);
    await dispatch('editScheduleable', {...state.selectedRow, notes: null}, false);
    await store.dispatch('Parties/Party/reload');
    commit('selectedRow', null);
    commit('noteLoading', false);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<template>
  <section v-if="loading" class="flex justify-content-center align-content-center h-10rem surface-overlay border-1 surface-border border-round shadow-1">
    <progress-spinner class="h-full"/>
  </section>
  <section v-else class="shadow-1">
    <ListBox ref="listBox" :options="characters" option-label="name" v-model="selected" filter :pt="pt" @change="setCharacter">
      <template #option="{option}">
        <div class="flex align-items-center m-0 p-0 fadein animation-duration-500">
          <img :src="getCharacterImage(option)" class="mr-4 mb-2 lg:mb-0 border-circle border-1 img" alt="Character Image"/>
          <span>{{ option.name }}</span>
        </div>
      </template>
    </ListBox>
  </section>
</template>

<script>

import {getCharacterImage} from "@/helpers/images.helper";

export default {
  name: "character-listbox",
  emits: ['item-selected'],
  props: {
    scheduleable: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      selected: null,
      filter: '',
      pt:{
        list:{class:'overflow-y-auto max-h-15rem m-0 p-0'},
        item:{class:'m-0 p-2'}
      }
    };
  },
  async mounted() {

    if (!this.characters.length) {
      await store.dispatch('Characters/load');
      this.focusOnFilter();
    } else {
      this.focusOnFilter();
    }
  },

  computed: {
    loading(){
      return store.get('Characters/loading');
    },
    characters(){
      return store.get('Characters/list')
    }
  },
  methods: {
    ...{getCharacterImage},

    setCharacter() {
      let payload = {...this.scheduleable, character_id: this.selected.id};
      this.$emit('item-selected', payload);
    },

    focusOnFilter() {
      const filterInput = this.$refs.listBox?.$el.querySelector('input');
      if (filterInput) {
        filterInput.focus();
      }
    },
  }
};
</script>
<style scoped lang="scss">
.img{
  width:36px;
  height:36px;
}
</style>


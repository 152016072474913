<template>
  <ContextMenu ref="cm" :model="items"/>
</template>
<script>
import Bus from '@/bus';
export default {
  name: "notes-context-menu",
  data() {
    return {
      items: null,
      noNote:[
        {label: 'Add Note to Staff', icon: 'pi pi-fw pi-bookmark', command: this.showNotes}
      ],
      hasNote:[
        {label: 'Edit Note to Staff', icon: 'pi pi-fw pi-bookmark', command: this.showNotes},
        {label: 'Delete Note to Staff', icon: 'pi pi-fw pi-times', command: this.deleteNote}
      ]
    }
  },
  computed: {
    startTime() {
      return store.get('Parties/Party/startTime');
    },
    endTime(){
      return store.get('Parties/Party/endTime');
    }
  },
  mounted() {
    Bus.$on('event.notes.contextmenu', this.showMenu);
  },
  unmounted() {
    Bus.$off('event.notes.contextmenu', this.showMenu);
  },
  methods: {

    showMenu({ event, data }) {
      this.$nextTick(() => {
        if (this.$refs.cm) {
          this.items = (!data.notes) ? this.noNote : this.hasNote;
          store.commit('Scheduleables/selectedRow', data);
          this.$refs.cm.show(event);
        }
      });
    },

    showNotes(){
      store.commit('Scheduleables/showNotes', true);
    },

    deleteNote(){
      store.dispatch('Scheduleables/deleteNote');
    },
  },
}
</script>

<style scoped lang="scss">

</style>

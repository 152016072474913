<template>
    <BlockUI :blocked="balance<=0">
    <form @submit.prevent="submitCheck" class="p-fluid">
        <div class="grid formgrid mt-5">
            <div class="field col-12 md:col-4">
                <label for="name" class="text-600 font-bold">Name On Check:</label>
                <InputText id="name" v-model="name" required/>
            </div>
            <div class="field col-12 md:col-2">
                <label for="check-number" class="text-600 font-bold">Check Number:</label>
                <InputText id="check-number" v-model="checkNumber" required/>
            </div>
            <div class="field col-12 md:col-6">
              <label for="amount" class="text-600 font-bold">Amount:</label>
              <InputGroup>
                <InputNumber id="amount" v-model="amount" mode="currency" currency="USD" :minFractionDigits="2" required/>
                <InputGroupAddon @click="amount=balance" class="white-space-nowrap px-5 cursor-pointer hover:bg-primary">
                  <span class="inline-block px-5">{{`Balance (${formatMoney(balance)})`}}</span>
                </InputGroupAddon>
              </InputGroup>
            </div>

            <div class="col-5 col-offset-7 flex ">
                <Button label="Cancel" @click="close" class="p-button-text"></Button>
                <Button type="submit" :label="balance<=0? 'Requires Balance':'Add Check'" class="p-button-rounded" :loading="loading"
                        :disabled="balance<=0"></Button>
            </div>
        </div>
    </form>
    </BlockUI>
</template>

<script>
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {formatMoney} from "accounting-js";

dayjs.extend(utc);

export default {
    name: 'CheckForm',
    data() {
        return {
            checkNumber: '',
            amount: 0,
            name: '',
            loading: false
        };
    },
    computed: {
        party() {
            return store.get('Parties/Party/current')
        },
        balance() {
            return store.get('Parties/Party/balance');
        },
    },
    methods: {
      formatMoney,
        async submitCheck() {
            // Check if all fields are filled
            if (!this.name || !this.checkNumber || this.amount <= 0) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Validation Error',
                    detail: 'All fields are required and must be filled out correctly.',
                    life: 5000
                });
                return;
            }

            this.loading = true;
            try {
                const transaction = {
                    accountNumber: this.checkNumber,
                    amount: this.amount,
                    invoice: this.party.token,
                    transactionStatus: 'processed',
                    accountType: 'check',
                    firstName: this.name,
                    submitTimeUTC: dayjs().utc().format('YYYY-MM-DD HH:mm:ss'),
                    submitTimeLocal: dayjs().format('YYYY-MM-DD HH:mm:ss')
                };

                await store.dispatch('Banking/createTransaction', transaction);

                // Reset form
                this.checkNumber = '';
                this.amount = 0;
                this.name = '';

                this.$toast.add({
                    severity: 'success',
                    summary: 'Check Payment Processed',
                    detail: 'The check payment has been recorded successfully.',
                    life: 3000
                });
            } catch (error) {
                console.error('Error creating check transaction:', error);
                this.$toast.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'There was an error processing the check payment.',
                    life: 5000
                });
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>

<style scoped>
</style>

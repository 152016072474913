<template>
  <ContextMenu ref="cm" :model="items"/>
</template>
<script>
import Bus from '@/bus';

export default {
  name: "customer-items-context-menu",
  data() {
    return {
      item: null,
      items: [
        {label: 'Clone Item To Open Event', icon: 'pi pi-fw pi-copy', command: this.saveClone},
      ]
    }
  },
  computed:{
    party(){
      return store.get('Parties/Party/current');
    },
    isEditing(){
      return store.get('Parties/Party/edit');
    }
  },
  mounted() {
    Bus.$on('customer.items.contextmenu', this.showMenu);
  },
  unmounted() {
    Bus.$off('customer.items.contextmenu', this.showMenu);
  },
  methods: {

    showMenu({event, data}) {
      this.$nextTick(() => {
        this.item = data;
        this.$refs.cm?.show(event);
      });
    },

    async saveClone() {
      if(!this.isEditing){
        this.$toast.add({severity: 'warn', summary: 'Not In Edit Mode', detail: 'You must be in edit mode to use this feature.', life: 3000});
        return;
      }

      this.item.party_id = this.party.id;
        try {
          await store.dispatch('Scheduleables/cloneScheduleable', this.item);
          this.$toast.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Item cloned to your current party',
            life: 3000
          });
        } catch (e) {
          this.$toast.add({severity: 'error', summary: 'Failure', detail: 'Something went wrong', life: 3000});
        }
    },
  },
}
</script>

<style scoped lang="scss">

</style>

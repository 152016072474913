<template>
  <section v-if="loading" class="flex justify-content-center align-content-center h-30rem">
    <progress-spinner class="h-full"/>
  </section>
  <section v-else class="fadein animation-duration-500">
    <DataTable
      :value="transactions"
      v-model:expandedRows="expandedRows"
      v-model:selection="selectedTransactions"
      data-key="id"
      :sort-field="sortField"
      :sort-order="sortOrder"
      selection-mode="multiple"
      showGridlines
      stripedRows
      size="small"
      @row-dblclick="onRowDblClick"
      @sort="onSort"
      :null-sort-order="0"
    >
      <template #empty>
        No Transactions.
      </template>
      <template #header>
        <div class="flex justify-content-end">
          <InputGroup>
            <InputGroupAddon>
              <i class="pi pi-search"/>
            </InputGroupAddon>
            <InputText
              v-model="filter"
              placeholder="Search"
              @keyup.enter="onFilter"
              class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border appearance-none outline-none focus:border-primary w-full"
            />
            <Button icon="pi pi-search" label="filter" @click="onFilter"/>
            <Button icon="pi pi-times" @click="clearFilter"/>
          </InputGroup>
          <Button
            :disabled="!selectedTransactions.length"
            @click="addToQuickbooks"
            icon="pi pi-plus"
            class="ml-3"
            label="Add to QuickBooks"
            type="button"
            severity="primary"
          />
          <Button
            :disabled="!selectedTransactions.length"
            @click="updateTransactionStatus"
            icon="pi pi-refresh"
            class="ml-3"
            label="Update Status"
            type="button"
            severity="secondary"
          />
        </div>
      </template>

      <Column selectionMode="multiple" class="w-2rem"></Column>
      <Column expander headerStyle="width: 3rem"/>
      <Column field="submitTimeLocal" header="Date" sortable>
        <template #body="{data}">
          {{ formatDateTime(data.submitTimeLocal) }}
        </template>
      </Column>
      <Column field="transId" header="Transaction Id" sortable></Column>
      <Column field="transactionStatus" header="Status" sortable></Column>
      <Column field="party.customer.full_name" header="Customer"></Column>
      <Column field="quickbooks_id" header="Quickbooks" sortable>
        <template #body="{data}">
          <a v-if="data.quickbooks_id" @click="goToQuickbooksPayment(data.quickbooks_id)" target="_blank"
             class="text-primary underline text-sm cursor-pointer">
            {{data.quickbooks_id }}
          </a>
          <span v-else>-</span>
        </template>
      </Column>
      <Column field="amount" header="Amount" sortable>
        <template #body="{data}">
          {{ formatMoney(data.amount) }}
        </template>
      </Column>

      <template #expansion="{ data }">
        <transaction-card :transaction="data" :has-container="true"></transaction-card>
      </template>

      <ColumnGroup type="footer">
        <Row>
          <Column footer="Balance:" :colspan="7" footerStyle="text-align:right"/>
          <Column :footer="formatMoney(total)"/>
        </Row>
      </ColumnGroup>
    </DataTable>

    <Paginator
      :totalRecords="totalRows"
      :rows="rowsPerPage"
      :pageLinkSize="3"
      :alwaysShow="true"
      template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} "
      :rowsPerPageOptions="[10, 25, 50]"
      @page="onPageChange"
    />
  </section>
</template>

<script>
import {formatMoney} from 'accounting-js';
import {formatDateTime} from '@/helpers/date.helper';
import {sumBy} from "lodash";
import {goToQuickbooksPayment} from '@/helpers/link.helper';
import TransactionCard from "@/components/events/edit/invoice/transaction-card.vue";

export default {
  name: "transaction-table",
  components: {TransactionCard},
  emits: ['update-status'],
  data() {
    return {
      transactions: [],
      loading: false,
      expandedRows: [],
      selectedTransactions: [],
      paginatorTemplate: "FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown",
      filter: '',
      totalRows: 0,
      rowsPerPage: 10,
      currentPage: 1,
      sortField: 'transId',
      sortOrder: -1,
    }
  },
  mounted() {
    this.load();
  },
  computed: {
    total() {
      return sumBy(this.transactions, (item) => parseFloat(item.amount) || 0);
    }
  },
  methods: {
    formatMoney,
    formatDateTime,
    goToQuickbooksPayment,
    getSortOrder() {
      return this.sortOrder === 1 ? 'asc' : 'desc';
    },
    getParams() {
      return {
        perPage: this.rowsPerPage,
        page: this.currentPage,
        filter: this.filter,
        sortField: this.sortField,
        sortOrder: this.getSortOrder(),
      };
    },
    bindResult(result) {
      this.transactions = result.data;
      this.totalRows = result.total;
      this.currentPage = result.current_page;
      this.lastPage = result.last_page;
      this.firstPageUrl = result.first_page_url;
      this.lastPageUrl = result.last_page_url;
      this.nextPageUrl = result.next_page_url;
      this.prevPageUrl = result.prev_page_url;
      this.from = result.from;
      this.to = result.to;
    },
    async load() {
      this.loading = true;
      let result = await api.url(`/payments/settled`).query(this.getParams()).get().json();
      this.bindResult({...result});
      this.loading = false;
    },
    async onRowDblClick({data}) {
      await store.dispatch('Parties/Party/load', data.party.token);
      store.commit('Parties/Party/view', true);
    },
    onPageChange(event) {
      this.currentPage = event.page + 1;
      this.rowsPerPage = event.rows;
      this.load();
    },
    onSort(event) {
      this.sortField = event.sortField;
      this.sortOrder = event.sortOrder;
      this.load();
    },
    clearFilter() {
      this.filter = '';
      this.load();
    },
    onFilter() {
      this.load();
    },
    addToQuickbooks() {
      this.selectedTransactions.forEach(transaction => {
        console.log(`Adding transaction ${transaction.transId} to QuickBooks`);
      });
    },
    updateTransactionStatus() {
      this.selectedTransactions.forEach(transaction => {
        console.log(`Updating status for transaction ${transaction.transId}`);
        // Implement the logic to update the transaction status here
      });
    }
  },
}
</script>

<style scoped lang="scss">
/* Your styles here */
</style>

<template>
  <Menu model="items" popup ref="menu" :appendTo="body">
    <template #activator="{ on, attrs }">
      <Button icon @click="on.click" v-bind="attrs" class="p-button-rounded p-button-text">
        <i class="pi pi-ellipsis-v"></i>
      </Button>
    </template>
  </Menu>
</template>

<script>
export default {
  name: "CharacterContextMenu",
  props: ["character"],

  computed: {
    current() {
      return store.get('Characters@current');
    },
    currentName() {
      return this.current ? this.current.name : '';
    },
    items() {
      return [
        {
          label: 'Set Related to ' + this.currentName,
          icon: 'pi pi-check-circle',
          command: () => {
            this.setRelationship();
          }
        }
      ];
    }
  },
  methods: {
    async setRelationship() {
      try {
        await store.dispatch('Characters/Relationships/update', { rid: this.character.id, cid: this.current.id });
        this.$toast.add({severity: 'success', summary: 'Success', detail: 'Relationship added', life: 3000});
      } catch (e) {
        this.$toast.add({severity: 'error', summary: 'Error', detail: e.message, life: 3000});
      }
    }
  }
}
</script>

<style scoped lang="scss">
/* Additional styles if needed */
</style>

<template>
  <div class="image-container">
    <img :src="png" :alt="altText" :width="width" :height="height" style="object-fit: contain; aspect-ratio: 1;"/>
  </div>
</template>

<script>
export default {
  name: "CharacterProfileImage",
  props: {
    'image':{
      type: Object,
      required: true
    },
    'width':{
      type: String,
      default: '100%'
    },
    'height':{
      type: String,
      default: 'auto'
    }
  },
  computed: {
    png() {
      return `https://www.yourmagicalparty.com/images/characters/${this.image.src}`;
    },
    altText() {
      return `Image of ${this.image.name || 'character'}`; // Assuming `name` might be a property of `image`
    }
  }
}
</script>

<style lang="scss" scoped>
.image-container {
  display: flex;
  justify-content: center; // Centers the image horizontally
  align-items: center; // Centers the image vertically
}
</style>

<template>
  <Menu model="items" popup ref="menu" :appendTo="body">
    <template #activator="{ on, attrs }">
      <Button icon @click="on.click" v-bind="attrs" class="p-button-rounded p-button-text">
        <i class="pi pi-ellipsis-v"></i>
      </Button>
    </template>
  </Menu>
</template>

<script>
import { find } from 'lodash';

export default {
  name: "CharacterRelationshipContextMenu",
  props: ["character", "relatedMap"],
  computed: {
    current() {
      return store.get('Characters@current');
    },
    items() {
      return [
        {
          label: `Remove Relationship with ${this.current?.name}`,
          icon: 'pi pi-times-circle',
          command: () => {
            this.removeRelationship();
          }
        }
      ];
    }
  },
  methods: {
    async removeRelationship() {
      try {
        const id = this.getRelationshipId();
        if (!id) throw new Error('Relationship ID not found.');
        await store.dispatch('Characters/Relationships/remove', {id});
        this.$toast.add({severity: 'success', summary: 'Success', detail: 'Relationship removed', life: 3000});
      } catch (e) {
        this.$toast.add({severity: 'error', summary: 'Error', detail: e.message, life: 3000});
      }
    },
    getRelationshipId() {
      const relationship = find(this.relatedMap, {rid: this.character.id});
      return relationship?.id;
    }
  }
}
</script>

<style scoped lang="scss">
/* Scoped styles */
</style>

import {make} from 'vuex-pathify';
import {forOwn, includes, isEqual} from "lodash";
import Customer from "@/stores/customer.store";
import api from "@/config.wretch";

const state = {
  list:[],
  listParams: '',
  listLoading: false,
  loading:false,
  current: null,

  view: false,
  edit: false,
  add: false,

  reloadKey:0,
};

const getters = {
  ...make.getters(state),

  changedFields(state) {
    return compareObjects(state.current, state);
  },

  isDirty(state, getters) {
    return getters.changedFields.length > 0;
  },
};

const mutations = {
  ...make.mutations(state),

  current(state, current) {
    state.current = current;
    store.dispatch('Customers/Customer/set', current);
  },

  transactions(state, parties) {
    state.transactions = parties.flatMap(party => party.transactions);
  }
};

const actions = {

  ...make.actions(state),

  async getCustomerById({commit}, id) {
    let customer = await api.url(`/customers/${id}/details`).get().json();
    commit('current', customer);
  },

  reloadList({dispatch}){
    dispatch('loadList');
  },

  async loadList({commit, state}) {
    commit('listLoading', true);
    let list = await api.url(`/customers`).query(state.listParams).get().json();
    commit('list', list);
    commit('listLoading', false);
  },

  async loadItems({commit, state}) {
    commit('loadingItems', true);
    let items = await api.url(`/scheduleable/customer/${state.current.id}`).get().json();
    commit('items', items);
    commit('loadingItems', false);
  },

  async delete({commit}, customer) {
    commit('loading', true);
    await api.url(`/customers/${customer.id}`).delete();
    commit('loading', false);
  },

  async mergeCustomers(obj, {primary, merge}) {
    let toMerge = merge.map(customer => customer.id);
    return await api.url('/customers/merge').post({ primary: primary.id, merge: toMerge }).json();
  },

  async associateQuickbooks({state, commit}, id) {
    commit('loading', true);
    let customer = await api.url('/quickbooks/customer/associate').post({customer_id: state.current.id, quickbooks_id: id}).json();
    commit('current', customer);
    commit('loading', false);
  },

  async unassociateQuickbooks({state, commit}) {
    commit('loading', true);
    let customer = await api.url(`/quickbooks/customer/unassociate/${state.current.id}`).put().json();
    commit('current', customer);
    commit('loading', false);
  },

  getQuickbooksProfile({state}) {
    return api.url(`/quickbooks/customer/${state.current.quickbooks_id}`).get().json()
  },

  async createQuickbooksCustomer({commit, state}) {
    let customer = await api.url('/quickbooks/customer/create').post({customer_id: state.current.id}).json();
    commit('current', customer);
  },

  async reinstate({commit}, customer_id){
    let customer = await api.url(`/customers/reinstate/${customer_id}`).put().json();
    commit('current', customer);
  },

  async permanentDelete(obj, customer_id){
    return await api.url(`/customers/permanently-delete/${customer_id}`).delete().res();
  }
};

// function addSearchIndex(parties) {
//     return parties.map(party => {
//       const searchString = party.scheduleables.reduce((acc, scheduleable) => {
//         // Extract the values and check for null or undefined
//         const characterName = scheduleable.character?.name || '';
//         const characterAlias = scheduleable.character?.alias || '';
//         const characterSearch = scheduleable.character?.search || '';
//         const addonTitle = scheduleable.addon?.title || '';
//         const misc = scheduleable.misc || '';
//
//         // Concatenate the non-empty parts
//         const parts = [characterName, characterAlias, characterSearch, addonTitle, misc];
//         return acc + ' ' + parts.filter(part => part).join(' ');
//       }, '').toLowerCase();
//
//       // Add the search string to the party object
//       return { ...party, search: searchString };
//     });
// }

function compareObjects(current, state) {
  const exclude = ['loading', 'current', 'quickview', 'edit', 'add', 'list', 'listLoading', 'listParams'];
  const changes = [];

  forOwn(state, (value, key) => {
    if (includes(exclude, key)) return;

    if (!isEqual(value, current[key])) {
      changes.push({field: key, value: value, old: current[key]});
    }
  });

  return changes;
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules: {
    Customer
  }
};

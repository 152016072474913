const unsuccessfulStatuses = [
    'AuthorizedPendingCapture',
    'CapturedPendingSettlement',
    'RefundPendingSettlement',
    'UnderReview',
    'FDSPendingReview',
    'FDSAuthorizedPendingReview',
    'UpdatingSettlement'
]
export function isRefund(transaction) {
    return !!transaction.refTransId;
}
export function isDeclined(transaction) {
    return transaction.transactionStatus === 'declined';
}
export function isVoided(transaction) {
    return transaction.transactionStatus === 'voided';
}
export function isFraud(transaction) {
    return transaction.transactionStatus === 'FDSPendingReview';
}
export function isCreditCard(transaction) {
    return transaction.transactionStatus !== 'processed';
}
export function isSuccessful(transaction) {
    return !unsuccessfulStatuses.includes(transaction.transactionStatus);
}
export function isSettled(transaction) {
    return transaction.transactionStatus === 'settledSuccessfully' || transaction.transactionStatus === 'refundSettledSuccessfully';
}
export function isFinalized(transaction) {
  return transaction.transactionStatus === 'settledSuccessfully'
    || transaction.transactionStatus === 'voided'
    || transaction.transactionStatus === 'declined'
    || transaction.transactionStatus === 'processed'
    || transaction.transactionStatus === 'refundSettledSuccessfully'
}

<template>
    <section v-if="loading" class="flex justify-content-center align-content-center h-30rem">
        <progress-spinner class="h-full"/>
    </section>
    <section v-else class="fadein animation-duration-500">
        <div class="flex justify-content-end flex-wrap">
            <div class="flex-1">
                <h1>Staffing: {{ selectedFilter }}</h1>
                <p>All events that we are booking that still need staff members.</p>
            </div>
            <div class="flex justify-content-end">
                <SelectButton :options="filterOptions" v-model="selectedFilter"/>
            </div>
        </div>
        <staffing-table :parties="scheduleables" @update-status="updateStatus" :loading="loading"/>

        <!--        <h1>Has Staff</h1>-->
        <!--        <p>All events that still need staff members. Misc items can have staff members but will not show here.</p>-->
        <!--        <staffing-table :parties="hasStaff" @update-status="updateStatus" :loading="loading"/>-->

        <employee-picker></employee-picker>
    </section>
</template>

<script>
import StaffingTable from "@/components/events/tables/staffing-table.vue";
import EmployeePicker from "@/components/employees/employee-picker.vue";
import {each} from "lodash";

export default {
  name: "TabStaff",
  components: {
    EmployeePicker,
    StaffingTable
  },
  data() {
    return {
      selectedFilter: 'Needs Staffing',
      filterOptions: ['Needs Staffing', 'Staffing Complete']
    }
  },
  computed: {
    loading(){
      return store.get('Parties/loading');
    },

    parties() {
      return store.get('Parties/booking');
    },

    scheduleables() {
      return (this.selectedFilter === 'Needs Staffing')
        ? this.needsStaff || []
        : this.hasStaff || [];
    },

    needsStaff() {
      let list = [];

      each(this.parties, (party) => {
        each(party.scheduleables, (scheduleable) => {
          scheduleable.party = party;
          if (scheduleable.employee_id === null)
            list.push(scheduleable);
        });
      });

      return list;
    },

    hasStaff() {
      let list = [];

      each(this.parties, (party) => {
        each(party.scheduleables, (scheduleable) => {
          scheduleable.party = party;
          if (scheduleable.employee_id !== null)
            list.push(scheduleable);
        });
      });

      return list;
    },

  },
  methods: {
    updateStatus(payload) {
      store.dispatch("Parties/updateStatus", payload)
    },
  },
};
</script>

<style scoped>
</style>

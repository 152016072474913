export const MiscItems = [
  {"name":"Travel","price":0},
  {"name":"Parking","price":0},
  {"name":"Labor","price":0},
  {"name":"Tip","price":0},
  {"name":"Gratuity","price":0},
  {"name":"Service Charge","price":0},
  {"name":"Delivery Setup and Teardown","price":0},
  {"name":"Hotel accommodations for staff","price":0},
  {"name":"Holiday Rate","price":150},
  {"name":"Insurance Certificate","price":100},
  {"name":"Special Insurance Certificate","price":150},
  {"name":"Administrative Fees 3.5%","priceFn":(obj)=>{return obj.total * .035}},
];
<template>
      <section class="px-3">
        <div class="flex justify-content-center">
          <div class="w-6 relative">
            <calendar-navigation class="p-3 cursor-pointer hover:text-primary hover:underline"  calendar="DayCalendar"/>
          </div>
        </div>
        <div class="flex align-items-center justify-content-between w-full">

          <div class="flex justify-content-between h-4rem p-2 pb-3 line-height-1">
            <calendar-view-filter calendar="DayCalendar" class="w-4"/>
            <calendar-items-filter calendar="DayCalendar" class="w-4 ml-2"/>
            <calendar-employee-filter calendar="DayCalendar" class="w-4 ml-2" v-if="showEmployeeFilter"/>
            <calendar-status-filter calendar="DayCalendar" class="w-4 ml-2"/>
          </div>

          <Button type="button" @click="close" icon="pi pi-times" text rounded v-if="!hideClose"></Button>
        </div>

        <section v-if="loading" class="flex justify-content-center align-content-center h-full w-full absolute bg-white-alpha-90 z-5">
          <progress-spinner class="h-full"/>
        </section>
        <section  class="fadein animation-duration-500 pt-1 h-6">
          <FullCalendar :options="calendarOptions" :key="`${view}${currentDate}`" ref="dayCal" id="dayCalendar">
            <template #eventContent="e">
              <div :title="`${e.event.title} (${e.event.extendedProps.status})`"
                   class="cursor-pointer text-overflow-ellipsis overflow-hidden whitespace-nowrap w-full text-xs px-1 custom-event"
                   @click.stop="onEventClick(e)">
                {{e.timeText}} {{e.event.title}}
              </div>
            </template>
          </FullCalendar>
        </section>
      </section>
</template>

<script>

import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import multiMonthPlugin from '@fullcalendar/multimonth'
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list';

import CalendarItemsFilter from "@/components/events/calendar/calendar-header/calendar-items-filter.vue";
import CalendarStatusFilter from "@/components/events/calendar/calendar-header/calendar-status-filter.vue";
import CalendarNavigation from "@/components/events/calendar/calendar-header/calendar-navigation.vue";
import CalendarEmployeeFilter from "@/components/events/calendar/calendar-header/calendar-employee-filter.vue";

import {getDBTime} from "@/helpers/time.helper";
import LogoCompany from "@/components/common/logo-company.vue";
import Bus from "@/bus";
import CalendarViewFilter from "@/components/events/calendar/calendar-header/calendar-view-filter.vue";
export default {

  name: 'day-calendar',
  props: {
    height: {type: String, default: '85vh'},
    hideClose: {type: Boolean, default: false},
    employeeId: {type: Number, default: null},
  },
  components: {CalendarViewFilter, CalendarEmployeeFilter, CalendarNavigation, CalendarStatusFilter, LogoCompany, CalendarItemsFilter, FullCalendar },

  mounted() {
    Bus.$on('daycalendar.date', this.goToDate);
    if(this.employeeId !== null) store.commit('DayCalendar/selectedEmployees', [this.employeeId]);
  },

  beforeUnmount() {
    Bus.$off('daycalendar.date', this.goToDate);
    store.commit('DayCalendar/selectedEmployees', []);
  },

  computed: {

    view(){return store.get('DayCalendar/view');},

    currentDate(){return store.get('DayCalendar/currentDate');},

    isPartyItems(){return store.get('DayCalendar/isPartyItems');},

    loading(){return store.get('DayCalendar/loading');},

    events(){return store.get('DayCalendar/events');},

    showEmployeeFilter(){return this.employeeId === null},

    calendarOptions(){
      return {
        plugins: [dayGridPlugin, timeGridPlugin, listPlugin, multiMonthPlugin, interactionPlugin],
        headerToolbar: false,

        selectable: true,
        height: this.height || '85vh',

        eventClick: this.onEventClick,
        datesSet: this.onDatesSet,

        eventDidMount: (info) => {
          const status = info.event.extendedProps.status;
          info.el.classList.add('event-container');
          info.el.classList.add(status);

          const token = info.event.extendedProps.token;
          info.el.classList.add(`E${token}`);
          info.el.addEventListener('mouseenter', () => this.highlightPartyEvents(token));
          info.el.addEventListener('mouseleave', () => this.removeHighlightPartyEvents(token));
        },

        initialView: this.view,
        events: this.events,
        initialDate: this.currentDate,
      }
    },

  },
  methods: {

    highlightPartyEvents(token) {
      document.querySelectorAll(`.E${token}`).forEach(el => {
        el.classList.add('highlight');
      });
    },

    removeHighlightPartyEvents(token) {
      document.querySelectorAll(`.E${token}`).forEach(el => {
        el.classList.remove('highlight');
      });
    },

    close(){
      store.commit('DayCalendar/showDay', false);
    },

    closeQuickView(){
      store.commit('Parties/Party/view', false);
    },

    async onDatesSet(info) {
      store.commit('DayCalendar/payload', {start: getDBTime(info.start), end: getDBTime(info.end)});
      await store.dispatch('DayCalendar/load');
    },

    async onEventClick({event}) {
      await store.dispatch('Parties/Party/load', event.extendedProps.token);
      store.commit('Parties/Party/view', true);
    },

    calendar(){
      return this.$refs.dayCal.getApi();
    },

    goToToday() {
      this.calendar().today();
    },

    goToDate(){
      this.calendar().gotoDate(this.currentDate);
    }
  }
};
</script>

<style lang="scss">


</style>

import {make} from 'vuex-pathify';
import Bus from "@/bus";
import {formatMoney} from "accounting-js";
import Config from '@/config';

const state = {

  amount: 0.00,

  cardNumber: '',
  month: '',
  year: '',
  cardCode: '',
  fullName: '',

  address: '',
  city: '',
  state: '',
  zip: '',

  advanced: false,
  loading: false,
};

const initialState = {...state};

const getters = make.getters(state);

const mutations = {
  ...make.mutations(state),

  resetState(state) {
    Object.assign(state, initialState);
  }
};

const actions = {

  async createCustomerProfile({state}, customerData) {
    try {
      let cardData = getCardData(state);
      let profileData = {...cardData, ...customerData};

      // Request Accept.js to tokenize card data
      let response = await new Promise((resolve, reject) => {
        window.Accept.dispatchData(
          {
            authData: {
              clientKey: Config.clientKey,
              apiLoginID: Config.apiLoginID
            },
            cardData: profileData
          },
          (res) => {
            if (res.messages.resultCode === "Ok") {
              resolve(res);
            } else {
              reject(new Error(res.messages.message[0].text));
            }
          }
        );
      });

      // Prepare API request payload
      let payload = {
        nonce: response.opaqueData.dataDescriptor,
        dataValue: response.opaqueData.dataValue,
        ...profileData
      };

      // Call API to create a customer profile with payment details
      return await api.url('/payments/create-profile').post(payload).json();

    } catch (error) {
      console.error("Error creating customer profile:", error);
      throw error;
    }
  },


  async chargeStoredProfile({state}, card) {
    let invoice = store.get('Parties/Party/token');

    let payload = {
      profile_id: card.customer_profile_id,
      payment_profile_id: card.payment_profile_id,
      amount: state.amount,
      invoice: invoice
    };

    try {
      let response = await api.url('/payments/charge-profile').post(payload).json();

      if (response.transactionStatus === 'declined') {
        Bus.$emit('authorize.payment-declined');
        store.dispatch('Interactions/create', `Declined Payment: ${formatMoney(response.amount)} | Transaction ID:${response.transId}`);
      } else {
        Bus.$emit('authorize.payment-success');
        store.dispatch('Interactions/create', `Payment: ${formatMoney(response.amount)} | Transaction ID:${response.transId}`);
      }
    } catch (e) {
      Bus.$emit('authorize.payment-error', e);
    } finally {
      store.dispatch('Parties/Party/reload');
    }
  },

  submitPayment({dispatch, state}) {
    let addressData = {
      "address": state.address,
      "city": state.city,
      "state": state.state,
      "zip": state.zip
    };
    let cardData = {
      "cardNumber": state.cardNumber,
      "month": state.month,
      "year": state.year,
      "cardCode": state.cardCode,
      "fullName": state.fullName
    }
    if(state.advanced){
      cardData = {
        ...cardData,
        ...addressData
      }
    }
    window.Accept.dispatchData({
      authData: {
        clientKey: Config.clientKey,
        apiLoginID: Config.apiLoginID
      },
      cardData
    }, (response) => {
      console.log('resultCode:'+response.messages);

      if (response.messages.resultCode === "Ok") {
        dispatch('processPayment', response.opaqueData);
      } else {
        throw new Error(response.messages.message[0].text);
      }
    });
  },

  async processPayment({state}, {dataDescriptor, dataValue}) {

    let invoice = store.get('Parties/Party/token');

    let payload = {
      nonce: dataDescriptor,
      dataValue: dataValue,
      amount: state.amount,
      invoice: invoice
    };

    try {
      let response = await api.url('/payments/process').post(payload).json();

      if (response.transactionStatus === 'declined') {
        Bus.$emit('authorize.payment-declined');
        store.dispatch('Interactions/create', `Declined Payment: ${formatMoney(response.amount)} | ${response.accountType} ${response.accountNumber} | Transaction ID:${response.transId}`);
      } else {
        Bus.$emit('authorize.payment-success');
        store.dispatch('Interactions/create', `Payment: ${formatMoney(response.amount)} | ${response.accountType} ${response.accountNumber} | Transaction ID:${response.transId}`);
      }
    } catch (e) {
      Bus.$emit('authorize.payment-error', e);
    } finally {
      store.dispatch('Parties/Party/reload');
    }
  },

  async associatePayment(obj, transaction) {
    let response = await api.url('/payments/save-associated').post(transaction).json();
    Bus.$emit('authorize.payment-success');
    //if payment is unsuccessful dont create interaction
    store.dispatch('Interactions/create', `Payment: ${formatMoney(response.amount)} | ${response.accountType} ${response.accountNumber} | Transaction ID:${response.transId}`);
    store.dispatch('Parties/Party/reload');
  },

  async getCustomerCards(obj, id){
    return await api.url(`/payments/cards/${id}`).get().json();
  },

  async deleteCard(obj, id){
    return await api.url(`/payments/cards/${id}`).delete().res();
  }

};

function getCardData(state) {
  let addressData = {
    "address": state.address,
    "city": state.city,
    "state": state.state,
    "zip": state.zip
  };
  let cardData = {
    "cardNumber": state.cardNumber,
    "month": state.month,
    "year": state.year,
    "cardCode": state.cardCode,
    "fullName": state.fullName
  };
  return (state.advanced) ? {...cardData, ...addressData} : cardData;
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

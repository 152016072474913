<template>
  <bills-gigs-invoice-context-menu ref="gcm" @hide="rtClickSelectedRow = null" @reload="onReload" class="w-2" :selected-row="rtClickSelectedRow" />
  <div class="w-full h-full flex justify-content-center align-content-center">
    <section v-if="loading">
      <progress-spinner class="h-full"/>
    </section>
    <section v-else class="w-full">
      <DataTable class="fadein animation-duration-1000"
                 show-gridlines striped-rows size="small"
                 :value="gigs"
                 :rows="10"
                 data-key="id"

                 selection-mode="multiple"
                 v-model:selection="selected"

                 scrollable
                 scrollHeight="60vh"
                 @row-dblclick="onRowDblClick"

                 contextMenu
                 v-model:contextMenuSelection="rtClickSelectedRow"
                 @contextmenu="showMenu"
      >

        <template #empty>
          No unpaid gigs found. Other Unpaid items may be in other bills.
        </template>

        <Column selectionMode="multiple" class="w-2rem"></Column>
        <Column field="start_time" header="Date"  :sortable="true">
          <template #body="{data}">
            <span v-if="data.startTime">{{ formatDate(data.start_time) }}</span>
            <span v-else>{{ formatDate(data.party.date) }}</span>
          </template>
        </Column>
        <Column field="misc" header="Gig" class="w-4">
          <template #body="{data}">
            <span v-if="data.character">{{ data.character.name }}</span>
            <span v-else-if="data.addon">{{ data.addon.title }}</span>
            <span v-else>{{ data.misc }}</span>
          </template>
        </Column>
        <column field="party.city" header="City" :sortable="true"/>
        <Column field="start_time" header="Start Time">
          <template #body="{data}">
            {{ formatTime(data.start_time) }}
          </template>
        </Column>
        <Column field="end_time" header="End Time">
          <template #body="{data}">
            {{ formatTime(data.end_time) }}
          </template>
        </Column>
        <Column field="cost" header="Earned" class="w-1">
          <template #body="{data}">
            <invoice-money-field :data="data" field="cost" @reload="onReload"/>
          </template>
        </Column>
      </DataTable>
    </section>
  </div>
</template>

<script>
import {formatMoney} from "accounting-js";
import {formatTime} from "@/helpers/time.helper";
import {formatDate} from "@/helpers/date.helper";
import {sync} from "vuex-pathify";
import InvoiceMoneyField from "@/components/events/edit/invoice/invoice-money-field.vue";
import BillsGigsInvoiceContextMenu from "@/components/team/bills/bills-gigs-invoice-context-menu.vue";
import ExpensesContextMenu from "@/components/team/expenses/expenses-context-menu.vue";

export default {
  name: "bills-gigs-table",
  components: {ExpensesContextMenu, BillsGigsInvoiceContextMenu, InvoiceMoneyField},
  emits: ['reload'],
  data() {
    return {
      gigs: [],
      loading: false,
      rtClickSelectedRow: null,
    };
  },

  mounted(){
    this.load();
  },

  computed: {

    bill_id() {
      return store.get('Bills/Bill/id');
    },

    reloadKey: sync('Bills/reloadKey', null, false),

    selected: sync('Bills/Bill/items', null, false),

    employee(){
      return store.get('Employees/current');
    }
  },

  methods: {

    showMenu(event) {
      this.$refs.gcm.show(event);
    },

    ...{formatTime, formatMoney, formatDate},

    async onReload(){
      this.reloadKey++;
      store.dispatch('Bills/loadBill', this.bill_id);
    },

    async load(){
      this.loading = true;
      const unpaid = await store.dispatch('Gigs/getUnpaidByEmployee', {employee_id: this.employee.id, bill_id: this.bill_id});
      this.gigs = this.getGigsNotInOtherBills(unpaid);
      store.commit('Bills/Bill/gigsCount', this.gigs.length);
      this.loading = false;
    },

    getGigsNotInOtherBills(gigs){
      return gigs.filter(item => !item.bill_id || item.bill_id === this.bill_id);
    },

    onRowDblClick(event) {
      let party = event.data?.party;
      if (!party) return;

      store.dispatch('Parties/Party/load', party.token);
      store.commit('Parties/Party/view', true);
    }
  },
}
</script>

import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);
dayjs.extend(utc);

export function partyDate(date) {
  let datetime = dayjs(date);
  return datetime.isValid() ? datetime.format("MM/DD/YY ddd @ h:mma").toLowerCase() : '-';
}

export function emailPartyDate(date) {
  let datetime = dayjs(date);
  return datetime.isValid() ? datetime.format("ddd MM/DD/YYYY @ h:mma"): '-';
}

export function partyDateShort(date) {
  let datetime = dayjs(date);
  return datetime.isValid() ? datetime.format("MM/DD/YY, ddd") : '-';
}

export function formatDate(date) {
  let datetime = dayjs(date);
  return datetime.isValid() ? datetime.format("MM/DD/YY") : '-';
}

export function formatWordDate(date) {
  let datetime = dayjs(date);
  return datetime.isValid() ? datetime.format("MMM D, YYYY") : '-';
}

export function formatWordDateWithDay(date) {
  let datetime = dayjs(date);
  return datetime.isValid() ? datetime.format("MMM D, YYYY ddd") : '-';
}

export function formatDateTime(date) {
  let datetime = dayjs(date);
  return datetime.isValid() ? datetime.format("MM/DD/YY h:mma") : '-';
}

export function relativeDate(date){
  return dayjs(date).fromNow();
}

<template>
  <Dropdown v-model="selection" :options="viewItems" @change="onChange"
            :panel-class="isDayCalendar?'h-14rem':'h-17rem'"
            :pt="{wrapper:{style:{height: isDayCalendar?'17rem':'20rem', maxHeight: isDayCalendar?'17rem':'20rem'}}}">
    <template #value="{ value, placeholder }">
      <div v-if="value">
        <span :class="value.icon"></span>
        <span class="ml-2">{{ value.label }}</span>
      </div>
      <div v-else>{{ placeholder }}</div>
    </template>
    <template #option="{ option }">
      <div>
        <span :class="option.icon"></span>
        <span class="ml-2">{{ option.label }}</span>
      </div>
    </template>
  </Dropdown>
</template>

<script>
import {find, filter} from 'lodash';

export default {
  name: "calendar-view-filter",
  props: {
    'calendar': {
      type: String,
      default: 'Calendar',
      validator(value) {
        return ['Calendar', 'DayCalendar'].includes(value);
      },
    }
  },

  created() {

    if (this.isDayCalendar) {
      this.selection = find(this.viewItems, {label: 'Day'});
      this.viewItems = filter(this.viewItems, (item) => {
        return item.label !== 'Month' && item.label !== 'Year';
      })
    } else {
      this.selection = find(this.viewItems, {label: 'Month'});
      this.viewItems = filter(this.viewItems, (item) => {
        return item.label !== 'Day'
      })
    }
  },

  data() {
    return {
      selection: null,
      viewItems: [
        {
          label: 'Day',
          icon: 'pi pi-calendar',
          value: 'timeGridDay'
        },
        {
          label: 'Week',
          icon: 'pi pi-calendar',
          value: 'timeGridWeek'
        },
        {
          label: 'Month',
          icon: 'pi pi-calendar',
          value: 'dayGridMonth'
        },
        {
          label: 'Year',
          icon: 'pi pi-calendar',
          value: 'multiMonthYear'
        },
        {
          label: 'List Week',
          icon: 'pi pi-list',
          value: 'listWeek'
        },
        {
          label: 'List Month',
          icon: 'pi pi-list',
          value: 'listMonth'
        },
        {
          label: 'List Year',
          icon: 'pi pi-list',
          value: 'listYear'
        },
      ],
    };
  },
  computed:{
    isDayCalendar(){
      return this.calendar === 'DayCalendar';
    }
  },
  methods: {
    onChange({value}) {
      this.selection = value;
      store.commit(`${this.calendar}/view`, value.value);
    }
  }
}
</script>

<style scoped>
/* Add styles for your icons and dropdown items here if needed */
</style>

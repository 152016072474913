<template>
  <section v-if="loading" class="flex justify-content-center align-content-center h-30rem">
    <progress-spinner class="h-full"/>
  </section>
  <section v-else class="fadein animation-duration-500">
    <div class="flex justify-content-end flex-wrap">
      <div class="flex-1">

        <h1 class="text-600 text-4xl pb-0 mb-0">Done Partying</h1>
        <p>Parties are all over and Serviced. Time to pay our people then put these parties to archive.</p>
      </div>
      <div class="flex justify-content-end">
        <SelectButton :options="filterOptions" v-model="selectedFilter"/>
      </div>
    </div>

    <employee-payup-table :loading="loading" :parties="parties" v-if="selectedFilter==='Show Staff'"/>
    <PartiesTable :parties="parties" @update-status="updateStatus" v-else :loading="loading" show-money/>
  </section>
</template>

<script>
import PartiesTable from "@/components/events/tables/parties-table.vue";
import EmployeePayupTable from "@/components/employees/employee-payup-table.vue";

export default {
  name: "TabDone",
  components: {
    EmployeePayupTable,
    PartiesTable,
  },
  data() {
    return {
      selectedFilter: 'Show Parties',
      filterOptions: ['Show Parties', 'Show Staff']
    }
  },
  computed: {
    loading() {
      return store.get('Parties/loading');
    },
    parties() {
      return store.get('Parties/serviced');
    },
  },
  methods: {
    updateStatus(payload) {
      store.dispatch("Parties/updateStatus", payload)
    },
  },
};
</script>

<style scoped>
</style>

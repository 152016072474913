<template>
  <section id="main-container">
    <main-menu-bar></main-menu-bar>
    <div class="flex flex-column surface-ground overflow-y-auto" style="height:87vh;">
      <div class="p-5 flex flex-column flex-auto surface-ground">
        <section>
          <TabView>
            <TabPanel header="Open Transactions">
              <transaction-table></transaction-table>
            </TabPanel>
            <TabPanel header="Authorize Unsettled">
              <unsettled-transaction-table></unsettled-transaction-table>
            </TabPanel>
          </TabView>
        </section>
      </div>
    </div>
    <quick-views/>
    <modals/>
    <toasts/>
    <confirms/>
  </section>
</template>

<script>
import UnsettledTransactionTable from "@/components/banking/unsettled-transaction-table.vue";
import TransactionTable from "@/components/banking/transaction-table.vue";
import MainMenuBar from "@/components/main-menu/main-menu-bar.vue";
import Toasts from "@/components/common/toasts.vue";
import QuickViews from "@/components/common/quick-views.vue";
import Confirms from "@/components/common/confirms.vue";
import Modals from "@/components/common/modals.vue";
import EventsList from "@/components/events/list/events-list.vue";
export default {
  name: "banking",
  components: {
    EventsList,
    Modals,
    Confirms,
    QuickViews,
    Toasts,
    MainMenuBar, TransactionTable, UnsettledTransactionTable},
}
</script>

<style scoped lang="scss">
#main-container {
  max-width: 1600px;
  overflow: auto;
  margin: 0 auto;
}
</style>

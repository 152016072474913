<template>
  <div :class="`bg${color}-100 border-1 border${color}-300 text${color}-600 p-3 flex justify-content-start border-round mb-3`"
       v-if="lastInteraction" :title="`Last interaction was ${daysSinceLastInteraction} days ago`">
    <div class="align-content-center pr-3">
      <i class="pi pi-book"></i>
    </div>
    <div class="flex-grow-1">
      <div class="flex justify-content-between w-full">
        <strong>Last Interaction: {{ lastInteraction.title }}</strong>
        <strong>{{ formatDateTime(lastInteraction.created_at) }}</strong>
      </div>
      <div class="w-full">{{ truncateString(stripHtml(notes), 120) }}</div>
    </div>
  </div>
</template>

<script>
import {formatDateTime} from "@/helpers/date.helper";
import dayjs from "dayjs";

export default {
  name: "last-interaction-message",
  computed: {
    party(){
      return store.get('Parties/Party/current');
    },
    isBooking(){
      return this.party.status === 'booking' || this.party.status === 'inquiry';
    },
    lastInteraction() {
      return this.party?.interactions[0];
    },
    notes(){
      return this.lastInteraction.notes ? this.lastInteraction.notes : '';
    },
    daysSinceLastInteraction(){
      return this.lastInteraction ? dayjs(dayjs()).diff(this.lastInteraction.created_at, 'day') : 0;
    },
    color(){

      if(!this.isBooking)
        return '-gray';

      if(this.daysSinceLastInteraction < 3){
        return '-blue';
      }
      if(this.daysSinceLastInteraction > 3 && this.daysSinceLastInteraction <= 10){
        return '-yellow';
      }
      if(this.daysSinceLastInteraction > 10){
        return '-red';
      }

      return '-gray';
    }
  },
  methods: {
    formatDateTime,
    stripHtml(html) {
      const doc = new DOMParser().parseFromString(html, 'text/html');
      return doc.body.textContent || "";
    },
    truncateString(str, num) {
      if (str.length <= num) {
        return str;
      }
      return str.slice(0, num) + '...';
    },
  },
}
</script>

<style scoped lang="scss">

</style>

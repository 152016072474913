export function getStatus(label){
  if(!label) return '';
  switch (label.toLowerCase()) {
    case 'new':
    case 'inquiry':
      return 'inquiry';
    case 'booking':
      return 'booking';
    case 'complete':
      return 'complete';
    case 'serviced':
      return 'serviced';
    case 'cancelled':
      return 'cancelled';
    case 'credit':
      return 'credit';
    default:
      return '';
  }
}

export const statusOptions = [
  {name: "New", value: "inquiry"},
  {name: "Booking", value: "booking"},
  {name: "Complete", value: "complete"},
  {name: "Serviced", value: "serviced"},
  {name: "Archive", value: "archived"},
  {name: "Cancel", value: "cancelled"},
  {name: "Credit", value: "credit"},
]

export function getStatusLabel(status){
  if(!status) return 'new';
  switch (status.toLowerCase()) {
    case 'new':
    case 'inquiry':
      return 'new';
    case 'booking':
      return 'booking';
    case 'complete':
      return 'complete';
    case 'serviced':
      return 'serviced';
    case 'cancelled':
      return 'cancelled';
    case 'credit':
      return 'credit';
    default:
      return '';
  }
}
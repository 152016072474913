<template>
    <section v-if="relationships">
      <div class="grid">
        <div v-for="character in relationships" :key="character.id" class="col-4">
          <CharactersCard :character="character" override-set-current></CharactersCard>
          <CharacterRelationshipContextMenu :character="character" :related-map="current.related"></CharacterRelationshipContextMenu>
        </div>
      </div>
    </section>
</template>

<script>
import CharactersCard from "@/components/characters/cards/characters-card.vue";
import CharacterRelationshipContextMenu from "@/components/characters/common/character-relationship-context-menu.vue";
import { map, filter } from 'lodash';

export default {
  name: "CharacterProfileRelationships",
  components: {
    CharacterRelationshipContextMenu,
    CharactersCard
  },
  computed: {
    characters() {
      return store.get('Characters/list');
    },
    current() {
      return store.get('Characters/current');
    },
    related() {
      return map(this.current.related, (item) => item.rid);
    },
    relationships() {
      return filter(this.characters, (c) => this.related.includes(c.id));
    }
  }
}
</script>

<style scoped>

</style>
